import {HTTPMethod, RequestBase} from './request-base';

export class RequestArtiflefilesDelete extends RequestBase {

  param: {
    id: number
  };

  constructor(id: number) {
    super(HTTPMethod.DELETE, '/api/articlefiles/');
    this.param = {
      id: id
    };
  }

  // getParam(): any {
  // }

  getUrl(): string {
    return this.host + this.endPoint + this.param.id;
  }

}
