import { HTTPMethod, RequestBase } from './request-base';

export class RequestSubdivisionFilesUpload extends RequestBase {


  constructor(
    subdivision_id: number,
    subdivision_file_type: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
    file: File | '',
  ) {
    super(HTTPMethod.POST, '/api/subdivisions/import_file');
    this.param = {
      subdivision_id: subdivision_id,
      subdivision_file_type: subdivision_file_type,
      file: file,
    };
  }

  // getParam(): FormData {
  //   const formData: FormData = new FormData();
  //   Object.keys(this.param).forEach((v) => {
  //     /*if (v === 'id') {
  //       formData.append(v, '');
  //       return;
  //     }*/
  //     if (v === 'file') {
  //       formData.append(v, this.param[v]);
  //       return;
  //     }
  //     if (this.param[v] === null) {
  //       formData.append(v, '');
  //       return;
  //     }
  //     formData.append(v, this.param[v] + '');
  //   });
  //   return formData;
  // }

  getUrl(): string {
    return this.host + this.endPoint;
  }
}
