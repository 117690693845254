import {HTTPMethod, RequestBase} from './request-base';

export class RequestArticlesPostPdf_export extends RequestBase {

  param: {
    svg_data: string
  };

  constructor(svg: string) {
    super(HTTPMethod.POST, '/api/articles/pdf_export');
    this.param = {
      svg_data: svg
    };
  }

  // getParam(): FormData {
  //   const formData: FormData = new FormData();
  //   Object.keys(this.param).forEach((v) => {
  //     /*if (v === 'id') {
  //       formData.append(v, '');
  //       return;
  //     }*/
  //     if (this.param[v] === null) {
  //       formData.append(v, '');
  //       return;
  //     }
  //     formData.append(v, this.param[v] + '');
  //   });
  //   return formData;
  // }

  getUrl(): string {
    return this.host + this.endPoint;
  }

}
