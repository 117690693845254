import * as React from 'react';

export class ValidationModel {

  constructor(
    public validationList: {
      func: (value: string) => boolean,
      invalidMessage: string,
    }[],
  ) {
  }

  getValid(value: string): boolean {
    return this.validationList.map(v => v.func(value)).findIndex(v => !v) === -1;
  }

  getInvalidMessages(value: string): string[] {
    return this.validationList.filter(v => !v.func(value)).map(v => v.invalidMessage);
  }

}

type ValidationInputProps = {
  value: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  validation: ValidationModel,
};

type ValidationInputState = {
  touched: boolean,
};

export class ValidationInput extends React.Component<ValidationInputProps, ValidationInputState> {

  constructor(props: ValidationInputProps) {
    super(props);
    this.state = {
      touched: false,
    };
  }

  render() {
    const {
      value,
      onChange,
      validation,
    } = this.props;
    const {
      touched,
    } = this.state;
    return (
      <>
        <input
          className={`textInput ${validation.getValid(value) || !touched ? '' : 'invalid'}`}
          onChange={(e) => {
            onChange(e);
            this.setState({
              touched: true,
            });
          }}
          value={value}
        />
        {touched ? validation.getInvalidMessages(value).filter(v => v).map((v, i) => (
          <div
            className={`invalid-message`}
            key={i}
          >{v}</div>
        )) : <></>}
      </>
    );
  }


}
