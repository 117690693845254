/**
 * 指定の長さで文字列を分割 yone
 * @param str 対象文字列
 * @param divideLength 分割したい長さ
 * @returns 結果の配列
 */
export const divideStr = (str: string, divideLength: number) => {
  const strs: string[] = [];

  if (str.length > divideLength) {
    const len = divideLength;
    const roopLength = str.length / len;
    let min = 0;
    let max = len;

    for (let i = 0; i < roopLength; i++) {
      strs.push(str.slice(min, max));
      min += len;
      max += len;
    }
  } else {
    strs.push(str);
  }

  return strs;
}