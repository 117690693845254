import * as React from 'react';
import { ResponseError } from '../model/api/response/response-error';
import { hasOwnProperty } from 'tslint/lib/utils';
import { ModalService } from './modal-service';
import { ModalAlertComponent } from '../component/common/modal/modal-alert/modal-alert-component';
import { ModalFuelErrorComponent } from '../component/common/modal/modal-fuel-error/modal-fuel-error-component';

export class ErrorService {

  private static _ins: ErrorService;

  private constructor() { }

  static get ins(): ErrorService {
    if (!this._ins) {
      this._ins = new ErrorService();
    }
    return this._ins;
  }

  response(err: ResponseError) {
    if (!err) {
      this.connection(err);
      return;
    }
    if (!hasOwnProperty(err, 'data')) {
      this.connection(err);
      return;
    }
    if (err.data && !hasOwnProperty(err.data, 'error')) {
      this.connection(err);
      return;
    }
    if (err.data && +err.data.error.code === 4010 && err.data.error.developer_message === "unauthenticated") {
      // if (ModalService.ins.getModalNum() > 0) return;
      ModalService.ins.close();
      ModalService.ins.push(
        <ModalAlertComponent
          msgList={['セッションが無効です。', 'ログイン画面に移動します。']}
          callback={() => {
            window.location.href = window.location.origin + '#/login';
            ModalService.ins.close();
          }}
        />
      );
      return;
    }
    ModalService.ins.push(
      <ModalAlertComponent
        msgList={err.data ? err.data.error.messages : []}
        callback={() => {
          ModalService.ins.pop();
        }}
      />
    );
  }

  private connection(err: any) {
    let isFuelError: boolean = false;
    if (err && hasOwnProperty(err, 'data') && (err.data + '').match('<!DOCTYPE html>')) {
      isFuelError = true;
    }
    if (isFuelError) {
      ModalService.ins.push(
        <ModalFuelErrorComponent
          htmlStr={err['data']}
          callback={() => {
            ModalService.ins.pop();
          }}
        />
      );
    } else {
      ModalService.ins.push(
        <ModalAlertComponent
          msgList={['ネットワークの接続状況を確認してください。', `error : ${err}`]}
          callback={() => {
            ModalService.ins.pop();
          }}
        />
      );
    }
  }

}
