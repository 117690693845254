import * as React from 'react';
import { ReactElement } from 'react';
import { DateFormat, DateFormatter } from '../../../util/date-formatter';
import { Article } from '../../../model/article';
import './article-detail-component.scss';
import { HeaderComponent } from '../../common/header/header-component';
import { SideMenuComponent } from '../../common/side-menu/side-menu-component';
import { ModalService } from '../../../service/modal-service';
import { ModalReservationListComponent } from './modal-reservation-list/modal-reservation-list-component';
import { ModalDocumentComponent } from './modal-document/modal-document-component';
import { ModalReleaseComponent } from './modal-release/modal-release-component';
import { ModalShareComponent } from './modal-share/modal-share-component';
import { RequestArticlesPost } from '../../../model/api/request/request-articles-post';
import { ConnectionService } from '../../../service/connection-service';
import { ErrorService } from '../../../service/error-service';
import { Prefecture } from '../../../model/prefecture';
import { City } from '../../../model/city';
import { User } from '../../../model/user';
import { Subdivision } from '../../../model/subdivision';
import { DataManagerService } from '../../../service/data-manager-service';
import { School } from '../../../model/school';
import { RequestArticlesGetOne } from '../../../model/api/request/request-articles-get-one';
import { ResponseArticlesGetOne } from '../../../model/api/response/response-articles-get-one';
import { DatePickerComponent } from '../../common/date-picker/date-picker-component';
import { ArticleInfo } from '../../../model/articleInfo';
import { RequestArticleinformationsGetSearch } from '../../../model/api/request/request-articleinformations-get-search';
import { RequestArticleinformationsPost } from '../../../model/api/request/request-articleinformations-post';
import { RoutingPass } from '../../../constant/routing-pass';
import { RequestBase } from '../../../model/api/request/request-base';
import { RequestArticlesintroducedGetOne } from '../../../model/api/request/request-articlesintroduced-get-one';
import { ArticleIntroduced } from '../../../model/articleIntroduced';
import { RequestArticlesintroducedPost } from '../../../model/api/request/request-articlesintroduced-post';
import { UserService } from '../../../service/user-service';
import { ModalAlertComponent } from '../../common/modal/modal-alert/modal-alert-component';
import iconUnReservedStatus from '../../../img/detail/calendar_1.png';
import iconReservedStatus from '../../../img/detail/calendar_2.png';
import iconCheckBoxChecked from '../../../img/common/checkbox_checked.png';
import iconCheckBox from '../../../img/common/checkbox.png';
import { ModalDialogComponent } from '../../common/modal/modal-dialog/modal-dialog-component';
import { ModalUserPickerComponent } from './modal-user-picker/modal-user-picker-component';
import iconSearch from '../../../img/common/icon_search.png';
import iconSearchOn from '../../../img/common/icon_search_on.png';
import thumbNoImage from '../../../img/detail/thumb_noimage.jpg';
import thumbPdf from '../../../img/detail/thumb_pdf.png';
import { MoneyFormatter } from '../../../util/money-formatter';
import { ImgLoaderComponent } from './img-loader/img-loader-component';
import { ArticleLinkedFile } from '../../../model/article-linked-file';
import { RequestArticlesPostImport_file } from '../../../model/api/request/request-articles-post-import_file';
import { SvgManager } from '../../../manager/svg/svg-manager';
import { RequestArticlesPostPdf_export } from '../../../model/api/request/request-articles-post-pdf_export';
import { RequestArticlesLinked_fileGet } from '../../../model/api/request/request-articles-linked_file-get';
import { BusyComponent } from '../../common/busy/busy-component';
import NoImg2 from '../../../img/detail/NoImg2.jpg';
import NoImg1 from '../../../img/detail/NoImg1.jpg';
import IconLink from '../../../img/common/link_icon.svg';
import { ValidationComponent } from './validation/validation-component';
import { validationArticleDetail } from '../../../model/validation';
import { ElementId } from '../../../constant/element-id';
import { RequestArticlesPostStopUrl } from '../../../model/api/request/request-articles-post-stop_url';
import { ModalReleaseUrlComponent } from '../../common/modal/modal-alert/modal-release-url-component';
import { ImagesLoaderComponent } from './images-loader/images-loader-component';
import { promises } from 'fs';
import { DispatchToProps, StateToProps, Store } from '../../../redux/store';
import { SystemActions } from '../../../redux/system/system.action';
import { connect } from 'react-redux';
import { State } from '../../../redux/root.reducer';
import { Action } from 'typescript-fsa';
import { ModalSubdivisionPickerComponent } from '../master-subdivision/modal-subdivision-picker/modal-subdivision-picker-component';
import { ModalCopyComponent } from './modal-copy/modal-copy-component';
import { push } from 'connected-react-router';

interface ArticleDetailComponentProps {
  match: {
    params: {
      id: string;
    };
  },
  history: any;
  refreshArticle: () => void;
  setIsBusy: (v: boolean) => void;
  dispatch: (v: any) => void;
  isAdmin: boolean;
}

interface ArticleDetailComponentState {
  me: User | null;
  id: number | null;
  isNew: boolean;
  isEdit: boolean;
  isBeforeLoad: boolean;
  isIntroduced: boolean;
  article: any;
  //
  prefectureList: Prefecture[];
  cityList: City[];
  userList: User[];
  primarySchoolList: School[];
  middleSchoolList: School[];
  //
  infoList: ArticleInfo[];
  editedInfoList: ArticleInfo[];
  infoInput: string;
  //
  sales_month: number;
  //
  route: string, //最寄り路線
  station: string, //最寄り駅
  privateArea: string, //私道面積
  privateUnit: string, //私道坪数
  tsuboPrice: string, //坪単価
  traffic_route: string, //交通_路線
  traffic_station: string, //交通_駅
  traffic_minute: string, //交通_徒歩○分
  landRight: number, //土地権利
  ground: number, //地目
  cityPlanning: string, //都市計画
  useDistrict: number, //土地用途
  coverageRatioInput: string; //建ぺい率
  floorAreaRatioInput: string; //容積率
  legalAuthorityInput: string; //法令n上の権限
  presentStatusInput: string; //現況
  approachSituationInput: string; //接道状況
  facilityInput: string; //設備
  remarksInput: string; //備考
  //
  imageList: ArticleLinkedFile[];
  subdivisionList: Subdivision[];
}

class ArticleDetailBodyComponent extends React.Component<ArticleDetailComponentProps, ArticleDetailComponentState> {

  private toolTipMsg: string = '追加したユーザのみ編集できます。';

  private defaultArticleData = new Article(
    null,
    '',
    1,
    null,
    null,
    '',
    null,
    null,
    null,
    null,
    null,
    '',
    null,
    null,
    null,
    null,
    null,
    null,
    '',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  );

  private useDistrictList: { value: number, label: string; }[] =
    [
      { value: 100, label: '無指定' },
      { value: 1, label: '1種低層' },
      { value: 2, label: '2種低層' },
      { value: 3, label: '1種中高層' },
      { value: 4, label: '2種中高層' },
      { value: 5, label: '1種住居' },
      { value: 6, label: '2種住居' },
      { value: 7, label: '準住居' },
      { value: 8, label: '近隣商業' },
      { value: 9, label: '準工業' },
      { value: 10, label: '工業' },
      { value: 11, label: '工業専用' },
    ];

  private landRightList: { value: number, label: string; }[] = [
    { value: 1, label: '所有権' },
    { value: 2, label: '旧法地上権' },
    { value: 3, label: '旧法賃借権' },
    { value: 4, label: '普通地上権' },
    { value: 5, label: '普通賃借権' },
    { value: 6, label: '定期借地権（地上権）' },
    { value: 7, label: '定期借地権（賃借権）' },
  ];

  private salesStatusList: { value: number, label: string; }[] = [
    { value: 1, label: '買付中' },
    { value: 2, label: '契約' },
    { value: 3, label: '契約済み' },
    { value: 4, label: '申請中' },
    { value: 5, label: '造成中' },
    { value: 6, label: '販売終了' },
    { value: 7, label: '販売中' },
  ];

  private typeList: { value: number, label: string; }[] = [
    { value: 1, label: '売地' },
    { value: 2, label: '建売' },
    { value: 3, label: '分譲' },
  ];

  private landCategoryList: { value: number, label: string; }[] = [
    { value: 1, label: '宅地' },
    { value: 2, label: '田' },
    { value: 3, label: '畑' },
  ];

  private loadedFileList: File[] | (string | null)[] = [null, null, null, null, null, null, null, null, null, null, null, null];
  private loadedFileAndUrlList: { file: File, url: string; }[] = [];

  constructor(props: ArticleDetailComponentProps) {
    super(props);
    this.state = {
      me: null,
      id: null,
      isNew: false,
      isEdit: false,
      isBeforeLoad: true,
      isIntroduced: false,
      article: this.defaultArticleData, // デフォルトデータ
      //
      prefectureList: [],
      cityList: [],
      userList: [],
      primarySchoolList: [],
      middleSchoolList: [],
      // subdivisionList
      subdivisionList: [],
      //
      infoList: [],
      editedInfoList: [],
      infoInput: '',
      sales_month: 0,
      //
      route: '',
      station: '',
      privateArea: '',
      privateUnit: '',
      tsuboPrice: '',
      traffic_route: '',
      traffic_station: '',
      traffic_minute: '',
      landRight: 0,
      ground: 0,
      cityPlanning: '',
      useDistrict: 0,
      coverageRatioInput: '',
      floorAreaRatioInput: '',
      legalAuthorityInput: '',
      presentStatusInput: '',
      approachSituationInput: '',
      facilityInput: '',
      remarksInput: '',
      //
      imageList: [],
    };
  }

  render() {
    const me = this.state.me;
    const isNew: boolean = this.state.isNew;
    const isEdit: boolean = this.state.isEdit || isNew;
    const isBeforeLoad: boolean = this.state.isBeforeLoad;
    const isIntroduced: boolean = this.state.isIntroduced;
    const article: Article = this.state.article;
    const prefectureList = this.state.prefectureList;
    const cityList = this.state.cityList;
    const userList = this.state.userList;
    const primarySchoolList = this.state.primarySchoolList;
    const middleSchoolList = this.state.middleSchoolList;
    // subdivisionList
    const subdivisionList = this.state.subdivisionList;
    const infoList: ArticleInfo[] = this.state.infoList;
    const editedInfoList: ArticleInfo[] = this.state.editedInfoList;
    const infoInput: string = this.state.infoInput;
    // table body
    const tableBody = this.renderTableBodyDefault();
    const tableBodyIntroduced = this.renderTableBodyIntroduced();
    const nowDate = new Date();
    const periodStartDate = new Date(article.period_start ?? '');
    const periodEndDate = new Date(article.period_end ?? '');
    return (
      <div className={''}
      >
        {/** RealEstateList */}

        {/* --- header --- */}
        <HeaderComponent />

        <div className={'body_wrap flex_box'}>

          {/* side menu */}
          <div className={'pc menu_wrap'}>
            <SideMenuComponent />
          </div>
          <div className={`content_wrap cont_article_detail ${isEdit ? 'mode_edit' : ''}`}>
            <div className={'contentInner_wrap'}>

              {/* ------------------------------------------------------------- */}

              {/* タイトル */}
              <div className={'contentHead_wrap'}>
                <div className={'contentHeadInner_wrap flex_box flex_align_center flex_space_between'}>
                  <div className={'contentHead_text flex_box flex_align_center'}>
                    <span>{isIntroduced ? ('不動産紹介') : ('土地管理')}</span>
                    <span className={'innerBar'} />
                    <span>{isNew ? '新規登録' : '詳細画面'}</span>
                  </div>
                </div>
              </div>
              {/** contentHead_wrap */}

              {/* ------------------------------------------------------------- */}

              <div className={'btnArea flex_box flex_align_center flex_space_between'}>

                {/* ステータス */}
                <div className={'status_wrap'}>
                  {isIntroduced || isNew || isBeforeLoad ? (
                    <></>
                  ) : (
                    article.reservation_flag ? (
                      <div className={'reserved flex_box flex_align_center'}>
                        <div><img src={iconReservedStatus} /></div>
                        <div>予約中</div>
                      </div>
                    ) : (
                      <div className={'unreserved flex_box flex_align_center'}>
                        <div><img src={iconUnReservedStatus} /></div>
                        <div>未予約</div>
                      </div>
                    )
                  )}
                </div>

                {/* button */}
                <div className={'btnList_wrap flex_box flex_align_center'}>
                  {isEdit ? (
                    <>
                      <div>
                        <button
                          className={'reset btnStyle_1'}
                          onClick={() => this.handlerClickBtnCancel()}
                        >キャンセル
                        </button>
                      </div>
                      <div>
                        <button
                          className={'reset btnStyle_1'}
                          onClick={() => this.handlerClickBtnSave()}
                        >保存
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        {Number(article.type) === 3 ? (
                          <>
                            {!article.period_start || !article.period_end ?
                              (
                                <button
                                  className={'reset btnStyle_1'}
                                  onClick={() => this.handlerClickBtnRelease()}
                                >公開設定
                                </button>
                              ) : (periodEndDate < nowDate) && (article.period_start !== article.period_end) ? (
                                <button
                                  className={'reset btnStyle_1'}
                                  onClick={() => this.handlerClickBtnRefresh()}
                                  title={`${String(article.period_start).replace(/-/g, '/')} ~ ${String(article.period_end).replace(/-/g, '/')}`}
                                >公開設定
                                </button>
                              ) : (
                                <>
                                  <button
                                    className={'reset btnStyle_1'}
                                    onClick={() => this.handlerClickBtnStop()}
                                    title={`${String(article.period_start).replace(/-/g, '/')} ~ ${String(article.period_end).replace(/-/g, '/')}`}
                                  >公開停止
                                  </button>
                                </>
                              )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div>
                        <button
                          className={'reset btnStyle_1'}
                          onClick={() => this.handlerClickBtnEdit()}
                        >編集
                        </button>
                      </div>
                      {!isIntroduced ?
                        <div>
                          <button
                            className={'reset btnStyle_1'}
                            onClick={() => this.handlerClickBtnCopy()}
                          >物件コピー
                        </button>
                        </div>
                        : <></>}
                      <div>
                        <button
                          className={'reset btnStyle_1'}
                          onClick={() => this.handlerClickBtnOutput() /* add */}
                        >物件シート出力
                        </button>
                      </div>
                      <div>
                        <button
                          className={`reset btnStyle_1 ${isIntroduced ? ' disabled' : ''}`}
                          onClick={() => this.handlerClickBtnReservation()}
                        >予約管理
                        </button>
                      </div>
                      <div>
                        <button
                          className={'reset btnStyle_1'}
                          onClick={() => this.handlerClickBtnDocument()}
                        >関連書類
                        </button>
                      </div>
                      <div>
                        <button
                          className={'reset btnStyle_1'}
                          onClick={() => this.handlerClickBtnShare()}
                        >共有
                        </button>
                      </div>
                    </>
                  )}
                </div>
                {/** btnList_wrap */}

              </div>
              {/** btn_area */}

              {/* ------------------------------------------------------------- */}

              <div className={'tableArea'}>

                {/* 表 */}
                <div className={'tableInner_wrap'}>

                  {/** --------------------------------- */}

                  <div className={'flex_box'}>
                    {/* 物件名 */}
                    <div className={'padd_t_adjust'}>
                      物件名{isEdit ? (<>*</>) : (<></>)}
                    </div>
                    <div className={''}>
                      {isEdit ? (
                        <>
                          <input
                            className={'textInput'}
                            value={article.name || ''}
                            onChange={(e) => {
                              article.name = e.target.value;
                              this.setState({
                                article: article
                              });
                            }}
                          />
                          <ValidationComponent validation={validationArticleDetail.name} str={article.name || ''} />
                        </>
                      ) : (
                        <>{article.name}</>
                      )}
                    </div>
                  </div>

                  {/** --------------------------------- */}
                  {/** テーブルのボディ */}
                  {isIntroduced ? (
                    /* 不動産 */
                    tableBodyIntroduced
                  ) : (
                    /* 普通 */
                    tableBody
                  )}
                  {/** --------------------------------- */}

                  {!isNew && !isIntroduced ? (
                    <div className={'flex_box salesSituation'}>
                      {/* 当月販売状況 */}
                      <div className={''}>
                        当月販売状況
                      </div>
                      <div>
                        {isEdit ? (
                          <>
                            <textarea
                              className={'textInput'}
                              style={{
                                height: '100%',
                                maxHeight: '100%',
                                maxWidth: '100%',
                                minHeight: '100%',
                                minWidth: '100%',
                              }}
                              onChange={(e) => {
                                this.setState({
                                  infoInput: e.target.value,
                                });
                              }}
                              value={infoInput}
                            >
                            </textarea>
                          </>
                        ) : (
                          <>
                            {infoInput.split('\n').map((v, i) => (
                              <div
                                key={v + '_' + i}
                                style={{ minHeight: 'calc(1rem + 5px)' }}
                              >
                                {v ? v : '　'}
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {isIntroduced ? (
                    <div className={'flex_box'}>
                      {/* 備考 */}
                      <div className={'padd_t_adjust'}>
                        備考
                      </div>
                      <div>
                        {isEdit ? (
                          <input
                            className={'textInput max_w_8'}
                            value={article['remarks'] || ''}
                            type={''}
                            onChange={(e) => {
                              article['remarks'] = e.target.value || null;
                              this.setState({
                                article: article
                              });
                            }}
                          />
                        ) : (
                          <>{article['remarks']}</>
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {/** --------------------------------- */}

                </div>
                {/** table_wrap */}

              </div>
              {/** tableArea */}

              {/* ------------------------------------------------------------- */}

            </div>
            {/** contentInner_wrap */}
          </div>
          {/** content_wrap */}

        </div>
        {/** body_wrap */}
      </div>
    );
  }

  private renderTableBodyDefault(): ReactElement<any> {
    const me = this.state.me;
    const isNew: boolean = this.state.isNew;
    const isEdit: boolean = this.state.isEdit || isNew;
    const isBeforeLoad: boolean = this.state.isBeforeLoad;
    const isIntroduced: boolean = this.state.isIntroduced;
    const article: Article = this.state.article;
    const prefectureList = this.state.prefectureList;
    const cityList = this.state.cityList;
    const userList = this.state.userList;
    const primarySchoolList = this.state.primarySchoolList;
    const middleSchoolList = this.state.middleSchoolList;
    // subdivisionList
    const subdivisionList = this.state.subdivisionList;
    const infoList: ArticleInfo[] = this.state.infoList;
    const editedInfoList: ArticleInfo[] = this.state.editedInfoList;
    const infoInput: string = this.state.infoInput;
    const {
      route,
      station,
      privateArea,
      privateUnit,
      tsuboPrice,
      traffic_route,
      traffic_station,
      traffic_minute,
      landRight,
      ground,
      cityPlanning,
      useDistrict,
      coverageRatioInput,
      floorAreaRatioInput,
      legalAuthorityInput,
      presentStatusInput,
      approachSituationInput,
      facilityInput,
      remarksInput,
      imageList,
    } = this.state;
    // 現状写真1~10
    const imgUrlList: string[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(v => this.getLinkedFileUrl(v as any, imageList));
    // 地図
    const img11: string = this.getLinkedFileUrl(11, this.state.imageList);
    const pdf: string = this.getLinkedFileUrl(12, this.state.imageList);
    const pdfFileName: string | undefined = this.state.imageList.find((v) => +v.article_file_type === 12) ?
      this.state.imageList?.find((v) => +v.article_file_type === 12)?.name : '';

    return (
      <>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 販売状況 */}
          <div className={'padd_t_adjust'}>
            販売状況{isEdit ? (<>*</>) : (<></>)}
          </div>
          <div className={''}>
            {isEdit ? (
              <select
                className={'textInput max_w_8'}
                value={article.sales_status || 1}
                onChange={async (e) => {
                  article.sales_status = Number(e.target.value);
                  this.setState({
                    article: article,
                  });
                }}
              >
                {/*<option value={''}>未選択</option>*/}
                {this.salesStatusList.map((v) => (
                  <option
                    value={v.value}
                    key={v.value}
                    disabled={
                      Boolean(
                        v.value === 6 && this.state.me?.group_id && (
                          article.user_id !== this.state.me?.id &&
                          +this.state.me?.group_id !== 1
                        ))
                    }
                  >
                    {v.label}
                  </option>
                ))}
              </select>
            ) : (
              <>
                {
                  article.sales_status ?
                    this.salesStatusList.find((v) => +v.value === +(article.sales_status ?? ''))?.label
                    : ''}
              </>
            )}
          </div>
        </div>

        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 種別 */}
          <div className={'padd_t_adjust'}>
            種別{isEdit ? (<>*</>) : (<></>)}
          </div>
          <div className={'item_type'}>
            {isEdit ? (
              <select
                className={'textInput max_w_8'}
                value={article.type || 0}
                onChange={async (e) => {
                  article.type = Number(e.target.value) || null;
                  this.setState({
                    article: article,
                  });
                }}
              >
                <option value={''}>未選択</option>
                {this.typeList.map((v) => (
                  <option value={v.value} key={v.value}>{v.label}</option>
                ))}
              </select>
            ) : (
              <div className={'flex_box flex_align_center'}>
                {this.typeList.find((v) => +v.value === +(article?.type ?? '')) ? (
                  <>
                    {Number(article.type) === 3 ? (
                      <>
                        {/*<div className={''}>{this.typeList.find((v) => +v.value === +article.type).label}</div>*/}
                        {/*<div className={''}>WEB予約 {article.reserve_count}件 <div className={'minBR'} />{article.area_count}/{article.area_max} 区分</div>*/}
                        <div className={''}>{this.typeList.find((v) => +v.value === +(article?.type ?? ''))?.label}</div>
                        <div className={''}>WEB予約 {article.reserve_count}件 <div
                          className={'minBR'} />{article.area_count}/{article.area_max} 区分
                        </div>
                      </>
                    ) : (this.typeList.find((v) => +v.value === +(article?.type ?? ''))?.label)}
                  </>
                ) : ''}
              </div>
            )}
          </div>

          {/** ------------------------------------------------------------------ */}
          {/* 分譲地名 */}
          {Number(article.type) === 3 ? (
            <>
              <div className={'padd_t_adjust'}>
                分譲地名{isEdit ? (<>*</>) : (<></>)}
              </div>
              <div className={'flex_box flex_align_center withButton_wrap'}>
                {isEdit ? (
                  <>
                    <div className={'flex_box_child'}>
                      {+(article?.reserve_count ?? '') === 0 ? (
                        <></>
                      ) : (
                        <div className={'filterEle'} />
                      )}
                      <select
                        className={'textInput max_w_8'}
                        value={article.subdivision_id || 0}
                        onChange={async (e) => {
                          article.subdivision_id = Number(e.target.value) || null;
                          this.setState({
                            article: article,
                          });
                        }}
                      >
                        <option value={''}>未選択</option>
                        {subdivisionList.map((v) => (
                          <option value={v.id ?? ''} key={v.id}>{v.name}</option>
                        ))}
                      </select>
                    </div>
                    {+(article?.reserve_count ?? '') === 0 ? (
                      <>
                        <div>
                          <button
                            className={'reset btnStyle_1'}
                            onClick={() => this.handlerClickBtnSubdivisionPicker((subdivisionId) => {
                              article.subdivision_id = Number(subdivisionId) || null;
                              this.setState({ article: article });
                            })}
                          >
                            <img className={'iconSearch'} src={iconSearch} alt={''} />
                            <img className={'iconSearchOn'} src={iconSearchOn} alt={''} />
                          </button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (

                  <>
                    {subdivisionList.find((v) => +(v.id ?? '') === +(article?.subdivision_id ?? '')) ? (
                      this.props.isAdmin ? <>
                        <button type="button" className="reset flex_align_center textLink" onClick={() => {
                          this.props.dispatch(push(RoutingPass.SUBDIVISION_DETAIL_COMPONENT + `/${article.subdivision_id}`));
                        }}>
                          {subdivisionList.find((v) => +(v.id ?? '') === +(article?.subdivision_id ?? ''))?.name}
                          <img className="img_icon" src={IconLink} alt="テキストリンク" />
                        </button>
                      </> : <>
                        {subdivisionList.find((v) => +(v.id ?? '') === +(article?.subdivision_id ?? ''))?.name}
                      </>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </div>
            </>
          ) : (
            <></>
          )}


        </div>

        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 都道府県 */}
          <div className={'padd_t_adjust'}>
            都道府県{isEdit ? (<>*</>) : (<></>)}
          </div>
          <div>
            {isEdit ? (
              <select
                className={'textInput max_w_8'}
                value={article.prefecture_id || 0}
                onChange={async (e) => {
                  article.prefecture_id = Number(e.target.value) || null;
                  article.city_id = null;
                  article.m_school_id = null;
                  article.p_school_id = null;
                  this.setState({
                    article: article,
                    cityList: article.prefecture_id ? await DataManagerService.ins.getCityList(article.prefecture_id) : [],
                  });
                }}
              >
                <option value={''}>未選択</option>
                {prefectureList.map((v) => (
                  <option value={v.id} key={v.id}>{v.name}</option>
                ))}
              </select>
            ) : (
              <>
                {prefectureList.find((v) => +v.id === +(article?.prefecture_id ?? '')) ? (
                  prefectureList.find((v) => +v.id === +(article?.prefecture_id ?? ''))?.name
                ) : (
                  ''
                )}
              </>
            )}
          </div>
          {/* 市区町村 */}
          <div className={'padd_t_adjust'}>
            市区町村{isEdit ? (<>*</>) : (<></>)}
          </div>
          <div>
            {isEdit ? (
              <select
                className={'textInput max_w_8'}
                value={article.city_id || 0}
                onChange={async (e) => {
                  article.city_id = Number(e.target.value) || null;
                  article.m_school_id = null;
                  article.p_school_id = null;
                  this.setState({
                    article: article,
                    primarySchoolList: await DataManagerService.ins.getPrimarySchoolList(article.prefecture_id, article.city_id),
                    middleSchoolList: await DataManagerService.ins.getMiddleSchoolList(article.prefecture_id, article.city_id),
                  });
                }}
              >
                <option value={''}>未選択</option>
                {cityList.map((v) => (
                  <option value={v.id ?? ''} key={v.id}>{v.name}</option>
                ))}
              </select>
            ) : (
              <>
                {article.city}
              </>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 住所 */}
          <div className={'padd_t_adjust'}>
            住所
          </div>
          <div>
            {isEdit ? (
              <>
                <input
                  className={'textInput'}
                  value={article.address || ''}
                  onChange={(e) => {
                    article.address = e.target.value;
                    this.setState({
                      article: article
                    });
                  }}
                />
                <ValidationComponent validation={validationArticleDetail.address} str={String(article.address || '')} />
              </>
            ) : (
              <>{article.address}</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 最寄駅 */}
          <div className={'padd_t_adjust'}>
            最寄駅
          </div>
          <div>
            {/**/}
            {isEdit ? (
              <>
                <div className={'flex_box flex_align_center sp_block'}>
                  <div className={'flex_box flex_align_center basis_10rem'}>
                    <input
                      className={'textInput'}
                      value={route || ''}
                      onChange={(e) => this.setState({ route: e.target.value })}
                    />&nbsp;線
                  </div>
                  <div className={'flex_box flex_align_center basis_10rem margin_l_20'}>
                    <input
                      className={'textInput'}
                      value={station || ''}
                      onChange={(e) => this.setState({ station: e.target.value })}
                    />&nbsp;駅
                  </div>
                </div>
                <ValidationComponent validation={validationArticleDetail.station}
                  str={(route || '') + (station || '')} />
              </>
            ) : (
              <>{route}線&nbsp;{station}駅</>
            )}
            {/* change */}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}

        {isEdit ? (
          <>
            {this.loadedFileAndUrlList.map((v, i, arr) => (
              i % 2 ? <div key={`loadedFileAndUrlList_${i}`} /> : (
                <div className={'flex_box sp_flex_container'} key={`loadedFileAndUrlList_main_${i}`}>
                  <div className={'padd_t_adjust'}>
                    現状写真{i + 1}
                  </div>
                  <div className={'displayImage_wrap flex_box flex_align_center flex_content_center'}>
                    <div className={'editImage_wrap'}>
                      <div className={'editImageInne_wrap flex_box flex_align_center'}>
                        <img
                          src={v ? v.url : thumbNoImage}
                          className={'marg_auto'}
                          alt={''}
                        />
                      </div>
                      <div className={'divide10'} />
                      <div className={'flex_box flex_align_center'}>
                        <div className={'selectImageBtn_wrap singleImageBtn'}>
                          {v && (
                            <button
                              className={'reset selectedImageDeleteBtn'}
                              onClick={() => {
                                this.loadedFileAndUrlList.splice(i, 1);
                                this.setState({});
                              }}
                            >削除</button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={'padd_t_adjust'}>
                    現状写真{i + 2}
                  </div>
                  <div className={'displayImage_wrap flex_box flex_align_center flex_content_center'}>
                    <div className={'editImage_wrap'}>
                      <div className={'editImageInne_wrap flex_box flex_align_center'}>
                        <img
                          src={arr[i + 1] ? arr[i + 1].url : thumbNoImage}
                          className={'marg_auto'}
                          alt={''}
                        />
                      </div>
                      <div className={'divide10'} />
                      <div className={'flex_box flex_align_center'}>
                        <div className={'selectImageBtn_wrap singleImageBtn'}>
                          {arr[i + 1] && (
                            <button
                              className={'reset selectedImageDeleteBtn'}
                              onClick={() => {
                                this.loadedFileAndUrlList.splice(i + 1, 1);
                                this.setState({});
                              }}
                            >削除</button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            ))}
            {this.loadedFileAndUrlList.length < 10 ? (
              <div className={'flex_box sp_flex_container'}>
                <div className={'padd_t_adjust'}>
                  現状写真
                </div>
                <div className={'displayImage_wrap flex_box flex_align_center flex_content_center'}>
                  <ImagesLoaderComponent
                    srcList={[]}
                    callback={async (fileList) => {
                      const urlList: string[] = [];
                      // file[] -> string[] へ変換
                      for (let i = 0; i < fileList.length; i++) {
                        urlList.push(await this.convertFile2Base64(fileList[i]));
                      }
                      if ((fileList.length + this.loadedFileAndUrlList.length) > 10) {
                        ModalService.ins.push(
                          <ModalAlertComponent
                            msgList={['アップロードできる画像ファイルは10件までです。']}
                            callback={() => {
                              ModalService.ins.close();
                            }}
                          />
                        );
                        return;
                      }
                      this.loadedFileAndUrlList =
                        this.loadedFileAndUrlList
                          .concat(fileList.map((file, i) => ({ file: file, url: urlList[i] })));
                      // .filter((_, i) => i < 10);
                      this.setState({});
                    }}
                    callbackDell={() => { }}
                  />
                </div>
              </div>
            ) : (
              <>
                {}
              </>
            )}
          </>
        ) : (
          <>
            {imgUrlList.filter((v, i) => !!v).map((v, i, arr) => (
              i % 2 ? <div key={`${v}_${i}`} /> : (
                <div className={'flex_box sp_flex_container'} key={`${v}_${i}`}>
                  <div className={'padd_t_adjust'}>
                    現状写真{i + 1}
                  </div>
                  <div className={'displayImage_wrap flex_box flex_align_center flex_content_center'}>
                    <img
                      src={v || thumbNoImage}
                      className={'marg_auto'}
                      alt={''}
                    />
                  </div>
                  <div className={'padd_t_adjust'}>
                    {arr[i + 1] ? (
                      <>
                        現状写真{i + 2}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className={'displayImage_wrap flex_box flex_align_center flex_content_center'}>
                    {arr[i + 1] ? (
                      <img
                        src={arr[i + 1] || thumbNoImage}
                        className={'marg_auto'}
                        alt={''}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              )
            ))}
          </>
        )}

        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 地図画像 */}
          <div className={'padd_t_adjust'}>
            地図画像
          </div>
          <div className={'displayImage_wrap flex_box flex_align_center flex_content_center'}>
            {isEdit ? (
              <>
                <ImgLoaderComponent
                  src={img11}
                  callback={(file) => this.loadedFileList[10] = file}
                  callbackDell={() => this.loadedFileList[10] = ''}
                />
              </>
            ) : (
              <>
                <img
                  src={img11 || thumbNoImage}
                  className={'marg_auto'}
                  alt={''}
                />
              </>
            )}
          </div>
          {/* 区画図PDF */}
          <div className={'padd_t_adjust'}>
            区画図PDF
          </div>
          <div className={'displayImage_wrap flex_box flex_align_center flex_content_center'}>
            {isEdit ? (
              <>
                <ImgLoaderComponent
                  src={pdf}
                  isPdfOnly={true}
                  pdfFileName={pdf ? pdfFileName : ''}
                  callback={(file) => this.loadedFileList[11] = file}
                  callbackDell={() => this.loadedFileList[11] = ''}
                />
              </>
            ) : (
              <>
                {pdf ? (
                  <div className={'pdf_wrap'}>
                    <div className={'align_c'}>{pdfFileName}</div>
                    <div className={'divide20'} />
                    <button
                      className={'reset flex_box flex_align_center flex_content_center'}
                      // onClick={() => this.handlerClickBtnOpenPdf('http://sv1.marietta.co.jp/_users/tenryu/Demo/sample.pdf')}
                      onClick={() => this.handlerClickBtnOpenPdf(pdf)}
                    >
                      <img
                        src={thumbPdf}
                        alt={''}
                      />
                      &nbsp;PDFを開く
                    </button>
                  </div>
                ) : (
                  <div>区画図は登録されていません。</div>
                )}
              </>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 価格 */}
          <div className={'padd_t_adjust'}>
            価格
          </div>
          <div>
            {isEdit ? (
              <>
                <div className={'flex_box flex_align_center'}>
                  <input
                    className={'textInput max_w_8'}
                    value={article['price'] || ''}
                    type={'number'}
                    onChange={(e) => {
                      article['price'] = Number(e.target.value) || null;
                      this.setState({
                        article: article
                      });
                    }}
                  />&nbsp;円
                </div>
                <ValidationComponent validation={validationArticleDetail.price} str={article['price'] + '' || ''} />
              </>
            ) : (
              <>{MoneyFormatter.thousandSeparate(article['price'] as number)}&nbsp;円</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 面積 */}
          <div className={'padd_t_adjust'}>
            土地面積
          </div>
          <div>
            {isEdit ? (
              <>
                <div className={'flex_box flex_align_center'}>
                  <input
                    className={'textInput max_w_8'}
                    value={article.area_measuresize || ''}
                    type={'number'}
                    onChange={(e) => {
                      article.area_measuresize = Number(e.target.value) || 0;
                      this.setState({
                        article: article
                      });
                    }}
                  />&nbsp;&#13217;
                </div>
                <ValidationComponent validation={validationArticleDetail.areaMeasureSize}
                  str={String(article.area_measuresize || '') + String(article.tsubo || '')} />
              </>
            ) : (
              <>{article.area_measuresize} &nbsp;&#13217;</>
            )}
          </div>
          {/* 坪数 */}
          <div className={'padd_t_adjust'}>
            土地坪数
          </div>
          <div>
            {isEdit ? (
              <>
                <input
                  className={'textInput max_w_8'}
                  value={article.tsubo || ''}
                  type={'number'}
                  onChange={(e) => {
                    article.tsubo = Number(e.target.value) || 0;
                    this.setState({
                      article: article
                    });
                  }}
                />
                <ValidationComponent validation={validationArticleDetail.tsubo}
                  str={String(article.area_measuresize || '') + String(article.tsubo || '')} />
              </>
            ) : (
              <>{article.tsubo}&nbsp;坪</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 私道面積 */}
          <div className={'padd_t_adjust'}>
            私道面積
          </div>
          <div>
            {isEdit ? (
              <>
                <div className={'flex_box flex_align_center'}>
                  <input
                    className={'textInput max_w_8'}
                    type={'number'}
                    value={privateArea || ''}
                    onChange={(e) => this.setState({ privateArea: e.target.value })}
                  />&nbsp;&#13217;
                </div>
                <ValidationComponent validation={validationArticleDetail.privateArea}
                  str={(privateArea + '') + (privateUnit + '') || ''} />
              </>
            ) : (
              <>{privateArea} &nbsp;&#13217;</>
            )}
          </div>
          {/* 私道坪数 */}
          <div className={'padd_t_adjust'}>
            私道坪数
          </div>
          <div>
            {isEdit ? (
              <>
                <input
                  className={'textInput max_w_8'}
                  type={'number'}
                  value={privateUnit || ''}
                  onChange={(e) => this.setState({ privateUnit: e.target.value })}
                />
                <ValidationComponent validation={validationArticleDetail.privateUnit}
                  str={(privateArea + '') + (privateUnit + '') || ''} />
              </>
            ) : (
              <>{privateUnit}&nbsp;坪</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 坪単価 */}
          <div className={'padd_t_adjust'}>
            坪単価
          </div>
          <div>
            {isEdit ? (
              <>
                <div className={'flex_box flex_align_center'}>
                  <input
                    className={'textInput max_w_8'}
                    type={'number'}
                    value={tsuboPrice || ''}
                    onChange={(e) => this.setState({ tsuboPrice: e.target.value })}
                  />&nbsp;万円&nbsp;/&nbsp;坪
                </div>
                {
                  (Number(tsuboPrice) >= 1000)
                  ? (<ValidationComponent validation={validationArticleDetail.tsuboPriceMax} str={String(tsuboPrice || '')} />)
                  : (<ValidationComponent validation={validationArticleDetail.tsuboPrice} str={String(tsuboPrice || '')} />)
                }
              </>
            ) : (
              <>{tsuboPrice}万円&nbsp;/&nbsp;坪</>
            )}
          </div>
          {/* 買付証 */}
          <div className={'padd_t_adjust'}>
            買付証
          </div>
          <div>
            {isEdit ? (
              <select
                className={'textInput max_w_8'}
                value={Number(article.has_purchase_card) || 0}
                onChange={(e) => {
                  article.has_purchase_card = e.target.value === '1';
                  this.setState({
                    article: article
                  });
                }}
              >
                <option value={0}>なし</option>
                <option value={1}>あり</option>
              </select>
            ) : (
              <>{article.has_purchase_card ? 'あり' : 'なし'}</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 交通 */}
          <div className={'padd_t_adjust'}>
            交通
          </div>
          <div>
            {isEdit ? (
              <>
                <div className={'flex_box flex_align_center sp_block input_3_col'}>
                  <div className={'flex_box flex_align_center basis_10rem'}>
                    <input
                      className={'textInput'}
                      value={traffic_route || ''}
                      onChange={(e) => this.setState({ traffic_route: e.target.value })}
                    />&nbsp;線
                  </div>
                  <div className={'flex_box flex_align_center basis_10rem margin_l_20'}>
                    <input
                      className={'textInput'}
                      value={traffic_station || ''}
                      onChange={(e) => this.setState({ traffic_station: e.target.value })}
                    />&nbsp;駅
                  </div>
                  <div className={'flex_box flex_align_center basis_10rem margin_l_20'}>
                    徒歩&nbsp;
                    <input
                      className={'textInput'}
                      type={'number'}
                      value={traffic_minute || ''}
                      onChange={(e) => this.setState({ traffic_minute: e.target.value })}
                    />&nbsp;分
                  </div>
                </div>
                <ValidationComponent validation={validationArticleDetail.traffic}
                  str={String(traffic_route || '') + String(traffic_station || '')} />
                {!validationArticleDetail.traffic.checkFunc(String(traffic_route || '') + String(traffic_station || '')) &&
                  !validationArticleDetail.trafficMinute.checkFunc(String(traffic_minute || ''))
                  ? (<br />) : (<></>)}
                <ValidationComponent validation={validationArticleDetail.trafficMinute}
                  str={String(traffic_minute || '')} />
              </>
            ) : (
              <>{traffic_route}線&nbsp;{traffic_station}駅&nbsp;徒歩{traffic_minute}分</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 土地権利 */}
          <div className={'padd_t_adjust'}>
            土地権利
          </div>
          <div>
            {isEdit ? (
              <select
                className={'textInput max_w_8'}
                value={landRight || 0}
                onChange={(e) => this.setState({ landRight: +e.target.value })}
              >
                <option value={''}>未選択</option>
                {this.landRightList.map((v) => (
                  <option value={v.value} key={v.value + '_' + v.label}>{v.label}</option>
                ))}
              </select>
            ) : (
              <>
                {this.landRightList.find((v) => +v.value === +(landRight ?? '')) ?
                  this.landRightList.find((v) => +v.value === +(landRight ?? ''))?.label : '未選択'}
              </>
            )}
          </div>
          {/* 地目 */}
          <div className={'padd_t_adjust'}>
            地目
          </div>
          <div>
            {isEdit ? (
              <select
                className={'textInput max_w_8'}
                value={ground || 0}
                onChange={(e) => this.setState({ ground: +e.target.value })}
              >
                <option value={''}>未選択</option>
                {this.landCategoryList.map((v) => (
                  <option value={v.value} key={v.value + '_' + v.label}>{v.label}</option>
                ))}
              </select>
            ) : (
              <>
                {this.landCategoryList.find((v) => +v.value === +(ground ?? '')) ?
                  this.landCategoryList.find((v) => +v.value === +(ground ?? ''))?.label : '未選択'}
              </>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 都市計画 */}
          <div className={'padd_t_adjust'}>
            都市計画
          </div>
          <div>
            {isEdit ? (
              <>
                <input
                  className={'textInput max_w_8'}
                  value={cityPlanning || ''}
                  onChange={(e) => this.setState({ cityPlanning: e.target.value })}
                />
                <ValidationComponent validation={validationArticleDetail.cityPlanning}
                  str={String(cityPlanning || '')} />
              </>
            ) : (
              <>{cityPlanning || ''}</>
            )}
          </div>
          {/* 用途地域 */}
          <div className={'padd_t_adjust'}>
            用途地域
          </div>
          <div>
            {isEdit ? (
              <select
                className={'textInput max_w_8'}
                value={useDistrict || 0}
                onChange={(e) => this.setState({ useDistrict: +e.target.value })}
              >
                <option value={''}>未選択</option>
                {this.useDistrictList.map((v) => (
                  <option value={v.value} key={v.value + '_' + v.label}>{v.label}</option>
                ))}
              </select>
            ) : (
              <>
                {this.useDistrictList.find((v) => +v.value === +useDistrict) ?
                  this.useDistrictList.find((v) => +v.value === +useDistrict)?.label : '未選択'}
              </>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 建ぺい率 */}
          <div className={'padd_t_adjust'}>
            建ぺい率
          </div>
          <div>
            {isEdit ? (
              <>
                <div className={'flex_box flex_align_center'}>
                  <input
                    className={'textInput max_w_8'}
                    type={'number'}
                    value={coverageRatioInput || ''}
                    onChange={(e) => this.setState({ coverageRatioInput: e.target.value })}
                  />&nbsp;%
                </div>
                <ValidationComponent validation={validationArticleDetail.coverageRatio}
                  str={String(coverageRatioInput || '')} />
              </>
            ) : (
              <>{coverageRatioInput}{coverageRatioInput ? ' %' : ''}</>
            )}
          </div>
          {/* 容積率 */}
          <div className={'padd_t_adjust'}>
            容積率
          </div>
          <div>
            {isEdit ? (
              <>
                <div className={'flex_box flex_align_center'}>
                  <input
                    className={'textInput max_w_8'}
                    value={floorAreaRatioInput || ''}
                    type={'number'}
                    onChange={(e) => this.setState({ floorAreaRatioInput: e.target.value })}
                  />&nbsp;%
                </div>
                <ValidationComponent validation={validationArticleDetail.floorAreaRatio}
                  str={String(floorAreaRatioInput || '')} />
              </>
            ) : (
              <>{floorAreaRatioInput}{floorAreaRatioInput ? ' %' : ''}</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 法令上の制限 */}
          <div className={'padd_t_adjust'}>
            法令上の制限
          </div>
          <div>
            {isEdit ? (
              <>
                <input
                  className={'textInput'}
                  value={legalAuthorityInput || ''}
                  onChange={(e) => this.setState({ legalAuthorityInput: e.target.value })}
                />
                <ValidationComponent validation={validationArticleDetail.legalAuthority}
                  str={String(legalAuthorityInput || '')} />
              </>
            ) : (
              <>{legalAuthorityInput}</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 現況 */}
          <div className={'padd_t_adjust'}>
            現況
          </div>
          <div>
            {isEdit ? (
              <>
                <input
                  className={'textInput'}
                  value={presentStatusInput || ''}
                  onChange={(e) => this.setState({ presentStatusInput: e.target.value })}
                />
                <ValidationComponent validation={validationArticleDetail.presentStatus}
                  str={String(presentStatusInput || '')} />
              </>
            ) : (
              <>{presentStatusInput}</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 接道状況 */}
          <div className={'padd_t_adjust'}>
            接道状況
          </div>
          <div>
            {isEdit ? (
              <>
                <input
                  className={'textInput'}
                  value={approachSituationInput || ''}
                  onChange={(e) => this.setState({ approachSituationInput: e.target.value })}
                />
                <ValidationComponent validation={validationArticleDetail.approachSituation}
                  str={String(approachSituationInput || '')} />
              </>
            ) : (
              <>{approachSituationInput}</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 設備 */}
          <div className={'padd_t_adjust'}>
            設備
          </div>
          <div>
            {isEdit ? (
              <>
                <input
                  className={'textInput'}
                  value={facilityInput || ''}
                  onChange={(e) => this.setState({ facilityInput: e.target.value })}
                />
                <ValidationComponent validation={validationArticleDetail.facility} str={String(facilityInput || '')} />
              </>
            ) : (
              <>{facilityInput}</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 備考 */}
          <div className={'padd_t_adjust'}>
            備考
          </div>
          <div>
            {isEdit ? (
              <>
                {/*<input*/}
                {/*  className={'textInput'}*/}
                {/*  value={remarksInput || ''}*/}
                {/*  onChange={(e) => this.setState({ remarksInput: e.target.value })}*/}
                {/*/>*/}
                <textarea
                  className={'textInput'}
                  value={remarksInput || ''}
                  onChange={(e) => this.setState({ remarksInput: e.target.value.replace('\n', '') })}
                />
                <ValidationComponent validation={validationArticleDetail.remarks} str={String(remarksInput || '')} />
              </>
            ) : (
              <>{remarksInput}</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 営業担当者名 */}
          <div className={'padd_t_adjust'}>
            営業担当者名{isEdit ? (<>*</>) : (<></>)}
          </div>
          <div className={'flex_box flex_align_center withButton_wrap'}>
            {isEdit ? (
              <>
                <div>
                  <select
                    className={'textInput max_w_8'}
                    value={article.user_id || 0}
                    onChange={async (e) => {
                      article.user_id = Number(e.target.value) || null;
                      this.setState({
                        article: article,
                      });
                    }}
                  >
                    <option value={''}>未選択</option>
                    {userList.map((v) => (
                      <option value={v.id ?? ''} key={v.id}>{v.fullname['value']}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <button
                    className={'reset btnStyle_1'}
                    onClick={() => this.handlerClickBtnUserPicker((userId) => {
                      article.user_id = Number(userId) || null;
                      this.setState({ article: article });
                    })}
                  >
                    <img className={'iconSearch'} src={iconSearch} alt={''} />
                    <img className={'iconSearchOn'} src={iconSearchOn} alt={''} />
                  </button>
                </div>
              </>
            ) : (
              <>
                {userList.find((v) => +(v.id ?? '') === +(article.user_id ?? '')) ? (
                  userList.find((v) => +(v.id ?? '') === +(article.user_id ?? ''))?.fullname['value']
                ) : (
                  ''
                )}
              </>
            )}
          </div>
          {/* 仕入れ責任者 */}
          <div className={'padd_t_adjust'}>
            仕入れ責任者{isEdit ? (<>*</>) : (<></>)}
          </div>
          <div className={'flex_box flex_align_center withButton_wrap'}>
            {isEdit ? (
              <>
                <div>
                  <select
                    className={'textInput max_w_8'}
                    value={article.purchasing_manager_id || 0}
                    onChange={async (e) => {
                      article.purchasing_manager_id = Number(e.target.value) || null;
                      this.setState({
                        article: article,
                      });
                    }}
                  >
                    <option value={''}>未選択</option>
                    {userList.map((v) => (
                      <option value={v.id ?? ''} key={v.id}>{v.fullname['value']}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <button
                    className={'reset btnStyle_1'}
                    onClick={() => this.handlerClickBtnUserPicker((userId) => {
                      article.purchasing_manager_id = Number(userId) || null;
                      this.setState({ article: article });
                    })}
                  >
                    <img className={'iconSearch'} src={iconSearch} alt={''} />
                    <img className={'iconSearchOn'} src={iconSearchOn} alt={''} />
                  </button>
                </div>
              </>
            ) : (
              <>
                {userList.find((v) => +(v.id ?? '') === +(article.purchasing_manager_id ?? '')) ? (
                  userList.find((v) => +(v.id ?? '') === +(article.purchasing_manager_id ?? ''))?.fullname['value']
                ) : (
                  ''
                )}
              </>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 仕入れ年月日 */}
          <div className={'padd_t_adjust'}>
            仕入れ年月日
          </div>
          <div>
            {isEdit ? (
              <DatePickerComponent
                min={null}
                max={null}
                current={article.purchase_datetime}
                onChange={(v) => {
                  article.purchase_datetime = v;
                  this.setState({
                    article: article
                  });
                }}
              />
            ) : (
              <>{DateFormatter.date2str(article.purchase_datetime, DateFormat.YYYYMMDD)}</>
            )}
          </div>
          {/* 建物建築年月日 */}
          <div className={'padd_t_adjust'}>
            建築年月日
          </div>
          <div>
            {isEdit ? (
              <DatePickerComponent
                min={null}
                max={null}
                current={article.build_datetime}
                onChange={(v) => {
                  article.build_datetime = v;
                  this.setState({
                    article: article
                  });
                }}
              />
            ) : (
              <>{DateFormatter.date2str(article.build_datetime, DateFormat.YYYYMMDD)}</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 顧客名 */}
          <div className={'padd_t_adjust'}>
            顧客名
          </div>
          <div>
            {isEdit ? (
              <input
                className={'textInput'}
                value={article.customer_name}
                onChange={(e) => {
                  article.customer_name = e.target.value;
                  this.setState({
                    article: article
                  });
                }}
              />
            ) : (
              <>{article.customer_name}</>
            )}
          </div>
          {/* 売買締結日 */}
          <div className={'padd_t_adjust'}>
            売買締結日
          </div>
          <div>
            {isEdit ? (
              <DatePickerComponent
                min={null}
                max={null}
                current={article.conclusion_datetime}
                onChange={(v) => {
                  article.conclusion_datetime = v;
                  this.setState({
                    article: article
                  });
                }}
              />
            ) : (
              <>{DateFormatter.date2str(article.conclusion_datetime, DateFormat.YYYYMMDD)}</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 中学校*/}
          <div className={'padd_t_adjust'}>
            中学校{/*{isEdit ? (<>*</>) : (<></>)}*/}
          </div>
          <div>
            {isEdit ? (
              <select
                className={'textInput max_w_8'}
                value={article.m_school_id || 0}
                onChange={async (e) => {
                  article.m_school_id = Number(e.target.value) || null;
                  this.setState({
                    article: article,
                  });
                }}
              >
                <option value={''}>未選択</option>
                {middleSchoolList.map((v) => (
                  <option value={v.id ?? ''} key={v.id}>{v.name}</option>
                ))}
              </select>
            ) : (
              <>
                {middleSchoolList.find((v) => +(v.id ?? '') === +(article.m_school_id ?? '')) ? (
                  middleSchoolList.find((v) => +(v.id ?? '') === +(article.m_school_id ?? ''))?.name
                ) : (
                  ''
                )}
              </>
            )}
          </div>
          {/* 小学校 */}
          <div className={'padd_t_adjust'}>
            小学校{/*{isEdit ? (<>*</>) : (<></>)}*/}
          </div>
          <div>
            {isEdit ? (
              <select
                className={'textInput max_w_8'}
                value={article.p_school_id || 0}
                onChange={async (e) => {
                  article.p_school_id = Number(e.target.value) || null;
                  this.setState({
                    article: article,
                  });
                }}
              >
                <option value={''}>未選択</option>
                {primarySchoolList.map((v) => (
                  <option value={v.id ?? ''} key={v.id}>{v.name}</option>
                ))}
              </select>
            ) : (
              <>
                {primarySchoolList.find((v) => +(v.id ?? '') === +(article.p_school_id ?? '')) ? (
                  primarySchoolList.find((v) => +(v.id ?? '') === +(article.p_school_id ?? ''))?.name
                ) : (
                  ''
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  }

  private renderTableBodyIntroduced(): ReactElement<any> {
    const me = this.state.me;
    const isNew: boolean = this.state.isNew;
    const isEdit: boolean = this.state.isEdit || isNew;
    const isBeforeLoad: boolean = this.state.isBeforeLoad;
    const isIntroduced: boolean = this.state.isIntroduced;
    const article: Article = this.state.article;
    const prefectureList = this.state.prefectureList;
    const cityList = this.state.cityList;
    const userList = this.state.userList;
    const primarySchoolList = this.state.primarySchoolList;
    const middleSchoolList = this.state.middleSchoolList;
    const infoList: ArticleInfo[] = this.state.infoList;
    const editedInfoList: ArticleInfo[] = this.state.editedInfoList;
    const infoInput: string = this.state.infoInput;
    return (
      <>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 都道府県 */}
          <div className={'padd_t_adjust'}>
            都道府県{isEdit ? (<>*</>) : (<></>)}
          </div>
          <div>
            {isEdit ? (
              <select
                className={'textInput max_w_8'}
                value={article.prefecture_id || 0}
                onChange={async (e) => {
                  article.prefecture_id = Number(e.target.value) || null;
                  article.city_id = null;
                  article.m_school_id = null;
                  article.p_school_id = null;
                  this.setState({
                    article: article,
                    cityList: article.prefecture_id ? await DataManagerService.ins.getCityList(article.prefecture_id) : [],
                  });
                }}
              >
                <option value={''}>未選択</option>
                {prefectureList.map((v) => (
                  <option value={v.id} key={v.id}>{v.name}</option>
                ))}
              </select>
            ) : (
              <>
                {prefectureList.find((v) => +v.id === +(article.prefecture_id ?? '')) ? (
                  prefectureList.find((v) => +v.id === +(article.prefecture_id ?? ''))?.name
                ) : (
                  ''
                )}
              </>
            )}
          </div>
          {/* 市区町村 */}
          <div className={'padd_t_adjust'}>
            市区町村{isEdit ? (<>*</>) : (<></>)}
          </div>
          <div>
            {isEdit ? (
              <select
                className={'textInput max_w_8'}
                value={article.city_id || 0}
                onChange={async (e) => {
                  article.city_id = Number(e.target.value) || null;
                  article.m_school_id = null;
                  article.p_school_id = null;
                  this.setState({
                    article: article,
                    primarySchoolList: await DataManagerService.ins.getPrimarySchoolList(article.prefecture_id, article.city_id),
                    middleSchoolList: await DataManagerService.ins.getMiddleSchoolList(article.prefecture_id, article.city_id),
                  });
                }}
              >
                <option value={''}>未選択</option>
                {cityList.map((v) => (
                  <option value={v.id ?? ''} key={v.id}>{v.name}</option>
                ))}
              </select>
            ) : (
              <>
                {article.city}
              </>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 住所 */}
          <div className={'padd_t_adjust'}>
            住所
          </div>
          <div>
            {isEdit ? (
              <input
                className={'textInput'}
                value={article.address || ''}
                onChange={(e) => {
                  article.address = e.target.value;
                  this.setState({
                    article: article
                  });
                }}
              />
            ) : (
              <>{article.address}</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 営業担当者名 */}
          <div className={'padd_t_adjust'}>
            営業担当者名{isEdit ? (<>*</>) : (<></>)}
          </div>
          <div className={'flex_box flex_align_center withButton_wrap'}>
            {isEdit ? (
              <>
                <div>
                  <select
                    className={'textInput max_w_8'}
                    value={article.user_id || 0}
                    onChange={async (e) => {
                      article.user_id = Number(e.target.value) || null;
                      this.setState({
                        article: article,
                      });
                    }}
                  >
                    <option value={''}>未選択</option>
                    {userList.map((v) => (
                      <option value={v.id ?? ''} key={v.id}>{v.fullname['value']}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <button
                    className={'reset btnStyle_1'}
                    onClick={() => this.handlerClickBtnUserPicker((userId) => {
                      article.user_id = Number(userId) || null;
                      this.setState({ article: article });
                    })}
                  >
                    <img className={'iconSearch'} src={iconSearch} alt={''} />
                    <img className={'iconSearchOn'} src={iconSearchOn} alt={''} />
                  </button>
                </div>
              </>
            ) : (
              <>
                {userList.find((v) => +(v.id ?? '') === +(article.user_id ?? '')) ? (
                  userList.find((v) => +(v.id ?? '') === +(article.user_id ?? ''))?.fullname['value']
                ) : (
                  ''
                )}
              </>
            )}
          </div>
          {/* 坪数 */}
          <div className={'padd_t_adjust'}>
            坪数
          </div>
          <div>
            {isEdit ? (
              <input
                className={'textInput max_w_8'}
                value={article['tsubo'] || ''}
                type={'number'}
                onChange={(e) => {
                  article['tsubo'] = Number(e.target.value) || null;
                  this.setState({
                    article: article
                  });
                }}
              />
            ) : (
              <>{article['tsubo']}</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 価格 */}
          <div className={'padd_t_adjust'}>
            価格
          </div>
          <div className={'flex_box flex_align_center'}>
            {isEdit ? (
              <>
                <input
                  className={'textInput'}
                  value={article['price'] || ''}
                  type={'number'}
                  onChange={(e) => {
                    article['price'] = Number(e.target.value) || null;
                    this.setState({
                      article: article
                    });
                  }}
                />&nbsp;円
              </>
            ) : (
              <>{MoneyFormatter.thousandSeparate(article['price'] as number)}&nbsp;円</>
            )}
          </div>
          {/* 面積 */}
          <div className={'padd_t_adjust'}>
            面積
          </div>
          <div>
            {isEdit ? (
              <div className={'flex_box flex_align_center'}>
                <input
                  className={'textInput max_w_8'}
                  value={article.area_measuresize || ''}
                  type={'number'}
                  onChange={(e) => {
                    article.area_measuresize = Number(e.target.value) || null;
                    this.setState({
                      article: article
                    });
                  }}
                />&nbsp;&#13217;
              </div>
            ) : (
              <>{article.area_measuresize} &nbsp;&#13217;</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 地目 */}
          <div className={'padd_t_adjust'}>
            地目
          </div>
          <div>
            {isEdit ? (
              <select
                className={'textInput max_w_8'}
                value={article['land_category'] || 0}
                onChange={async (e) => {
                  article['land_category'] = Number(e.target.value) || null;
                  this.setState({
                    article: article,
                  });
                }}
              >
                <option value={''}>未選択</option>
                {this.landCategoryList.map((v) => (
                  <option value={v.value} key={v.value}>{v.label}</option>
                ))}
              </select>
            ) : (
              <>
                {this.landCategoryList.find((v) => +v.value === +(article['land_category'] ?? '')) ? (
                  this.landCategoryList.find((v) => +v.value === +(article['land_category'] ?? ''))?.label
                ) : (
                  ''
                )}
              </>
            )}
          </div>
          {/* 用途地域 */}
          <div className={'padd_t_adjust'}>
            用途地域
          </div>
          <div>
            {isEdit ? (
              <select
                className={'textInput max_w_8'}
                value={article['use_district'] || 0}
                onChange={async (e) => {
                  article['use_district'] = Number(e.target.value) || null;
                  this.setState({
                    article: article,
                  });
                }}
              >
                <option value={''}>未選択</option>
                {this.useDistrictList.map((v) => (
                  <option value={v.value} key={v.value}>{v.label}</option>
                ))}
              </select>
            ) : (
              <>
                {this.useDistrictList.find((v) => +v.value === +(article['use_district'] ?? '')) ? (
                  this.useDistrictList.find((v) => +v.value === +(article['use_district'] ?? ''))?.label
                ) : (
                  '未選択'
                )}
              </>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 中学校*/}
          <div className={'padd_t_adjust'}>
            中学校{/*{isEdit ? (<>*</>) : (<></>)}*/}
          </div>
          <div>
            {isEdit ? (
              <select
                className={'textInput max_w_8'}
                value={article.m_school_id || 0}
                onChange={async (e) => {
                  article.m_school_id = Number(e.target.value) || null;
                  this.setState({
                    article: article,
                  });
                }}
              >
                <option value={''}>未選択</option>
                {middleSchoolList.map((v) => (
                  <option value={v.id ?? ''} key={v.id}>{v.name}</option>
                ))}
              </select>
            ) : (
              <>
                {middleSchoolList.find((v) => +(v.id ?? '') === +(article.m_school_id ?? '')) ? (
                  middleSchoolList.find((v) => +(v.id ?? '') === +(article.m_school_id ?? ''))?.name
                ) : (
                  ''
                )}
              </>
            )}
          </div>
          {/* 小学校 */}
          <div className={'padd_t_adjust'}>
            小学校{/*{isEdit ? (<>*</>) : (<></>)}*/}
          </div>
          <div>
            {isEdit ? (
              <select
                className={'textInput max_w_8'}
                value={article.p_school_id || 0}
                onChange={async (e) => {
                  article.p_school_id = Number(e.target.value) || null;
                  this.setState({
                    article: article,
                  });
                }}
              >
                <option value={''}>未選択</option>
                {primarySchoolList.map((v) => (
                  <option value={v.id ?? ''} key={v.id}>{v.name}</option>
                ))}
              </select>
            ) : (
              <>
                {primarySchoolList.find((v) => +(v.id ?? '') === +(article.p_school_id ?? '')) ? (
                  primarySchoolList.find((v) => +(v.id ?? '') === +(article.p_school_id ?? ''))?.name
                ) : (
                  ''
                )}
              </>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 有効/無効 */}
          <div className={'padd_t_adjust'}>
            有効/無効
          </div>
          <div>
            {isEdit ? (
              <div
                onClick={() => {
                  article['valid_flag'] = +(article['valid_flag'] ?? '') === 1 ? 0 : 1;
                  this.setState({ article: article });
                }}
              >
                {+(article['valid_flag'] ?? '') ? (
                  <div className={'innerImg checked'}><img src={iconCheckBoxChecked} alt={''} /></div>
                ) : (
                  <div className={'innerImg noCheck'}><img src={iconCheckBox} alt={''} /></div>
                )}
              </div>
            ) : (
              <>
                {+(article['valid_flag'] ?? '') ? (
                  <div className={'innerImg checked'}><img src={iconCheckBoxChecked} alt={''} /></div>
                ) : (
                  <div className={'innerImg noCheck'}><img src={iconCheckBox} alt={''} /></div>
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  }


  async componentDidMount(): Promise<void> {
    window.scrollTo(0, 0);
    await UserService.ins.getMe()
      .then((v) => {
        this.setState({ me: v });
      })
      .catch((v) => {
        this.setState({ me: v });
      });
    const isIntroduced: boolean = window.location.hash.match(RoutingPass.ARTICLE_DETAIL_INTRODUCED) !== null;
    this.setState({
      editedInfoList: [],
      isIntroduced: isIntroduced
    });
    const id = parseInt(this.props['match']['params']['id'], 10);
    const isNew = id === 0;
    this.getInfoList(this.state.id);
    if (isNew) {
      this.setState({
        id: id,
        isNew: true,
      });
      DataManagerService.ins.getPrefectureList()
        .then((v) => this.setState({ prefectureList: v, isBeforeLoad: false }))
        .catch((v) => this.setState({ prefectureList: v, isBeforeLoad: false }));
      DataManagerService.ins.getUserList()
        .then((v) => this.setState({ userList: v, isBeforeLoad: false }))
        .catch((v) => this.setState({ userList: v, isBeforeLoad: false }));
      DataManagerService.ins.getSubdivisionList()
        .then((v) => this.setState({ subdivisionList: v, isBeforeLoad: false }))
        .catch((v) => this.setState({ subdivisionList: v, isBeforeLoad: false }));
    } else {
      this.getDetail(id);
    }
  }

  async componentDidUpdate(props: ArticleDetailComponentProps) {
    if (this.props.match.params.id !== props.match.params.id) {
      this.getDetail(Number(this.props.match.params.id));
      this.loadedFileAndUrlList = await this.createLoadedFileAndUrlList();
    }
  }


  // async componentDidUpdate(): Promise<void> {
  //   await UserService.ins.getMe()
  //     .then((v) => {
  //       this.setState({ me: v });
  //     })
  //     .catch((v) => {
  //       this.setState({ me: v });
  //     });
  //   const isIntroduced: boolean = window.location.hash.match(RoutingPass.ARTICLE_DETAIL_INTRODUCED) !== null;
  //   this.setState({
  //     editedInfoList: [],
  //     isIntroduced: isIntroduced
  //   });
  //   const id = parseInt(this.props['match']['params']['id'], 10);
  //   const isNew = id === 0;
  //   this.getInfoList(this.state.id);
  //   if (isNew) {
  //     this.setState({
  //       id: id,
  //       isNew: true
  //     });
  //     DataManagerService.ins.getPrefectureList()
  //       .then((v) => this.setState({ prefectureList: v, isBeforeLoad: false }))
  //       .catch((v) => this.setState({ prefectureList: v, isBeforeLoad: false }));
  //     DataManagerService.ins.getUserList()
  //       .then((v) => this.setState({ userList: v, isBeforeLoad: false }))
  //       .catch((v) => this.setState({ userList: v, isBeforeLoad: false }));
  //     DataManagerService.ins.getSubdivisionList()
  //       .then((v) => this.setState({ subdivisionList: v, isBeforeLoad: false }))
  //       .catch((v) => this.setState({ subdivisionList: v, isBeforeLoad: false }));
  //   } else {
  //     this.getDetail(id);
  //   }
  // }

  private handlerClickBtnSubdivisionPicker(callback: (subdivisionId: number | null) => void): void {
    ModalService.ins.display(
      <ModalSubdivisionPickerComponent callback={callback} />
    );
  }


  private handlerClickBtnUserPicker(callback: (userId: number) => void): void {
    ModalService.ins.display(
      <ModalUserPickerComponent callback={callback} />
    );
  }

  // private handlerChangeInputEdit(e, v, editedInfoList): void {
  //   let editedInfo: ArticleInfo = editedInfoList.find((w) => w.id === v.id);
  //   if (editedInfo) {
  //     editedInfo.item_content = e.target.value;
  //     this.setState({ editedInfoList: editedInfoList });
  //     return;
  //   }
  //   editedInfo = Lodash.cloneDeep(v);
  //   editedInfo.item_content = e.target.value;
  //   editedInfoList.push(editedInfo);
  //   this.setState({ editedInfoList: editedInfoList });
  // }

  //
  private async handlerClickBtnOutput(): Promise<void> {
    this.props.setIsBusy(true);
    const article: Article = this.state.article;
    const state = this.state;
    const urlImg1: string = this.getLinkedFileUrl(1, this.state.imageList);
    const urlImg2: string = this.getLinkedFileUrl(2, this.state.imageList);
    const urlImg3: string = this.getLinkedFileUrl(11, this.state.imageList);
    const fileImg1: File = await ConnectionService.ins.getFileFromUrl(urlImg1);
    const fileImg2: File = await ConnectionService.ins.getFileFromUrl(urlImg2);
    const fileImg3: File = await ConnectionService.ins.getFileFromUrl(urlImg3);
    const base64Img1: string = urlImg1 ? await this.convertFile2Base64(fileImg1) : NoImg1;
    const base64Img2: string = urlImg2 ? await this.convertFile2Base64(fileImg2) : NoImg1;
    const base64Img3: string = urlImg3 ? await this.convertFile2Base64(fileImg3) : NoImg2;
    const articlePrice: string = MoneyFormatter.thousandSeparate(+(article.price ?? '') / 10000, 1);
    const mng: SvgManager = new SvgManager(
      article.id + '',
      DateFormatter.date2str(new Date(), DateFormat.YYYYMMDD, '/'),
      article.name,
      articlePrice,
      article.city,
      article.address,
      state.route || '',
      state.station || '',
      article.area_measuresize + '',
      article.tsubo + '',
      this.typeList.find((v) => +v.value === +(article.type ?? '')) ? (
        this.typeList.find((v) => +v.value === +(article.type ?? ''))?.label || ''
      ) : '',
      `${article.area_measuresize}㎡ (${article.tsubo}坪)`,
      `${state.privateArea}㎡ (${state.privateUnit}坪)`,
      state.tsuboPrice,
      article.address,
      state.route && state.station ? `${state.route}線 ${state.station}駅` : '',
      state.route && state.station ? `${state.route}線 ${state.station}駅 徒歩${state.traffic_minute}分` : '',
      `${state.landRight ? this.landRightList[state.landRight - 1].label : '-'}`,
      `${state.ground ? this.landCategoryList.find((v) => +v.value === +state.ground)?.label : '-'}`,
      `${state.cityPlanning || ''}`,
      `${this.useDistrictList.find(v => String(v.value) === String(state.useDistrict)) ? this.useDistrictList.find(v => String(v.value) === String(state.useDistrict))?.label : '-'}`,
      state.coverageRatioInput ? `${state.coverageRatioInput} %` : '-',
      state.floorAreaRatioInput ? `${state.floorAreaRatioInput} %` : '-',
      `${state.legalAuthorityInput || '-'}`,
      `${state.presentStatusInput || '-'}`,
      `${state.approachSituationInput || '-'}`,
      `${state.facilityInput || '-'}`,
      `${state.remarksInput || '-'}`,
      base64Img1,
      base64Img2,
      base64Img3,
    );
    const svg: string = mng.createSvg();
    const request: RequestArticlesPostPdf_export = new RequestArticlesPostPdf_export(svg);
    ConnectionService.ins.connect(request)
      .then((response) => {
        const pdfName: string = response.data['pdf_name'];
        const origin: string = window.location.origin === 'http://localhost:3000' ? 'https://rms.marietta.co.jp' : window.location.origin;
        const dir: string = '/contents/pdf/';
        const url = `${origin}${dir}${pdfName}`;
        ConnectionService.ins.getFileFromUrl(url)
          .then((v) => {
            window.open(URL.createObjectURL(v));
            this.props.setIsBusy(false);
          });
      })
      .catch((e) => {
        ErrorService.ins.response(e);
        this.props.setIsBusy(false);
      });
  }

  private async handlerClickBtnEdit() {
    const article = this.state.article;
    this.defaultArticleData = Object.assign({}, article);
    this.loadedFileAndUrlList = await this.createLoadedFileAndUrlList();
    this.setState({
      isEdit: true
    });
  }

  private handlerClickBtnCopy(): void {
    const article: Article = this.state.article;
    if (article.subdivision_upper_limit_flag) {
      ModalService.ins.push(
        <ModalAlertComponent
          msgList={['区画数上限に達したため、物件登録できません。']}
          callback={() => {
            ModalService.ins.pop();
          }}
        />
      );
    } else {
      const id = article.id as number;
      const articleName = article.name;
      const landName = this.state.subdivisionList.find((v) => +(v.id ?? '') === +(article?.subdivision_id ?? ''))?.name;
      ModalService.ins.display(
        <ModalCopyComponent articleId={id} landName={landName || ''} objectName={articleName} callback={
          async (res: any) => {
            const article = this.state.article;
            this.defaultArticleData = Object.assign({}, article);
            this.loadedFileAndUrlList = await this.createLoadedFileAndUrlList();

            await Promise.all([
              ...this.loadedFileAndUrlList.map((v, i) => this.uploadLinkedFile(res.data.id, i + 1 as any, v.file)),
              ...[...Array(10 - this.loadedFileAndUrlList.length)].fill('').map((_, i) => this.uploadLinkedFile(res.data.id, (i + 1 + this.loadedFileAndUrlList.length) as any, '')),
              this.uploadLinkedFile(res.data.id, 11, this.loadedFileList[10] as File  || await this.convertUrl2File(this.getLinkedFileUrl(11, this.state.imageList))),
              this.uploadLinkedFile(res.data.id, 12, this.loadedFileList[11] as File || await this.convertUrl2File(this.getLinkedFileUrl(12, this.state.imageList))),
            ])
              .then(() => {
                ModalService.ins.pop();
                ModalService.ins.push(
                  <ModalAlertComponent
                    msgList={['保存しました。']}
                    callback={() => {
                      // @ts-ignore
                      ModalService.ins.pop();
                      this.handlerClickBtnCancel();
                    }}
                  />
                );
              })
              .catch((err) => {
                ErrorService.ins.response(err);
              });
          }
        } />
      );
    }
  }

  private handlerClickBtnReservation(): void {
    ModalService.ins.display(
      <ModalReservationListComponent article={this.state.article} callback={() => this.getDetail(this.state.id as number)} />
    );
  }

  private handlerClickBtnDocument(): void {
    const isIntroduced: boolean = window.location.hash.match(RoutingPass.ARTICLE_DETAIL_INTRODUCED) !== null;
    ModalService.ins.display(
      <ModalDocumentComponent article={this.state.article} isIntroduced={isIntroduced} />
    );
  }

  private handlerClickBtnRelease(): void {
    const isIntroduced: boolean = window.location.hash.match(RoutingPass.ARTICLE_DETAIL_INTRODUCED) !== null;
    const imgUrlList: string[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(v => this.getLinkedFileUrl(v as any, this.state.imageList));
    const pdfFileName: string = this.state.imageList.find((v) => +v.article_file_type === 12) ?
      this.state.imageList.find((v) => +v.article_file_type === 12)?.name || '' : '';
    ModalService.ins.display(
      <ModalReleaseComponent
        article={this.state.article}
        isIntroduced={isIntroduced}
        imgUrlList={imgUrlList}
        pdfUrl={this.getLinkedFileUrl(12, this.state.imageList)}
        pdfName={pdfFileName}
        refreshArticle={() => {
          // Article 詳細取得APIを呼ぶ処理
          this.getDetail(this.state.id as number);
        }} />
    );
  }

  //受け付期間すぎた場合、処理
  private handlerClickBtnRefresh(): void {
    const test = () => {
      const send = { id: this.state.article.id };
      const request: RequestArticlesPostStopUrl = new RequestArticlesPostStopUrl(
        send.id,
      );
      ConnectionService.ins.connect(request)
        .then(() => {
          this.getDetail(this.state.id as number)
            .then(() => {
              this.handlerClickBtnRelease();
            })
            .catch((err) => {
              ErrorService.ins.response(err);
            });
        })
        .catch((err) => {
          ErrorService.ins.response(err);
        });
    };
    test();

    // const isIntroduced: boolean = location.hash.match(RoutingPass.ARTICLE_DETAIL_INTRODUCED) !== null;
    // const imgUrlList: string[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(v => this.getLinkedFileUrl(v as any, this.state.imageList));
    // ModalService.ins.display(
    //   <ModalReleaseComponent
    //     article={this.state.article}
    //     isIntroduced={isIntroduced}
    //     imgUrlList={imgUrlList}
    //     refreshArticle={() => {
    //       // Article 詳細取得APIを呼ぶ処理
    //       this.getDetail(this.state.id);
    //     }}/>
    // );
  }

  //REVIEW 公開停止設定
  private handlerClickBtnStop(): void {
    const article = this.state.article;
    ModalService.ins.push(
      <ModalDialogComponent
        msgList={['公開中のページを停止しますか？']}
        stopAlert={[`${String(article.period_start).replace(/-/g, '/')} ~ ${String(article.period_end).replace(/-/g, '/')}`]}
        callbackOk={() => {
          const send = { id: this.state.article.id };
          const request: RequestArticlesPostStopUrl = new RequestArticlesPostStopUrl(
            send.id,
          );
          ConnectionService.ins.connect(request)
            .then(() => {
              this.getDetail(this.state.id as number);
              ModalService.ins.close();
            })
            .catch((err) => {
              ErrorService.ins.response(err);
            });
        }}
        callbackNg={() => {
          ModalService.ins.close();
        }} />
    );
  }

  private handlerClickBtnShare(): void {
    const isIntroduced: boolean = window.location.hash.match(RoutingPass.ARTICLE_DETAIL_INTRODUCED) !== null;
    ModalService.ins.display(
      <ModalShareComponent article={this.state.article} isIntroduced={isIntroduced} />
    );
  }

  private async handlerClickBtnCancel(): Promise<void> {
    this.setState({ editedInfoList: [], infoInput: '' });
    if (this.state.isNew) {
      this.props['history'].goBack();
      return;
    }
    this.setState({
      isEdit: false,
      article: this.defaultArticleData,
      cityList: await DataManagerService.ins.getCityList(this.defaultArticleData.prefecture_id || null),
    });
    this.getDetail(this.state.id as number);
  }

  private handlerClickBtnSave(): void {
    const isValidSuccess: boolean = document.getElementsByClassName(ElementId.VALID_ERROR_CLASS).length === 0;
    if (!isValidSuccess) {
      ModalService.ins.push(
        <ModalAlertComponent
          msgList={['正しく入力されていない項目があります。']}
          callback={() => {
            ModalService.ins.pop();
          }}
        />
      );
      return;
    }
    const isIntroduced: boolean = window.location.hash.match(RoutingPass.ARTICLE_DETAIL_INTRODUCED) !== null;
    const article = this.state.article;
    const isNew = this.state.isNew;
    const {
      route,
      station,
      privateArea,
      privateUnit,
      tsuboPrice,
      traffic_route,
      traffic_station,
      traffic_minute,
      landRight,
      ground,
      cityPlanning,
      useDistrict,
      coverageRatioInput,
      floorAreaRatioInput,
      legalAuthorityInput,
      presentStatusInput,
      approachSituationInput,
      facilityInput,
      remarksInput,
    } = this.state;
    const request: RequestBase = isIntroduced ? (
      new RequestArticlesintroducedPost(
        article['id'],
        article['name'],
        article['prefecture_id'],
        article['city_id'],
        article['address'],
        article['price'],
        article['tsubo'],
        article['area_measuresize'],
        article['land_category'],
        article['use_district'],
        article['user_id'],
        article['p_school_id'],
        article['m_school_id'],
        article['remarks'],
        article['valid_flag'],
      )
    ) : (
      new RequestArticlesPost(
        article.id,
        article.name,
        article.sales_status,
        article.type,
        article.prefecture_id,
        article.city_id,
        article.address,
        article.building_name,
        article.tsubo,
        article.price,
        article.area_measuresize,
        article.purchasing_manager_id,
        article.user_id,
        article.subdivision_id,
        DateFormatter.date2str(article.build_datetime, DateFormat.YYYYMMDD, '-'),
        DateFormatter.date2str(article.purchase_datetime, DateFormat.YYYYMMDD, '-'),
        article.has_purchase_card,
        article.customer_name,
        DateFormatter.date2str(article.conclusion_datetime, DateFormat.YYYYMMDD, '-'),
        article.p_school_id,
        article.m_school_id,
        //
        route,
        station,
        traffic_route,
        traffic_station,
        +traffic_minute,
        +privateArea,
        +privateUnit,
        +tsuboPrice,
        landRight,
        ground,
        cityPlanning,
        useDistrict,
        +coverageRatioInput,
        +floorAreaRatioInput,
        legalAuthorityInput,
        presentStatusInput,
        approachSituationInput,
        facilityInput,
        remarksInput
      )
    );
    const editedInfoList: ArticleInfo[] = this.state.infoList;
    if (!isIntroduced && +article.sales_status === 6) {
      ModalService.ins.push(
        <ModalDialogComponent
          msgList={['この物件の予約情報がある場合、全て削除されます。', 'よろしいですか？']}
          callbackOk={() => {
            ModalService.ins.close();
            editedInfoList.forEach((info) => {
              const requestInfo: RequestArticleinformationsPost = new RequestArticleinformationsPost(
                info.id,
                isIntroduced ? null : info.article_id,
                isIntroduced ? info.article_id : null,
                Number(!isIntroduced),
                info.item_content,
              );
              let countSuccess: number = 0;
              let countError: number = 0;
              ConnectionService.ins.connect(requestInfo)
                .then((res) => {
                  countSuccess++;
                  if (countSuccess + countError === editedInfoList.length) {
                    this.setState({ editedInfoList: [] });
                    this.getDetail(this.state.id as number);
                  }
                })
                .catch((err) => {
                  countError++;
                  if (countSuccess + countError === editedInfoList.length) {
                    this.setState({ editedInfoList: [] });
                    this.getDetail(this.state.id as number);
                    ErrorService.ins.response(err);
                  }

                });
            });
            //REVIEW 現状画像枚数10枚まで増やす
            ConnectionService.ins.connect(request)
              .then((res) => {
                Promise.all([
                  ...this.loadedFileAndUrlList.map((v, i) => this.uploadLinkedFile(isNew ? res.data.id : article.id, i + 1 as any, v.file)),
                  ...[...Array(10 - this.loadedFileAndUrlList.length)].fill('').map((v, i) => this.uploadLinkedFile(isNew ? res.data.id : article.id, (i + 1 + this.loadedFileAndUrlList.length) as any, '')),
                  this.uploadLinkedFile(isNew ? res.data.id : article.id, 11, this.loadedFileList[10] as File),
                  this.uploadLinkedFile(isNew ? res.data.id : article.id, 12, this.loadedFileList[11] as File),
                ])
                  .then(() => {
                    ModalService.ins.push(
                      <ModalAlertComponent
                        msgList={['保存しました。']}
                        callback={() => {
                          // @ts-ignore
                          ModalService.ins.pop();
                          this.handlerClickBtnCancel();
                        }}
                      />
                    );
                  })
                  .catch((err) => {
                    ErrorService.ins.response(err);
                  });
              })
              .catch((err) => {
                ErrorService.ins.response(err);
              });
            this.setState({
              isEdit: true
            });
          }}
          callbackNg={() => {
            ModalService.ins.close();
          }} />
      );
    } else {
      const requestInfo: RequestArticleinformationsPost = new RequestArticleinformationsPost(
        this.state.infoList[0] ? this.state.infoList[0].id : null,
        isIntroduced ? null : this.state.article.id,
        isIntroduced ? this.state.article.id : null,
        Number(!isIntroduced),
        this.state.infoInput,
      );
      let countSuccess: number = 0;
      let countError: number = 0;
      ConnectionService.ins.connect(requestInfo)
        .then(() => {
          countSuccess++;
          if (countSuccess + countError === editedInfoList.length) {
            this.setState({ editedInfoList: [] });
            this.getDetail(this.state.id as number);
          }
        })
        .catch((err) => {
          countError++;
          if (countSuccess + countError === editedInfoList.length) {
            this.setState({ editedInfoList: [] });
            this.getDetail(this.state.id as number);
            ErrorService.ins.response(err);
          }
        });
      //REVIEW 現状画像枚数10枚まで増やす
      ConnectionService.ins.connect(request)
        .then((res) => {
          Promise.all([
            ...this.loadedFileAndUrlList.map((v, i) => this.uploadLinkedFile(isNew ? res.data.id : article.id, i + 1 as any, v.file)),
            ...[...Array(10 - this.loadedFileAndUrlList.length)].fill('').map((v, i) => this.uploadLinkedFile(isNew ? res.data.id : article.id, (i + 1 + this.loadedFileAndUrlList.length) as any, '')),
            this.uploadLinkedFile(isNew ? res.data.id : article.id, 11, this.loadedFileList[10] as File),
            this.uploadLinkedFile(isNew ? res.data.id : article.id, 12, this.loadedFileList[11] as File),
          ])
            .then(() => {
              ModalService.ins.push(
                <ModalAlertComponent
                  msgList={['保存しました。']}
                  callback={() => {
                    ModalService.ins.pop();
                    this.handlerClickBtnCancel();
                  }}
                />
              );
            })
            .catch((err) => {
              ErrorService.ins.response(err);
            });
        })
        .catch((err) => {
          ErrorService.ins.response(err);
        });
      this.setState({
        isEdit: true
      });
    }
  }

  private getDetail(id: number): Promise<void> {
    return new Promise((resolve: () => void, reject: () => void) => {
      const isIntroduced: boolean = window.location.hash.match(RoutingPass.ARTICLE_DETAIL_INTRODUCED) !== null;
      const request: RequestBase = isIntroduced ? (
        new RequestArticlesintroducedGetOne(id)
      ) : (
        new RequestArticlesGetOne(id)
      );
      ConnectionService.ins.connect(request)
        .then(async (res: ResponseArticlesGetOne | any) => {
          const article = isIntroduced ? (
            new ArticleIntroduced(
              res.data.id,
              res.data.name,
              res.data.prefecture_id,
              res.data['city']['name'],
              res.data.city_id,
              res.data.address,
              res.data['price'],
              res.data['tsubo'],
              res.data.area_measuresize,
              res.data['land_category'],
              res.data['use_district'],
              res.data.user_id,
              res.data.pschool_id,
              res.data.mschool_id,
              res.data['remarks'],
              res.data['valid_flag'],
            )
          ) : (
            new Article(
              res.data.id,
              res.data.name,
              res.data.sales_status,
              Boolean(+res.data.reservation_flag),
              res.data.type,
              res.data.building_name,
              res.data.tsubo,
              res.data.price,
              res.data.prefecture_id,
              res.data['city']['name'],
              res.data.city_id,
              res.data.address,
              res.data.area_measuresize,
              res.data.purchasing_manager_id,
              res.data.user_id,
              DateFormatter.str2date(res.data.build_datetime ? res.data.build_datetime + '' : ''),
              DateFormatter.str2date(res.data.purchase_datetime ? res.data.purchase_datetime + '' : ''),
              Boolean(+res.data.has_purchase_card),
              res.data.customer_name,
              DateFormatter.str2date(res.data.conclusion_datetime ? res.data.conclusion_datetime + '' : ''),
              res.data.mschool_id,
              res.data.pschool_id,
              res.data.subdivision_id,
              res.data.period_start,
              res.data.period_end,
              res.data.message,
              res.data.email,
              res.data.reserve_count,
              res.data.area_count,
              res.data.area_max,
              res.data.remarks,
              res.data.land_category,
              res.data.use_district,
              res.data.valid_flag,
              res.data.subdivision_upper_limit_flag
            )
          );

          // TODO 画像取得処理実装
          const fileRequest: RequestArticlesLinked_fileGet = new RequestArticlesLinked_fileGet(id);
          ConnectionService.ins.connect(fileRequest)
            .then((fileResponse) => {
              this.getInfoList(id);
              this.setState({
                id: id,
                isNew: false,
                isBeforeLoad: false,
                article: article,
                //
                route: res.data.route1,
                station: res.data.station1,
                traffic_route: res.data.route2,
                traffic_station: res.data.station2,
                traffic_minute: res.data.walk_time,
                privateArea: res.data.private_road_measuresize,
                privateUnit: res.data.private_road_tsubo,
                tsuboPrice: res.data.tsubo_unit,
                landRight: res.data.ownership || 0,
                ground: res.data.land_category || 0,
                cityPlanning: res.data.city_planning,
                useDistrict: res.data.use_district || 0,
                coverageRatioInput: res.data.coverage,
                floorAreaRatioInput: res.data.floor_area,
                legalAuthorityInput: res.data.law_limitation,
                presentStatusInput: res.data.current_state,
                approachSituationInput: res.data.connecting_roads,
                facilityInput: res.data.equipment,
                remarksInput: res.data.remarks,
                imageList: fileResponse.data.list as ArticleLinkedFile[],
              });
              this.refreshList(article as Article);
              resolve();
            })
            .catch((err) => {
              ErrorService.ins.response(err);
              reject();
            });
        })
        .catch((err) => {
          ErrorService.ins.response(err);
          reject();
        });
    });
  }

  private refreshList(article: Article): void {
    DataManagerService.ins.getUserList()
      .then((v) => {
        this.setState({ userList: v });
      })
      .catch((v) => {
        this.setState({ userList: v });
      });
    DataManagerService.ins.getPrefectureList()
      .then((v) => {
        this.setState({ prefectureList: v });
        DataManagerService.ins.getCityList(article.prefecture_id)
          .then((v) => {
            this.setState({ cityList: v });
          })
          .catch((v) => {
            this.setState({ cityList: v });
          });
      })
      .catch((v) => {
        this.setState({ prefectureList: v });
      });
    DataManagerService.ins.getMiddleSchoolList(article.prefecture_id, article.city_id)
      .then((v) => {
        this.setState({ middleSchoolList: v });
      })
      .catch((v) => {
        this.setState({ middleSchoolList: v });
      });
    DataManagerService.ins.getPrimarySchoolList(article.prefecture_id, article.city_id)
      .then((v) => {
        this.setState({ primarySchoolList: v });
      })
      .catch((v) => {
        this.setState({ primarySchoolList: v });
      });
    DataManagerService.ins.getSubdivisionList()
      .then((v) => {
        this.setState({ subdivisionList: v });
      })
      .catch((v) => {
        this.setState({ subdivisionList: v });
      });
  }

  private getInfoList(articleId: number | null, saleseMonth: number = this.state.sales_month): void {
    const isIntroduced: boolean = window.location.hash.match(RoutingPass.ARTICLE_DETAIL_INTRODUCED) !== null;
    const list: ArticleInfo[] = [];
    if (!articleId) {
      this.setState({ infoList: list });
      return;
    }
    const request: RequestArticleinformationsGetSearch = new RequestArticleinformationsGetSearch(
      '',
      100,
      1,
      1,
      1,
      isIntroduced ? null : articleId,
      isIntroduced ? articleId : null,
      saleseMonth
    );
    ConnectionService.ins.connect(request)
      .then((res) => {
        this.setState({
          infoList: res.data.list,
          editedInfoList: [],
          infoInput: res.data.list[0] ? res.data.list[0].item_content : ''
        });
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }

  /**
   * pdf 開くボタンのハンドラ
   * @param {string} src
   */
  private handlerClickBtnOpenPdf(src: string): void {
    ConnectionService.ins.getFileFromUrl(src)
      .then((v) => {
        window.open(URL.createObjectURL(v));
      });
  }

  /**
   * 現状写真とかのURL生成処理
   * @param {1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 11 | 12} index
   * @param {ArticleLinkedFile[]} linkedFileList
   * @return {string}
   */
  private getLinkedFileUrl(index: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12, linkedFileList: ArticleLinkedFile[]): string {
    const origin: string = window.location.origin === 'http://localhost:3000' ? 'https://rms.marietta.co.jp' : window.location.origin;
    const dir: string = '/contents/';
    const fileName: string = linkedFileList.find((v) => +v.article_file_type === index) ?
      linkedFileList.find((v) => +v.article_file_type === index)?.saved_as || '' : '';
    return fileName ? `${origin}${dir}${fileName}` : '';
  }

  /**
   * ファイルアップロード
   * @param {number} articleId
   * @param {1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 11 | 12} index
   * @param {File} file
   */
  private async uploadLinkedFile(articleId: number, index: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12, file: File | ''): Promise<void> {

    if (file === null) {
      return;
    }
    const request: RequestArticlesPostImport_file = new RequestArticlesPostImport_file(
      articleId,
      index,
      file,
    );
    await ConnectionService.ins.connect(request)
      .then((res) => {
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }

  private convertFile2Base64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const fr: FileReader = new FileReader();
      fr.onload = (e) => {
        if (e.target?.result && typeof e.target.result === 'string') {
          resolve(e.target.result);
        }
      };
      fr.readAsDataURL(file);
    });
  }

  private async createLoadedFileAndUrlList() {
    const imgUrlList: string[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(v => this.getLinkedFileUrl(v as any, this.state.imageList)).filter((v) => !!v);
    const loadedFileAndUrlList: { file: File, url: string; }[] = [];
    for (let i = 0; i < imgUrlList.length; i++) {
      loadedFileAndUrlList.push({
        file: await this.convertUrl2File(imgUrlList[i]),
        url: imgUrlList[i],
      });
    }
    return loadedFileAndUrlList;
  }

  private async convertUrl2File(url: string): Promise<File> {
    return fetch(url)
      .then(res => res.blob())
      .then(blob => new File([blob], url.split('/')[url.split('/').length - 1]));
  }
}

const mapStateToProps: StateToProps = (state: State) => ({
  isAdmin: state.user.isAdmin
});

const mapDispatchToProps: DispatchToProps = (dispatch) => ({
  setIsBusy: (v: boolean) => dispatch(SystemActions.setIsBusy(v)),
  dispatch: (v: any) => dispatch(v)
});

export const ArticleDetailComponent = connect(mapStateToProps, mapDispatchToProps)(ArticleDetailBodyComponent);