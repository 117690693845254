import * as React from 'react';
import './modal-dialog-component.scss';

interface ModalDialogComponentProps {
  msgList: string[];
  stopAlert?: string[];
  callbackOk: () => void;
  callbackNg: () => void;
}

interface ModalDialogComponentState {
  msgList: string[];
  callbackOk: () => void;
  callbackNg: () => void;
}

export class ModalDialogComponent extends React.Component<ModalDialogComponentProps, ModalDialogComponentState> {

  constructor(props: ModalDialogComponentProps) {
    super(props);
    this.state = {
      msgList: props.msgList,
      callbackOk: props.callbackOk,
      callbackNg: props.callbackNg
    }
  }

  render() {
    const msgList: string[] = this.state.msgList;
    const stopAlert: string[] | undefined = this.props.stopAlert;
    const callbackOk: () => void = this.state.callbackOk;
    const callbackNg: () => void = this.state.callbackNg;
    return (
      <div className={'modalInnerBody_wrap msgModal_wrap'}>

        {/* msg */}
        <div className={'msg_wrap'}>
          {stopAlert? (
            <div>公開期間：{stopAlert}</div>
          ) : (
            <></>
          )}
          {msgList.map((msg, i) => (
            <div
              key={msg + i}
            >
              {msg}
            </div>
          ))}
        </div>

        <div className={'divide20'}></div>

        {/* button */}
        <div className={'btn_wrap flex_box flex_align_center flex_space_between'}>
          <div>
            <button
              className={'reset btnStyle_1'}
              onClick={() => callbackNg()}
            >
              キャンセル
            </button>
          </div>
          <div>
            <button
              className={'reset btnStyle_1'}
              onClick={() => callbackOk()}
            >
              OK
            </button>
          </div>
        </div>

      </div>
    );
  }

}
