import { HTTPMethod, RequestBase, NullPermission } from './request-base';

type Param = NullPermission<{
  id: number,
  subdivision_id: number,
  file: File,
}>;

export class RequestQuestionnaireUpload extends RequestBase {
  constructor(
    public param: Param
  ) {
    super(HTTPMethod.POST, '/api/subdivisionfiles/import_file');
  }

  createFormData(): FormData {
    const formData = new FormData();
    const keys = Object.keys(this.param);
    const values: any = Object.values(this.param);
    for (let i = 0; i < keys.length; i += 1) {
      if (values[i] !== undefined && values[i] !== null) {
        formData.append(keys[i], values[i] as string);
      } else {
        formData.append(keys[i], '');
      }
    }
    return formData;
  }

  getUrl(): string {
    return this.host + this.endPoint;
  }
}