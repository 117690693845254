import lodash from 'lodash';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { SideMenuType } from '../../component/common/side-menu/side-menu-component';
import { ModalService } from '../../service/modal-service';
import { SystemActions } from './system.action';

export type SystemState = {
  isLoading: boolean;
  isBusy: boolean;
  currentMenu: SideMenuType | null;
  hideMenu: boolean;
};

const initialState: SystemState = {
  isLoading: false,
  isBusy: false,
  currentMenu: null,
  hideMenu: false,
};

export const SystemReducer = reducerWithInitialState<SystemState>(initialState)
  .case(SystemActions.setIsLoading, (state, payload) => (
    {
      ...state,
      isLoading: payload,
    }
  ))
  .case(SystemActions.setCurrentMenu, (state, payload) => (
    {
      ...state,
      currentMenu: lodash.cloneDeep(payload),
    }
  ))
  .case(SystemActions.setHideMenu, (state, payload) => (
    {
      ...state,
      hideMenu: payload,
    }
  ))
  .case(SystemActions.setIsBusy, (state, payload) => {
    if (payload) {
      ModalService.ins.addClassName2App();
    } else {
      ModalService.ins.removeClassName2App();
    }
    return {
      ...state,
      isBusy: payload,
    };
  });
