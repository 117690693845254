import {HTTPMethod, RequestBase} from './request-base';

export class RequestUsersGetInvitees extends RequestBase {

  param: {
    hash: string
  };

  constructor(hash: string) {
    super(HTTPMethod.GET, '/api/users/invitees/');
    this.param = {
      hash: hash
    };
  }

  // getParam(): string {
  //   let param: string = '?';
  //   Object.keys(this.param).forEach((v) => {
  //     param += `${v}=${this.param[v]}&`;
  //   });
  //   return param;
  // }

  getUrl(): string {
    return this.host + this.endPoint + this.param.hash;
  }

}
