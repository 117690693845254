import { HTTPMethod, RequestBase } from './request-base';

export class RequestSubdivisionDetailGet extends RequestBase {
  constructor(
    subDivisionId: number,
  ) {
    super(HTTPMethod.GET, `/api/subdivisions/${subDivisionId}`);
  }

  getUrl(): string {
    return this.host + this.endPoint;
  }

}