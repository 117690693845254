import { HTTPMethod, NullPermission, RequestBase } from './request-base';

type Param = NullPermission<{
  id: number,
  name: string,
  address: string,
  valid_flag: number, //
  prefecture_id: number,
  city_id: number,
  area_max: number,
  reservation_count: number,
  reservation_flag: number,
  sales_status: number,
  type: number,
  tsubo: number,
  price: number,
  area_measuresize: number,
  purchasing_manager_id: number,
  user_id: number,
  build_datetime: string,
  purchase_datetime: string,
  has_purchase_card: number,
  customer_name: string,
  conclusion_datetime: string,
  pschool_id: number,
  mschool_id: number,
  route1: string,
  station1: string,
  route2: string,
  station2: string,
  walk_time: number,
  private_road_measuresize: number,
  private_road_tsubo: number,
  tsubo_unit: number,
  ownership: number, //enum
  land_category: number, //enum
  city_planning: string,
  use_district: number, //enum
  coverage: number,
  floor_area: number,
  law_limitation: string,
  current_state: string,
  connecting_roads: string,
  equipment: string,
  remarks: string,
  itemcontent: string | null,
}>;

export class RequestSubdivisionsPost extends RequestBase {

  param: Param;

  constructor(param: Param) {
    super(HTTPMethod.POST, '/api/subdivisions');
    this.param = param;
  }

  createFormData(): FormData {
    const formData = new FormData();
    const keys = Object.keys(this.param);
    const values = Object.values(this.param);
    for (let i = 0; i < keys.length; i += 1) {
      if (keys[i] === 'ownership' || keys[i] === 'land_category' || keys[i] === 'use_district') {
        if (!values[i]) {
          formData.append(keys[i], '');
          continue;
        }
      }
      if (keys[i] === 'coverage' || keys[i] === 'floor_area') {
        if (!values[i]) {
          formData.append(keys[i], '0');
          continue;
        }
      }

      if (values[i] !== undefined && values[i] !== null) {
        formData.append(keys[i], values[i] as string);
      } else {
        formData.append(keys[i], '');
      }
    }
    formData.append('reservation_flag', '');
    return formData;
  }

  getUrl(): string {
    return this.host + this.endPoint;
  }
}
