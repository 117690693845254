import * as React from 'react';
import { ImgRotater } from '../../../../util/img-rotater';
import { ModalService } from '../../../../service/modal-service';
import { ModalAlertComponent } from '../../../common/modal/modal-alert/modal-alert-component';

interface ImagesLoaderComponentProps {
  srcList?: string[];
  callback: (fileList: File[]) => void,
  callbackDell: () => void,
}

interface ImagesLoaderComponentState {
  srcList?: string[];
}

export class ImagesLoaderComponent extends React.Component<ImagesLoaderComponentProps, ImagesLoaderComponentState> {

  private acceptFileType: string[] = [
    'image/png',
    'image/jpeg',
  ];

  constructor(props: ImagesLoaderComponentProps) {
    super(props);
    this.state = {
      srcList: props.srcList,
    };
  }

  render() {
    const {
      srcList,
    } = this.state;
    return (
      <>
        <div className={'addFile_wrap'}>
          <div
            className={'addFileArea_wrap flex_box flex_align_center flex_content_center'}
            onDragOver={(e) => this.handlerDragOverFile(e)}
            onDrop={(e) => this.handlerDropFile(e)}
          >
            <div>
              <div className={'align_c font_b'}>ファイルをドラッグ&ドロップ</div>
              <div className={'divide10'} />
              <div className={'align_c font_b'}>または</div>
              <div className={'divide10'} />
              <button
                className={'reset marg_auto'}
                onClick={() => this.handlerClickBtnChoseFile()}
              >
                ファイルを選択
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  /**
   * ファイルドラッグオーバー時のハンドラ
   * ブラウザがファイルをオープンしないために必要
   * @param {React.DragEvent} e
   */
  private handlerDragOverFile(e: React.DragEvent): void {
    e.preventDefault();
  }

  /**
   * ファイルのドロップ時のハンドラ
   * @param {React.DragEvent} e
   */
  private async handlerDropFile(e: React.DragEvent) {
    e.preventDefault();
    const fileList: File[] = [];
    const filePathList: string[] = [];
    const fileLength = e.dataTransfer.files.length;
    const tmpFileList = e.dataTransfer.files;

    const failureFiles = [];
    // FileType 確認処理
    for (let i = 0; i < fileLength; i++) {
      const file: File = tmpFileList[i];
      const match = this.acceptFileType.findIndex((v) => v === file.type) !== -1;
      if (!match) failureFiles.push(file.name);
    }
    if (failureFiles.length) {
      ModalService.ins.push(
        <ModalAlertComponent
          msgList={[...failureFiles,'対応していない拡張子です']}
          callback={() => {
            ModalService.ins.close();
          }}
        />
      );
      this.props.callback([]);
      return;
    }
    //
    for (let i = 0; i < fileLength; i++) {
      const file = tmpFileList[i];
      fileList.push(await ImgRotater.rotate(file));
      filePathList.push(await this.getBlobPath(file));
    }
    this.props.callback(fileList);
    this.setState({ srcList: filePathList });
  }

  /**
   * ファイル選択ボタン押下時のハンドラ
   */
  private handlerClickBtnChoseFile() {
    const acceptFileType: string[] = this.acceptFileType;
    const inputElement: HTMLInputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.multiple = true;
    inputElement.accept = acceptFileType.reduce((prevValue: string, currentValue: string) => prevValue += `${currentValue}, `, '');
    inputElement.className = 'hideEle';
    inputElement.onchange = async (e: any) => {
      const fileList: File[] = [];
      const filePathList: string[] = [];
      const fileLength = e.target.files.length;
      const tmpFileList = e.target.files;
      for (let i = 0; i < fileLength; i++) {
        const file = tmpFileList[i];
        fileList.push(await ImgRotater.rotate(file));
        filePathList.push(await this.getBlobPath(file));
      }
      if (fileLength > 10) {
        ModalService.ins.push(
          <ModalAlertComponent
            msgList={['アップロードできる画像ファイルは10件までです']}
            callback={() => {
              ModalService.ins.close();
            }}
          />
        );
      }
      this.props.callback(fileList);
      this.setState({ srcList: filePathList });
    };
    document.body.appendChild(inputElement);
    inputElement.value = '';
    (window as any)['_tmp'] = inputElement;
    inputElement.click();
    document.body.removeChild(inputElement);
  }

  /**
   * ファイル削除ボタン押下時のハンドラ
   */
  private handlerClickBtnDeleteFile(): void {
    // 削除処理
    this.setState({
      srcList: [],
    });
    this.props.callbackDell();
  }

  /**
   * Fileオブジェクトのパス取得メソッド
   * @param {File} file
   * @return {Promise<string>}
   */
  private getBlobPath(file: File): Promise<string> {
    const acceptFileType: string[] = this.acceptFileType;
    return new Promise<string>((resolve, reject) => {
      if (acceptFileType.findIndex((v) => v === file.type) === -1) {
        reject();
        return;
      }
      const reader: FileReader = new FileReader();
      reader.onload = (readerEvent) => {
        resolve(readerEvent.target?.result ? readerEvent.target.result as string : '');
      };
      reader.onerror = () => {
        reject();
      };
      reader.readAsDataURL(file);
    });
  }

}
