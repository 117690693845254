
import * as React from 'react';
import './modal-subdivision-picker-component.scss';
import { Subdivision } from '../../../../model/subdivision';
import { ModalService } from '../../../../service/modal-service';
import { DataManagerService } from '../../../../service/data-manager-service';
import { Prefecture } from '../../../../model/prefecture';
import { City } from '../../../../model/city';
import { RequestSubdivisionGetSearch } from '../../../../model/api/request/request-subdivision-get-search';
import { ConnectionService } from '../../../../service/connection-service';
import { ErrorService } from '../../../../service/error-service';

interface ModalShareComponentProps {
  callback: (subdivisionId: number | null) => void;
}

interface ModalShareComponentState {
  callback: (subdivisionId: number | null) => void;
  subdivisionList: Subdivision[];
  inputUserName: string;
  searchParamName: string;
  searchParamIsDisplayInvalid: boolean;
  searchParamPrefectureId: number;
  searchParamCityId: number;
  sortType: number;
  sortOrder: number;
  sortLimit: number;
  prefectureList: Prefecture[];
  cityList: City[];
  pageCurrent: number,
  pageMax: number,
  numberTotal: number; // 件数
  numberCurrentFrom: number; // 現在の表示件数
  numberCurrentTo: number; // 現在の表示件数
}

export class ModalSubdivisionPickerComponent extends React.Component<ModalShareComponentProps, ModalShareComponentState> {

  constructor(props: ModalShareComponentProps) {
    super(props);
    this.state = {
      callback: props.callback,
      subdivisionList: [],
      inputUserName: '',
      searchParamName: '',
      searchParamIsDisplayInvalid: false,
      searchParamPrefectureId: 0,
      searchParamCityId: 0,
      sortType: 1,
      sortOrder: 0,
      sortLimit: 25,
      prefectureList: [],
      cityList: [],
      pageCurrent: 1,
      pageMax: 1,
      numberTotal: 0,
      numberCurrentFrom: 0,
      numberCurrentTo: 0,
    };
  }

  render() {
    const subdivisionList: Subdivision[] = this.state.subdivisionList;
    const inputUserName: string = this.state.inputUserName;
    const searchParamPrefectureId: number = this.state.searchParamPrefectureId;
    const searchParamCityId: number = this.state.searchParamCityId;
    const prefectureList: Prefecture[] = this.state.prefectureList;
    const cityList: City[] = this.state.cityList;
    return (
      <div className={'modalInnerBody_wrap shareModal_wrap searchModal_wrap'}>
        <div className={'select_wrap flex_box flex_align_center'}>
          <div className={'left_wrap flex_box flex_align_center'}>
            <div>都道府県</div>
            <div>
              <select
                onChange={(e) => {
                  this.setState({ searchParamPrefectureId: Number(e.target.value) || 0, searchParamCityId: 0 });
                  this.postSearchAPI({
                    ...this.state,
                    searchParamPrefectureId: Number(e.target.value) || 0,
                    searchParamCityId: 0
                  });
                  DataManagerService.ins.getCityList(Number(e.target.value) || null)
                    .then((v) => {
                      this.setState({ cityList: v });
                    })
                    .catch((v) => {
                      this.setState({ cityList: v });
                    });
                }}
                value={searchParamPrefectureId}
              >
                <option
                  value={0}
                >
                  未選択
                </option>
                {prefectureList.map((prefecture: Prefecture) => (
                  <option
                    key={prefecture.id}
                    value={prefecture.id}
                  >
                    {prefecture.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className={'right_wrap flex_box flex_align_center'}>
            <div>市区町村</div>
            <div>
              <select
                onChange={(e) => {
                  this.setState({ searchParamCityId: Number(e.target.value) || 0 });
                  this.postSearchAPI({ ...this.state, searchParamCityId: Number(e.target.value) || 0 });
                }}
                value={searchParamCityId}
              >
                <option
                  value={0}
                >
                  未選択
                </option>
                {cityList.map((city: City) => (
                  <option
                    key={city.id}
                    value={city.id ?? ''}
                  >
                    {city.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className={'userSelect_wrap flex_box'}>
          {/* 未選択ユーザ一覧 */}
          <div className={'userList_noSelected_wrap'}>
            <div className={'divide10'} />
            <div>
              <input
                className={'textInput'}
                placeholder={'検索'}
                onChange={(e) => {
                  this.setState({ inputUserName: e.target.value });
                }}
              />
            </div>
            <div className={'divide10'} />
            <div className={'userList_noSelected'}>
              {subdivisionList.filter((v) => v.name.match(inputUserName) !== null).map((subdivision, i) => (
                <div
                  onClick={() => this.handlerClickSubdivision(subdivision)}
                  key={subdivision.id}
                >
                  {subdivision.name}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={'divide20'} />
        {/* ボタン */}
        <div className={'btn_wrap flex_box flex_space_between'}>
          <div>
            <button
              className={'reset btnStyle_1'}
              onClick={() => this.handlerClickBtnClose()}
            >閉じる
            </button>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount(): void {
    DataManagerService.ins.getSubdivisionList()
      .then((v) => this.setState({ subdivisionList: v }))
      .catch((v) => this.setState({ subdivisionList: v }));

    DataManagerService.ins.getPrefectureList()
      .then((v) => this.setState({ prefectureList: v }))
      .catch((v) => this.setState({ prefectureList: v }));
  }

  private postSearchAPI(state: ModalShareComponentState): void {
    const request: RequestSubdivisionGetSearch = new RequestSubdivisionGetSearch(
      state.searchParamName,
      state.sortLimit,
      state.pageCurrent,
      state.sortType,
      state.sortOrder,
      state.searchParamIsDisplayInvalid ? 0 : 1,
      state.searchParamPrefectureId,
      state.searchParamCityId,
    );
    ConnectionService.ins.connect(request)
      .then((res) => {
        const list: Subdivision[] = res.data.list;
        this.setState({
          subdivisionList: list,
          pageCurrent: state.pageCurrent,
          pageMax: Math.ceil(res.data.count / res.data.limit),
          numberTotal: res.data.count,
          numberCurrentFrom: res.data.from,
          numberCurrentTo: res.data.to,
        });
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }

  private handlerClickSubdivision(subdivision: Subdivision): void {
    this.state.callback(subdivision.id);
    ModalService.ins.close();
  }

  private handlerClickBtnClose(): void {
    ModalService.ins.close();
  }

}
