import {HTTPMethod, RequestBase} from './request-base';

export class RequestArticlesintroducedGetOne extends RequestBase {

  param: {
    id: number,
  };

  constructor(id: number) {
    super(HTTPMethod.GET, '/api/articlesintroduced/');
    this.param = {
      id: id,
    };
  }

  // getParam(): string {
  //   let param: string = '';
  //   Object.keys(this.param).forEach((v) => {
  //     param += `${this.param[v]}`;
  //   });
  //   return param;
  // }

  getUrl(): string {
    return this.host + this.endPoint + this.param.id;
  }

}
