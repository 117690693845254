// npm i 'blueimp-load-image'
import loadImg, { ExifTagValue, LoadImageOptions } from 'blueimp-load-image';


export class ImgRotater {

  static rotate(file : File): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      loadImg.parseMetaData(file, (data) => {
        const options: {
          canvas: boolean,
          orientation: ExifTagValue | null,
        } = {
          canvas: true,
          orientation: null,
        };
        if (data.exif) {
          options.orientation = data.exif.get('Orientation');
        }
        loadImg(file, (canvas) => {
          if(!canvas || !canvas.hasOwnProperty('toDataURL')){
            resolve(file);
            return;
          }
          const base64Image: string = (canvas as HTMLCanvasElement).toDataURL(file.type);
          const bin = atob(base64Image.replace(/^.*,/, ''));
          const buffer = new Uint8Array(bin.length);
          for (let i: number = 0; i < bin.length; i++) {
            buffer[i] = bin.charCodeAt(i);
          }
          resolve(new File([buffer.buffer], file.name, {type: file.type}));
        }, options as LoadImageOptions);
      });
    });
  }

}
