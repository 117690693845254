
export type SubdivisionDetailParam = {
  id: number | null,
  name: string,
  sales_status: number | null,
  reservation_flag: boolean | null,
  type: number | null,
  building_name: string,
  tsubo: number | null,
  price: number | null,
  prefecture_id: number | null,
  city: any,
  city_id: number | null,
  address: string,
  area_measuresize: number | null,
  purchasing_manager_id: number | null,
  user_id: number | null,
  build_datetime: Date | null,
  purchase_datetime: Date | null,
  has_purchase_card: boolean | null,
  customer_name: string,
  conclusion_datetime: Date | null,
  m_school_id: number | null,
  p_school_id: number | null,
  subdivision_id: number | null,
  period_start: Date | null,
  period_end: Date | null,
  message: string | null,
  email: string | null,
  reserve_count: number | null,
  area_count: number | null,
  area_max: number | null,
  remarks?: string | null,
  land_category?: number | null,
  use_district?: number | null,
  valid_flag?: number | null,
  subdivision_upper_limit_flag?: number | null,
  itemcontent?: string | null,
};

export class SubdivisionDetail {
  public id: number | null;
  public name: string;
  public sales_status: number | null;
  public reservation_flag: boolean | null;
  public type: number | null;
  public building_name: string;
  public tsubo: number | null;
  public price: number | null;
  public prefecture_id: number | null;
  public city: any;
  public city_id: number | null;
  public address: string;
  public area_measuresize: number | null;
  public purchasing_manager_id: number | null;
  public user_id: number | null;
  public build_datetime: Date | null;
  public purchase_datetime: Date | null;
  public has_purchase_card: boolean | null;
  public customer_name: string;
  public conclusion_datetime: Date | null;
  public m_school_id: number | null;
  public p_school_id: number | null;
  public subdivision_id: number | null;
  public period_start: Date | null;
  public period_end: Date | null;
  public message: string | null;
  public email: string | null;
  public reserve_count: number | null;
  public area_count: number | null;
  public area_max: number | null;
  public remarks?: string | null;
  public land_category?: number | null;
  public use_district?: number | null;
  public valid_flag?: number | null;
  public subdivision_upper_limit_flag?: number | null;
  public itemcontent?: string | null;

  constructor(param: SubdivisionDetailParam) {
    this.id = param.id;
    this.name = param.name;
    this.sales_status = param.sales_status;
    this.reservation_flag = param.reservation_flag;
    this.type = param.type;
    this.building_name = param.building_name;
    this.tsubo = param.tsubo;
    this.price = param.price;
    this.prefecture_id = param.prefecture_id;
    this.city = param.city;
    this.city_id = param.city_id;
    this.address = param.address;
    this.area_measuresize = param.area_measuresize;
    this.purchasing_manager_id = param.purchasing_manager_id;
    this.user_id = param.user_id;
    this.build_datetime = param.build_datetime;
    this.purchase_datetime = param.purchase_datetime;
    this.has_purchase_card = param.has_purchase_card;
    this.customer_name = param.customer_name;
    this.conclusion_datetime = param.conclusion_datetime;
    this.m_school_id = param.m_school_id;
    this.p_school_id = param.p_school_id;
    this.subdivision_id = param.subdivision_id;
    this.period_start = param.period_start;
    this.period_end = param.period_end;
    this.message = param.message;
    this.email = param.email;
    this.reserve_count = param.reserve_count;
    this.area_count = param.area_count;
    this.area_max = param.area_max;
    this.remarks = param.remarks;
    this.land_category = param.land_category;
    this.use_district = param.use_district;
    this.valid_flag = param.valid_flag;
    this.subdivision_upper_limit_flag = param.subdivision_upper_limit_flag;
    this.itemcontent = param.itemcontent;

  }
}
