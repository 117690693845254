
export class RoutingPass {

  private static _LOGIN: string = '/login';
  static get LOGIN(): string {
    return this._LOGIN;
  }

  private static _SETTING_PASS: string = '/setting-pass';
  static get SETTING_PASS(): string {
    return this._SETTING_PASS;
  }

  private static _ERROR: string = '/error';
  static get ERROR(): string {
    return this._ERROR;
  }

  private static _ARTICLE_LIST: string = '/article-list';
  static get ARTICLE_LIST(): string {
    return this._ARTICLE_LIST;
  }

  private static _ARTICLE_DETAIL: string = '/article-detail';
  static get ARTICLE_DETAIL(): string {
    return this._ARTICLE_DETAIL;
  }

  private static _ARTICLE_NEW: string = '/article-detail/0';
  static get ARTICLE_NEW(): string {
    return this._ARTICLE_NEW;
  }

  private static _ARTICLE_LIST_INTRODUCED: string = '/article-list-introduced';
  static get ARTICLE_LIST_INTRODUCED(): string {
    return this._ARTICLE_LIST_INTRODUCED;
  }

  private static _ARTICLE_DETAIL_INTRODUCED: string = '/article-detail-introduced';
  static get ARTICLE_DETAIL_INTRODUCED(): string {
    return this._ARTICLE_DETAIL_INTRODUCED;
  }

  private static _ARTICLE_NEW_INTRODUCED: string = '/article-detail-introduced/0';
  static get ARTICLE_NEW_INTRODUCED(): string {
    return this._ARTICLE_NEW_INTRODUCED;
  }

  private static _ARTICLE_RELEASE: string = '/article-release';
  static get ARTICLE_RELEASE(): string {
    return this._ARTICLE_RELEASE;
  }

  private static _ARTICLE_RESERVATION: string = '/article-reservation';
  static get ARTICLE_RESERVATION(): string {
    return this._ARTICLE_RESERVATION;
  }

  private static _MASTER_PREFECTURE: string = '/master-prefecture';
  static get MASTER_PREFECTURE(): string {
    return this._MASTER_PREFECTURE;
  }

  private static _MASTER_CITY: string = '/master-city';
  static get MASTER_CITY(): string {
    return this._MASTER_CITY;
  }

  private static _MASTER_PRIMARY_SCHOOL: string = '/master-primary-school';
  static get MASTER_PRIMARY_SCHOOL(): string {
    return this._MASTER_PRIMARY_SCHOOL;
  }

  private static _MASTER_MIDDLE_SCHOOL: string = '/master-middle-school';
  static get MASTER_MIDDLE_SCHOOL(): string {
    return this._MASTER_MIDDLE_SCHOOL;
  }

  private static _MASTER_STORE: string = '/master-store';
  static get MASTER_STORE(): string {
    return this._MASTER_STORE;
  }

  private static _MASTER_USER: string = '/master-user';
  static get MASTER_USER(): string {
    return this._MASTER_USER;
  }

  private static _MASTER_SUB_DIVISION: string = '/master-subdivision';
  static get MASTER_SUB_DIVISION(): string {
    return this._MASTER_SUB_DIVISION;
  }

  private static _SUBDIVISION_DETAIL_COMPONENT: string = '/subdivision-detail';
  static get SUBDIVISION_DETAIL_COMPONENT(): string {
    return this._SUBDIVISION_DETAIL_COMPONENT;
  }
}
