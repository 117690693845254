import * as React from 'react';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import './article-list-component.scss';
import { HeaderComponent } from '../../common/header/header-component';
import { SideMenuComponent } from '../../common/side-menu/side-menu-component';
import { PagerComponent } from '../../common/pager/pager-component';
import { DateFormat, DateFormatter } from '../../../util/date-formatter';
import { RoutingPass } from '../../../constant/routing-pass';
import { RequestArticlesGetSearch } from '../../../model/api/request/request-articles-get-search';
import { ConnectionService } from '../../../service/connection-service';
import { ErrorService } from '../../../service/error-service';
import { ResponseArticlesGetSearch } from '../../../model/api/response/response-articles-get-search';
import { User } from '../../../model/user';
import { DataManagerService } from '../../../service/data-manager-service';
import { RequestArticlesintroducedGetSearch } from '../../../model/api/request/request-articlesintroduced-get-search';
import { hasOwnProperty } from 'tslint/lib/utils';
import iconCheckBoxChecked from '../../../img/common/checkbox_checked.png';
import iconCheckBox from '../../../img/common/checkbox.png';
import { MoneyFormatter } from '../../../util/money-formatter';
import { useDidMount } from '../../../life-cycle/life-cycle';
import { State } from '../../../redux/root.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { ArticleListCollection } from './article-list-collection';
import { RowData, RowDataIntroduced } from './article-list-model';
import { RequestArticlesintroducedGetCsv_export } from '../../../model/api/request/request-articlesintroduced-get-csv_export';
import { RequestArticlesGetCsv_export } from '../../../model/api/request/request-articles-get-csv_export';
import { DatePickerComponent } from '../../common/date-picker/date-picker-component';

type FromTo<T> = {
  from: T | null;
  to: T | null;
};

export const ArticleListComponent = () => {
  const dispatch = useDispatch();
  const hash = window.location.hash;
  const isIntroduced = window.location.hash.match(RoutingPass.ARTICLE_LIST_INTRODUCED) !== null;

  const [tableData, setTableData] = useState([]);
  const [userList, setUserList] = useState<User[]>([]);

  // 検索State S = search
  const [sName, setSName] = useState('');
  const [sDate, setSDate] = useState<FromTo<Date>>({ from: null, to: null });
  const [sIsInvalid, setSIsInvalid] = useState(false);

  // 詳細検索
  const [sIsDetailSearch, setSIsDetailSearch] = useState(false);
  const [sTsubo, setSTsubo] = useState<FromTo<string>>({ from: null, to: null });
  const [sArea, setSArea] = useState<FromTo<string>>({ from: null, to: null });
  const [sPrice, setSPrice] = useState<FromTo<number>>({ from: null, to: null });
  const [sSubDivision, setSSubDivision] = useState('');
  const [sFreeWord, setSFreeWord] = useState('');
  const [sUser, setSUser] = useState('');

  // ソート
  const [sortType, setSortType] = useState(1);
  const [sortOrder, setSortOrder] = useState(0);
  const [sortLimit, setSortLimit] = useState(25);

  const [numberCurrent, setNumberCurrent] = useState<FromTo<number>>({ from: 0, to: 0 });
  const [numberTotal, setNumberTotal] = useState<number>(0);

  // ページャ
  const [pageCurrent, setPageCurrent] = useState<number>(1);
  const [pageTotal, setPageTotal] = useState<number>(0);

  const headerData = isIntroduced ? ArticleListCollection.headerDataIntroduced : ArticleListCollection.headerData;

  const createTableData = useCallback(
    (list: any[] = [], usersList: User[] = []) => {
      const tableData: any = [];
      let users = usersList;
      if (usersList.length < 1) {
        users = userList;
      }
      try {
        const getUserName = (artic: any) => {
          const user = users.find((v) => +v.id === +artic['user_id']);
          return user ? user.fullname.value : '';
        };
        list.forEach((article) => {
          if (isIntroduced) {
            tableData.push({
              id: Number(article.id),
              name: article['name'],
              address: `${article['prefecture']['name']} ${article['city']['name']} ${article['address'] || ''}`, // 物件所在地
              area_measuresize: Number(article['area_measuresize']), // 面積
              store: article['user']['store'], // 支店別
              price: MoneyFormatter.thousandSeparate(article['price']),// 販売価格
              user_name: getUserName(article), // 担当者
              land_category: getLandCategoryLabel(+article['land_category']),
              use_district: getUseDistrictLabel(+article['use_district']),
              valid_flag: article['valid_flag'],
            } as RowDataIntroduced);
          } else {
            tableData.push(
              {
                id: Number(article['id']),
                sales_status: Number(article['sales_status']),
                type: article['type'] === '1' ? '売地' : (
                  article['type'] === '2' ? '建売' : '分譲'
                ),
                name: article['name'],
                address: `${article['prefecture']['name']} ${article['city']['name']} ${article['address'] || ''}`,
                area_measuresize: Number(article['area_measuresize']),
                purchasing_manager: getUserName(article),
                purchase_datetime: DateFormatter.str2date(article['purchase_datetime']),
                store: article['user']['store'],
                price: MoneyFormatter.thousandSeparate(article['price']),
                has_purchase_card: +article['has_purchase_card'] ? '有り' : '無し',
                customer_name: article['customer_name'],
                user_name: users.find((v) => +v.id === +article['user_id'])?.fullname.value || '',
                conclusion_datetime: DateFormatter.str2date(article['conclusion_datetime']),
              } as RowData
            );
          }
        });
      } catch (e) {
        return [];
      }
      return tableData;
    },
    [hash, userList],
  );

  const postSearchAPI = useCallback(
    (userList?: User[]) => {
      const tsuboFrom = Number(sTsubo.from) || null;
      const tsuboTo = Number(sTsubo.to) || null;
      const areaFrom = Number(sArea.from) || null;
      const areaTo = Number(sArea.to) || null;
      const priceFrom = Number(sPrice.from) || null;
      const priceTo = Number(sPrice.to) || null;
      const location = sFreeWord || null;
      const subdivision = sSubDivision || null;
      const request: RequestArticlesGetSearch | RequestArticlesintroducedGetSearch = isIntroduced ? (
        new RequestArticlesintroducedGetSearch(
          {
            q: sName,
            limit: sortLimit,
            p: pageCurrent,
            sort: sortType,
            is_descending: sortOrder,
            prefecture_id: null,
            city_id: null,
            mschool_id: null,
            pschool_id: null,
            user_id: null,
            purchasing_manager_id: null,
            purchase_datetime_start: DateFormatter.date2str(sDate.from),
            purchase_datetime_end: DateFormatter.date2str(sDate.to),
            tsubo_start: tsuboFrom,
            tsubo_end: tsuboTo && tsuboFrom && tsuboTo < tsuboFrom ? tsuboFrom : tsuboTo,
            area_measuresize_start: areaFrom,
            area_measuresize_send: areaTo && areaFrom && areaTo < areaFrom ? areaFrom : areaTo,
            user: sUser,
            valid_flag: Number(!sIsInvalid),
            price_start: priceFrom,
            price_end: priceTo,
            location,
          }
        )
      ) : (
        new RequestArticlesGetSearch(
          sName,
          sortLimit,
          pageCurrent,
          sortType,
          sortOrder,
          null,
          null,
          null,
          null,
          null,
          null,
          DateFormatter.date2str(sDate.from),
          DateFormatter.date2str(sDate.to),
          tsuboFrom,
          tsuboTo && tsuboFrom && tsuboTo < tsuboFrom ? tsuboFrom : tsuboTo,
          areaFrom,
          areaTo && areaFrom && areaTo < areaFrom ? areaFrom : areaTo,
          sUser,
          Number(!sIsInvalid),
          priceFrom,
          priceTo,
          location,
          subdivision,
        )
      );
      if (tsuboTo && tsuboFrom && tsuboTo < tsuboFrom) {
        setSTsubo({ ...sTsubo, to: tsuboFrom ? tsuboFrom + '' : null });
      }
      if (areaTo && areaFrom && areaTo < areaFrom) {
        setSArea({ ...sArea, to: areaFrom ? areaFrom + '' : null });
      }
      ConnectionService.ins.connect(request)
        .then((res: ResponseArticlesGetSearch) => {
          if (!res.data) return;
          const d = res.data;
          const list = d.list;
          setTableData(createTableData(list, userList || []));
          setPageCurrent(pageCurrent);
          setPageTotal(Math.ceil(d.count / d.limit));
          setNumberTotal(d.count);
          setNumberCurrent({ from: d.from, to: d.to });
        })
        .catch((err) => {
          ErrorService.ins.response(err);
        });
    },
    [sTsubo, sArea, sPrice.from, sPrice.to, sFreeWord, sSubDivision, isIntroduced, sName, sortLimit, pageCurrent, sortType, sortOrder, sDate.from, sDate.to, sUser, sIsInvalid, createTableData],
  );

  const handlerClickBtnExport = useCallback(
    () => {
      const tsuboFrom = Number(sTsubo.from) || null;
      const tsuboTo = Number(sTsubo.to) || null;
      const areaFrom = Number(sArea.from) || null;
      const areaTo = Number(sArea.to) || null;
      const priceFrom = Number(sPrice.from) || null;
      const priceTo = Number(sPrice.to) || null;
      const locationA = sFreeWord || null;
      const sendParam = {
        q: sName,
        limit: sortLimit,
        p: pageCurrent,
        sort: sortType,
        is_descending: sortOrder,
        prefecture_id: null,
        city_id: null,
        mschool_id: null,
        pschool_id: null,
        user_id: null,
        purchasing_manager_id: null,
        purchase_datetime_start: DateFormatter.date2str(sDate.from),
        purchase_datetime_end: DateFormatter.date2str(sDate.to),
        tsubo_start: tsuboFrom,
        tsubo_end: tsuboTo && tsuboFrom && tsuboTo < tsuboFrom ? tsuboFrom : tsuboTo,
        area_measuresize_start: areaFrom,
        area_measuresize_end: areaTo && areaFrom && areaTo && areaTo < areaFrom ? areaFrom : areaTo,
        price_start: priceFrom,
        price_end: priceTo,
        location: locationA
      };
      const request = isIntroduced ? (
        new RequestArticlesintroducedGetCsv_export(sendParam)
      ) : (
        new RequestArticlesGetCsv_export(sendParam)
      );
      ConnectionService.ins.connect(request)
        .then((res) => {
          const url: string = window.URL.createObjectURL(res);
          const link: HTMLAnchorElement = document.createElement('a');
          link.href = url;
          link.target = '_blank';
          link.setAttribute('download', 'export.csv');
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          ErrorService.ins.response(err);
        });
    },
    [isIntroduced, pageCurrent, sArea.from, sArea.to, sDate.from, sDate.to, sFreeWord, sName, sPrice.from, sPrice.to, sTsubo.from, sTsubo.to, sortLimit, sortOrder, sortType],
  );

  const handlerClickBtnNew = () => {
    /*ModalService.ins.display(
      <ModalArticleCreateComponent/>
    );*/
    if (isIntroduced) {
      dispatch(push(RoutingPass.ARTICLE_NEW_INTRODUCED));
    } else {
      dispatch(push(RoutingPass.ARTICLE_NEW));
    }
  };

  const handlerClickRow = (id: number) => {
    if (isIntroduced) {
      dispatch(push(RoutingPass.ARTICLE_DETAIL_INTRODUCED + `/${id}`));
    } else {
      dispatch(push(RoutingPass.ARTICLE_DETAIL + `/${id}`));
    }
  };


  const getNextDate = (date: Date | null) => {
    if (!date) return null;
    const nextDate: Date = new Date(date);
    nextDate.setDate(date.getDate() + 1);
    return nextDate;
  };

  const getLandCategoryLabel = (value: number) => {
    const landCategory = ArticleListCollection.landCategoryList.find((v) => v.value === value);
    return landCategory?.label || '';
  };

  const getUseDistrictLabel = (value: number) => {
    const useDistrict = ArticleListCollection.useDistrictList.find((v) => v.value === value);
    return useDistrict?.label || '無指定';
  };

  const splitString = (str: string) => {
    return (
      str.split('\n').map((label, i_label) => (
        <span key={label + '_' + i_label}>
          {i_label ? <br /> : ''}{label}
        </span>
      ))
    );
  };

  useEffect(() => {
    setTableData([]);
    const setData = (val: any, isCatch: boolean = false) => {
      setTableData(createTableData(tableData, isCatch ? [] : val));
      setUserList(isCatch ? [] : val);
      postSearchAPI(isCatch ? undefined : val);
    };
    DataManagerService.ins.getUserList()
      .then((v) => {
        setData(v);
      })
      .catch((v) => {
        setData(v, true);
      });
  }, [hash]);

  useEffect(() => {
    if (!tableData.length) return;
    postSearchAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortType, sortOrder, sortLimit, pageCurrent, sIsInvalid]);


  return (
    <div className={''}>

      {/* --- header --- */}
      <HeaderComponent />

      <div className={'body_wrap flex_box'}>

        {/* side menu */}
        <div className={'pc menu_wrap'}>
          <SideMenuComponent />
        </div>

        {/* <div className={'content_wrap cont_article_list'}> */}
        <div className={isIntroduced ? 'content_wrap cont_article_list introduced' : 'content_wrap cont_article_list'}>
          <div className={'contentInner_wrap'}>


            {/* ------------------------------------------------------------- */}

            {/* タイトル */}
            <div className={'contentHead_wrap'}>
              <div className={'contentHeadInner_wrap flex_box flex_align_center flex_space_between'}>
                <div className={'contentHead_text flex_box flex_align_center'}>
                  <span>{isIntroduced ? ('不動産紹介') : ('土地管理')}</span>
                  <span className={'innerBar'} />
                  <span>一覧画面</span>
                </div>
                <div className={'contentHead_right'}>
                  <button
                    className={'reset btnStyle_1'}
                    onClick={handlerClickBtnNew}
                  >
                    新規登録
                  </button>
                </div>
              </div>
            </div>
            {/** contentHead_wrap */}

            {/* ------------------------------------------------------------- */}

            {/* 検索 */}
            <div className={'searchArea'}>

              {/* 基本検索 */}
              <div className={'searchDefault_wrap flex_box flex_align_center'}>
                {/* 物件・住所 */}
                <div className={'left_wrap flex_box flex_align_center'}>
                  <div>物件</div>
                  <div className={'input_wrap'}>
                    <input
                      className={'textInput'}
                      type={'search'}
                      onChange={(e) => setSName(e.target.value)}
                    />
                  </div>
                </div>
                {/* 仕入れ年月日 */}
                <div className={'right_wrap flex_box flex_align_center'}>
                  <div>仕入れ<br className={'br_992_1199'} />年月日</div>
                  <div className={'input_wrap flex_box flex_align_center'}>
                    <div>
                      <DatePickerComponent
                        min={null}
                        max={null}
                        current={sDate.from}
                        onChange={(date) => {
                          if (date) {
                            if (sDate.to) {
                              setSDate({ from: date, to: date >= sDate.to ? getNextDate(date) : sDate.to });
                            } else {
                              setSDate({ from: date, to: sDate.to });
                            }
                          } else {
                            setSDate({ from: null, to: null });
                          }
                        }}
                      />
                    </div>
                    <div className={'space_wrap'}>~</div>
                    <div>
                      <DatePickerComponent
                        min={getNextDate(sDate.from)}
                        max={null}
                        current={sDate.to}
                        onChange={(date) => setSDate({ ...sDate, to: date })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/** searchDefault_wrap */}

              <div className={'divide10'}></div>

              {/* 有効・無効切り替え */}
              <div
                className={'checkbox_wrap flex_box flex_align_center'}
                onClick={() => setSIsInvalid(!sIsInvalid)}
              >
                {sIsInvalid ? (
                  <div className={'innerImg checked'}><img src={iconCheckBoxChecked} /></div>
                ) : (
                  <div className={'innerImg noCheck'}><img src={iconCheckBox} /></div>
                )}
                <div className={'innerText selectDisabled'}>
                  {isIntroduced ? (
                    '非公開データを含める'
                  ) : (
                    '販売終了データを含める'
                  )}
                </div>
              </div>

              {/** ------------------------------------------------------------------- */}

              <div className={'divide10'} />

              <div className={'btn_wrap position_r'}>

                {/* 検索ボタン */}
                {sIsDetailSearch ? (
                  <></>
                ) : (
                  <div className={'btn_search_wrap'}>
                    <button
                      className={'reset btnStyle_1 marg_auto'}
                      onClick={() => postSearchAPI()}
                    >
                      検索
                    </button>
                  </div>
                )}

                {/* 詳細検索表示ボタン */}
                <div className={'btn_searchMore_wrap'}>
                  <button
                    className={'reset btnStyle_1'}
                    onClick={() => setSIsDetailSearch(!sIsDetailSearch)}
                  >
                    {sIsDetailSearch ? <>▲</> : <>▼</>}&nbsp;詳細検索
                  </button>
                </div>

              </div>
              {/** btn_wrap */}

              {/** ------------------------------------------------------------------- */}

              {/* 詳細検索 */}
              {sIsDetailSearch ? (
                <div className={'searchDetail_wrap'}>

                  <div className={'input_col_wrap flex_box flex_align_center'}>
                    {/* 坪数 */}
                    <div className={'input_1col_wrap flex_box flex_align_center'}>
                      <div>坪数</div>
                      <div className={'flex_box flex_align_center'}>
                        <div>
                          <input
                            className={'textInput'}
                            type={'number'}
                            value={sTsubo.from || ''}
                            onChange={(e) => {
                              const fromNum: number = Number(e.target.value) || 0;
                              const toNum: number = Number(sTsubo.to) || 0;
                              if (toNum < fromNum) {
                                setSTsubo({
                                  from: e.target.value,
                                  to: e.target.value
                                });
                              } else {
                                setSTsubo({ ...sTsubo, from: e.target.value });
                              }
                            }}
                          />
                        </div>
                        <div className={'space_wrap'}>~</div>
                        <div>
                          <input
                            className={'textInput'}
                            type={'number'}
                            value={sTsubo.to ?? ''}
                            onChange={(e) => setSTsubo({ ...sTsubo, to: e.target.value })}
                          />
                        </div>
                      </div>
                    </div>
                    {/* 面積 */}
                    <div className={'input_1col_wrap flex_box flex_align_center'}>
                      <div>面積</div>
                      <div className={'flex_box flex_align_center'}>
                        <div>
                          <input
                            className={'textInput'}
                            type={'number'}
                            value={sArea.from || ''}
                            onChange={(e) => {
                              const fromNum: number = Number(e.target.value) || 0;
                              const toNum: number = Number(sArea.to) || 0;
                              if (toNum < fromNum) {
                                setSArea({
                                  from: e.target.value,
                                  to: e.target.value
                                });
                              } else {
                                setSArea({ ...sArea, from: e.target.value });
                              }
                            }}
                          />
                        </div>
                        <div className={'space_wrap'}>~</div>
                        <div>
                          <input
                            className={'textInput'}
                            type={'number'}
                            value={sArea.to ?? ''}
                            onChange={(e) => setSArea({ ...sArea, to: e.target.value })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={'input_col_wrap flex_box flex_align_center'}>
                    {/* 価格 */}
                    <div className={'input_1col_wrap flex_box flex_align_center'}>
                      <div>価格</div>
                      <div className={'flex_box flex_align_center'}>
                        <div className={'flex_box flex_align_center'}>
                          <input
                            className={'textInput'}
                            type={'number'}
                            value={sPrice.from ?? ''}
                            onChange={(e) => {
                              const fromNum: number = Number(e.target.value) || 0;
                              const toNum: number = Number(sPrice.to) || 0;
                              if (toNum < fromNum) {
                                setSPrice({
                                  from: Number(e.target.value),
                                  to: Number(e.target.value),
                                });
                              } else {
                                setSPrice({ ...sPrice, from: Number(e.target.value) ?? 0 });
                              }
                            }}
                          />&nbsp;円
                        </div>
                        <div className={'space_wrap'}>~</div>
                        <div className={'flex_box flex_align_center'}>
                          <input
                            className={'textInput'}
                            type={'number'}
                            value={sPrice.to ?? ''}
                            onChange={(e) => setSPrice({ ...sPrice, to: Number(e.target.value) ?? null })}
                          />&nbsp;円
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={'input_col_wrap flex_box flex_align_center'}>
                    {/* 仕入れ責任者 */}
                    <div className={'input_1col_wrap flex_box flex_align_center'}>
                      <div>仕入れ責任者・営業担当者名</div>
                      <div>
                        <input
                          className={'textInput'}
                          type={'search'}
                          onChange={(e) => setSUser(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* 担当 */}
                    {/*<div className={'input_1col_wrap flex_box flex_align_center'}>
                      <div>担当</div>
                      <div><input className={'textInput'} type={'search'}/></div>
                    </div>*/}
                  </div>

                  <div className={'input_col_wrap flex_box flex_align_center'}>
                    {/* 物件所在地フリーワード検索 */}
                    <div className={'input_1col_wrap flex_box flex_align_center'}>
                      <div>物件所在地フリーワード検索</div>
                      <div>
                        <input
                          value={sFreeWord || ''}
                          className={'textInput'}
                          type={'text'}
                          onChange={(e) => setSFreeWord(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={'input_col_wrap flex_box flex_align_center'}>
                    {/* 分譲地検索 */}
                    <div className={'input_1col_wrap flex_box flex_align_center'}>
                      <div>分譲地検索</div>
                      <div>
                        <input
                          value={sSubDivision || ''}
                          className={'textInput'}
                          type={'text'}
                          onChange={(e) => setSSubDivision(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                </div>
              ) : (
                <></>
              )}
              {/** searchDetail_wrap */}

              {/* 検索ボタン */}
              {sIsDetailSearch ? (
                <>
                  <div className={'divide10'} />
                  <div className={'btn_search_wrap'}>
                    <button
                      className={'reset btnStyle_1 marg_auto'}
                      onClick={() => postSearchAPI()}
                    >
                      検索
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}

            </div>
            {/** searchArea */}

            {/* ------------------------------------------------------------- */}

            {/* ソート・ページャー */}
            <div className={'sort_pager_wrap'}>

              <div className={'sort_pager_inner_wrap flex_box flex_align_center flex_space_between'}>

                <div className={'select_wrap flex_box flex_align_center'}>
                  <div>
                    {/* ソート種類 */}
                    <select
                      onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                        setSortType(Number(e.target.value));
                        // this.postSearchAPI({ ...this.state, sortParamType: Number(e.target.value) });
                      }}
                      value={sortType}
                    >
                      {isIntroduced ? (
                        ArticleListCollection.sortTypeListIntroduced.map((sortType) => (
                          <option key={sortType.value} value={sortType.value}>{sortType.label}</option>
                        ))
                      ) : (
                        ArticleListCollection.sortTypeList.map((sortType) => (
                          <option key={sortType.value} value={sortType.value}>{sortType.label}</option>
                        ))
                      )}
                    </select>
                  </div>
                  <div>
                    {/* 降順・昇順 */}
                    <select
                      onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                        setSortOrder(Number(e.target.value));
                        // this.postSearchAPI({ ...this.state, sortParamIsDescending: Number(e.target.value) });
                      }}
                      value={Number(sortOrder)}
                    >
                      {ArticleListCollection.sortOrderList.map((sortOrder) => (
                        <option key={sortOrder.value} value={sortOrder.value}>{sortOrder.label}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    {/* 表示件数 */}
                    <select
                      onChange={(e) => setSortLimit(Number(e.target.value))}
                      value={sortLimit}
                    >
                      {ArticleListCollection.sortLimitList.map((sortLimit) => (
                        <option key={sortLimit.value} value={sortLimit.value}>{sortLimit.label}</option>
                      ))}
                    </select>
                  </div>
                </div>
                {/** select_wrap */}

                <div className={'btn_wrap flex_box flex_align_center'}>
                  <div className={'sp_pagenum_wrap flex_box flex_align_center'}>
                    <div>{numberCurrent.from}~{numberCurrent.to}件</div>
                    <div>/</div>
                    <div>全{numberTotal}件</div>
                  </div>
                  <button
                    className={'reset btnStyle_1'}
                    onClick={handlerClickBtnExport}
                  >
                    出力
                  </button>
                </div>
                {/** btn_wrap */}

              </div>

              <div className={'divide10'} />

              <div className={'sort_pager_inner_wrap flex_box flex_align_center flex_space_between'}>

                <div className={'pagenum_wrap'}>
                  <div className={'pagenumInner_wrap flex_box flex_align_center'}>
                    <div>{numberCurrent.from}~{numberCurrent.to}件</div>
                    <div>/</div>
                    <div>全{numberTotal}件</div>
                  </div>
                </div>
                {/** pagenum_wrap */}

                <PagerComponent
                  currentPage={pageCurrent}
                  max={pageTotal}
                  callback={setPageCurrent}
                />

              </div>
              {/** sort_pager_inner_wrap */}

            </div>
            {/** sort_pager_wrap */}

            <div className={'divide10'} />

            {/* ------------------------------------------------------------- */}

            {/* 表 */}
            <div className={'tableArea'}>
              <div className={'table_wrap'}>

                {/* ヘッダ */}
                <div className={'table_row table_head'}>
                  {
                    headerData.map((v, i_cell) => (
                      <div
                        className={'table_cell'}
                        key={v.value + i_cell}
                      >
                        {
                          v.label === '面積' ? (
                            <>面積 (m&sup2;)</>
                          ) : (
                            <>{splitString(v.label)}</>
                          )
                        }
                      </div>
                    ))
                  }
                </div>

                {/* ボディ */}
                {/* row */}
                {tableData
                  /*.filter((row: RowData) => (
                    searchParamIsDisplayInvalid ||
                    !(hasOwnProperty(row, 'sales_status') && +row.sales_status === 6)
                  ))*/
                  .map((row: any, i_row: number) => {
                    const isSalesFinished: boolean =
                      (hasOwnProperty(row, 'sales_status') && +row.sales_status === 6) ||
                      Boolean(row.valid_flag && +row.valid_flag === 0);
                    return (
                      <div
                        className={'table_row table_body' + (isSalesFinished ? ' sales_finished' : '')}
                        key={row['id'] + '_' + i_row}
                        onClick={() => handlerClickRow(row.id)}
                      >
                        {/* cell */}
                        {headerData.map((header, i_cell) => (
                          <div
                            className={'table_cell'}
                            key={header.value + i_cell}
                          >
                            <div className={'sp_table_head'}>
                              {
                                header.label
                              }
                            </div>
                            <div className={'table_cell_content'}>
                              {
                                header.value === 'purchase_datetime' ||
                                  header.value === 'conclusion_datetime' ||
                                  header.value === 'sales_status'
                                  ? (
                                    ''
                                  ) : (
                                    row[header.value]
                                  )}
                              {header.value === 'purchase_datetime'
                                ? (
                                  DateFormatter.date2str(row[header.value], DateFormat.YYYYMMDD)
                                ) : (
                                  ''
                                )}
                              {header.value === 'conclusion_datetime'
                                ? (
                                  DateFormatter.date2str(row[header.value], DateFormat.YYYYMMDD)
                                ) : (
                                  ''
                                )}
                              {!isIntroduced && header.value === 'sales_status'
                                ? (
                                  <div className={`sales_status_${row[header.value]}`}>
                                    {
                                      ArticleListCollection.salesStatusList.find((v) => v.value === +row[header.value]) ? (
                                        ArticleListCollection.salesStatusList.find((v) => v.value === +row[header.value])?.label
                                      ) : (
                                        ''
                                      )
                                    }
                                  </div>
                                ) : (
                                  ''
                                )}
                            </div>
                          </div>
                        ))}

                      </div>
                    );

                  })}

              </div>
              {/** table_wrap */}
            </div>
            {/** tableArea */}

            {/* ------------------------------------------------------------- */}

          </div>
          {/** contentInner_wrap */}
        </div>
        {/** content_wrap */}
      </div>
      {/** body_wrap */}
    </div >
  );

};
