
export class Prefecture {

  constructor(
    public id: number,
    public name: string,
    public valid_flag: boolean
  ) {}

}
