
export class School {

  constructor(
    public id: number | null,
    public name: string,
    public name_kana: string,
    public address: string,
    public valid_flag: boolean,
    public prefecture_id: number,
    public city_id: number,
  ) {}

}
