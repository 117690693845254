import React from 'react';
import './App.scss';
import { BusyComponent } from './component/common/busy/busy-component';
// import { DebugComponent } from './component/common/debug/debug-component';
import { ModalComponent } from './component/common/modal/modal-component';
import { ElementId } from './constant/element-id';
// import { APIType, RequestBase } from './model/api/request/request-base';
import { Routes } from './routes/routes';
import { Config } from './config/config';

const App = () => {
  return <div className={'App'} id={ElementId.APP_ID}>
    <Routes />
    {/* modal */}
    <ModalComponent />

    {/* busy */}
    <BusyComponent />

    {/* debug */}
    {/* {RequestBase.API_TYPE === APIType.local ? (
      <DebugComponent />
    ) : (
      <></>
    )} */}
  </div>;
};

export default App;
