import { push } from 'connected-react-router';
import React, { useCallback, useState } from 'react';
import { KeyboardEvent } from 'react';
import { useDispatch } from 'react-redux';
import { RoutingPass } from '../../../constant/routing-pass';
import iconCheckBox from '../../../img/common/checkbox.png';
import iconCheckBoxChecked from '../../../img/common/checkbox_checked.png';
import { RequestUsersPostLogin } from '../../../model/api/request/request-users-post-login';
import { ResponseError } from '../../../model/api/response/response-error';
import { ConnectionService } from '../../../service/connection-service';
import { ErrorService } from '../../../service/error-service';
import { ModalService } from '../../../service/modal-service';
import { SideMenuService } from '../../../service/side-menu-service';
import { UserService } from '../../../service/user-service';
import './login-component.scss';
import { ModalForgetPassComponent } from './modal-forget-pass/modal-forget-pass-component';
import { useDidMount } from '../../../life-cycle/life-cycle';
import { SystemActions } from '../../../redux/system/system.action';
import { SideMenuType } from '../../common/side-menu/side-menu-component';

export const LoginComponent = () => {
  const dispatch = useDispatch();
  const [id, setId] = useState('');
  const [pass, setPass] = useState('');
  const [isHoldLogin, setIsHoldLogin] = useState(false);

  const handleClickLogin = useCallback(
    () => {
      const request: RequestUsersPostLogin = new RequestUsersPostLogin(id, pass);
      ConnectionService.ins.connect(request)
        .then((response) => {
          UserService.ins.destroy();
          SideMenuService.ins.destroy();
          dispatch(push(RoutingPass.ARTICLE_LIST));
        })
        .catch((err: ResponseError) => {
          ErrorService.ins.response(err);
        });
    },
    [dispatch, id, pass],
  );

  const handlerClickBtnForgetPass = () => {
    ModalService.ins.display(
      <ModalForgetPassComponent />
    );
  };

  const handlerPressEnter = (e: KeyboardEvent) => {
    if (e.key === 'Enter') handleClickLogin();
  };

  useDidMount(() => dispatch(SystemActions.setCurrentMenu(SideMenuType.article)))


  return (
    <div
      className={'login_wrap flex_box flex_align_center flex_content_center'}
    >
      <div className={'loginInner_wrap'}>
        <div className={'systemName align_c'}>不動産管理システム</div>

        <div className={'input_wrap'}>
          <input
            value={id}
            onChange={(e) => setId(e.target.value)}
            onKeyPress={handlerPressEnter}
            type={'text'}
            placeholder={'ログインアドレス'}
            className={'textInput'}
          />
        </div>

        <div className={'input_wrap'}>
          <input
            value={pass}
            onChange={(e) => setPass(e.target.value)}
            onKeyPress={handlerPressEnter}
            type={'password'}
            placeholder={'パスワード'}
            className={'textInput'}
          />
        </div>

        <div className={'keepLogin_wrap'}>
          <div
            className={
              'keepLoginInner_wrap flex_box flex_align_center flex_content_center cursor_p' +
              (isHoldLogin ? ' checked' : '')
            }
            onClick={() => setIsHoldLogin(!isHoldLogin)}
          >
            <div className={'innerImg noCheck'}>
              <img src={iconCheckBox} alt='' />
            </div>
            <div className={'innerImg checked'}>
              <img src={iconCheckBoxChecked} alt='' />
            </div>
            <div className={'innerText selectDisabled'}>
              ログイン状態を保持する
          </div>
          </div>
        </div>

        <div className={'btn_wrap'}>
          <button
            className={'reset btnStyle_1'}
            onClick={handleClickLogin}
          >
            ログイン
        </button>
        </div>

        <div
          className={
            'forgetPass flex_box flex_align_center flex_content_center'
          }
        >
          <div>パスワードを忘れた方は</div>
          <div>
            <button
              className={'reset'}
              onClick={handlerClickBtnForgetPass}
            >
              こちら
          </button>
          </div>
        </div>
      </div>
    </div>
  );
};
