import './modal-forget-pass-component.scss';
import { ModalService } from '../../../../service/modal-service';
import { ModalAlertComponent } from '../../../common/modal/modal-alert/modal-alert-component';
import { RequestUsersPostPassword_request } from '../../../../model/api/request/request-users-post-password_request';
import { ConnectionService } from '../../../../service/connection-service';
import { ErrorService } from '../../../../service/error-service';
import { useCallback, useState } from 'react';

export const ModalForgetPassComponent = () => {

  const [email, setEmail] = useState('');

  const handlerClickBtnCancel = () => {
    ModalService.ins.close();
  };


  const handlerClickBtnOk = useCallback(
    () => {
      if (email.length < 1) {
        ModalService.ins.push(
          <ModalAlertComponent
            msgList={['メールアドレスを入力してください。']}
            callback={() => {
              ModalService.ins.pop();
            }}
          />
        );
        return;
      }
      const request: RequestUsersPostPassword_request = new RequestUsersPostPassword_request(email);
      ConnectionService.ins.connect(request)
        .then((res) => {
          ModalService.ins.push(
            <ModalAlertComponent
              msgList={[
                '再設定用のURLがメールで送信されました。',
                'メールをご確認ください。'
              ]}
              callback={() => {
                ModalService.ins.close();
              }}
            />
          );
        })
        .catch((err) => {
          ErrorService.ins.response(err);
        });
    },
    [email],
  );

  return (
    <div className={'modalInnerBody_wrap alertModal_wrap'}>

      <div>
        パスワード再設定用リンクをメールで送信します。
    </div>

      <div className={'divide20'} />

      <div className={'msg_wrap'}>
        {/* メールアドレス */}
        <input
          onChange={(e) => setEmail(e.target.value)}
          className={'textInput'}
          placeholder={'メールアドレス'}
        />
        <div className={'divide20'} />
      </div>

      <div className={'divide20'} />

      <div className={'btn_wrap flex_box flex_space_between'}>
        <div>
          <button
            className={'reset btnStyle_1'}
            onClick={handlerClickBtnCancel}
          >
            キャンセル
        </button>
        </div>
        <div>
          <button
            className={'reset btnStyle_1'}
            onClick={handlerClickBtnOk}
          >
            OK
        </button>
        </div>
      </div>

    </div>
  );
};
