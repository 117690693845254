import {HTTPMethod, RequestBase} from './request-base';

export class RequestCitiesGetSearch extends RequestBase {

  param: {
    q: string,
    limit: number,
    p: number,
    sort: number,
    is_descending: number,
    valid_flag: number,
    prefecture_id: number,
  };

  constructor(
    q: string,
    limit: number,
    p: number,
    sort: number,
    is_descending: number,
    valid_flag: number,
    prefecture_id: number,
  ) {
    super(HTTPMethod.GET, '/api/cities/search');
    this.param = {
      q: q,
      limit: limit,
      p: p,
      sort: sort,
      is_descending: is_descending,
      valid_flag: valid_flag,
      prefecture_id: prefecture_id,
    };
  }

  // getParam(): string {
  //   let param: string = '?';
  //   Object.keys(this.param).forEach((v) => {
  //     if (this.param[v] === null) {
  //       return;
  //     }
  //     param += `${v}=${this.param[v]}&`;
  //   });
  //   return param;
  // }

  getUrl(): string {
    return this.host + this.endPoint + this.getParam();
  }

}
