import * as React from 'react';
import './modal-copy-component.scss';
import { ModalService } from '../../../../service/modal-service';
import { RequestArticlesCopy } from '../../../../model/api/request/request_articles_copy';
import { ConnectionService } from '../../../../service/connection-service';
import { ErrorService } from '../../../../service/error-service';
import { ModalAlertComponent } from '../../../common/modal/modal-alert/modal-alert-component';
import { replace } from 'connected-react-router';
import { RoutingPass } from '../../../../constant/routing-pass';
import { DispatchToProps } from '../../../../redux/store';
import { connect } from 'react-redux';

interface ModalCopyProps {
  articleId: number,
  landName: string,// 分譲地名
  objectName: string,// 物件名
  callback: (res: any) => Promise<void>;
  dispatch?: (action: any) => void;
}

interface ModalCopyState {
  objectName: string,// 物件名
}

export class ModalCopyBodyComponent extends React.Component<ModalCopyProps, ModalCopyState> {

  constructor(props: ModalCopyProps) {
    super(props);
    this.state = {
      objectName: this.props.objectName,
    };
  }

  render() {
    const objectNameVal: string = this.state.objectName;
    const disabled = objectNameVal.length < 1;
    return (
      <div className={'modalInnerBody_wrap articleCopyModal_wrap'}>

        <div className={'table_wrap'}>
          <div className={'table_row'}>
            <div className={'table_cell'}>
              分譲地名
            </div>
            <div className={'table_cell'}>
              <input
                className={'textInput'}
                value={this.props.landName}
                disabled
              />
            </div>
          </div>{/** table_row row_head */}

          <div className={'table_row'}>
            <div className={'table_cell'}>
              物件名*
            </div>
            <div className={'table_cell'}>
              <input
                className={'textInput'}
                value={objectNameVal}
                onChange={(e) => this.setState({
                  objectName: e.target.value,
                })}
              />
            </div>
          </div>{/** table_row row_body */}
        </div>{/** table_wrap */}

        <div className={'divide20'} />

        <div className={'btn_wrap flex_box flex_align_center flex_space_between'}>
          <div>
            <button
              className={'reset btnStyle_1'}
              onClick={() => ModalService.ins.close()}
            >戻る</button>
          </div>
          <div>
            <button
              className={'reset btnStyle_1 flex_box flex_align_center'}
              onClick={() => this.handlerClickBtnCopy()}
              disabled={disabled}
            >
              <span>物件コピー</span>
            </button>
          </div>
        </div>

      </div>
    );
  }

  private async handlerClickBtnCopy() {
    const request: RequestArticlesCopy = new RequestArticlesCopy(
      this.props.articleId,
      this.state.objectName,
    );
    await ConnectionService.ins.connect(request)
      .then(async (res) => {
        await this.props.callback(res).then(() => {
          ModalService.ins.pop();
          ModalService.ins.push(
            <ModalAlertComponent
              msgList={['物件コピーが完了しました。', 'コピーした物件を表示します。']}
              callback={() => {
                if (!this.props.dispatch) return;
                this.props.dispatch(replace(RoutingPass.ARTICLE_DETAIL + `/${res.data.id}`));
                ModalService.ins.close();
              }}
            />
          );
        });
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }
}

const mapDispatchToProps: DispatchToProps = (dispatch) => ({
  dispatch: (action: any) => dispatch(action),
});

export const ModalCopyComponent = connect(() => ({}), mapDispatchToProps)(ModalCopyBodyComponent);
