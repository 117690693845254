import { HTTPMethod, RequestBase, NullPermission } from './request-base';

type Param = NullPermission<{
  id: number,
  subdivision_id: number,
  customer_name: string,
  sales_staff: number,
  application_date: string,
  questionnaire_id: number,
}>;

export class RequestSubdivisionsQuestionNairePost extends RequestBase {
  constructor(
    public param: Param
  ) {
    super(HTTPMethod.POST, '/api/subdivisionsquestionnaire/');
  }

  getUrl(): string {
    return this.host + this.endPoint;
  }
}