

type Param = {

  subdivision_id: number,
  customer_name: string,
  sales_staff: number | null,
  user_id: number | null,
  questionnaire_id: number | null,
  application_date: Date | null,
  id: number | null,
  created_at: Date | null,
  updated_at: Date | null,
  deleted_at: Date | null,
  questionnaire_file_name: string;

};

export class SubdivisionQuestionnaire {
  public subdivision_id: number;
  public customer_name: string;
  public sales_staff: number | null;
  public user_id: number | null;
  public questionnaire_id: number | null;
  public application_date: Date | null;
  public id: number | null;
  public created_at: Date | null;
  public updated_at: Date | null;
  public deleted_at: Date | null;
  public questionnaire_file_name: string;

  constructor(param: Param
  ) {
    this.subdivision_id = param.subdivision_id;
    this.customer_name = param.customer_name;
    this.sales_staff = param.sales_staff;
    this.user_id = param.user_id;
    this.questionnaire_id = param.questionnaire_id;
    this.application_date = param.application_date;
    this.id = param.id;
    this.created_at = param.created_at;
    this.updated_at = param.updated_at;
    this.deleted_at = param.deleted_at;
    this.questionnaire_file_name = param.questionnaire_file_name;
  }
}
