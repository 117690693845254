import { HTTPMethod, RequestBase } from './request-base';

type Param = {
  q: string,
  limit: number,
  p: number,
  sort: number,
  is_descending: number,
  subdivision_id: number,
};

export class RequestSubdivisionsQuestionNaireGetSearch extends RequestBase {
  constructor(
    public param: Param
  ) {
    super(HTTPMethod.GET, '/api/subdivisionsquestionnaire/search');
  }

  getUrl(): string {
    return this.host + this.endPoint + this.getParam();
  }
}