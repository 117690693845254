import lodash from 'lodash';
import { Subdivision } from '../../../model/subdivision';
import { MasterListHeader } from '../../common/master-list/master-list-header';
import { MasterListRow } from '../../common/master-list/master-list-row';
import { Store } from '../../../redux/store';
import { SubdivisionActions } from '../../../redux/subdibision/subdivision.action';

type Param = {
  data: Subdivision[],
  isDisplayInvalid: boolean;
  callbackBtnEdit: (v: number) => void;
  callbackBtnInvalid: () => void;
  rowStatus: MasterListRow[] | null;
};

export class MasterSubdivisionModel {
  public data: Param;
  public tableData: MasterListRow[] = [];

  static headerList: MasterListHeader[] = [
    new MasterListHeader('id', 'No'),
    new MasterListHeader('name', '分譲地名'),
    new MasterListHeader('prefecture', '都道府県'),
    new MasterListHeader('city', '市区町村'),
    new MasterListHeader('address', '住所'),
    new MasterListHeader('reservation_count', 'WEB予約'),
    new MasterListHeader('area_max', '区画数'),
  ];

  static sortLimitList: { value: number, label: string; }[] = [
    { value: 10, label: '10件' },
    { value: 25, label: '25件' },
    { value: 50, label: '50件' },
    { value: 100, label: '100件' },
  ];

  static sortOrderList: { value: number, label: string; }[] = [
    { value: 0, label: '降順' },
    { value: 1, label: '昇順' },
  ];

  constructor(value: Param) {
    this.data = lodash.cloneDeep(value);
    this.tableData = lodash.cloneDeep(this.createTableData());
    this.returnData(lodash.cloneDeep(value.rowStatus));
    Store.dispatch(SubdivisionActions.setRowStatus(this.getRowStatus()));
  }

  /**
   * アコーディオンを開いてる状態に戻す
   * @param rowStatus rowの開いてる状態
   */
  private returnData(rowStatus: null | MasterListRow[]) {
    if (!rowStatus) return;
    rowStatus.forEach(v => {
      if (v.isParent && v.isOpen) {
        this.rowOpen(v.parentId as number);
      }
    });
  }


  /**
   * テーブルデータの生成
   * @returns テーブルデータに加工された配列
   */
  private createTableData() {
    const { data, isDisplayInvalid, callbackBtnEdit, callbackBtnInvalid } = this.data;
    return data.filter((v) => (isDisplayInvalid || (!isDisplayInvalid && v.valid_flag)))
      .map((v: any,) => (
        new MasterListRow(
          MasterSubdivisionModel.headerList.map((header) => {
            if (
              header.value === 'prefecture' ||
              header.value === 'city'
            ) {
              return v[header.value]['name'];
            }
            return v[header.value];
          }),
          +v.valid_flag === 1,
          () => callbackBtnEdit(v.id),
          () => callbackBtnInvalid(),
          false,
          true,
          v.id,
          undefined,
          !Array.isArray(v.child_subdivisions)
        )
      ));
  }


  /**
  * open
  * @param id 分譲地ID
  */
  public rowOpen(id: number) {
    const { data, callbackBtnEdit, callbackBtnInvalid } = this.data;

    const targetRowIndex = this.tableData.findIndex((v) => v.parentId === id && v.isParent);
    this.tableData[targetRowIndex].isOpen = true;


    const findData = data.find((v) => v.id === id)?.child_subdivisions || {};

    const targetArray = Object.keys(findData).map((v) => (findData as any)[v]);

    this.tableData.splice(
      targetRowIndex + 1,
      0,
      ...[...(targetArray.map((v: any, i) => (
        new MasterListRow(
          MasterSubdivisionModel.headerList.map((header) => {
            if (
              header.value === 'prefecture' ||
              header.value === 'city'
            ) {
              return (data.find((v) => v.id === id) as any)[header.value]['name'];
            } else if (header.value === 'id') {
              return `[${i + 1}]`;
            } else {
              return v[header.value];
            }
          }),
          +v.valid_flag === 1,
          () => callbackBtnEdit(Number(v.id) as number),
          () => callbackBtnInvalid(),
          true,
          false,
          this.tableData[targetRowIndex].parentId,
          v.sales_status
        )
      )))]);
    Store.dispatch(SubdivisionActions.setRowStatus(this.getRowStatus()));
  }

  /**
   * close
   * @param id 分譲地ID
   */
  public rowClose(id: number) {
    this.tableData = this.tableData.filter((v) => v.parentId !== id || (v.parentId === id && v.isParent));

    const targetRowIndex = this.tableData.findIndex((v) => v.parentId === id && v.isParent);
    this.tableData[targetRowIndex].isOpen = false;
    Store.dispatch(SubdivisionActions.setRowStatus(this.getRowStatus()));
  }


  /**
   * Redux保存用データ
   * アコーディオン状態維持に使う
   */
  public getRowStatus() {
    const list: MasterListRow[] = [];
    this.tableData.forEach(v => {
      if (v.isParent) list.push(v);
    });
    return lodash.cloneDeep(list);
  }
}