import { HTTPMethod, RequestBase } from './request-base';
import { strict } from 'assert';
import { string } from 'prop-types';

export class RequestArticlesPost extends RequestBase {

  param: {
    id: number,
    name: string,
    sales_status: number,
    type: number,
    prefecture_id: number,
    city_id: number,
    building_name: string,
    address: string,
    tsubo: number,
    price: number,
    area_measuresize: number,
    purchasing_manager_id: number,
    user_id: number,
    //REVIEW subdivision
    subdivision_id: number,
    build_datetime: string,
    purchase_datetime: string,
    has_purchase_card: boolean,
    customer_name: string,
    conclusion_datetime: string,
    pschool_id: number,
    mschool_id: number,
    //
    route1: string,
    station1: string,
    route2: string,
    station2: string,
    walk_time: number,
    private_road_measuresize: number,
    private_road_tsubo: number,
    tsubo_unit: number,
    ownership: number, //enum
    land_category: number, //enum
    city_planning: string,
    use_district: number, //enum
    coverage: number,
    floor_area: number,
    law_limitation: string,
    current_state: string,
    connecting_roads: string,
    equipment: string,
    remarks: string,
  };

  constructor(
    id: number,
    name: string,
    sales_status: number,
    type: number,
    prefecture_id: number,
    city_id: number,
    address: string,
    building_name: string,
    tsubo: number,
    price: number,
    area_measuresize: number,
    purchasing_manager_id: number,
    user_id: number,
    //REVIEW subdivision
    subdivision_id: number,
    build_datetime: string,
    purchase_datetime: string,
    has_purchase_card: boolean,
    customer_name: string,
    conclusion_datetime: string,
    pschool_id: number,
    mschool_id: number,
    //
    route1: string,
    station1: string,
    route2: string,
    station2: string,
    walk_time: number,
    private_road_measuresize: number,
    private_road_tsubo: number,
    tsubo_unit: number,
    ownership: number, //enum
    land_category: number, //enum
    city_planning: string,
    use_district: number, //enum
    coverage: number,
    floor_area: number,
    law_limitation: string,
    current_state: string,
    connecting_roads: string,
    equipment: string,
    remarks: string,
  ) {
    super(HTTPMethod.POST, '/api/articles');
    this.param = {
      id: id,
      name: name,
      sales_status: sales_status,
      type: type,
      prefecture_id: prefecture_id,
      city_id: city_id,
      address: address,
      building_name: building_name,
      tsubo: tsubo,
      price: price,
      area_measuresize: area_measuresize,
      purchasing_manager_id: purchasing_manager_id,
      user_id: user_id,
      subdivision_id: subdivision_id,
      build_datetime: build_datetime,
      purchase_datetime: purchase_datetime,
      has_purchase_card: has_purchase_card,
      customer_name: customer_name,
      conclusion_datetime: conclusion_datetime,
      pschool_id: pschool_id,
      mschool_id: mschool_id,
      //
      route1: route1,
      station1: station1,
      route2: route2,
      station2: station2,
      walk_time: walk_time,
      private_road_measuresize: private_road_measuresize,
      private_road_tsubo: private_road_tsubo,
      tsubo_unit: tsubo_unit,
      ownership: ownership, //enum
      land_category: land_category, //enum
      city_planning: city_planning,
      use_district: use_district, //enum
      coverage: coverage,
      floor_area: floor_area,
      law_limitation: law_limitation,
      current_state: current_state,
      connecting_roads: connecting_roads,
      equipment: equipment,
      remarks: remarks,
    };
  }


  createFormData(): FormData {
    const formData = new FormData();
    const keys = Object.keys(this.param);
    const values: (string | string[] | number | undefined | File | boolean)[] = Object.values(this.param);
    for (let i = 0; i < keys.length; i += 1) {
      if (keys[i] === 'ownership' || keys[i] === 'land_category' || keys[i] === 'use_district') {
        if (!values[i]) {
          formData.append(keys[i], '');
          continue;
        }
      }
      if (keys[i] === 'coverage' || keys[i] === 'floor_area') {
        if (!values[i]) {
          formData.append(keys[i], '0');
          continue;
        }
      }

      if (values[i] !== undefined && values[i] !== null) {
        formData.append(keys[i], values[i] as string);
      } else {
        formData.append(keys[i], '');
      }
    }
    formData.append('reservation_flag', '');
    return formData;
  }

  // getParam(): FormData {
  //   const formData: FormData = new FormData();
  //   Object.keys(this.param).forEach((v) => {
  //     /*if (v === 'id') {
  //       formData.append(v, '');
  //       return;
  //     }*/
  //     if (v === 'ownership' || v === 'land_category' || v === 'use_district') {
  //       if (!this.param[v]) {
  //         formData.append(v, '');
  //         return;
  //       }
  //     }
  //     if (v === 'coverage' || v === 'floor_area') {
  //       if (!this.param[v]) {
  //         formData.append(v, '0');
  //         return;
  //       }
  //     }
  //     //FIXME 新規登録エラー対応
  //     // if (v === 'area_measuresize' && !this.param[v]) {
  //     //   formData.append(v, '0');
  //     //   return;
  //     // }
  //     // if (v === 'tsubo' && !this.param[v]) {
  //     //   formData.append(v, '0');
  //     //   return;
  //     // }
  //     if (this.param[v] === null) {
  //       formData.append(v, '');
  //       return;
  //     }
  //     formData.append(v, this.param[v] + '');
  //   });
  //   formData.append('reservation_flag', '');
  //   return formData;
  // }

  getUrl(): string {
    return this.host + this.endPoint;
  }

}
