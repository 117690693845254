import * as React from 'react';
import * as Lodash from 'lodash';
import './modal-edit-school-component.scss';
import { School } from '../../../../model/school';
import { Prefecture } from '../../../../model/prefecture';
import iconCheckBoxChecked from '../../../../img/common/checkbox_checked.png';
import iconCheckBox from '../../../../img/common/checkbox.png';
import { ModalService } from '../../../../service/modal-service';
import { ModalAlertComponent } from '../../../common/modal/modal-alert/modal-alert-component';
import { City } from '../../../../model/city';
import { DataManagerService } from '../../../../service/data-manager-service';

interface ModalEditSchoolComponentProps {
  isPrimary: boolean;
  school: School;
  callback: (school: School) => void;
}

interface ModalEditSchoolComponentState {
  school: School;
  callback: (school: School) => void;
  prefectureList: Prefecture[];
  cityList: City[];
}

export class ModalEditSchoolComponent extends React.Component<ModalEditSchoolComponentProps, ModalEditSchoolComponentState> {

  /*private testPrefectureList: Prefecture[] = [
    new Prefecture(1, '福岡県', true),
    new Prefecture(2, '佐賀県', true),
    new Prefecture(3, '大分県', false),
    new Prefecture(4, '熊本県', true),
    new Prefecture(5, '宮崎県', false),
    new Prefecture(6, '長崎県', false),
    new Prefecture(7, '鹿児島県', false),
    new Prefecture(8, '沖縄県', false),
    new Prefecture(9, '山口県', false),
    new Prefecture(10, '島根県', false),
    new Prefecture(11, '鳥取県', false),
    new Prefecture(12, '広島県', false),
  ];*/

  /*private testCityList: City[] = [
    new City(1, '北九州市八幡西区', 1, true),
    new City(2, '北九州市八幡東区', 1, true),
    new City(3, '北九州市八幡北区', 1, true),
    new City(4, '北九州市八幡南区', 1, true),
    new City(5, '北九州市小倉北区', 1, false),
    new City(6, '北九州市小倉南区', 1, false),
    new City(7, '北九州市小倉東区', 1, false),
    new City(8, '北九州市小倉西区', 1, false),
    new City(9, '北九州市博多区', 1, true),
    new City(10, '北九州市門司区', 1, true),
  ];*/

  constructor(props: ModalEditSchoolComponentProps) {
    super(props);
    this.state = {
      school: Lodash.cloneDeep(props.school),
      callback: props.callback,
      prefectureList: [],
      cityList: [],
    };
  }

  render() {
    const isPrimary: boolean = this.props.isPrimary;
    const isNew: boolean = this.state.school.id === null;
    const school: School = this.state.school;
    const prefectureList: Prefecture[] = this.state.prefectureList;
    const cityList: City[] = this.state.cityList;
    return (
      <div className={'modalInnerBody_wrap masterEdit_modal'}>

        <div className={'masterEditInner_modal table_wrap'}>
          <div className={'table_row'}>
            <div className={'table_cell'}>都道府県*</div>
            <div className={'table_cell'}>
              <select
                value={school.prefecture_id || 0}
                onChange={(e) => {
                  school.prefecture_id = Number(e.target.value) || 0;
                  this.setState({ school: school });
                  DataManagerService.ins.getCityList(Number(e.target.value) || null)
                    .then((v) => {
                      this.setState({ cityList: v });
                    })
                    .catch((v) => {
                      this.setState({ cityList: v });
                    });
                }}
              >
                <option
                  value={0}
                >
                  未選択
                </option>
                {prefectureList.map((prefecture: Prefecture) => (
                  <option
                    key={prefecture.id}
                    value={prefecture.id}
                  >
                    {prefecture.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className={'table_row'}>
            <div className={'table_cell'}>市区町村*</div>
            <div className={'table_cell'}>
              <select
                value={school.city_id || 0}
                onChange={(e) => {
                  school.city_id = Number(e.target.value) || 0;
                  this.setState({ school: school });
                }}
              >
                <option
                  value={0}
                >
                  未選択
                </option>
                {(cityList).map((city) => (
                  <option
                    key={city.id ?? ''}
                    value={city.id ?? ''}
                  >
                    {city.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className={'table_row'}>
            <div className={'table_cell'}>
              住所
            </div>
            <div className={'table_cell'}>
              <input
                className={'textInput'}
                value={school.address || ''}
                onChange={(e) => {
                  school.address = e.target.value;
                  this.setState({ school: school });
                }}
              />
            </div>
          </div>

          <div className={'table_row'}>
            <div className={'table_cell'}>
              {isPrimary ? (
                '小学校名*'
              ) : (
                '中学校名*'
              )}
            </div>
            <div className={'table_cell'}>
              <input
                className={'textInput'}
                value={school.name || ''}
                onChange={(e) => {
                  school.name = e.target.value;
                  this.setState({ school: school });
                }}
              />
            </div>
          </div>

          <div className={'table_row'}>
            <div className={'table_cell'}>
              よみがな*
            </div>
            <div className={'table_cell'}>
              <input
                className={'textInput'}
                value={school.name_kana || ''}
                onChange={(e) => {
                  school.name_kana = e.target.value;
                  this.setState({ school: school });
                }}
              />
            </div>
          </div>

          {isNew ? (
            <></>
          ) : (
            <div className={'status_wrap table_row'}>
              <div className={'table_cell'}>有効/無効</div>
              {+school.valid_flag === 1 ? (
                <div
                  onClick={() => {
                    school.valid_flag = false;
                    this.setState({ school: school });
                  }}
                  className={'table_cell innerImg checked'}
                >
                  <img src={iconCheckBoxChecked} />
                </div>
              ) : (
                <div
                  onClick={() => {
                    school.valid_flag = true;
                    this.setState({ school: school });
                  }}
                  className={'table_cell innerImg noCheck'}
                >
                  <img src={iconCheckBox} />
                </div>
              )}
            </div>
          )}

        </div>

        <div className={'divide40'} />

        <div className={'btn_wrap flex_box flex_space_between'}>
          <div>
            <button
              className={'reset btnStyle_1'}
              onClick={() => this.handlerClickBtnCancel()}
            >
              キャンセル
            </button>
          </div>
          <div>
            <button
              className={'reset btnStyle_1'}
              onClick={() => this.handlerClickBtnOk()}
            >
              保存
            </button>
          </div>
        </div>

      </div>
    );
  }

  componentDidMount(): void {
    DataManagerService.ins.getPrefectureList()
      .then((v) => {
        this.setState({ prefectureList: v });
      })
      .catch((v) => {
        this.setState({ prefectureList: v });
      });
    DataManagerService.ins.getCityList(this.state.school.prefecture_id)
      .then((v) => {
        this.setState({ cityList: v });
      })
      .catch((v) => {
        this.setState({ cityList: v });
      });
  }

  private handlerClickBtnCancel(): void {
    ModalService.ins.close();
  }

  private handlerClickBtnOk(): void {
    const errorMsgList: string[] = this.checkValidation();
    if (errorMsgList.length > 0) {
      ModalService.ins.push(
        <ModalAlertComponent
          msgList={errorMsgList}
          callback={() => {
            ModalService.ins.pop();
          }}
        />
      );
      return;
    }
    this.state.callback(this.state.school);
  }

  private checkValidation(): string[] {
    const errorMsgList: string[] = [];
    const school: School = this.state.school;
    if (school.prefecture_id < 1) {
      errorMsgList.push('都道府県を選択してください。');
    }
    if (school.city_id < 1) {
      errorMsgList.push('市区町村を選択してください。');
    }
    if (school.name.length === 0) {
      errorMsgList.push('学校名を入力してください。');
    }
    return errorMsgList;
  }

}
