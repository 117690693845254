import * as React from 'react';
import * as Lodash from 'lodash';
import './modal-edit-user-component.scss';
import { Prefecture } from '../../../../model/prefecture';
import { City } from '../../../../model/city';
import { User } from '../../../../model/user';
import { ModalService } from '../../../../service/modal-service';
import { ModalAlertComponent } from '../../../common/modal/modal-alert/modal-alert-component';
import { DataManagerService } from '../../../../service/data-manager-service';
import { Store } from '../../../../model/store';
import iconCheckBoxChecked from '../../../../img/common/checkbox_checked.png';
import iconCheckBox from '../../../../img/common/checkbox.png';
import { UserService } from '../../../../service/user-service';

interface ModalEditUserComponentProps {
  user: User;
  callback: (user: User) => void;
}

interface ModalEditUserComponentState {
  user: User;
  callback: (user: User) => void;
  prefectureList: Prefecture[];
  cityList: City[];
  storeList: Store[];
  me: User | null;
}

export class ModalEditUserComponent extends React.Component<ModalEditUserComponentProps, ModalEditUserComponentState> {

  private testPrefectureList: Prefecture[] = [
    new Prefecture(1, '福岡県', true),
    new Prefecture(2, '佐賀県', true),
    new Prefecture(3, '大分県', false),
    new Prefecture(4, '熊本県', true),
    new Prefecture(5, '宮崎県', false),
    new Prefecture(6, '長崎県', false),
    new Prefecture(7, '鹿児島県', false),
    new Prefecture(8, '沖縄県', false),
    new Prefecture(9, '山口県', false),
    new Prefecture(10, '島根県', false),
    new Prefecture(11, '鳥取県', false),
    new Prefecture(12, '広島県', false),
  ];

  private testCityList: City[] = [
    new City(1, '北九州市八幡西区', 1, true),
    new City(2, '北九州市八幡東区', 1, true),
    new City(3, '北九州市八幡北区', 1, true),
    new City(4, '北九州市八幡南区', 1, true),
    new City(5, '北九州市小倉北区', 1, false),
    new City(6, '北九州市小倉南区', 1, false),
    new City(7, '北九州市小倉東区', 1, false),
    new City(8, '北九州市小倉西区', 1, false),
    new City(9, '北九州市博多区', 1, true),
    new City(10, '北九州市門司区', 1, true),
  ];

  private groupList: { value: number, label: string; }[] = [
    { value: 1, label: '管理者' },
    { value: 2, label: '一般ユーザ' },
  ];

  constructor(props: ModalEditUserComponentProps) {
    super(props);

    this.state = {
      user: Lodash.cloneDeep({ ...props.user, group_id: !props.user.group_id || Number(props.user.group_id) !== 8 ? props.user.group_id : 2 }),
      callback: props.callback,
      prefectureList: [],
      cityList: [],
      storeList: [],
      me: null,
    };
  }

  render() {
    const isNew: boolean = this.state.user.id === null;
    const user: User = this.state.user;
    const prefectureList: Prefecture[] = this.state.prefectureList;
    const cityList: City[] = this.state.cityList;
    const storeList: Store[] = this.state.storeList;
    const me = this.state.me;
    return (
      <div className={'modalInnerBody_wrap masterEdit_modal masterUserEdit_modal'}>

        <div className={'masterEditInner_modal table_wrap'}>
          <div className={'table_row'}>
            <div className={'table_cell'}>苗字*</div>
            <div className={'table_cell'}>
              <input
                className={'textInput'}
                value={user.last_name.value}
                onChange={(e) => {
                  user.last_name.value = e.target.value;
                  this.setState({ user: user });
                }}
              />
            </div>
          </div>

          <div className={'table_row'}>
            <div className={'table_cell'}>名前*</div>
            <div className={'table_cell'}>
              <input
                className={'textInput'}
                value={user.first_name.value}
                onChange={(e) => {
                  user.first_name.value = e.target.value;
                  this.setState({ user: user });
                }}
              />
            </div>
          </div>

          <div className={'table_row'}>
            <div className={'table_cell'}>メールアドレス*</div>
            <div className={'table_cell'}>
              <input
                className={'textInput'}
                value={user.email}
                onChange={(e) => {
                  user.email = e.target.value;
                  this.setState({ user: user });
                }}
              />
            </div>
          </div>

          <div className={'table_row'}>
            <div className={'table_cell'}>支店*</div>
            <div className={'table_cell'}>
              <select
                value={user.store_id.value}
                onChange={(e) => {
                  user.store_id.value = Number(e.target.value) || 0;
                  this.setState({ user: user });
                }}
              >
                <option
                  value={0}
                >
                  未選択
                </option>
                {storeList.map((store: Store) => (
                  <option
                    key={store.id}
                    value={store.id ?? ''}
                  >
                    {store.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {me && +user.id !== +me.id ? (
            <>
              <div className={'table_row'}>
                <div className={'table_cell'}>権限*</div>
                <div className={'table_cell'}>
                  <select
                    value={user.group_id || 2}
                    onChange={(e) => {
                      user.group_id = Number(e.target.value) || 2;
                      this.setState({ user: user });
                    }}
                  >
                    {this.groupList.map((v) => (
                      <option
                        key={v.value}
                        value={v.value}
                      >
                        {v.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className={'table_row'}>
                <div className={'table_cell'}>有効/無効</div>
                <div className={'table_cell'}>
                  <div
                    onClick={() => {
                      user.valid_flag = +user.valid_flag === 1 ? 0 : 1;
                      this.setState({ user: user });
                    }}
                  >
                    {+user.valid_flag ? (
                      <div className={'innerImg checked'}><img src={iconCheckBoxChecked} /></div>
                    ) : (
                      <div className={'innerImg noCheck'}><img src={iconCheckBox} /></div>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

        </div>

        <div className={'divide40'} />

        <div className={'flex_box flex_align_center flex_space_between'}>
          <div>
            <button
              className={'reset btnStyle_1'}
              onClick={() => this.handlerClickBtnCancel()}
            >
              キャンセル
            </button>
          </div>
          <div>
            <button
              className={'reset btnStyle_1'}
              onClick={() => this.handlerClickBtnOk()}
            >
              保存
            </button>
          </div>
        </div>

      </div>
    );
  }

  componentDidMount(): void {
    DataManagerService.ins.getStoreList()
      .then((v) => {
        this.setState({ storeList: v });
      })
      .catch((v) => {
        this.setState({ storeList: v });
      });
    UserService.ins.getMe()
      .then((v) => {
        this.setState({ me: v });
      });
  }

  private handlerClickBtnCancel(): void {
    ModalService.ins.close();
  }

  private handlerClickBtnOk(): void {
    const errorMsgList: string[] = this.checkValidation();
    if (errorMsgList.length > 0) {
      ModalService.ins.push(
        <ModalAlertComponent
          msgList={errorMsgList}
          callback={() => {
            ModalService.ins.pop();
          }}
        />
      );
      return;
    }
    this.state.callback(this.state.user);
  }

  private checkValidation(): string[] {
    const errorMsgList: string[] = [];
    const user: User = this.state.user;
    if (user.last_name.value.length === 0) {
      errorMsgList.push('苗字を入力してください。');
    }
    if (user.first_name.value.length === 0) {
      errorMsgList.push('名前を入力してください。');
    }
    if (user.email.length === 0) {
      errorMsgList.push('メールアドレスを入力してください。');
    }
    return errorMsgList;
  }

}
