
export class ElementId {

  private static _APP_ID: string = 'id-app';
  static get APP_ID(): string {
    return this._APP_ID;
  }

  private static _APP_FIXED_CLASS: string = 'fixed';
  static get APP_FIXED_CLASS(): string {
    return this._APP_FIXED_CLASS;
  }

  private static _VALID_ERROR_CLASS: string = 'valid-error';
  static get VALID_ERROR_CLASS(): string {
    return this._VALID_ERROR_CLASS;
  }

}
