import * as React from 'react';
import { Component } from 'react';
import iconDdFile from '../../../../img/common/icon_ddfile.png';
import { ModalService } from '../../../../service/modal-service';
import { ModalAlertComponent } from '../../modal/modal-alert/modal-alert-component';
import './file-dnd-component.scss';

interface FileDndComponentProps {
  // ファイルを受け取ることができる。
  callbackAccept: (files: File[], callback: () => void, errorCallback: () => void,) => void,
  callbackError: (rejectFiles: File[]) => void,
  isAllowMulti: boolean,
  accept: string;
  callbackCsvFile?: () => void;
  files?: File[] | undefined;
}

interface FileDndComponentState {
  callbackAccept: (files: File[], callback: () => void, errorCallback: () => void,) => void,
  callbackError: (rejectFiles: File[]) => void,
  files: File[] | undefined,
  accept: string;
}

class FileDnDComponent extends Component<FileDndComponentProps, FileDndComponentState> {

  static defaultProps = {
    files: [],
  };

  private acepts: string[] = [];

  constructor(props: FileDndComponentProps) {
    super(props);
    this.state = {
      callbackAccept: props.callbackAccept,
      callbackError: props.callbackError,
      files: props.files,
      accept: props.accept,
    };

    this.acepts = props.accept.split(',');
  }

  render() {
    const files: File[] | undefined = this.state.files;
    const isAfterDrop: boolean = Boolean(files && files.length > 0);
    const lastFile: File | null = isAfterDrop && files ? files[files.length - 1] : null;
    const isAllowMulti: boolean = this.props.isAllowMulti;
    const accept: string = this.state.accept;
    return (
      <label className={'dropzone_wrap display_b'}
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => {
          e.preventDefault();
          this.handleOnDrop(e.dataTransfer.files);
        }}
      >
        <div className={'dropzoneInner'}>
          {/* <Dropzone
          onDragOver={(e) => e.preventDefault()}
          onDrop={(filesOK: File[]) => {
            this.handleOnDrop(filesOK, []);
          }}
          accept={accept}
        > */}
          {/* {({ getRootProps, getInputProps }) => ( */}
          <div className={'background_wrap'}>
            <div className={'background_child_wrap'}>
              <input type="file" style={{ display: 'none' }} onChange={(e) => this.handleOnDrop(e.target.files)} accept={this.props.accept} />
              {isAfterDrop ? (
                isAllowMulti && files ? (
                  files.map((file, index) => (
                    <div
                      key={file.name + index}
                      className={'fileName_wrap flex_box flex_align_center'}
                    >
                      <div className={'img_wrap'}><img src={iconDdFile} /></div>
                      <div
                        className={'fileName'}
                      >
                        {file.name}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className={'fileName_wrap flex_box flex_align_center justify_center'}>
                    <div className={'img_wrap'}><img src={iconDdFile} /></div>
                    <div className={'fileName'}>
                      {lastFile?.name}
                    </div>
                  </div>
                )
              ) : (
                <>
                  <div className={'flex_box flex_align_center flex_content_center'}>
                    <div className={'img_wrap'}><img src={iconDdFile} /></div>
                    <div className={'text_wrap'}>
                      ドラッグ & ドロップで追加 <br />
                    クリックでファイル選択 <br />
                    </div>
                  </div>
                  <div className={'divide5'} />
                  <span
                    className={'display_b font_b align_c'}
                  >
                    ({accept.replace(/,/g, ' ')})
                  </span>
                </>
              )}
              {isAfterDrop ? (
                <>
                  <div className={'divide5'} />
                  <div className={'flex_box flex_align_center flex_content_center'}>
                    <button
                      className={'reset btnStyle_1'}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.handlerClickBtnClear();
                      }}
                    >
                      clear
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}

            </div>
          </div>
          {/* )} */}
          {/* </Dropzone> */}
        </div>
      </label>
    );
  }

  componentWillUnmount() {
    FileDnDComponent.defaultProps.files = [];
    this.setState({ files: [] });
  }

  async handleOnDrop(e: FileList | null) {
    if (!e) return;
    const filesAccept: File[] = this.state.files || [];
    const filesReject: File[] = [];
    let isHit = false;

    

    Object.keys(e).forEach((keys: any, i) => {
      const fileType = e[keys].type;
      
      isHit = false;
      this.acepts.forEach((v, i) => {

        if ((!isHit && fileType.match(v)) ||(!isHit && this.acepts[0]=== '.csv' && fileType.match('excel'))) {
          isHit = true;
        }
      });
      
      if (!isHit) {
        filesReject.push(e[keys]);
      } else {
        filesAccept.push(e[keys]);
      }
    });

    if (filesReject.length) {
      this.state.callbackError(filesReject as unknown as File[]);
      this.handlerErrorFiles(filesReject);
    } else if (filesAccept.length) {
      this.setState({
        files: [...filesAccept],
      });
      this.state.callbackAccept(filesAccept, () => {
        this.setState({ files: [] });
      }, () => this.handlerClickBtnClear());
    }
  }

  private handlerClickBtnClear(): void {
    if (this.props.callbackCsvFile) {
      this.props.callbackCsvFile();
    }
    this.setState({ files: [] });
  }

  private handlerErrorFiles(files: File[]) {
    const fileNames = this.props.isAllowMulti ? files.map((v) => v.name) : [files[0].name];
    ModalService.ins.push(
      <ModalAlertComponent
        msgList={[...fileNames, '対応していない拡張子です']}
        callback={() => {
          ModalService.ins.pop();
        }}
      />
    );
  }

}

export default FileDnDComponent;
