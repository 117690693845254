
export class MasterListRow {

  constructor(
    public cellList: string[],
    public isValid: boolean,
    public callbackBtnEdit: () => void,
    public callbackBtnInvalid: () => void,
    public isOpen?: boolean,
    public isParent?: boolean,
    public parentId?: number,
    public salesStatus?: string,
    public hasChild?: boolean,
  ) { }

}
