import * as React from 'react';
import './modal-reservation-edit-component.scss';
import { Article } from '../../../../model/article';
import { Reserve } from '../../../../model/reserve';
import { DatePickerComponent } from '../../../common/date-picker/date-picker-component';
import { ModalService } from '../../../../service/modal-service';
import { ModalReservationListComponent } from '../modal-reservation-list/modal-reservation-list-component';
import { ModalAlertComponent } from '../../../common/modal/modal-alert/modal-alert-component';
import { ModalDialogComponent } from '../../../common/modal/modal-dialog/modal-dialog-component';
import { FileLoaderComponent } from '../../../common/file-loader/file-loader-component';
import { RequestArticlefilesPostImport_file } from '../../../../model/api/request/request-articlefiles-post-import_file';
import { ConnectionService } from '../../../../service/connection-service';
import { ErrorService } from '../../../../service/error-service';
import { RequestArticlereservesPost } from '../../../../model/api/request/request-articlereserves-post';
import { DateFormat, DateFormatter } from '../../../../util/date-formatter';
import { RequestArticlereservesDelete } from '../../../../model/api/request/request-articlereserves-delete';

interface ModalReservationEditProps {
  article: Article;
  reserve: Reserve;
  callback: () => void;
  articleCallback: () => void;
}

interface ModalReservationEditState {
  article: Article;
  reserve: Reserve;
  file: File | null;
}

export class ModalReservationEditComponent extends React.Component<ModalReservationEditProps, ModalReservationEditState> {

  constructor(props: ModalReservationEditProps) {
    super(props);
    this.state = {
      article: props.article,
      reserve: props.reserve,
      file: null,
    };
  }

  render() {
    const article: Article = this.state.article;
    const reserve: Reserve = this.state.reserve;
    const isNew: boolean = this.state.reserve.id === null;
    const file = this.state.file;
    return (
      <div className={'modalInnerBody_wrap reservationEditModal_wrap'}>

        <div className={'table_wrap'}>

          <div className={'table_row'}>
            <div className={'table_cell'}>
              物件名
            </div>
            <div className={'table_cell'}>
              {article.name}
            </div>
          </div>

          <div className={'table_row'}>
            <div className={'table_cell'}>
              予約日*
            </div>
            <div className={'table_cell reservationDateCell'}>
              <div className={'flex_box'}>
                <div>
                  <div>開始日</div>
                  <div className={'divide5'} />
                  <DatePickerComponent
                    min={reserve.reserve_start}
                    max={null}
                    current={reserve.reserve_start}
                    onChange={(v) => {
                      reserve.reserve_start = v;
                      this.setState({ reserve: reserve });
                    }}
                  />
                </div>
                <div>
                  <div>終了日</div>
                  <div className={'divide5'} />
                  <DatePickerComponent
                    min={reserve.reserve_start}
                    max={null}
                    current={reserve.reserve_end}
                    onChange={(v) => {
                      reserve.reserve_end = v;
                      this.setState({ reserve: reserve });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={'table_row'}>
            <div className={'table_cell'}>
              顧客名*
            </div>
            <div className={'table_cell customerNameCell'}>
              <div className={'flex_box flex_align_center'}>
                <div>
                  <input
                    className={'textInput'}
                    value={reserve.customer_name}
                    onChange={(e) => {
                      reserve.customer_name = e.target.value;
                      this.setState({ reserve });
                    }}
                  />
                </div>
                <div>様</div>
              </div>
            </div>
          </div>

        </div>{/* table_wrap */}

        <div className={'divide10'} />

        {/* ファイルDDコンポネント */}
        <div>
          買付証明書
          <FileLoaderComponent
            callbackAccept={(v) => {
              this.setState({ file: v[0] });
            }}
            callbackError={() => { }}
            isAllowMulti={false}
          />
        </div>

        <div className={'divide10'} />

        {/* ボタン */}
        <div className={'btn_wrap flex_box flex_align_center flex_space_between'}>
          {isNew ? (
            <div />
          ) : (
            <div>
              <button
                className={'reset btnStyle_1'}
                onClick={() => this.handlerClickBtnDelete()}
              >予約取り消し</button>
            </div>
          )}
          <div className={'flex_box flex_align_center'}>
            <div>
              <button
                className={'reset btnStyle_1'}
                onClick={() => this.handlerClickBtnBack()}
              >一覧へ戻る</button>
            </div>
            <div>
              <button
                className={'reset btnStyle_1'}
                onClick={() => this.handlerClickBtnSave()}
              >保存</button>
            </div>
          </div>
        </div>

      </div>
    );
  }

  // componentDidUpdate(nextProps: Readonly<ModalReservationEditProps>, nextContext: any): void {
  //   this.setState({
  //     article: nextProps.article,
  //     reserve: nextProps.reserve
  //   });
  // }

  private handlerClickBtnDelete(): void {
    ModalService.ins.push(
      <ModalDialogComponent
        msgList={['予約を削除してもよろしいですか？']}
        callbackOk={() => {
          ModalService.ins.pop();
          const request: RequestArticlereservesDelete = new RequestArticlereservesDelete(this.state.reserve.id as number);
          ConnectionService.ins.connect(request)
            .then(() => {
              ModalService.ins.push(
                <ModalAlertComponent
                  msgList={['削除しました。']}
                  callback={() => {
                    this.gotoReserveList();
                  }}
                />
              );
            })
            .catch((err) => {
              ErrorService.ins.response(err);
            });
        }}
        callbackNg={() => {
          ModalService.ins.pop();
        }} />
    );
  }

  private handlerClickBtnBack(): void {
    this.gotoReserveList();
  }

  private handlerClickBtnSave(): void {
    if (this.state.file) {
      const requestFileUpload: RequestArticlefilesPostImport_file = new RequestArticlefilesPostImport_file(
        null,
        this.state.article.id,
        null,
        1,
        this.state.file,
      );
      ConnectionService.ins.connect(requestFileUpload)
        .then((res) => {
          this.postReserve(res.data.id);
        })
        .catch((err) => {
          ErrorService.ins.response(err);
        });
    } else {
      this.postReserve(this.state.reserve.purchase_card_id as number);
    }
  }

  private postReserve(fileId: number): void {
    const request: RequestArticlereservesPost = new RequestArticlereservesPost(
      this.state.reserve.id as number,
      this.state.reserve.article_id,
      this.state.reserve.customer_name,
      //1,
      fileId,
      DateFormatter.date2str(this.state.reserve.reserve_start, DateFormat.YYYYMMDD, '-'),
      DateFormatter.date2str(this.state.reserve.reserve_end, DateFormat.YYYYMMDD, '-'),
    );
    ConnectionService.ins.connect(request)
      .then((res) => {
        ModalService.ins.push(
          <ModalAlertComponent
            msgList={['保存しました。']}
            callback={() => {
              this.gotoReserveList();
            }}
          />
        );
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }

  private gotoReserveList(): void {
    ModalService.ins.display(
      <ModalReservationListComponent article={this.state.article} callback={this.props.articleCallback} />
    );
    this.props.callback();
  }
}
