import { HTTPMethod, RequestBase } from './request-base';

export class RequestArticlesGetSearch extends RequestBase {

  param: {
    q: string,
    limit: number,
    p: number,
    sort: number,
    is_descending: number,
    prefecture_id: number | null,
    city_id: number | null,
    mschool_id: number | null,
    pschool_id: number | null,
    user_id: number | null,
    purchasing_manager_id: number | null,
    purchase_datetime_start: string,
    purchase_datetime_end: string,
    tsubo_start: number | null,
    tsubo_end: number | null,
    area_measuresize_start: number | null,
    area_measuresize_end: number | null,
    user: string,
    valid_flag: number,
    price_start: number | null,
    price_end: number | null,
    location: string | null,
    subdivision?: string | null,
  };

  constructor(
    q: string,
    limit: number,
    p: number,
    sort: number,
    is_descending: number,
    prefecture_id: number | null,
    city_id: number | null,
    mschool_id: number | null,
    pschool_id: number | null,
    user_id: number | null,
    purchasing_manager_id: number | null,
    purchase_datetime_start: string,
    purchase_datetime_end: string,
    tsubo_start: number | null,
    tsubo_end: number | null,
    area_measuresize_start: number | null,
    area_measuresize_send: number | null,
    user: string,
    valid_flag: number,
    price_start: number | null,
    price_end: number | null,
    location: string | null,
    subdivision: string | null,
  ) {
    super(HTTPMethod.GET, '/api/articles/search');
    this.param = {
      q: q,
      limit: limit,
      p: p,
      sort: sort,
      is_descending: is_descending,
      prefecture_id: prefecture_id,
      city_id: city_id,
      mschool_id: mschool_id,
      pschool_id: pschool_id,
      user_id: user_id,
      purchasing_manager_id: purchasing_manager_id,
      purchase_datetime_start: purchase_datetime_start,
      purchase_datetime_end: purchase_datetime_end,
      tsubo_start: tsubo_start,
      tsubo_end: tsubo_end,
      area_measuresize_start: area_measuresize_start,
      area_measuresize_end: area_measuresize_send,
      user: user,
      valid_flag: valid_flag,
      price_start: price_start,
      price_end: price_end,
      location: location,
      subdivision: subdivision,
    };
  }

  // getParam(): string {
  //   let param: string = '?';
  //   Object.keys(this.param).forEach((v) => {
  //     if (this.param[v] === null) {
  //       return;
  //     }
  //     param += `${v}=${this.param[v]}&`;
  //   });
  //   return param;
  // }

  getUrl(): string {
    return this.host + this.endPoint + this.getParam();
  }

}
