import './pager-component.scss';

type PagerComponentProps = {
  currentPage: number;
  max: number;
  callback: (page: number) => void;
}

export const PagerComponent = (props: PagerComponentProps) => {
  const {
    currentPage,
    max,
    callback
  } = props;

  const range = (start: number, end: number): number[] => {
    return Array.from({ length: (end - start + 1) }, (v, k) => k + start);
  };

  const list: number[] = range(1, max);
  return (
    <div className={'pager_wrap'}>
      <ul className={'flex_box flex_align_center'}>
        {currentPage !== 1 ? (
          <>
            <li
              onClick={() => callback(1)}
            >&laquo;</li>{/** 最初のページへ */}
            <li
              onClick={() => callback(currentPage - 1)}
            >&lsaquo;</li>{/** 前のページへ */}
          </>
        ) : (
          <>
          </>
        )}
        {list.map((page: number) => {
          if (
            page === currentPage - 1 ||
            page === currentPage ||
            page === currentPage + 1
          ) {
            return (
              <li
                className={`${page === currentPage ? 'active' : ''}`}
                key={page}
                onClick={() => callback(page)}
              >{page}</li>
            );
          }
        })}
        {currentPage !== max ? (
          <>
            <li
              onClick={() => callback(currentPage + 1)}
            >&rsaquo;</li>{/** 次のページへ */}
            <li
              onClick={() => callback(max)}
            >&raquo;</li>{/** 最後のページへ */}
          </>
        ) : (
          <>
          </>
        )}
      </ul>
    </div>
  );
};