import { Redirect, Route, Switch } from 'react-router-dom';
import { RoutingPass } from '../constant/routing-pass';
import { LoginComponent } from '../component/page/login/login-component';
import { ErrorComponent } from '../component/page/error/error-component';
import { ArticleListComponent } from '../component/page/article-list/article-list-component';
import { ArticleDetailComponent } from '../component/page/article-detail/article-detail-component';
import { MasterSubdivisionComponent } from '../component/page/master-subdivision/master-subdivision-component';
import { MasterPrefectureComponent } from '../component/page/master-prefecture/master-prefecture-component';
import { MasterCityComponent } from '../component/page/master-city/master-city-component';
import { MasterPrimarySchoolComponent } from '../component/page/master-primary-school/master-primary-school-component';
import { MasterMiddleSchoolComponent } from '../component/page/master-middle-school/master-middle-school-component';
import { MasterStoreComponent } from '../component/page/master-store/master-store-component';
import { MasterUserComponent } from '../component/page/master-user/master-user-component';
import { SubdivisionDetailComponent } from '../component/page/subdivision-detail/subdivision-detail-component';
import { SettingPassComponent } from '../component/page/setting-pass/setting-pass-component';
import { ArticleReleaseComponent } from '../component/page/article-release/article-release-component';
import { ArticleReservationComponent } from '../component/page/article-reservation/article-reservation-component';

export const Routes = () => (
  <Switch>
    <Route path={RoutingPass.ERROR} component={ErrorComponent} />
    <Route path={RoutingPass.LOGIN} component={LoginComponent} />
    <Route path={RoutingPass.SETTING_PASS + '/:hash'} component={SettingPassComponent} />
    <Route path={RoutingPass.ARTICLE_LIST} component={ArticleListComponent} />
    <Route path={RoutingPass.ARTICLE_LIST_INTRODUCED} component={ArticleListComponent} />
    <Route path={RoutingPass.ARTICLE_DETAIL + '/:id'} component={ArticleDetailComponent} />
    <Route path={RoutingPass.ARTICLE_DETAIL_INTRODUCED + '/:id'} component={ArticleDetailComponent} />
    <Route path={RoutingPass.ARTICLE_NEW} component={ArticleDetailComponent} />
    <Route path={RoutingPass.ARTICLE_NEW_INTRODUCED} component={ArticleDetailComponent} />
    <Route path={RoutingPass.MASTER_SUB_DIVISION} component={MasterSubdivisionComponent} />
    <Route path={RoutingPass.MASTER_PREFECTURE} component={MasterPrefectureComponent} />
    <Route path={RoutingPass.MASTER_CITY} component={MasterCityComponent} />
    <Route path={RoutingPass.MASTER_PRIMARY_SCHOOL} component={MasterPrimarySchoolComponent} />
    <Route path={RoutingPass.MASTER_MIDDLE_SCHOOL} component={MasterMiddleSchoolComponent} />
    <Route path={RoutingPass.MASTER_STORE} component={MasterStoreComponent} />
    <Route path={RoutingPass.MASTER_USER} component={MasterUserComponent} />
    <Route path={RoutingPass.SUBDIVISION_DETAIL_COMPONENT + '/:id'} component={SubdivisionDetailComponent} />
    <Route path={RoutingPass.ARTICLE_RELEASE + '/:id'} component={ArticleReleaseComponent} />
    <Route path={RoutingPass.ARTICLE_RESERVATION + '/:id'} component={ArticleReservationComponent} />
    <Redirect to={RoutingPass.ERROR} />
  </Switch>
);