import * as React from 'react';
import iconMenuManagement from '../../../img/menu/icon_menu_management.png';
import iconMenuIntroduction from '../../../img/menu/icon_menu_introduction.png';
import iconMenuBuilding from '../../../img/menu/icon_menu_building.png';
import iconMenuCity from '../../../img/menu/icon_menu_city.png';
import iconMenuBunjou from '../../../img/menu/icon_menu_bunjou.png';
import iconMenuSchoolJ from '../../../img/menu/icon_menu_school_j.png';
import iconMenuSchoolP from '../../../img/menu/icon_menu_school_p.png';
import iconMenuUser from '../../../img/menu/icon_menu_user.png';
import iconMenuVillege from '../../../img/menu/icon_menu_villege.png';
import iconMenuSetting from '../../../img/menu/icon_menu_setting.png';
import { SideMenuService } from '../../../service/side-menu-service';
import './side-menu-component.scss';
import { RoutingPass } from '../../../constant/routing-pass';
import { UserService } from '../../../service/user-service';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDidMount } from '../../../life-cycle/life-cycle';
import { State } from '../../../redux/root.reducer';
import { UserActions } from '../../../redux/user/user.action';
import { SystemActions } from '../../../redux/system/system.action';
import { push } from 'connected-react-router';
import { SubdivisionActions } from '../../../redux/subdibision/subdivision.action';
import { ErrorService } from '../../../service/error-service';
import { ModalService } from '../../../service/modal-service';

export enum SideMenuType {
  article,
  articleIntroduced,
  master,
  prefecture,
  city,
  primarySchool,
  middleSchool,
  store,
  user,
  subDivision
}

interface SideMenuComponentProps {
}

interface SideMenuComponentState {
  isAdmin: boolean;
  currentMenu: SideMenuType | null;
}

export const SideMenuComponent = () => {
  const dispatch = useDispatch();
  const { isAdmin, currentMenu } = useSelector((state: State) => ({
    isAdmin: state.user.isAdmin,
    currentMenu: state.system.currentMenu,
  }));

  useEffect(() => {
    UserService.ins.getMe()
      .then((v) => dispatch(UserActions.setIsAdmin(+v.group_id === 1)))
      .catch((err) => {
        dispatch(UserActions.setIsAdmin(false));
        ErrorService.ins.response(err);
      });
  }, [currentMenu, dispatch]);

  useDidMount(() => {
    const isIntroduced: boolean =
      window.location.hash.match(RoutingPass.ARTICLE_LIST_INTRODUCED) !== null ||
      window.location.hash.match(RoutingPass.ARTICLE_DETAIL_INTRODUCED) !== null;
    SideMenuService.ins.init(
      false,
      currentMenu || (isIntroduced ? SideMenuType.articleIntroduced : SideMenuType.article)
    );
  });

  const handlerClickMenu = useCallback(
    (type: SideMenuType) => {
      dispatch(SystemActions.setCurrentMenu(type));
      let pass = '';
      switch (type) {
        case SideMenuType.article:
          pass = RoutingPass.ARTICLE_LIST;
          break;
        case SideMenuType.articleIntroduced:
          pass = RoutingPass.ARTICLE_LIST_INTRODUCED;
          break;
        case SideMenuType.prefecture:
          pass = RoutingPass.MASTER_PREFECTURE;
          break;
        case SideMenuType.city:
          pass = RoutingPass.MASTER_CITY;
          break;
        case SideMenuType.primarySchool:
          pass = RoutingPass.MASTER_PRIMARY_SCHOOL;
          break;
        case SideMenuType.middleSchool:
          pass = RoutingPass.MASTER_MIDDLE_SCHOOL;
          break;
        case SideMenuType.store:
          pass = RoutingPass.MASTER_STORE;
          break;
        case SideMenuType.user:
          pass = RoutingPass.MASTER_USER;
          break;
        case SideMenuType.subDivision:
          pass = RoutingPass.MASTER_SUB_DIVISION;
          break;
      }
      if (pass === RoutingPass.MASTER_SUB_DIVISION) {
        dispatch(SubdivisionActions.setRowStatus(null));
      }
      dispatch(push(pass));
    },
    [dispatch],
  );

  // const handlerClickMenu = (type: SideMenuType) => {
  //   dispatch(SystemActions.setCurrentMenu(type));
  //   // SideMenuService.ins.currentMenu = type;
  //   // this.setState({ currentMenu: type });
  //   let pass = '';
  //   switch (type) {
  //     case SideMenuType.article:
  //       pass = RoutingPass.ARTICLE_LIST;
  //       break;
  //     case SideMenuType.articleIntroduced:
  //       pass = RoutingPass.ARTICLE_LIST_INTRODUCED;
  //       break;
  //     case SideMenuType.prefecture:
  //       pass = RoutingPass.MASTER_PREFECTURE;
  //       break;
  //     case SideMenuType.city:
  //       pass = RoutingPass.MASTER_CITY;
  //       break;
  //     case SideMenuType.primarySchool:
  //       pass = RoutingPass.MASTER_PRIMARY_SCHOOL;
  //       break;
  //     case SideMenuType.middleSchool:
  //       pass = RoutingPass.MASTER_MIDDLE_SCHOOL;
  //       break;
  //     case SideMenuType.store:
  //       pass = RoutingPass.MASTER_STORE;
  //       break;
  //     case SideMenuType.user:
  //       pass = RoutingPass.MASTER_USER;
  //       break;
  //     case SideMenuType.subDivision:
  //       pass = RoutingPass.MASTER_SUB_DIVISION;
  //       break;
  //   }
  //   dispatch(push(pass))
  // };

  const getActiveClassName = (currentType: SideMenuType | null, type: SideMenuType) => {
    return currentType === type ? 'active' : '';
  };

  return (
    <div className={'menuInner_wrap'}>

      {/* 土地一覧 */}
      <div
        className={`menuItem flex_box flex_align_center cursor_p ${getActiveClassName(currentMenu, SideMenuType.article)}`}
        onClick={() => handlerClickMenu(SideMenuType.article)}
      >
        <div><img src={iconMenuIntroduction} /></div>
        <div>土地一覧</div>
        <div className={'arrow_active'}>&#9654;</div>
      </div>

      {/* 土地一覧(不動産紹介) */}
      <div
        className={`menuItem flex_box flex_align_center cursor_p ${getActiveClassName(currentMenu, SideMenuType.articleIntroduced)}`}
        onClick={() => handlerClickMenu(SideMenuType.articleIntroduced)}
      >
        <div><img src={iconMenuManagement} /></div>
        <div>不動産紹介</div>
        <div className={'arrow_active'}>&#9654;</div>
      </div>

      {isAdmin ? (
        <>
          {/* マスタ管理 */}
          {/*<div
              className={`menuItem flex_box flex_align_center cursor_p ${this.getActiveClassName(currentMenu, SideMenuType.master)}`}
            >
              <div><img src={iconMenuMaster} /></div>
              <div>マスタ管理</div>
              <div className={'arrow_active'}>&#9654;</div>
            </div>*/}

          {/* 分譲マスタ */}
          <div
            className={`menuItem flex_box flex_align_center cursor_p ${getActiveClassName(currentMenu, SideMenuType.subDivision)}`}
            onClick={() => handlerClickMenu(SideMenuType.subDivision)}
          >
            <div><img src={iconMenuBunjou} /></div>
            <div>分譲マスタ</div>
            <div className={'arrow_active'}>&#9654;</div>
          </div>

          {/* 都道府県マスタ */}
          <div
            className={`menuItem flex_box flex_align_center cursor_p ${getActiveClassName(currentMenu, SideMenuType.prefecture)}`}
            onClick={() => handlerClickMenu(SideMenuType.prefecture)}
          >
            <div><img src={iconMenuCity} /></div>
            <div>都道府県マスタ</div>
            <div className={'arrow_active'}>&#9654;</div>
          </div>

          {/* 市町村マスタ */}
          <div
            className={`menuItem flex_box flex_align_center cursor_p ${getActiveClassName(currentMenu, SideMenuType.city)}`}
            onClick={() => handlerClickMenu(SideMenuType.city)}
          >
            <div><img src={iconMenuVillege} /></div>
            <div>市区町村マスタ</div>
            <div className={'arrow_active'}>&#9654;</div>
          </div>

          {/* 小学校マスタ */}
          <div
            className={`menuItem flex_box flex_align_center cursor_p ${getActiveClassName(currentMenu, SideMenuType.primarySchool)}`}
            onClick={() => handlerClickMenu(SideMenuType.primarySchool)}
          >
            <div><img src={iconMenuSchoolP} /></div>
            <div>小学校マスタ</div>
            <div className={'arrow_active'}>&#9654;</div>
          </div>

          {/* 中学校マスタ */}
          <div
            className={`menuItem flex_box flex_align_center cursor_p ${getActiveClassName(currentMenu, SideMenuType.middleSchool)}`}
            onClick={() => handlerClickMenu(SideMenuType.middleSchool)}
          >
            <div><img src={iconMenuSchoolJ} /></div>
            <div>中学校マスタ</div>
            <div className={'arrow_active'}>&#9654;</div>
          </div>

          {/* 支店マスタ */}
          <div
            className={`menuItem flex_box flex_align_center cursor_p ${getActiveClassName(currentMenu, SideMenuType.store)}`}
            onClick={() => handlerClickMenu(SideMenuType.store)}
          >
            <div><img src={iconMenuBuilding} /></div>
            <div>支店マスタ</div>
            <div className={'arrow_active'}>&#9654;</div>
          </div>

          {/* ユーザマスタ */}
          <div
            className={`menuItem flex_box flex_align_center cursor_p ${getActiveClassName(currentMenu, SideMenuType.user)}`}
            onClick={() => handlerClickMenu(SideMenuType.user)}
          >
            <div><img src={iconMenuUser} /></div>
            <div>ユーザマスタ</div>
            <div className={'arrow_active'}>&#9654;</div>
          </div>

          {/* 設定(仮設置) */}
          {/*<div
              className={`menuItem menuItemSetting flex_box flex_align_center cursor_p ${this.getActiveClassName(currentMenu, SideMenuType.user)}`}
            >
              <div><img src={iconMenuSetting} /></div>
              <div>設定</div>
              <div className={'arrow_active'}>&#9654;</div>
            </div>*/}
        </>
      ) : (
        <></>
      )}

    </div>
  );
};

// export class SideMenuComponent extends React.Component<SideMenuComponentProps, SideMenuComponentState> {

//   constructor(props: SideMenuComponentProps) {
//     super(props);
//     this.state = {
//       isAdmin: false, //SideMenuService.ins.component ? SideMenuService.ins.isAdmin : false,
//       currentMenu: SideMenuService.ins.component ? SideMenuService.ins.currentMenu : SideMenuType.article,
//     };
//   }

//   render() {
//     const isAdmin: boolean = this.state.isAdmin;
//     const currentMenu: SideMenuType | null = this.state.currentMenu;
//     return (
//       <div className={'menuInner_wrap'}>

//         {/* 土地一覧 */}
//         <div
//           className={`menuItem flex_box flex_align_center cursor_p ${this.getActiveClassName(currentMenu, SideMenuType.article)}`}
//           onClick={() => this.handlerClickMenu(SideMenuType.article)}
//         >
//           <div><img src={iconMenuIntroduction} /></div>
//           <div>土地一覧</div>
//           <div className={'arrow_active'}>&#9654;</div>
//         </div>

//         {/* 土地一覧(不動産紹介) */}
//         <div
//           className={`menuItem flex_box flex_align_center cursor_p ${this.getActiveClassName(currentMenu, SideMenuType.articleIntroduced)}`}
//           onClick={() => this.handlerClickMenu(SideMenuType.articleIntroduced)}
//         >
//           <div><img src={iconMenuManagement} /></div>
//           <div>不動産紹介</div>
//           <div className={'arrow_active'}>&#9654;</div>
//         </div>

//         {isAdmin ? (
//           <>
//             {/* 分譲マスタ */}
//             <div
//               className={`menuItem flex_box flex_align_center cursor_p ${this.getActiveClassName(currentMenu, SideMenuType.subDivision)}`}
//               onClick={() => this.handlerClickMenu(SideMenuType.subDivision)}
//             >
//               <div><img src={iconMenuBunjou} /></div>
//               <div>分譲マスタ</div>
//               <div className={'arrow_active'}>&#9654;</div>
//             </div>

//             {/* 都道府県マスタ */}
//             <div
//               className={`menuItem flex_box flex_align_center cursor_p ${this.getActiveClassName(currentMenu, SideMenuType.prefecture)}`}
//               onClick={() => this.handlerClickMenu(SideMenuType.prefecture)}
//             >
//               <div><img src={iconMenuCity} /></div>
//               <div>都道府県マスタ</div>
//               <div className={'arrow_active'}>&#9654;</div>
//             </div>

//             {/* 市町村マスタ */}
//             <div
//               className={`menuItem flex_box flex_align_center cursor_p ${this.getActiveClassName(currentMenu, SideMenuType.city)}`}
//               onClick={() => this.handlerClickMenu(SideMenuType.city)}
//             >
//               <div><img src={iconMenuVillege} /></div>
//               <div>市区町村マスタ</div>
//               <div className={'arrow_active'}>&#9654;</div>
//             </div>

//             {/* 小学校マスタ */}
//             <div
//               className={`menuItem flex_box flex_align_center cursor_p ${this.getActiveClassName(currentMenu, SideMenuType.primarySchool)}`}
//               onClick={() => this.handlerClickMenu(SideMenuType.primarySchool)}
//             >
//               <div><img src={iconMenuSchoolP} /></div>
//               <div>小学校マスタ</div>
//               <div className={'arrow_active'}>&#9654;</div>
//             </div>

//             {/* 中学校マスタ */}
//             <div
//               className={`menuItem flex_box flex_align_center cursor_p ${this.getActiveClassName(currentMenu, SideMenuType.middleSchool)}`}
//               onClick={() => this.handlerClickMenu(SideMenuType.middleSchool)}
//             >
//               <div><img src={iconMenuSchoolJ} /></div>
//               <div>中学校マスタ</div>
//               <div className={'arrow_active'}>&#9654;</div>
//             </div>

//             {/* 支店マスタ */}
//             <div
//               className={`menuItem flex_box flex_align_center cursor_p ${this.getActiveClassName(currentMenu, SideMenuType.store)}`}
//               onClick={() => this.handlerClickMenu(SideMenuType.store)}
//             >
//               <div><img src={iconMenuBuilding} /></div>
//               <div>支店マスタ</div>
//               <div className={'arrow_active'}>&#9654;</div>
//             </div>

//             {/* ユーザマスタ */}
//             <div
//               className={`menuItem flex_box flex_align_center cursor_p ${this.getActiveClassName(currentMenu, SideMenuType.user)}`}
//               onClick={() => this.handlerClickMenu(SideMenuType.user)}
//             >
//               <div><img src={iconMenuUser} /></div>
//               <div>ユーザマスタ</div>
//               <div className={'arrow_active'}>&#9654;</div>
//             </div>

//             {/* 設定(仮設置) */}
//             {/*<div
//               className={`menuItem menuItemSetting flex_box flex_align_center cursor_p ${this.getActiveClassName(currentMenu, SideMenuType.user)}`}
//             >
//               <div><img src={iconMenuSetting} /></div>
//               <div>設定</div>
//               <div className={'arrow_active'}>&#9654;</div>
//             </div>*/}
//           </>
//         ) : (
//           <></>
//         )}

//       </div>
//     );
//   }

//   // componentDidMount(): void {
//   //   UserService.ins.getMe()
//   //     .then((v) => this.setState({ isAdmin: +v.group_id === 1 }))
//   //     .catch(() => this.setState({ isAdmin: false }));
//   //   const isIntroduced: boolean =
//   //     window.location.hash.match(RoutingPass.ARTICLE_LIST_INTRODUCED) !== null ||
//   //     window.location.hash.match(RoutingPass.ARTICLE_DETAIL_INTRODUCED) !== null;
//   //   SideMenuService.ins.init(
//   //     this,
//   //     false,
//   //     SideMenuService.ins.currentMenu || (isIntroduced ? SideMenuType.articleIntroduced : SideMenuType.article)
//   //   );
//   // }

//   componentWillReceiveProps(nextProps: Readonly<SideMenuComponentProps>, nextContext: any): void {
//     UserService.ins.getMe()
//       .then((v) => this.setState({ isAdmin: +v.group_id === 1 }))
//       .catch(() => this.setState({ isAdmin: false }));
//     const isIntroduced: boolean = window.location.hash.match(RoutingPass.ARTICLE_LIST_INTRODUCED) !== null;
//     this.setState({
//       isAdmin: SideMenuService.ins.component ? SideMenuService.ins.isAdmin : false,
//       currentMenu: SideMenuService.ins.currentMenu || (isIntroduced ? SideMenuType.articleIntroduced : SideMenuType.article),
//     });
//   }

//   private handlerClickMenu(type: SideMenuType): void {
//     SideMenuService.ins.currentMenu = type;
//     this.setState({ currentMenu: type });
//     switch (type) {
//       case SideMenuType.article:
//         this.props['history'].push(RoutingPass.ARTICLE_LIST);
//         break;
//       case SideMenuType.articleIntroduced:
//         this.props['history'].push(RoutingPass.ARTICLE_LIST_INTRODUCED);
//         break;
//       case SideMenuType.prefecture:
//         this.props['history'].push(RoutingPass.MASTER_PREFECTURE);
//         break;
//       case SideMenuType.city:
//         this.props['history'].push(RoutingPass.MASTER_CITY);
//         break;
//       case SideMenuType.primarySchool:
//         this.props['history'].push(RoutingPass.MASTER_PRIMARY_SCHOOL);
//         break;
//       case SideMenuType.middleSchool:
//         this.props['history'].push(RoutingPass.MASTER_MIDDLE_SCHOOL);
//         break;
//       case SideMenuType.store:
//         this.props['history'].push(RoutingPass.MASTER_STORE);
//         break;
//       case SideMenuType.user:
//         this.props['history'].push(RoutingPass.MASTER_USER);
//         break;
//       case SideMenuType.subDivision:
//         this.props['history'].push(RoutingPass.MASTER_SUB_DIVISION);
//         break;
//     }
//   }

//   private getActiveClassName(currentType: SideMenuType | null, type: SideMenuType): string {
//     return currentType === type ? 'active' : '';
//   }

// };
