import * as React from 'react';
import {Validation} from '../../../../model/validation';
import {ElementId} from '../../../../constant/element-id';

interface ValidationComponentProps {
  /** バリデーションモデル */
  validation: Validation,
  /** 入力文字列 */
  str: string,
}

interface ValidationComponentState {
}

export class ValidationComponent extends React.Component<ValidationComponentProps, ValidationComponentState> {

  constructor(props: ValidationComponentProps) {
    super(props);
  }

  render() {
    const {validation, str} = this.props;
    return (
      validation.checkFunc(str) ? (
        <></>
      ) : (
        <span className={ElementId.VALID_ERROR_CLASS} style={{color: 'red'}}>{validation.errMessage}</span>
      )
    );
  }

}
