import { HTTPMethod, RequestBase } from './request-base';

export class RequestArticlefilesPostCsv_file extends RequestBase {

  param: {
    csv_file: File,
  };

  a = typeof this.param;

  constructor(
    csv_file: File,
  ) {
    super(HTTPMethod.POST, '/api/articles/import_csv');
    this.param = {
      csv_file: csv_file,
    };
  }


  getUrl(): string {
    return this.host + this.endPoint;
  }

}
