import * as React from 'react';
import './modal-release-component.scss';
import { Article } from '../../../../model/article';
import { CsvLoaderComponent } from '../../../common/file-loader/csv-loader-component';
import { ArticleFile } from '../../../../model/article-file';
import { ModalService } from '../../../../service/modal-service';
import iconCheckBox from '../../../../img/common/checkbox.png';
import iconCheckBoxChecked from '../../../..//img/common/checkbox_checked.png';
import iconPDF from '../../../../img/common/icon_pdf.svg';
import { RequestArticlefilesGetSearch } from '../../../../model/api/request/request-articlefiles-get-search';
import { ConnectionService } from '../../../../service/connection-service';
import { ErrorService } from '../../../../service/error-service';
import { User } from '../../../../model/user';
import { Release } from '../../../../model/release';
import { DataManagerService } from '../../../../service/data-manager-service';
import { ModalAlertComponent } from '../../../common/modal/modal-alert/modal-alert-component';
import { ModalReleaseUrlComponent } from '../../../common/modal/modal-alert/modal-release-url-component';
//REVIEW validation追加
import { ValidationInput, ValidationModel } from '../validation/validation-input';
//NOTE DatePicker機能できるためのimport
import { DateFormat, DateFormatter } from '../../../../util/date-formatter';
import { DatePickerComponent } from '../../../common/date-picker/date-picker-component';
//REVIEW メール送信API
import { RequestArticlesPostMail } from '../../../../model/api/request/request-articles-post-mail';
import { RequestArticlefilesPostCsv_file } from '../../../../model/api/request/request-articlefiles-post-csv_file';
import { DummyImgGetter } from '../../../../util/dummy-img-getter';

interface ModalDocumentComponentProps {
  article: Article;
  isIntroduced: boolean;
  imgUrlList: string[];
  pdfUrl: string;
  pdfName: string;
  refreshArticle: () => void;
}


interface ModalDocumentComponentState {
  article: Article;
  fileList: ArticleFile[];
  selectFlagList: boolean[];
  userList: User[];
  releaseList: Release[];
  isIntroduced: boolean;
  useImgList: string[];
  email: string;
  emailListFromCsv: string[];
  isInput: boolean;
  csvDisable: boolean,
  message: string;
  isCsvMessageCheck: string;
}

export class ModalReleaseComponent extends React.Component<ModalDocumentComponentProps, ModalDocumentComponentState> {

  private csvFile: File[] = [];

  constructor(props: ModalDocumentComponentProps) {
    super(props);
    this.state = {
      article: props.article,
      fileList: [],
      selectFlagList: [],
      userList: [],
      releaseList: [],
      isIntroduced: props.isIntroduced,
      useImgList: props.imgUrlList.map((v) => v),
      email: '',
      emailListFromCsv: [],
      isInput: true,
      csvDisable: false,
      message: '',
      isCsvMessageCheck: '',
    };
  }

  render() {
    const selectFlagList: boolean[] = this.state.selectFlagList;
    const fileList: ArticleFile[] = this.state.fileList;
    const userList: User[] = this.state.userList;
    const isSelected: boolean = selectFlagList.filter(v => v).length > 0;
    const inputDisable: boolean = this.state.email ? true : false;
    const {
      imgUrlList,
      pdfUrl,
    } = this.props;
    const {
      useImgList,
      article,
      email,
      isInput,
      csvDisable,
      message,
      isCsvMessageCheck
    } = this.state;
    return (
      <div className={'modalInnerBody_wrap releaseModal_wrap'}>

        <h2>{isInput ? '公開情報を入力してください' : '送信内容確認'}</h2>
        <div className={'divide10'} />

        {/* 個別に送信する */}
        {isInput ? (
          <>
            <div className={'table_wrap position_r'}>
              <div className={'table_row'}>
                <div className={'table_cell'}>
                  個別に送信する
                </div>
                <div className={'table_cell'}>
                  <>
                    <ValidationInput
                      value={email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      validation={new ValidationModel([
                        {
                          func: (v) => this.checkMailListStr(v),
                          invalidMessage: '正しい形式でメールアドレスを入力してください。',
                        },
                      ])}
                    />
                    {csvDisable ? (
                      <>
                        <div className={'filterEle'} />
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {csvDisable ? (
              <></>
            ) : (
              <div className={'table_wrap position_r'}>
                <div className={'table_row'}>
                  <div className={'table_cell'}>
                    個別に送信する
                  </div>
                  <div className={'table_cell'}>
                    {email}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        <div className={'divide10'} />


        {/* 一括送信する */}
        {isInput ? (
          <>
            <div className={'table_wrap position_r'}>
              <div className={'table_row'}>
                <div className={'table_cell'}>
                  一括送信する
                </div>
                <div className={'table_cell'}>
                  <>
                    <CsvLoaderComponent
                      callbackAccept={(fileList, callback, errorCallback) => this.callbackFileAdd(fileList, callback, errorCallback)}
                      callbackError={() => {
                      }}
                      isAllowMulti={false}
                      callbackCsvFile={() => this.callbackCsvFile()}
                      files={this.csvFile}
                    />
                    {inputDisable ? (
                      <>
                        <div className={'filterEle'} />
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {inputDisable ? (
              <></>
            ) : (
              <div className={'table_wrap position_r'}>
                <div className={'table_row'}>
                  <div className={'table_cell'}>
                    一括送信する
                  </div>
                  <div className={'table_cell'}>
                    {isCsvMessageCheck}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        <div className={'divide10'} />


        {/* 受付期間 */}
        <div className={'table_wrap'}>
          <div className={'table_row'}>
            <div className={'table_cell'}>
              受付期間{isInput ? (<>*</>) : (<></>)}
            </div>
            <div className={'table_cell'}>
              {isInput ? (
                <>
                  <DatePickerComponent
                    min={null}
                    max={null}
                    current={article.period_start}
                    onChange={(v) => {
                      article.period_start = v;
                      this.setState({
                        article: article
                      });
                    }}
                  />&emsp;〜&emsp;
                  <DatePickerComponent
                    min={null}
                    max={null}
                    current={
                      article.period_end
                    }
                    onChange={(v) => {
                      article.period_end = v;
                      if (!article.period_end || !article.period_start) return;
                      if (article.period_end < article.period_start) {
                      }
                      this.setState({
                        article: article
                      });
                    }}
                  />
                </>
              ) : (
                <>
                  {DateFormatter.date2str(article.period_start, DateFormat.YYYYMMDD)}
                  &emsp;〜&emsp;
                  {DateFormatter.date2str(article.period_end, DateFormat.YYYYMMDD)}
                </>
              )}

            </div>
          </div>
        </div>
        <div className={'divide10'} />

        {/* 公開画像 */}
        <div className={'table_wrap'}>
          <div className={'table_row'}>
            <div className={'table_cell'}>
              公開画像
            </div>
            <div className={'table_cell'}>
              <div className={'table_img flex_box flex_container_wrap'}>
                {isInput ? (
                  imgUrlList.filter(v => v).map(
                    (v, i) => (
                      <div
                        key={`${v}_${i}`}
                        className={'thumb_wrap'}
                      >
                        {
                          <div
                            className={'position_r cursor_p' + (!useImgList.find(v2 => v === v2) ? ' noCheck' : '')}
                            onClick={() => {
                              const findIndex = useImgList.findIndex(v2 => (v === v2 && !!v));
                              if (findIndex !== -1) {
                                useImgList.splice(findIndex, 1);
                              } else {
                                useImgList.push(v);
                              }
                              this.setState({});
                            }}
                          >
                            <img src={v} alt={''} className={'' + i} />
                            {useImgList.find(v2 => v === v2) ? (
                              <img src={iconCheckBoxChecked} className={'img_checkIcon'} alt={''} />
                            ) : (
                              <>
                                <img src={iconCheckBox} className={'img_checkIcon'} alt={''} />
                                <div className={'filterEle'} />
                              </>
                            )}
                          </div>
                        }
                      </div>
                    )
                  )
                ) : (
                  imgUrlList.filter(v => v).map(
                    (v, i) => (
                      <div
                        key={`${v}_${i}`}
                        className={''}
                      >
                        {useImgList.find(v2 => v === v2) ? (
                          <div className={'thumb_wrap position_r'}>
                            <div>
                              <img src={v} alt={''} className={'' + i} />
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    )
                  )
                )}
              </div>
            </div>

          </div>
        </div>
        <div className={'divide10'} />

        <div className={'table_wrap position_r'}>
          <div className={'table_row'}>
            <div className={'table_cell'}>
              区画図PDF
            </div>
            <div className={'table_cell andIcon'}>
              {this.props.pdfUrl ? (
                <div className={'flex_box flex_align_center'}>
                  <img
                    src={iconPDF}
                    alt={'PDF'}
                    className={'imgIcon'}
                  />
                  {this.props.pdfName}
                </div>
              ) : (
                <>
                  区画図は登録されていません。
                </>
              )}
            </div>
          </div>
        </div>
        <div className={'divide10'} />

        {/* 公開情報入力 */}
        <div className={'table_wrap'}>
          <div className={'table_row'}>
            <div>
              {isInput ? (
                <>
                  <textarea
                    className={'textInput'}
                    value={message}
                    onChange={(e) => {
                      this.setState({
                        message: e.target.value,
                      });
                    }}
                    placeholder={'ご案内メッセージを入力してください'}
                    style={{
                      height: '100%',
                      maxHeight: '100%',
                      maxWidth: '100%',
                      minHeight: '100%',
                      minWidth: '100%'
                    }}
                  />
                </>
              ) : (
                <>
                  {message.split('\n').map((v, i) => (
                    <div
                      key={v + '_' + i}
                      style={{ minHeight: 'calc(1rem + 5px)' }}
                    >
                      {v ? v : '　'}
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
        <div className={'divide10'} />
        {/* ボタン */}
        <div className={'btn_wrap flex_box flex_align_center flex_space_between'}>
          <div>
            {isInput ? (
              <button
                className={'reset btnStyle_1'}
                onClick={() => this.handlerClickBtnCancel()}
              >キャンセル
              </button>
            ) : (
              <button
                className={'reset btnStyle_1'}
                onClick={() => this.handlerClickBtnBack()}
              >戻る
              </button>
            )}
          </div>
          <div>
            {isInput ? (
              <button
                className={'reset btnStyle_1'}
                onClick={() => this.handlerClickBtnNext()}
              >確認
              </button>
            ) : (
              <button
                className={'reset btnStyle_1'}
                onClick={() => this.handlerClickBtnSend()}
              >送信
              </button>
            )}
          </div>
        </div>

      </div>
    );
  }

  componentDidMount(): void {
    this.getDocumentList();
    DataManagerService.ins.getUserList()
      .then((v) => this.setState({ userList: v }))
      .catch((v) => this.setState({ userList: v }));
  }

  private callbackFileAdd(fileList: File[], callback: () => void, errorCallback: () => void): void {
    let errFlag: boolean = false;
    fileList.forEach((csv_file) => {
      if (errFlag) {
        callback();
        return;
      }
      const request: RequestArticlefilesPostCsv_file = new RequestArticlefilesPostCsv_file(
        csv_file,
      );
      this.csvFile = fileList;

      ConnectionService.ins.connect(request)
        .then((res) => {
          let str = '';
          res.data.map((v: any, i: number) => {
            if (i !== 0) {
              str += ', ';
            }
            str += v;
            return v;
          });
          this.setState({
            email: '',
            emailListFromCsv: res.data,
            csvDisable: true,
            isCsvMessageCheck: str,
          });
        })
        .catch((err) => {
          errFlag = true;
          ModalService.ins.push(
            <ModalAlertComponent
              msgList={err.data ? err.data.error.messages : []}
              callback={() => {
                this.csvFile = [];
                this.setState({});
                errorCallback();
                ModalService.ins.pop();
              }}
            />
          );
        });
    });
  }

  private getDocumentList(): void {
    const request: RequestArticlefilesGetSearch = new RequestArticlefilesGetSearch(
      '',
      100,
      1,
      1,
      1,
      this.state.isIntroduced ? null : this.state.article.id,
      this.state.isIntroduced ? this.state.article.id : null,
    );
    ConnectionService.ins.connect(request)
      .then((res) => {
        this.setState({
          fileList: res.data.list,
          selectFlagList: res.data.list.map(() => false),
        });
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }

  private callbackCsvFile(): void {
    this.csvFile = [];
    this.setState({
      csvDisable: false,
      isCsvMessageCheck: '',
    });
  }

  //REVIEW 戻るボタン
  private handlerClickBtnBack(): void {
    this.setState({
      isInput: true
    });
  }

  //REVIEW キャンセルボタン
  private handlerClickBtnCancel(): void {
    ModalService.ins.close();
  }

  //REVIEW 確認ボタン
  private handlerClickBtnNext(): void {
    const errorMsgList: string[] = this.checkValidation();
    if (errorMsgList.length > 0) {
      ModalService.ins.push(
        <ModalAlertComponent
          msgList={errorMsgList}
          callback={() => {
            ModalService.ins.pop();
          }}
        />
      );
      return;
    }
    if (errorMsgList.length === 0) {
      this.setState({
        isInput: false
      });
    }
  }

  //REVIEW 確認ボタンのチェックバリデーション
  private checkValidation(): string[] {
    const errowMsgList: string[] = [];
    const {
      email,
      emailListFromCsv,
      article,
      fileList
    } = this.state;
    if ((!email && !emailListFromCsv.length) || (!email && this.state.isCsvMessageCheck === '')) {
      errowMsgList.push('個別送信もしくは一括送信が空欄になっています。');
    }
    if (!article.period_start || !article.period_end) {
      errowMsgList.push('受付開始日もしくは受付完了日が選択されていません。');
    } else if (article.period_end < article.period_start) {
      errowMsgList.push('受付完了日が受付開始日よりも過去日になっています。');
    }
    return errowMsgList;
  }

  //REVIEW 個別に送信するバリデーション
  private checkMailListStr(inputStr: string): boolean {
    if (!inputStr) {
      return true;
    }
    // 1. inputStr の中のスペースを削除
    const v = inputStr.replace(/\s/gi, '');
    // 2. inputStr を 「,」 で分割 -> mailStrList を作成する (String.prototype.Split)
    const mailStrList: string[] = v.split(',');
    // 3. mailStrList で ↓ これを実行
    const checkMailStr = (mailStr: string): boolean => {
      const mailRegex = new RegExp('(?:[-!#-\'*+/-9=?A-Z^-~]+\.?(?:\.[-!#-\'*+/-9=?A-Z^-~]+)*|"(?:[!#-\[\]-~]|\\\\[\x09 -~])*")@[-!#-\'*+/-9=?A-Z^-~]+(?:\.[-!#-\'*+/-9=?A-Z^-~]+)*');
      const mailRegex2 = new RegExp('^[^\@]+\@[^\@]+$');
      if (mailStr.match(mailRegex) && mailStr.match(mailRegex2)) {
        // 全角チェック
        if (mailStr.match(/[^a-zA-Z0-9\!\"\#\$\%\&\'\(\)\=\~\|\-\^\\\@\[\;\:\]\,\.\/\\\<\>\?\_\`\{\+\*\} ]/)) {
          return false;
        }
        // 末尾トップレベルドメインチェック
        if (!mailStr.match(/\.[a-z]+$/)) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    };

    const resultList = mailStrList.map((mailStr) => checkMailStr(mailStr));
    return resultList.filter((v) => v === false).length === 0;
    // return mailStrList
    //   .map((mailStr) => checkMailStr(mailStr))
    //   .filter((result) => result === false)
    //   .length === 0;
  }

  //REVIEW 送信メール
  private handlerClickBtnSend(): void {
    const {
      imgUrlList,
    } = this.props;
    const mailListFromInput = this.state.email ? this.state.email.replace(/\s/gi, '').split(',') : [];
    const mailListFromCsv = this.state.emailListFromCsv;
    const send = {
      article_id: this.state.article.id,
      email: JSON.stringify(mailListFromInput.length ? mailListFromInput : mailListFromCsv),
      period_start: DateFormatter.date2str(this.state.article.period_start, DateFormat.YYYYMMDD, '-'),
      period_end: DateFormatter.date2str(this.state.article.period_end, DateFormat.YYYYMMDD, '-'),
      message: this.state.message,
      file_type: JSON.stringify(
        this.state.useImgList
          .map((v, i) => {
            const findIndex = imgUrlList.findIndex(v2 => v && v === v2);
            return (findIndex !== -1) ? String(findIndex + 1) : '';
          })
          .filter(v => v)
      ),
    };
    const request: RequestArticlesPostMail = new RequestArticlesPostMail(
      send.article_id as number,
      send.email,
      send.period_start,
      send.period_end,
      send.message,
      send.file_type,
    );
    ConnectionService.ins.connect(request)
      .then((res) => {
        ModalService.ins.push(
          <ModalReleaseUrlComponent
            msgList={['公開した土地情報ページ']}
            url={res.data['url']}
            callback={() => {
              ModalService.ins.close();
              this.props.refreshArticle();
            }}
          />
        );
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }
}
