import { useEffect } from 'react';

/**
 * DidMount と同じ挙動
 * @param func
 */
export const useDidMount = (func: () => void) => useEffect(() => {
  func();
}, []);

/**
 * WillUnMount と同じ
 * @param func
 */
export const useWillUnMount = (func: () => void) => useEffect(() => func, []);