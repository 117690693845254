import * as React from 'react';
import { useSelector } from 'react-redux';
import iconLoading from '../../../img/common/icon_loading.png';
import iconLoadingRet from '../../../img/common/icon_loading@2x.png';
import { ModalService } from '../../../service/modal-service';
import { State } from '../../../redux/root.reducer';

interface BusyComponentProps {
}

interface BusyComponentState {
  isDisplay: boolean,
}

export const BusyComponent = () => {
  const isBusy = useSelector((state: State) => state.system.isBusy);

  return (
    isBusy ? (
      <div className={'loading_wrap'}>
        <div className={'loading_bg'}></div>
        <div className={'loadingInner_wrap flex_box flex_align_center flex_content_center'}>
          <div>
            <img
              className={'marg_auto'}
              src={iconLoading}
              srcSet={`${iconLoading} 1x, ${iconLoadingRet} 2x`}
            />
            <div className={'divide20'} />
            <div className={'align_c innerText'}>LOADING...</div>
          </div>
        </div>
      </div>
    ) : (
      <></>
    )
  );
};

// export class BusyComponent extends React.Component<BusyComponentProps, BusyComponentState> {

//   private static ins: BusyComponent;

//   constructor(props: BusyComponentProps) {
//     super(props);
//     this.state = {
//       isDisplay: false,
//     };
//     BusyComponent.ins = this;
//   }

//   render() {
//     const { isDisplay } = this.state;
//     return (
//       isDisplay ? (
//         <div className={'loading_wrap'}>
//           <div className={'loading_bg'}></div>
//           <div className={'loadingInner_wrap flex_box flex_align_center flex_content_center'}>
//             <div>
//               <img
//                 className={'marg_auto'}
//                 src={iconLoading}
//                 srcSet={`${iconLoading} 1x, ${iconLoadingRet} 2x`}
//               />
//               <div className={'divide20'} />
//               <div className={'align_c innerText'}>LOADING...</div>
//             </div>
//           </div>
//         </div>
//       ) : (
//         <></>
//       )
//     );
//   }

//   static show(): void {
//     this.ins.setState({ isDisplay: true });
//     ModalService.ins.addClassName2App();
//   }

//   static hide(): void {
//     this.ins.setState({ isDisplay: false });
//     ModalService.ins.removeClassName2App();
//   }

// }
