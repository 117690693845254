
export class City {

  constructor(
    public id: number | null,
    public name: string,
    public prefecture_id: number | null,
    public valid_flag: boolean
  ) {}

}
