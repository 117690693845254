import { combineReducers } from 'redux';
import { History } from 'history';
import { SystemReducer, SystemState } from './system/system.reducer';
import { connectRouter, RouterState } from 'connected-react-router';
import { UserState, UserReducer } from './user/user.reducer';
import { ModalReducer, ModalState } from './modal/modal.reducer';
import { SubdivisionState, SubdivisionReducer } from './subdibision/subdivision.reducer';

export type State = {
  router: RouterState;
  system: SystemState;
  user: UserState;
  modal: ModalState;
  subdivision: SubdivisionState;
};

export const RootReducer = (history: History) =>
  combineReducers<State>({
    router: connectRouter(history),
    system: SystemReducer,
    user: UserReducer,
    modal: ModalReducer,
    subdivision: SubdivisionReducer,
  });
