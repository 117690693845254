import { reducerWithInitialState } from 'typescript-fsa-reducers';
import lodash from 'lodash';
import { MasterListRow } from '../../component/common/master-list/master-list-row';
import { SubdivisionActions } from './subdivision.action';

export type SubdivisionState = {
  rowStatus: MasterListRow[] | null;
};

const initialState: SubdivisionState = {
  rowStatus: null,
};

export const SubdivisionReducer = reducerWithInitialState<SubdivisionState>(initialState)
  .case(SubdivisionActions.setRowStatus, (state, payload) => (
    {
      ...state,
      rowStatus: lodash.cloneDeep(payload),
    }
  ));