export class Validation {
  constructor(
    public checkFunc: (str: string) => boolean,
    public errMessage: string,
  ) {
  }
}

export const validationArticleDetail = {
  /** 物件名 */
  name: new Validation(
    (str) => str.length <= 20,
    '20文字以内で入力してください。',
  ),
  /** 住所 */
  address: new Validation(
    (str) => str.length <= 20,
    '20文字以内で入力してください。',
  ),
  /** 最寄り駅 */
  station: new Validation(
    (str) => str.length <= 12,
    '路線と駅をあわせて12文字以内で入力してください。',
  ),
  /** 価格 */
  price: new Validation(
    (str) => str.length <= 10,
    '10文字以内で入力してください。',
  ),
  /** 土地面積 */
  areaMeasureSize: new Validation(
    (str) => str.length <= 16,
    '「土地坪数」とあわせて16文字以内で入力してください。',
  ),
  /** 土地坪数 */
  tsubo: new Validation(
    (str) => str.length <= 16,
    '「土地面積」とあわせて16文字以内で入力してください。',
  ),
  /** 私道面積 */
  privateArea: new Validation(
    (str) => str.length <= 16,
    '「私道坪数」とあわせて16文字以内で入力してください。',
  ),
  /** 私道坪数 */
  privateUnit: new Validation(
    (str) => str.length <= 16,
    '「私道面積」とあわせて16文字以内で入力してください。',
  ),
  /** 坪単価 */
  tsuboPrice: new Validation(
    (str) => str.substring(str.indexOf('.')).length <= 3,
    '小数第二位までの数字を入力してください。',
  ),
  tsuboPriceMax: new Validation(
    (str) => Number(str) < 1000,
    '1000未満の数字を入力してください。',
  ),
  /** 交通 */
  traffic: new Validation(
    (str) => str.length <= 12,
    '路線と駅をあわせて12文字以内で入力してください。',
  ),
  /** 交通 (徒歩時間) */
  trafficMinute: new Validation(
    (str) => str.length <= 3,
    '徒歩時間は3文字以内で入力してください。',
  ),
  /** 都市計画 */
  cityPlanning: new Validation(
    (str) => str.length <= 20,
    '20文字以内で入力してください。',
  ),
  /** 建ぺい率 */
  coverageRatio: new Validation(
    (str) => str.length <= 5,
    '5文字以内で入力してください。',
  ),
  /** 容積率 */
  floorAreaRatio: new Validation(
    (str) => str.length <= 5,
    '5文字以内で入力してください。',
  ),
  /** 法令上の制限 */
  legalAuthority: new Validation(
    (str) => str.length <= 20,
    '20文字以内で入力してください。',
  ),
  /** 現況 */
  presentStatus: new Validation(
    (str) => str.length <= 20,
    '20文字以内で入力してください。',
  ),
  /** 接道状況 */
  approachSituation: new Validation(
    (str) => str.length <= 20,
    '20文字以内で入力してください。',
  ),
  /** 設備 */
  facility: new Validation(
    (str) => str.length <= 20,
    '20文字以内で入力してください。',
  ),
  /** 備考 */
  remarks: new Validation(
    (str) => str.length <= 60,
    '60文字以内で入力してください。',
  ),
};
