import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import history from '../routes/history';
import { routerMiddleware } from 'connected-react-router';
import { RootReducer, State } from './root.reducer';
import { RootSaga } from './root.saga';
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import { createLogger } from 'redux-logger';
import { Dispatch } from 'react';
import { Action } from 'typescript-fsa';

// Redux用ImportType
export type StateToProps = (state: State) => void;
export type DispatchToProps = (dispatch: Dispatch<Action<any>>) => void;

// redux-saga
const sagaMiddleWare = createSagaMiddleware();

// redux-persist
const persistedReducer = persistReducer({
  key: "auth",
  whitelist: [],
  // blacklist: ["user", "event"],
  storage,
  debug: false,
}, RootReducer(history));

// redux-logger
// const reduxLogger = createLogger({
//   collapsed: true,
//   diff: true,
//   duration: true,
// });

// ストア生成
export const ConfigureStore = (preloadState?: State) => {
  const middleware = [sagaMiddleWare, routerMiddleware(history)];
  // const middleware = [reduxLogger, sagaMiddleWare, routerMiddleware(history)];
  const middlewareEnhancer = applyMiddleware(...middleware);
  const store = createStore(
    persistedReducer,
    preloadState,
    middlewareEnhancer
  );
  const persist = persistStore(store);
  sagaMiddleWare.run(RootSaga);
  return { store, persist };
};

const obj = ConfigureStore();
export const Store = obj.store;
export const Persist = obj.persist;