import { HTTPMethod, RequestBase } from './request-base';

export class RequestArticlesCopy extends RequestBase {

  param: {
    id: number | null,
    name: string | null,
  };

  constructor(
    article_id: number | null,
    name: string | null,
  ) {
    super(HTTPMethod.POST, '/api/articles/copy');
    this.param = {
      id: article_id,
      name: name,
    };
  }


  getUrl(): string {
    return this.host + this.endPoint;
  }

}
