import {HTTPMethod, RequestBase} from './request-base';

export class RequestArticlereservesPost extends RequestBase {

  param: {
    id: number,
    article_id: number,
    customer_name: string,
    //user_id: number,
    //purchase_card_id: string,
    purchase_card_id: number,
    reserve_start: string,
    reserve_end: string,
  };

  constructor(
    id: number,
    article_id: number,
    customer_name: string,
    //user_id: number,
    //purchase_card_id: string,
    purchase_card_id: number,
    reserve_start: string,
    reserve_end: string,
  ) {
    super(HTTPMethod.POST, '/api/articlereserves');
    this.param = {
      id: id,
      article_id: article_id,
      customer_name: customer_name,
      //user_id: user_id,
      //purchase_card_id: purchase_card_id,
      purchase_card_id: purchase_card_id,
      reserve_start: reserve_start,
      reserve_end: reserve_end,
    };
  }

  // getParam(): FormData {
  //   const formData: FormData = new FormData();
  //   Object.keys(this.param).forEach((v) => {
  //     /*if (v === 'id') {
  //       formData.append(v, '');
  //       return;
  //     }*/
  //     if (this.param[v] === null) {
  //       formData.append(v, '');
  //       return;
  //     }
  //     formData.append(v, this.param[v] + '');
  //   });
  //   return formData;
  // }

  getUrl(): string {
    return this.host + this.endPoint;
  }

}
