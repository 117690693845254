import * as React from 'react';
import './modal-alert-component.scss';

interface ModalAlertComponentProps {
  msgList: string[],
  callback?: (() => void) | undefined,
  hideBtn?: boolean,
}

interface ModalAlertComponentState {
  msgList: string[];
  callback: (() => void) | undefined;
}

export class ModalAlertComponent extends React.Component<ModalAlertComponentProps, ModalAlertComponentState> {

  constructor(props: ModalAlertComponentProps) {
    super(props);
    this.state = {
      msgList: props.msgList,
      callback: props.callback,
    };
  }

  render() {
    const msgList: string[] = this.state.msgList;
    const callback: (() => void) | undefined = this.state.callback;
    const hideBtn: boolean | undefined = this.props.hideBtn;
    return (
      <div className={'modalInnerBody_wrap alertModal_wrap'}>

        {/* msg */}
        <div className={'msg_wrap'}>
          {msgList.map((msg, i) => (
            <div
              key={msg + i}
            >
              {msg}
            </div>
          ))}
        </div>

        <div className={'divide20'} />

        {hideBtn ? (
          <></>
        ) : (
          <>
            <div className={'btn_wrap'}>
              <button
                className={'reset btnStyle_1 marg_auto'}
                  onClick={() => {
                  if(callback)callback()
                }}
              >OK
              </button>
            </div>
          </>
        )}
      </div>
    );
  }

}
