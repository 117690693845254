import { ModalComponent } from '../component/common/modal/modal-component';
import { ReactElement } from 'react';
import { ElementId } from '../constant/element-id';

export class ModalService {

  private component: ModalComponent | null = null;

  private static _ins: ModalService;

  private constructor() { }

  static get ins(): ModalService {
    if (!this._ins) {
      this._ins = new ModalService();
    }
    return this._ins;
  }

  init(component: ModalComponent): void {
    this.component = component;
  }

  display(reactElement: ReactElement<any>): void {
    this.addClassName2App();
    if (this.component) {
      this.component.setState({
        isDisplay: true,
        bodyList: [reactElement]
      });
    }
  }

  push(reactElement: ReactElement<any>): void {
    this.addClassName2App();
    const eleList: ReactElement<any>[] = this.component?.state.bodyList || [];
    eleList.push(reactElement);
    if (this.component) {
      this.component.setState({
        isDisplay: true,
        bodyList: eleList
      });
    }
  }

  pop(): void {
    const eleList: ReactElement<any>[] = this.component?.state.bodyList || [];
    if (eleList.length === 1) {
      this.close();
      return;
    }
    eleList.pop();
    if (eleList.length < 1) {
      this.removeClassName2App();
    }
    if (this.component) {
      this.component.setState({
        isDisplay: true,
        bodyList: eleList
      });
    }
  }

  close(): void {
    this.removeClassName2App();
    if (this.component) {
      this.component.setState({
        isDisplay: false,
        bodyList: [],
      });
    }
  }

  getModalNum(): number {
    if (!this.component) return 0;
    if (!this.component.state) return 0;
    if (!this.component.state.bodyList) return 0;
    return this.component.state.bodyList.length;
  }

  addClassName2App(): void {
    const ele: HTMLElement | null = document.getElementById(ElementId.APP_ID);
    if (ele && !ele.classList.contains(ElementId.APP_FIXED_CLASS)) {
      ele.classList.add(ElementId.APP_FIXED_CLASS);
    }
  }

  removeClassName2App(): void {
    const ele: HTMLElement | null = document.getElementById(ElementId.APP_ID);
    if (ele && ele.classList.contains(ElementId.APP_FIXED_CLASS)) {
      ele.classList.remove(ElementId.APP_FIXED_CLASS);
    }
  }

}
