export class ArticleListCollection {

  static landCategoryList = [
    { value: 1, label: '宅地' },
    { value: 2, label: '田' },
    { value: 3, label: '畑' },
  ];

  static useDistrictList = [
    { value: 100, label: '無指定' },
    { value: 1, label: '1種低層' },
    { value: 2, label: '2種低層' },
    { value: 3, label: '1種中高層' },
    { value: 4, label: '2種中高層' },
    { value: 5, label: '1種住居' },
    { value: 6, label: '2種住居' },
    { value: 7, label: '準住居' },
    { value: 8, label: '近隣商業' },
    { value: 9, label: '準工業' },
    { value: 10, label: '工業' },
    { value: 11, label: '工業専用' },
  ];

  static salesStatusList = [
    { value: 1, label: '買付中' },
    { value: 2, label: '契約' },
    { value: 3, label: '契約済み' },
    { value: 4, label: '申請中' },
    { value: 5, label: '造成中' },
    { value: 6, label: '販売終了' },
    { value: 7, label: '販売中' },
  ];

  static sortTypeList = [
    { value: 1, label: '登録順' },
    { value: 2, label: '種別' },
    { value: 3, label: '物件名' },
    { value: 4, label: '都道府県' },
    { value: 5, label: '市区町村' },
    { value: 6, label: '坪数' },
    { value: 7, label: '面積' },
    //{value: 8, label: '仕入れ責任者'},
    { value: 9, label: '仕入れ年月日' },
    { value: 10, label: '担当' },
  ];

  static sortTypeListIntroduced = [
    { value: 1, label: '登録順' },
    { value: 2, label: '物件名' },
    { value: 3, label: '都道府県' },
    { value: 4, label: '市区町村' },
    { value: 5, label: '面積' },
    { value: 6, label: '担当' },
    { value: 7, label: '価格(円)' },
    { value: 8, label: '地目' },
    { value: 9, label: '用途地域' },
  ];

  static sortOrderList = [
    { value: 0, label: '降順' },
    { value: 1, label: '昇順' },
  ];

  static sortLimitList = [
    { value: 10, label: '10件' },
    { value: 25, label: '25件' },
    { value: 50, label: '50件' },
    { value: 100, label: '100件' },
  ];


  static headerData = [
    { value: 'sales_status', label: '販売状況' },
    { value: 'type', label: '種別' },
    { value: 'name', label: '物件名' },
    { value: 'address', label: '物件所在地' },
    { value: 'area_measuresize', label: '面積' },
    { value: 'purchasing_manager', label: '仕入れ責任者' },
    { value: 'purchase_datetime', label: '仕入れ年月日' },
    { value: 'store', label: '支店別' },
    { value: 'price', label: '販売価格 (円)' },
    { value: 'has_purchase_card', label: '買付証明\nの有無' },
    { value: 'customer_name', label: '顧客名' },
    { value: 'user_name', label: '営業担当者名' },
    { value: 'conclusion_datetime', label: '売買締結日' },
  ];

  static headerDataIntroduced = [
    { value: 'name', label: '物件名' },
    { value: 'address', label: '物件所在地' },
    { value: 'area_measuresize', label: '面積' },
    { value: 'price', label: '販売価格 (円)' },
    { value: 'user_name', label: '担当者' },
    { value: 'land_category', label: '地目' },
    { value: 'use_district', label: '用途地域' },
  ];
}