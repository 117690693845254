import * as React from 'react';
import './file-loader-component.scss';
import {UserAgentService} from '../../../service/user-agent-service';
import FileDnDComponent from './file-dnd/file-dnd-component';

interface FileLoaderComponentProps {
  callbackAccept: (files: File[], callback: () => void) => void;
  callbackError: (rejectFiles: File[]) => void;
  isAllowMulti: boolean;
}

interface FileLoaderComponentState {
  callbackAccept: (files: File[], callback: () => void) => void;
  callbackError: (rejectFiles: File[]) => void;
  isAllowMulti: boolean;
}

export class FileLoaderComponent extends React.Component<FileLoaderComponentProps, FileLoaderComponentState> {

  private accept: string = '.pdf,.png,.jpg,.jpeg,.doc,.docx,.xls,.xlsx';

  constructor(props: FileLoaderComponentProps) {
    super(props);
    this.state = {
      callbackAccept: props.callbackAccept,
      callbackError: props.callbackError,
      isAllowMulti: props.isAllowMulti,
    };
  }

  render() {
    const callbackAccept = this.state.callbackAccept;
    const callbackError = this.state.callbackError;
    const isAllowMulti = this.state.isAllowMulti;
    const isMobile: boolean = UserAgentService.ins.isMobile();
    return (
      <>
        <FileDnDComponent
          callbackAccept={(files: File[], callback: () => void) => callbackAccept(files, callback)}
          callbackError={(rejectFiles: File[]) => callbackError(rejectFiles)}
          isAllowMulti={isAllowMulti}
          accept={this.accept}
        />
      </>
      /*isMobile ? (
        <div className={'selectFile_sp flex_box flex_align_center flex_content_center'}>
          <label>
            ファイルを選択
            <input
              className={'reset btnStyle_1'}
              type={'file'}
              multiple={isAllowMulti}
              onChange={(e) => console.log('e : ', e)}
            />
          </label>
        </div>
      ) : (
        <>
          <FileDnDComponent
            callbackAccept={(files: File[], callback: () => void) => callbackAccept(files, callback)}
            callbackError={(rejectFiles: File[]) => callbackError(rejectFiles)}
            isAllowMulti={isAllowMulti}
            accept={this.accept}
          />
        </>
      )*/
    );
  }

}
