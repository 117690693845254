import {HTTPMethod, RequestBase} from './request-base';

export class RequestUsersGetMe extends RequestBase {

  param: {
  };

  constructor() {
    super(HTTPMethod.GET, '/api/users/me');
    this.param = {};
  }

  // getParam(): FormData {
  //   const formData: FormData = new FormData();
  //   Object.keys(this.param).forEach((v) => {
  //     formData.append(v, this.param[v] + '');
  //   });
  //   return formData;
  // }

  getUrl(): string {
    return this.host + this.endPoint;
  }

}
