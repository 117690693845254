import {HTTPMethod, RequestBase} from './request-base';

export class RequestArticlefilesPostDownload extends RequestBase {

  param: {
    file_list: string,
  };

  responseType: string = 'blob';

  constructor(
    file_list: string,
  ) {
    super(HTTPMethod.POST, '/api/articlefiles/download');
    this.param = {
      file_list: file_list,
    };
  }

  getUrl(): string {
    return this.host + this.endPoint;
  }

}
