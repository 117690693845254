
export class MoneyFormatter {

  static thousandSeparate(num: number, numOfDecimal: number = 0): string {
    const slideNum: number = num * (10 ** numOfDecimal);
    const roundNum: number = Math.round(slideNum);
    const unSlideNum: number = roundNum / (10 ** numOfDecimal);
    const intNum: number = Math.floor(unSlideNum);
    const decimalNum: number = Math.round((Math.round(unSlideNum * 100000) - Math.round(intNum * 100000)) / 100000 * (10 ** numOfDecimal));
    return String(intNum).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") + (decimalNum ? '.' + String(decimalNum) : '');
  }

  static thousandCombine(separatedNum: string): number {
    return Number(separatedNum.replace(/,/g, ''));
  }

}
