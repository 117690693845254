import { HTTPMethod, RequestBase, NullPermission } from './request-base';

type Param = {
  id: number,
};

export class RequestQuestionnaireDelete extends RequestBase {

  constructor(
    public param: Param
  ) {
    super(HTTPMethod.DELETE, '/api/subdivisionsquestionnaire/');
  }

  getUrl(): string {
    return this.host + this.endPoint + this.param.id;
  }
}