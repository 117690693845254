
export class Article {

  constructor(
    public id: number | null,
    public name: string,
    public sales_status: number | null,
    public reservation_flag: boolean | null,
    public type: number | null,
    public building_name: string,
    public tsubo: number | null,
    public price: number | null,
    public prefecture_id: number | null,
    public city: any,
    public city_id: number | null,
    public address: string,
    public area_measuresize: number | null,
    public purchasing_manager_id: number | null,
    public user_id: number | null,
    public build_datetime: Date | null,
    public purchase_datetime: Date | null,
    public has_purchase_card: boolean | null,
    public customer_name: string,
    public conclusion_datetime: Date | null,
    public m_school_id: number | null,
    public p_school_id: number | null,
    public subdivision_id: number | null,
    public period_start: Date | null,
    public period_end: Date | null,
    public message: string | null,
    public email: string | null,
    public reserve_count: number | null,
    public area_count: number | null,
    public area_max: number | null,
    public remarks?: string | null,
    public land_category?: number | null,
    public use_district?: number | null,
    public valid_flag?: number | null,
    public subdivision_upper_limit_flag?: number | null,
    //
    // public route1: string,
    // public station1: string,
    // public route2: string,
    // public station2: string,
    // public walk_time: number,
    // public private_road_measuresize: number,
    // public private_road_tsubo: number,
    // public tsubo_unit: number,
    // public ownership: number, //enum
    // public land_category: number, //enum
    // public city_planning: string,
    // public use_district: number, //enum
    // public coverage: number,
    // public floor_area: number,
    // public law_limitation: string,
    // public current_state: string,
    // public connecting_roads: string,
    // public equipment: string,
    // public remarks: string,
  ) {
  }

}
