import * as React from 'react';
import './modal-fuel-error-component.scss';

interface ModalFuelErrorComponentProps {
  htmlStr: string;
  callback: () => void;
}

interface ModalFuelErrorComponentState {
  htmlStr: string;
  callback: () => void;
}

export class ModalFuelErrorComponent extends React.Component<ModalFuelErrorComponentProps, ModalFuelErrorComponentState> {

  private targetId: string = 'error-html-target';

  constructor(props: ModalFuelErrorComponentProps) {
    super(props);
    this.state = {
      htmlStr: props.htmlStr,
      callback: props.callback,
    };
  }

  render() {
    const callback = this.state.callback;
    return (
      <div className={'modalInnerBody_wrap alertModal_wrap alertFuelModal_wrap'}>

        {/* msg */}
        <div className={'msg_wrap'}>
          <div
            id={this.targetId}
            style={{
            }}
          />
        </div>

        <div className={'divide20'} />

        {/* button */}
        <div className={'btn_wrap'}>
          <button
            className={'reset btnStyle_1 marg_auto'}
            onClick={() => callback()}
          >OK</button>
        </div>

      </div>
    );
  }

  componentDidMount(): void {
    const htmlStr = this.state.htmlStr;
    const targetEle = document.getElementById(this.targetId);
    if (targetEle) targetEle.innerHTML = htmlStr;
  }

}
