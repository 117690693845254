import * as React from 'react';
import './master-prefecture-component.scss';
import { HeaderComponent } from '../../common/header/header-component';
import { SideMenuComponent } from '../../common/side-menu/side-menu-component';
import { Prefecture } from '../../../model/prefecture';
import { MasterListHeader } from '../../common/master-list/master-list-header';
import { MasterListTableComponent } from '../../common/master-list/master-list-table/master-list-table-component';
import { MasterListRow } from '../../common/master-list/master-list-row';
import iconCheckBox from '../../../img/common/checkbox.png';
import iconCheckBoxChecked from '../../../img/common/checkbox_checked.png';
import { PagerComponent } from '../../common/pager/pager-component';
import { ModalService } from '../../../service/modal-service';
import { ModalEditPrefectureComponent } from './modal-edit-prefecture/modal-edit-prefecture-component';
import { ModalAlertComponent } from '../../common/modal/modal-alert/modal-alert-component';
import { RequestPrefecuresGetSearch } from '../../../model/api/request/request-prefecures-get-search';
import { ConnectionService } from '../../../service/connection-service';
import { ErrorService } from '../../../service/error-service';
import { RequestPrefecturesPost } from '../../../model/api/request/request-prefectures-post';

interface MasterPrefectureComponentProps {
}

interface MasterPrefectureComponentState {
  searchParamName: string,
  searchParamIsDisplayInvalid: boolean,
  sortType: number,
  sortOrder: number,
  sortLimit: number,
  pageCurrent: number,
  pageMax: number,
  numberTotal: number; // 件数
  numberCurrentFrom: number; // 現在の表示件数
  numberCurrentTo: number; // 現在の表示件数
  prefectureList: Prefecture[],
}

export class MasterPrefectureComponent extends React.Component<MasterPrefectureComponentProps, MasterPrefectureComponentState> {

  private sortOrderList: { value: number, label: string; }[] = [
    { value: 0, label: '降順' },
    { value: 1, label: '昇順' },
  ];

  private sortLimitList: { value: number, label: string; }[] = [
    { value: 10, label: '10件' },
    { value: 25, label: '25件' },
    { value: 50, label: '50件' },
    /*{ value: 100, label: '100件' },*/
  ];

  private headerList: MasterListHeader[] = [
    new MasterListHeader('id', 'No'),
    new MasterListHeader('name', '都道府県名'),
  ];

  private testPrefectureList: Prefecture[] = [
    new Prefecture(0, '福岡県', true),
    new Prefecture(1, '佐賀県', true),
    new Prefecture(2, '大分県', false),
    new Prefecture(3, '熊本県', true),
    new Prefecture(4, '宮崎県', false),
    new Prefecture(5, '長崎県', false),
    new Prefecture(6, '鹿児島県', false),
    new Prefecture(7, '沖縄県', false),
    new Prefecture(8, '山口県', false),
    new Prefecture(9, '島根県', false),
    new Prefecture(10, '鳥取県', false),
    new Prefecture(11, '広島県', false),
  ];

  constructor(props: MasterPrefectureComponentProps) {
    super(props);
    this.state = {
      searchParamName: '',
      searchParamIsDisplayInvalid: false,
      sortType: 1,
      sortOrder: 0,
      sortLimit: 25,
      pageCurrent: 1,
      pageMax: 1,
      numberTotal: 0,
      numberCurrentFrom: 0,
      numberCurrentTo: 0,
      prefectureList: []
    };
  }

  render() {
    const searchParamName: string = this.state.searchParamName;
    const searchParamIsDisplayInvalid: boolean = this.state.searchParamIsDisplayInvalid;
    const sortType: number = this.state.sortType;
    const sortOrder: number = this.state.sortOrder;
    const sortLimit: number = this.state.sortLimit;
    const headerList: MasterListHeader[] = this.headerList;
    const prefectureList: Prefecture[] = this.state.prefectureList;
    const numberTotal = this.state.numberTotal;
    const numberCurrentFrom = this.state.numberCurrentFrom;
    const numberCurrentTo = this.state.numberCurrentTo;
    return (
      <div>

        {/* --- header --- */}
        <HeaderComponent />

        <div className={'body_wrap flex_box'}>

          {/* side menu */}
          <div className={'pc menu_wrap'}>
            <SideMenuComponent />
          </div>

          <div className={'content_wrap masterSystem_cont prefecture_cont'}>
            <div className={'contentInner_wrap table_masterPrefecture'}>


              {/* title */}
              <div className={'contentHead_wrap'}>
                <div className={'contentHeadInner_wrap flex_box flex_align_center flex_space_between'}>
                  <div className={'contentHead_text flex_box flex_align_center'}>
                    <span>都道府県マスタ</span>
                    <span></span>
                  </div>
                </div>
              </div>{/** contentHead_wrap */}

              {/* search */}
              <div className={'searchArea'}>

                <div className={'masterInput_wrap flex_box flex_align_center'}>
                  <div className={'left_wrap flex_box flex_align_center'}>
                    <div>都道府県名</div>
                    <div>
                      <input
                        className={'textInput'}
                        type={'search'}
                        value={searchParamName}
                        placeholder={'都道府県名を入力してください'}
                        onChange={(e) => this.setState({ searchParamName: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className={'right_wrap'}>
                    <div
                      className={'checkbox_wrap flex_box flex_align_center'}
                      onClick={() => {
                        this.setState({ searchParamIsDisplayInvalid: !searchParamIsDisplayInvalid });
                        this.postSearchAPI({ ...this.state, searchParamIsDisplayInvalid: !searchParamIsDisplayInvalid });
                      }}
                    >
                      {searchParamIsDisplayInvalid ? (
                        <div className={'innerImg checked'}><img src={iconCheckBoxChecked} /></div>
                      ) : (
                        <div className={'innerImg noCheck'}><img src={iconCheckBox} /></div>
                      )}
                      <div className={'innerText selectDisabled'}>無効データを含める</div>
                    </div>
                  </div>
                </div>

                <div className={'divide20'}></div>

                <div className={'searchBtn_wrap'}>
                  <button
                    className={'reset btnStyle_1 marg_auto'}
                    onClick={() => this.postSearchAPI(this.state)}
                  >
                    検索
                  </button>
                </div>
              </div>

              {/* sort */}
              <div className={'sort_pager_wrap'}>
                <div className={'sort_pager_inner_wrap flex_box flex_align_center flex_space_between'}>
                  <div className={'select_wrap flex_box flex_align_center'}>

                    <div>
                      <select
                        onChange={(e) => {
                          this.setState({ sortType: Number(e.target.value) });
                          this.postSearchAPI({ ...this.state, sortType: Number(e.target.value) });
                        }}
                        value={sortType}
                      >
                        {headerList.map((header, i) => (
                          <option key={header.value} value={i + 1}>{header.label}</option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <select
                        onChange={(e) => {
                          this.setState({ sortOrder: Number(e.target.value) });
                          this.postSearchAPI({ ...this.state, sortOrder: Number(e.target.value) });
                        }}
                        value={sortOrder}
                      >
                        {this.sortOrderList.map((v) => (
                          <option key={v.value} value={v.value}>{v.label}</option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <select
                        onChange={(e) => {
                          this.setState({ sortLimit: Number(e.target.value) });
                          this.postSearchAPI({ ...this.state, sortLimit: Number(e.target.value), pageCurrent: 1 });
                        }}
                        value={sortLimit}
                      >
                        {this.sortLimitList.map((v) => (
                          <option key={v.value} value={v.value}>{v.label}</option>
                        ))}
                      </select>
                    </div>

                  </div>
                </div>
              </div>

              <div className={'divide10'}></div>

              <div className={'displayNum_pager_wrap flex_box flex_align_center flex_space_between'}>

                <div className={'pagenum_wrap'}>
                  <div className={'pagenumInner_wrap flex_box flex_align_center'}>
                    <div>{numberCurrentFrom}~{numberCurrentTo}件</div>
                    <div>/</div>
                    <div>全{numberTotal}件</div>
                  </div>
                </div>{/** pagenum_wrap */}

                {/* ページャ */}
                <PagerComponent
                  currentPage={this.state.pageCurrent}
                  max={this.state.pageMax}
                  callback={(page) => this.callbackSelectPage(page)}
                />

              </div>{/* displayNum_pager_wrap */}

              <div className={'divide10'}></div>

              {/* テーブル */}
              <MasterListTableComponent
                headerList={headerList}
                rowList={this.makeTableData(prefectureList, searchParamIsDisplayInvalid)}
                isEdited={false}
                isValidEdited={true}
              />


            </div>
          </div>

        </div>

      </div>
    );
  }
  componentDidMount(): void {
    this.postSearchAPI(this.state);
  }

  private callbackBtnInvalid(): void {
  }

  private callbackBtnEdit(id: number): void {
    const prefecture = this.state.prefectureList.find((v) => v.id === id);
    if (!prefecture) return;
    ModalService.ins.display(
      <ModalEditPrefectureComponent
        prefecture={prefecture}
        callback={(prefecture) => this.postEditAPI(prefecture)}
      />
    );
  }

  private callbackSelectPage(page: number): void {
    this.setState({ pageCurrent: page });
    this.postSearchAPI({ ...this.state, pageCurrent: page });
  }

  private postSearchAPI(state: MasterPrefectureComponentState): void {
    const request: RequestPrefecuresGetSearch = new RequestPrefecuresGetSearch(
      state.searchParamName,
      state.sortLimit,
      state.pageCurrent,
      state.sortType,
      state.sortOrder,
      state.searchParamIsDisplayInvalid ? 0 : 1
    );
    ConnectionService.ins.connect(request)
      .then((res) => {
        const list: Prefecture[] = res.data.list;
        this.setState({
          prefectureList: list,
          pageCurrent: state.pageCurrent,
          pageMax: Math.ceil(res.data.count / res.data.limit),
          numberTotal: res.data.count,
          numberCurrentFrom: res.data.from,
          numberCurrentTo: res.data.to,
        });
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }

  private postEditAPI(prefecture: Prefecture): void {
    const request: RequestPrefecturesPost = new RequestPrefecturesPost(
      prefecture.id,
      prefecture.name,
      +prefecture.valid_flag === 1 ? 1 : 0,
    );
    ConnectionService.ins.connect(request)
      .then((res) => {
        ModalService.ins.push(
          <ModalAlertComponent
            msgList={[prefecture.id ? '更新しました。' : '登録しました。']}
            callback={() => {
              ModalService.ins.close();
              this.postSearchAPI(this.state);
            }}
          />
        );
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }

  private makeTableData(list: Prefecture[], isDisplayInvalid: boolean): MasterListRow[] {
    return list
      .filter((v) => (isDisplayInvalid || (!isDisplayInvalid && v.valid_flag)))
      .map((v:any) => (
        new MasterListRow(
          this.headerList.map((header) => v[header.value]),
          +v.valid_flag === 1,
          () => this.callbackBtnEdit(v.id),
          () => this.callbackBtnInvalid()
        )
      ));
  }

}
