import * as React from 'react';
import './master-subdivision-component.scss';
import { HeaderComponent } from '../../common/header/header-component';
import { SideMenuComponent } from '../../common/side-menu/side-menu-component';
import { Subdivision } from '../../../model/subdivision';
import { MasterListRow } from '../../common/master-list/master-list-row';
import iconCheckBoxChecked from '../../../img/common/checkbox_checked.png';
import iconCheckBox from '../../../img/common/checkbox.png';
import { MasterListTableComponent } from '../../common/master-list/master-list-table/master-list-table-component';
import { MasterListHeader } from '../../common/master-list/master-list-header';
import { PagerComponent } from '../../common/pager/pager-component';
import { Prefecture } from '../../../model/prefecture';
import { City } from '../../../model/city';
import { ModalService } from '../../../service/modal-service';
import { ModalEditSubdivisionComponent } from './modal-edit-subdivision/modal-edit-subdivision-component';
import { ModalAlertComponent } from '../../common/modal/modal-alert/modal-alert-component';
import { ErrorService } from '../../../service/error-service';
import { ConnectionService } from '../../../service/connection-service';
import { DataManagerService } from '../../../service/data-manager-service';
//Get,Post
import { RequestSubdivisionGetSearch } from '../../../model/api/request/request-subdivision-get-search';
import { RequestSubdivisionPost } from '../../../model/api/request/request-subdivision-post';
import { DispatchToProps, StateToProps } from '../../../redux/store';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { RoutingPass } from '../../../constant/routing-pass';
import { MasterSubdivisionModel } from './master-subdivision.model';
import { SubdivisionActions } from '../../../redux/subdibision/subdivision.action';
import { State } from '../../../redux/root.reducer';

interface MasterSubdivisionComponentProps {
  dispatch: (v: any) => void;
  rowStatus: MasterListRow[] | null;
}

interface MasterSubdivisionComponentState {
  searchParamName: string;
  searchParamIsDisplayInvalid: boolean;
  searchParamPrefectureId: number;
  searchParamCityId: number;
  sortType: number;
  sortOrder: number;
  sortLimit: number;
  subdivisionList: Subdivision[];
  prefectureList: Prefecture[];
  cityList: City[];
  pageCurrent: number,
  pageMax: number,
  numberTotal: number; // 件数
  numberCurrentFrom: number; // 現在の表示件数
  numberCurrentTo: number; // 現在の表示件数
  tableData: MasterSubdivisionModel | null;
}

export class MasterSubdivisionBodyComponent extends React.Component<MasterSubdivisionComponentProps, MasterSubdivisionComponentState> {

  constructor(props: MasterSubdivisionComponentProps) {
    super(props);
    this.state = {
      searchParamName: '',
      searchParamIsDisplayInvalid: false,
      searchParamPrefectureId: 0,
      searchParamCityId: 0,
      sortType: 1,
      sortOrder: 0,
      sortLimit: 25,
      subdivisionList: [],
      prefectureList: [],
      cityList: [],
      pageCurrent: 1,
      pageMax: 1,
      numberTotal: 0,
      numberCurrentFrom: 0,
      numberCurrentTo: 0,
      tableData: null,
    };
  }

  render() {
    const searchParamName: string = this.state.searchParamName;
    const searchParamIsDisplayInvalid: boolean = this.state.searchParamIsDisplayInvalid;
    const searchParamPrefectureId: number = this.state.searchParamPrefectureId;
    const searchParamCityId: number = this.state.searchParamCityId;
    const sortType: number = this.state.sortType;
    const sortOrder: number = this.state.sortOrder;
    const sortLimit: number = this.state.sortLimit;
    const headerList: MasterListHeader[] = MasterSubdivisionModel.headerList;
    const subdivisionList: Subdivision[] = this.state.subdivisionList;
    const prefectureList: Prefecture[] = this.state.prefectureList;
    const cityList: City[] = this.state.cityList;
    const numberTotal = this.state.numberTotal;
    const numberCurrentFrom = this.state.numberCurrentFrom;
    const numberCurrentTo = this.state.numberCurrentTo;
    return (
      <div className={''}>
        {/* --- header --- */}
        <HeaderComponent />

        <div className={'body_wrap flex_box'}>

          {/* side menu */}
          <div className={'pc menu_wrap'}>
            <SideMenuComponent />
          </div>

          <div className={'content_wrap masterSystem_cont masterSale_cont'}>
            <div className={'contentInner_wrap table_masterSale'}>

              {/* title */}
              <div className={'contentHead_wrap'}>
                <div className={'contentHeadInner_wrap flex_box flex_align_center flex_space_between'}>
                  <div className={'contentHead_text flex_box flex_align_center'}>
                    <span>分譲マスタ</span>
                    <span />
                  </div>
                </div>
              </div>
              {/** contentHead_wrap */}

              {/* search */}
              <div className={'searchArea masterSale'}>
                <div className={'select_wrap flex_box flex_align_center'}>
                  <div className={'left_wrap flex_box flex_align_center'}>
                    <div>都道府県</div>
                    <div>
                      <select
                        onChange={(e) => {

                          this.setState({ searchParamPrefectureId: Number(e.target.value) || 0, searchParamCityId: 0 });
                          this.postSearchAPI({
                            ...this.state,
                            searchParamPrefectureId: Number(e.target.value) || 0,
                            searchParamCityId: 0
                          });
                          DataManagerService.ins.getCityList(Number(e.target.value) || null)
                            .then((v) => {
                              this.setState({ cityList: v });
                            })
                            .catch((v) => {
                              this.setState({ cityList: v });
                            });
                        }}
                        value={searchParamPrefectureId}
                      >
                        <option
                          value={0}
                        >
                          未選択
                        </option>
                        {prefectureList.map((prefecture: Prefecture) => (
                          <option
                            key={prefecture.id}
                            value={prefecture.id}
                          >
                            {prefecture.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className={'right_wrap flex_box flex_align_center'}>
                    <div>市区町村</div>
                    <div>
                      <select
                        onChange={(e) => {
                          this.setState({ searchParamCityId: Number(e.target.value) || 0 });
                          this.postSearchAPI({ ...this.state, searchParamCityId: Number(e.target.value) || 0 });
                        }}
                        value={searchParamCityId}
                      >
                        <option
                          value={0}
                        >
                          未選択
                        </option>
                        {cityList.map((city: City) => (
                          <option
                            key={city.id}
                            value={city.id ?? ''}
                          >
                            {city.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className={'divide10'} />

                <div className={'masterInput_wrap flex_box flex_align_center'}>
                  <div className={'left_wrap flex_box flex_align_center'}>
                    <div>分譲地名</div>
                    <div>
                      <input
                        className={'textInput'}
                        type={'search'}
                        value={searchParamName}
                        placeholder={'分譲地名を入力してください'}
                        onChange={(e) => {
                          this.setState({ searchParamName: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className={'right_wrap'}>
                    <div
                      className={'checkbox_wrap flex_box flex_align_center'}
                      onClick={() => {
                        this.setState({ searchParamIsDisplayInvalid: !searchParamIsDisplayInvalid });
                        this.postSearchAPI({ ...this.state, searchParamIsDisplayInvalid: !searchParamIsDisplayInvalid });
                      }}
                    >
                      {searchParamIsDisplayInvalid ? (
                        <div className={'innerImg checked'}><img src={iconCheckBoxChecked} alt={''} /></div>
                      ) : (
                        <div className={'innerImg noCheck'}><img src={iconCheckBox} alt={''} /></div>
                      )}
                      <div className={'innerText selectDisabled'}>無効データを含める</div>
                    </div>
                  </div>
                </div>

                <div className={'divide20'} />

                <div className={'searchBtn_wrap'}>
                  <button
                    className={'reset btnStyle_1 marg_auto'}
                    onClick={() => this.postSearchAPI(this.state)}
                  >
                    検索
                  </button>
                </div>
              </div>

              {/* sort */}
              <div className={'sort_pager_wrap flex_box flex_align_center flex_space_between'}>

                <div className={'sort_pager_inner_wrap flex_box flex_align_center flex_space_between'}>

                  <div className={'select_wrap flex_box flex_align_center'}>

                    <div>
                      <select
                        onChange={(e) => {
                          this.setState({ sortType: Number(e.target.value) });
                          this.postSearchAPI({ ...this.state, sortType: Number(e.target.value) });
                        }}
                        value={sortType}
                      >
                        {headerList.map((header, i) => (
                          // header.value !== 'name' ? (
                          //   <option key={header.value} value={i + 1}>{header.label}</option>
                          // ) : (null)
                          <option key={header.value} value={i + 1}>{header.label}</option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <select
                        onChange={(e) => {
                          this.setState({ sortOrder: Number(e.target.value) });
                          this.postSearchAPI({ ...this.state, sortOrder: Number(e.target.value) });
                        }}
                        value={sortOrder}
                      >
                        {MasterSubdivisionModel.sortOrderList.map((v) => (
                          <option key={v.value} value={v.value}>{v.label}</option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <select
                        onChange={(e) => {
                          this.setState({ sortLimit: Number(e.target.value) });
                          this.postSearchAPI({ ...this.state, sortLimit: Number(e.target.value), pageCurrent: 1 });
                        }}
                        value={sortLimit}
                      >
                        {MasterSubdivisionModel.sortLimitList.map((v) => (
                          <option key={v.value} value={v.value}>{v.label}</option>
                        ))}
                      </select>
                    </div>

                  </div>
                </div>

                <div className={'btn_newAdd_wrap'}>
                  <button
                    className={'reset btnStyle_1'}
                    onClick={() => this.handlerBtnNew()}
                  >
                    新規登録
                  </button>
                </div>
              </div>

              <div className={'divide10'} />

              <div className={'displayNum_pager_wrap flex_box flex_align_center flex_space_between'}>

                <div className={'pagenum_wrap'}>
                  <div className={'pagenumInner_wrap flex_box flex_align_center'}>
                    <div>{numberCurrentFrom}~{numberCurrentTo}件</div>
                    <div>/</div>
                    <div>全{numberTotal}件</div>
                  </div>
                </div>
                {/** pagenum_wrap */}

                {/* ページャ */}
                <PagerComponent
                  currentPage={this.state.pageCurrent}
                  max={this.state.pageMax}
                  callback={(page) => this.callbackSelectPage(page)}
                />

              </div>
              {/* displayNum_pager_wrap */}

              <div className={'divide10'} />
              {/* テーブル */}
              <MasterListTableComponent
                headerList={headerList}
                rowList={this.state.tableData?.tableData || []}
                isEdited={true}
                isValidEdited={true}
                open={(v) => this.state.tableData?.rowOpen(v)}
                close={(v) => this.state.tableData?.rowClose(v)}
                isSubdivision
              />
            </div>
          </div>
        </div>

      </div>
    );
  }

  componentDidMount(): void {
    this.postSearchAPI(this.state, true);
    DataManagerService.ins.getPrefectureList()
      .then((v) => this.setState({ prefectureList: v }))
      .catch((v) => this.setState({ prefectureList: v }));
  }

  private callbackBtnInvalid(): void {
  }

  private callbackBtnEdit(id: number): void {
    const isParent = this.state.tableData?.tableData.find((v) => v.parentId === id)?.isParent;
    let pass = RoutingPass.SUBDIVISION_DETAIL_COMPONENT;
    if (!isParent) pass = RoutingPass.ARTICLE_DETAIL;
    this.props.dispatch(push(`${pass}/${id}`));

    // ModalService.ins.display(
    //   <ModalEditSubdivisionComponent
    //     isPrimary={false}
    //     subdivision={subdivision}
    //     callback={(subdivision) => this.postEditAPI(subdivision)}
    //   />,
    // );
  }

  private callbackSelectPage(page: number): void {
    this.setState({ pageCurrent: page });
    this.postSearchAPI({ ...this.state, pageCurrent: page });
  }

  private handlerBtnNew(): void {
    this.props.dispatch(push(`${RoutingPass.SUBDIVISION_DETAIL_COMPONENT}/0`));

    // const subdivision: Subdivision = new Subdivision(null, '', '', '', true, 0, 0, 0, 0, []);
    // ModalService.ins.display(
    //   <ModalEditSubdivisionComponent
    //     isPrimary={false}
    //     subdivision={subdivision}
    //     callback={(subdivision) => this.postEditAPI(subdivision)}
    //   />,
    // );
  }

  private postSearchAPI(state: MasterSubdivisionComponentState, isDidMount?: boolean): void {
    if (!isDidMount) {
      this.props.dispatch(SubdivisionActions.setRowStatus(null));
    }

    const request: RequestSubdivisionGetSearch = new RequestSubdivisionGetSearch(
      state.searchParamName,
      state.sortLimit,
      state.pageCurrent,
      state.sortType,
      state.sortOrder,
      state.searchParamIsDisplayInvalid ? 0 : 1,
      state.searchParamPrefectureId,
      state.searchParamCityId,
    );
    ConnectionService.ins.connect(request)
      .then((res) => {
        const list: Subdivision[] = res.data.list;
        const tableData = new MasterSubdivisionModel({
          data: list,
          isDisplayInvalid: state.searchParamIsDisplayInvalid,
          callbackBtnEdit: (v) => this.callbackBtnEdit(v),
          callbackBtnInvalid: () => this.callbackBtnInvalid,
          rowStatus: this.props.rowStatus,
        });
        this.setState({
          subdivisionList: list,
          pageCurrent: state.pageCurrent,
          pageMax: Math.ceil(res.data.count / res.data.limit),
          numberTotal: res.data.count,
          numberCurrentFrom: res.data.from,
          numberCurrentTo: res.data.to,
          tableData
        });
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }

  private postEditAPI(sale: Subdivision): void {
    const request: RequestSubdivisionPost = new RequestSubdivisionPost(
      sale.id,
      sale.name,
      sale.name_kana,
      sale.address,
      Number(sale.valid_flag),
      sale.prefecture_id,
      sale.city_id,
      sale.area_max,
      sale.reservation_count,
    );
    ConnectionService.ins.connect(request)
      .then(() => {
        ModalService.ins.push(
          <ModalAlertComponent
            msgList={[sale.id ? '更新しました。' : '登録しました。']}
            callback={() => {
              ModalService.ins.close();
              this.postSearchAPI(this.state);
            }}
          />
        );
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }
}


const mapStateToProps: StateToProps = (state: State) => ({
  rowStatus: state.subdivision.rowStatus
});

const mapDispatchToProps: DispatchToProps = (dispatch) => ({
  dispatch: (v: any) => dispatch(v)
});

export const MasterSubdivisionComponent = connect(mapStateToProps, mapDispatchToProps)(MasterSubdivisionBodyComponent);