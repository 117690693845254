import * as React from 'react';
import * as Lodash from 'lodash';
import './modal-edit-prefecture-component.scss';
import {Prefecture} from '../../../../model/prefecture';
import iconCheckBoxChecked from '../../../../img/common/checkbox_checked.png';
import iconCheckBox from '../../../../img/common/checkbox.png';
import {ModalService} from '../../../../service/modal-service';

interface ModalEditPrefectureComponentProps {
  prefecture: Prefecture;
  callback: (prefecture: Prefecture) => void;
}

interface ModalEditPrefectureComponentState {
  prefecture: Prefecture;
  callback: (prefecture: Prefecture) => void;
}

export class ModalEditPrefectureComponent extends React.Component<ModalEditPrefectureComponentProps, ModalEditPrefectureComponentState> {

  constructor(props: ModalEditPrefectureComponentProps) {
    super(props);
    this.state = {
      prefecture: Lodash.cloneDeep(props.prefecture),
      callback: props.callback,
    };
  }

  render() {
    const prefecture: Prefecture = this.state.prefecture;
    return (
      <div className={'modalInnerBody_wrap masterEdit_modal'}>

        <div className={'masterEditInner_modal table_wrap'}>
          <div className={'status_wrap table_row'}>
            <div className={'table_cell'}>有効/無効</div>
            {+prefecture.valid_flag === 1 ? (
              <div
                onClick={() => {
                  prefecture.valid_flag = false;
                  this.setState({prefecture: prefecture});
                }}
                className={'table_cell innerImg checked'}
              >
                <img src={iconCheckBoxChecked}/>
              </div>
            ) : (
              <div
                onClick={() => {
                  prefecture.valid_flag = true;
                  this.setState({prefecture: prefecture});
                }}
                className={'table_cell innerImg noCheck'}
              >
                <img src={iconCheckBox}/>
              </div>
            )}
          </div>
        </div>

        <div className={'divide40'}></div>

        <div className={'btn_wrap flex_box flex_space_between'}>
          <div>
            <button
              className={'reset btnStyle_1'}
              onClick={() => this.handlerClickBtnCancel()}
            >
              キャンセル
            </button>
          </div>
          <div>
            <button
              className={'reset btnStyle_1'}
              onClick={() => this.handlerClickBtnOk()}
            >
              更新
            </button>
          </div>
        </div>

      </div>
    );
  }

  private handlerClickBtnCancel(): void {
    ModalService.ins.close();
  }

  private handlerClickBtnOk(): void {
    /*const errorMsgList: string[] = this.checkValidation();
    if (errorMsgList.length > 0) {
      ModalService.ins.push(
        <ModalAlertComponent
          msgList={errorMsgList}
          callback={() => {
            ModalService.ins.pop();
          }}
        />
      );
      return;
    }*/
    this.state.callback(this.state.prefecture);
  }

}
