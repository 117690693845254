import * as React from 'react';
import './setting-pass-component.scss';
import { ChangeEvent } from 'react';
import { KeyboardEvent } from 'react';
import { RoutingPass } from '../../../constant/routing-pass';
import { ModalService } from '../../../service/modal-service';
import { ModalAlertComponent } from '../../common/modal/modal-alert/modal-alert-component';
import { RequestUsersGetInvitees } from '../../../model/api/request/request-users-get-invitees';
import { ConnectionService } from '../../../service/connection-service';
import { ErrorService } from '../../../service/error-service';
import { RequestUsersPostPassword } from '../../../model/api/request/request-users-post-password';
import { Store } from '../../../redux/store';
import { push } from 'connected-react-router';

interface SettingPassComponentProps {
  match: {
    params: {
      hash: string;
    };
  };
}

interface SettingPassComponentState {
  pass: string;
  passConfirm: string;
  id: number | null;
}

export class SettingPassComponent extends React.Component<SettingPassComponentProps, SettingPassComponentState> {

  constructor(props: SettingPassComponentProps) {
    super(props);
    this.state = {
      pass: '',
      passConfirm: '',
      id: null,
    };
  }

  render() {
    const pass: string = this.state.pass;
    const passConfirm: string = this.state.passConfirm;
    return (
      <div className={'login_wrap flex_box flex_align_center flex_content_center'}>
        <div className={'loginInner_wrap'}>

          パスワードを入力してください

          <div className={'divide20'} />

          <div className={'input_wrap'}>
            <input
              onChange={(e: ChangeEvent<HTMLInputElement>) => { this.setState({ pass: e.target.value }); }}
              onKeyPress={(e: KeyboardEvent) => this.handlerPressEnter(e)}
              type={'password'}
              placeholder={'パスワード'}
              className={'textInput'}
            />
          </div>

          <div className={'input_wrap'}>
            <input
              onChange={(e: ChangeEvent<HTMLInputElement>) => { this.setState({ passConfirm: e.target.value }); }}
              onKeyPress={(e: KeyboardEvent) => this.handlerPressEnter(e)}
              type={'password'}
              placeholder={'再入力'}
              className={'textInput'}
            />
          </div>

          <div className={'btn_wrap'}>
            <button
              className={'reset btnStyle_1'}
              onClick={() => this.handlerClickBtnSubmit()}
            >
              決定
            </button>
          </div>

        </div>
      </div>
    );
  }

  componentDidMount(): void {
    const hash: string = this.props['match']['params']['hash'] || '';
    const request: RequestUsersGetInvitees = new RequestUsersGetInvitees(hash);
    ConnectionService.ins.connect(request)
      .then((res) => {
        const id: number = +res.data.id;
        this.setState({ id: id });
      })
      .catch((err) => {
        //ErrorService.ins.response(err);
      });
  }

  private handlerPressEnter(e: KeyboardEvent): void {
    if (e.key === 'Enter') {
    }
  }

  private handlerClickBtnSubmit(): void {
    const errorMsgList: string[] = this.checkValidation();
    if (errorMsgList.length > 0) {
      ModalService.ins.push(
        <ModalAlertComponent
          msgList={errorMsgList}
          callback={() => {
            ModalService.ins.close();
          }}
        />
      );
      return;
    }
    const request: RequestUsersPostPassword = new RequestUsersPostPassword(this.state.id, this.state.pass);
    ConnectionService.ins.connect(request)
      .then((res) => {
        ModalService.ins.display(
          <ModalAlertComponent
            msgList={[
              'パスワードを設定しました。',
              'ログイン画面に戻ります。'
            ]}
            callback={() => {
              Store.dispatch(push(RoutingPass.LOGIN));
              ModalService.ins.close();
            }}
          />
        );
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }

  private postAPI(): void {
  }

  private checkValidation(): string[] {
    const msgList: string[] = [];
    const pass: string = this.state.pass;
    const passConfirm: string = this.state.passConfirm;
    if (!pass) {
      msgList.push('パスワード が未入力です。');
    }
    if (!passConfirm) {
      msgList.push('パスワード (再入力) が未入力です。');
    }
    if (msgList.length > 0) {
      return msgList;
    }
    if (pass !== passConfirm) {
      msgList.push('入力されたパスワードが一致していません。');
    }
    return msgList;
  }

}
