import * as React from 'react';
import './modal-alert-component.scss';
import iconCopy from '../../../../img/common/icon_copy.svg';
import iconCopyHov from '../../../../img/common/icon_copy_hov.svg';

interface ModalUrlComponentProps {
  msgList: string[];
  url: string;
  callback: () => void;
}

interface ModalUrlComponentState {
  msgList: string[];
  url: string;
  callback: () => void;
}

export class ModalReleaseUrlComponent extends React.Component<ModalUrlComponentProps, ModalUrlComponentState> {

  constructor(props: ModalUrlComponentProps) {
    super(props);
    this.state = {
      msgList: props.msgList,
      url: props.url,
      callback: props.callback,
    };
  }

  render() {
    const msgList: string[] = this.state.msgList;
    const url: string = this.state.url;
    const callback: () => void = this.state.callback;
    return (
      <div className={'modalInnerBody_wrap alertModal_wrap'}>

        {/* msg */}
        <div className={'msg_wrap'}>
          <h2>送信完了</h2>
          {msgList.map((msg, i) => (
            <div
              key={msg + i}
            >
              {msg}
            </div>
          ))}
        </div>

        <div className={'divide20'}/>

        {/* url */}
        <div className={'url_wrap flex_box flex_align_center'}>
          <div><p>{url}</p></div>
          <div>
            <button
              className={'reset btnStyle_1 flex_box flex_align_center flex_content_center'}
              onClick={() => this.copyText(url)}
            >
              <img src={iconCopy} alt={'コピーする'} className={'img_def'}/>
              <img src={iconCopyHov} alt={'コピーする'} className={'img_hov'}/>
            </button>
          </div>
        </div>

        <div className={'divide20'}/>

        {/* button */}
        <div className={'btn_wrap'}>
          <button
            className={'reset btnStyle_1 marg_auto'}
            onClick={() => callback()}
          >OK
          </button>
        </div>

      </div>
    );
  }

  public copyText(text: string) {
    const textAreaEle = document.createElement('textarea');
    textAreaEle.textContent = text;
    document.body.appendChild(textAreaEle);
    textAreaEle.select();
    const result = document.execCommand('copy');
    document.body.removeChild(textAreaEle);
  }
}
