import * as React from 'react';
import './modal-questionnaire-component.scss';
import { ModalService } from '../../../../service/modal-service';
import iconAdd from '../../../../img/common/icon_add.png';
import iconAddHover from '../../../../img/common/icon_add_hover.png';
import { ModalQuestionnaireEditComponent } from '../modal-questionnaire-edit/modal-questionnaire-edit-component';
import { UserService } from '../../../../service/user-service';
import { RequestSubdivisionsQuestionNaireGetSearch } from '../../../../model/api/request/request-subdivisions-question-naire-get-search';
import { SubdivisionDetail } from '../../../../model/subdivision-detail';
import { User } from '../../../../model/user';
import { DateFormatter } from '../../../../util/date-formatter';
import { DataManagerService } from '../../../../service/data-manager-service';
import { ConnectionService } from '../../../../service/connection-service';
import { SubdivisionQuestionnaire } from '../../../../model/subdivision-questionnaire';
import { ErrorService } from '../../../../service/error-service';
import lodash from 'lodash';
import { RequestQuestionnaireDownload } from '../../../../model/api/request/request-questionnaire-download';
import { UserAgentService } from '../../../../service/user-agent-service';

interface ModalQuestionnaireComponentProps {
  subdivision: SubdivisionDetail;
}

interface ModalQuestionnaireComponentState {
  subdivision: SubdivisionDetail;
  questionnaire: { count: number, list: SubdivisionQuestionnaire[]; } | null;
  userList: User[];
  me: User | null;
}

export class ModalQuestionnaireComponent extends React.Component<ModalQuestionnaireComponentProps, ModalQuestionnaireComponentState> {

  constructor(props: ModalQuestionnaireComponentProps) {
    super(props);
    this.state = {
      subdivision: props.subdivision,
      questionnaire: null,
      userList: [],
      me: null,
    };
  }

  render() {
    const subdivision = this.state.subdivision;
    const questionnaireList: SubdivisionQuestionnaire[] = this.state.questionnaire?.list || [];
    const userList: User[] = this.state.userList;


    return (
      <div className={'modalInnerBody_wrap reservationListModal_wrap'}>

        <div className={'table_wrap'}>
          <div className={'table_row'}>
            <div className={'table_cell'}>
              分譲地名
            </div>
            <div className={'table_cell'}>
              {subdivision.name}
            </div>
          </div>{/** table_row row_head */}

          <div className={'table_row'}>
            <div className={'table_cell'}>
              アンケート申込状況
            </div>
            <div className={'table_cell customCell'}>
              <div className={'innerCell_scrollArea'}>
                {questionnaireList.map((questionnaire: SubdivisionQuestionnaire, i) => (

                  <div key={'res' + i} className={'reservedItem_wrap flex_box'}>
                    <div className={'itemDetail_wrap'}>
                      {/* 申込日 */}
                      <div>{
                      }
                      申込日 :{DateFormatter.date2str(new Date(String(questionnaire.application_date)))}
                        {/* 申込日 :{''} */}
                      </div>
                      <div className={'divide10'} />
                      {/* 顧客名 / 営業担当 */}
                      <div>
                        顧客名 :  {questionnaire.customer_name}
                      </div>
                      <div className={'divide10'} />
                      <div>
                        営業担当 :
                        {this.state.userList.find((v) => +(v.id ?? '') === +(questionnaire.sales_staff ?? '')) ? (
                          this.state.userList.find((v) => +(v.id ?? '') === +(questionnaire.sales_staff ?? ''))?.fullname['value']
                        ) : (
                          ''
                        )}
                      </div>
                    </div>{/** itemDetail_wrap */}

                    {/* ボタン */}
                    <div className={'itemInnerBtn_wrap'}>
                      {questionnaire.questionnaire_id ? (
                        <button
                          className={'reset btnStyle_1'}
                          onClick={() => this.handleClickQuestionnaire(questionnaire)}
                        >アンケート</button>
                      ) : (
                        <></>
                      )}
                      <div className={'divide10'} />
                      <button
                        className={'reset btnStyle_1'}
                        onClick={() => this.handlerClickBtnEdit(questionnaire)}
                      >編集</button>
                    </div>{/** itemInnerBtn_wrap */}
                  </div>
                ))}
                <div className={'reservedItem_wrap reservedNumber flex_box flex_align_center flex_content_center'}>
                  <div>申込件数 : </div>
                  <div className={'itemDetail_wrap'}>
                    {this.state.questionnaire?.count} 件
                  </div>
                </div>

              </div>
            </div>
          </div>{/** table_row row_body */}
        </div>{/** table_wrap */}

        <div className={'divide20'} />

        <div className={'btn_wrap flex_box flex_align_center flex_space_between'}>
          <div>
            <button
              className={'reset btnStyle_1'}
              onClick={() => this.handlerClickBtnClose()}
            >戻る</button>
          </div>
          <div>
            <button
              className={'reset btnStyle_1 flex_box flex_align_center'}
              onClick={() => this.handlerClickBtnNew()}
            >
              <img className={'imgIconAdd'} src={iconAdd} alt={''} />
              <img className={'imgIconAddHover'} src={iconAddHover} alt={''} />
              <span>新しく申込する</span>
            </button>
          </div>
        </div>

      </div>
    );
  }

  getApi() {
    UserService.ins.getMe()
      .then((v) => this.setState({ me: v }))
      .catch((v) => this.setState({ me: v }));
    const request = new RequestSubdivisionsQuestionNaireGetSearch(
      {
        q: '',
        limit: 100,
        p: 1,
        sort: 1,
        is_descending: 1,
        subdivision_id: this.state.subdivision.id as number
        ,
      });
    DataManagerService.ins.getUserList()
      .then((v) => {
        this.setState({ userList: v });
      })
      .catch((v) => {
        this.setState({ userList: v });
      });
    ConnectionService.ins.connect(request)
      .then((res) => {
        this.setState({
          questionnaire: lodash.cloneDeep(res.data)
        });
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }

  componentDidMount(): void {
    this.getApi();
  }

  private handlerClickBtnClose(): void {
    ModalService.ins.close();
  }

  private handlerClickBtnNew(): void {
    const reserve = new SubdivisionQuestionnaire({
      subdivision_id: this.state.subdivision.id as number,
      customer_name: '',
      sales_staff: null,
      user_id: null,
      questionnaire_id: null,
      application_date: new Date(),
      id: null,
      created_at: null,
      updated_at: null,
      deleted_at: null,
      questionnaire_file_name: '',
    });
    ModalService.ins.display(<ModalQuestionnaireEditComponent
      subdivision={this.state.subdivision}
      questionnaire={reserve}
      callback={() => this.getApi()} />);
  }

  private handlerClickBtnEdit(questionnaire: SubdivisionQuestionnaire): void {
    ModalService.ins.display(<ModalQuestionnaireEditComponent
      subdivision={this.state.subdivision}
      questionnaire={{ ...questionnaire, application_date: new Date(String(questionnaire.application_date)) }}
      callback={() => this.getApi()}
    />);
  }

  private handleClickQuestionnaire(questionnaire: SubdivisionQuestionnaire) {
    const request = new RequestQuestionnaireDownload({
      // file_list: JSON.stringify(["7"])
      file_list: JSON.stringify([questionnaire.questionnaire_id])
    });
    ConnectionService.ins.connect(request)
      .then((res) => {
        const url: string = window.URL.createObjectURL(res);
        if (UserAgentService.ins.isMobile()) {
          window.location.href = url;
          return;
        }
        const link: HTMLAnchorElement = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.setAttribute('download', questionnaire.questionnaire_file_name);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }
}
