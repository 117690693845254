
export class Reserve {

  constructor(
    public id: number | null,
    public article_id: number,
    public customer_name: string,
    public user_id: number | null,
    public reserve_start: Date | null,
    public reserve_end: Date | null,
    public purchase_card_id: number | null,
    public purchase_card_file_name: string,
  ) {
  }

}
