import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ModalActions } from './modal.action';

export type ModalState = {
  isDisplay: boolean;
  bodyList: JSX.Element[];
};

const initialState: ModalState = {
  isDisplay: false,
  bodyList: [],
};

export const ModalReducer = reducerWithInitialState<ModalState>(initialState)
  .case(ModalActions.setIsDisplay, (state, payload) => (
    {
      ...state,
      isDisplay: payload,
    }
  ))
  .case(ModalActions.setBodyList, (state, payload) => (
    {
      ...state,
      bodyList: payload,
    }
  ));
