import { SubdivisionDetail } from '../../../model/subdivision-detail';

export class SubdivisionDetailModel {
 

  static readonly useDistrictList: { value: number, label: string; }[] =
    [
      { value: 100, label: '無指定' },
      { value: 1, label: '1種低層' },
      { value: 2, label: '2種低層' },
      { value: 3, label: '1種中高層' },
      { value: 4, label: '2種中高層' },
      { value: 5, label: '1種住居' },
      { value: 6, label: '2種住居' },
      { value: 7, label: '準住居' },
      { value: 8, label: '近隣商業' },
      { value: 9, label: '準工業' },
      { value: 10, label: '工業' },
      { value: 11, label: '工業専用' },
    ];

  static readonly landRightList: { value: number, label: string; }[] = [
    { value: 1, label: '所有権' },
    { value: 2, label: '旧法地上権' },
    { value: 3, label: '旧法賃借権' },
    { value: 4, label: '普通地上権' },
    { value: 5, label: '普通賃借権' },
    { value: 6, label: '定期借地権（地上権）' },
    { value: 7, label: '定期借地権（賃借権）' },
  ];

  static readonly salesStatusList: { value: number, label: string; }[] = [
    { value: 1, label: '買付中' },
    { value: 2, label: '契約' },
    { value: 3, label: '契約済み' },
    { value: 4, label: '申請中' },
    { value: 5, label: '造成中' },
    { value: 6, label: '販売終了' },
    { value: 7, label: '販売中' },
  ];

  static readonly typeList: { value: number, label: string; }[] = [
    { value: 1, label: '売地' },
    { value: 2, label: '建売' },
    { value: 3, label: '分譲' },
  ];

  static readonly landCategoryList: { value: number, label: string; }[] = [
    { value: 1, label: '宅地' },
    { value: 2, label: '田' },
    { value: 3, label: '畑' },
  ];

}