import * as React from 'react';
import './modal-reservation-list-component.scss';
import { Reserve } from '../../../../model/reserve';
import { Article } from '../../../../model/article';
import { DateFormatter } from '../../../../util/date-formatter';
import { ModalService } from '../../../../service/modal-service';
import { ModalReservationEditComponent } from '../modal-reservation-edit/modal-reservation-edit-component';
import iconAdd from '../../../../img/common/icon_add.png';
import iconAddHover from '../../../../img/common/icon_add_hover.png';
import { RequestArticlereservesGetSearch } from '../../../../model/api/request/request-articlereserves-get-search';
import { ConnectionService } from '../../../../service/connection-service';
import { ErrorService } from '../../../../service/error-service';
import { User } from '../../../../model/user';
import { DataManagerService } from '../../../../service/data-manager-service';
import { UserService } from '../../../../service/user-service';
import { RequestArticlefilesPostDownload } from '../../../../model/api/request/request-articlefiles-post-download';
import { UserAgentService } from '../../../../service/user-agent-service';

interface ModalReservationComponentProps {
  article: Article;
  callback: () => void;
}

interface ModalReservationComponentState {
  article: Article;
  reserveList: Reserve[];
  userList: User[];
  me: User | null;
  call: () => void;
}

export class ModalReservationListComponent extends React.Component<ModalReservationComponentProps, ModalReservationComponentState> {

  constructor(props: ModalReservationComponentProps) {
    super(props);
    this.state = {
      article: props.article,
      reserveList: [],
      userList: [],
      me: null,
      call: props.callback
    };
  }

  render() {
    const article: Article = this.state.article;
    const reserveList: Reserve[] = this.state.reserveList;
    const userList: User[] = this.state.userList;
    const me = this.state.me;
    return (
      <div className={'modalInnerBody_wrap reservationListModal_wrap'}>

        <div className={'table_wrap'}>
          <div className={'table_row'}>
            <div className={'table_cell'}>
              物件名
            </div>
            <div className={'table_cell'}>
              {article.name}
            </div>
          </div>{/** table_row row_head */}

          <div className={'table_row'}>
            <div className={'table_cell'}>
              現在の予約状況
            </div>
            <div className={'table_cell customCell'}>
              <div className={'innerCell_scrollArea'}>
                {reserveList.map((reserve: Reserve) => (
                  <div className={'reservedItem_wrap flex_box'} key={reserve.id}>
                    <div className={'itemDetail_wrap'}>
                      {/* 日付 */}
                      <div>
                        {DateFormatter.date2str(reserve.reserve_start)} ~ {DateFormatter.date2str(reserve.reserve_end)}
                      </div>
                      <div className={'divide10'} />
                      {/* 顧客名 */}
                      <div>
                        顧客名 : {reserve.customer_name}
                      </div>
                      <div className={'divide5'} />
                      {/* 担当者 */}
                      <div>
                        担当者 : {userList.find((v) => +(v['id'] ?? '') === +(reserve.user_id as number)) ? (
                          userList.find((v) => +(v['id'] ?? '') === +(reserve.user_id as number))?.last_name?.value || '' +
                          userList.find((v) => +(v['id'] ?? '') === +(reserve.user_id as number))?.first_name?.value || ''
                        ) : ('')}
                      </div>
                    </div>{/** itemDetail_wrap */}

                    {/* ボタン */}
                    <div className={'itemInnerBtn_wrap'}>
                      {reserve.purchase_card_id ? (
                        <button
                          className={'reset btnStyle_1'}
                          onClick={() => this.handlerClickBtnPurchaseCard(reserve)}
                        >買付証明証</button>
                      ) : (
                        <></>
                      )}
                      <div className={'divide10'} />
                      {me && +(reserve.user_id as number) === +(me.id ?? '') ? (
                        <button
                          className={'reset btnStyle_1'}
                          onClick={() => this.handlerClickBtnEdit(reserve)}
                        >編集</button>
                      ) : (
                        <></>
                      )}
                    </div>{/** itemInnerBtn_wrap */}
                  </div>
                ))}

                <div className={'reservedItem_wrap reservedNumber flex_box flex_align_center flex_content_center'}>
                  <div>予約件数 : </div>
                  <div className={'itemDetail_wrap'}>
                    {reserveList.length} 件
                  </div>
                </div>

              </div>
            </div>
          </div>{/** table_row row_body */}
        </div>{/** table_wrap */}

        <div className={'divide20'} />

        <div className={'btn_wrap flex_box flex_align_center flex_space_between'}>
          <div>
            <button
              className={'reset btnStyle_1'}
              onClick={() => this.handlerClickBtnClose()}
            >戻る</button>
          </div>
          <div>
            <button
              className={'reset btnStyle_1 flex_box flex_align_center'}
              onClick={() => this.handlerClickBtnAdd()}
            >
              <img className={'imgIconAdd'} src={iconAdd} alt={''} />
              <img className={'imgIconAddHover'} src={iconAddHover} alt={''} />
              <span>新しく予約する</span>
            </button>
          </div>
        </div>

      </div>
    );
  }

  getApi() {
    UserService.ins.getMe()
      .then((v) => this.setState({ me: v }))
      .catch((v) => this.setState({ me: v }));
    const request: RequestArticlereservesGetSearch = new RequestArticlereservesGetSearch(
      '',
      100,
      1,
      1,
      1,
      this.state.article.id as number
    );
    DataManagerService.ins.getUserList()
      .then((v) => {
        this.setState({ userList: v });
      })
      .catch((v) => {
        this.setState({ userList: v });
      });
    ConnectionService.ins.connect(request)
      .then((res) => {
        this.setState({
          reserveList: this.createReserveList(res.data.list)
        });
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }

  componentDidMount(): void {
    this.getApi();
  }


  private handlerClickBtnPurchaseCard(reserve: Reserve): void {
    const request: RequestArticlefilesPostDownload = new RequestArticlefilesPostDownload(JSON.stringify([reserve.purchase_card_id]));
    ConnectionService.ins.connect(request)
      .then((res) => {
        const url: string = window.URL.createObjectURL(res);
        if (UserAgentService.ins.isMobile()) {
          window.location.href = url;
          return;
        }
        const link: HTMLAnchorElement = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.setAttribute('download', reserve.purchase_card_file_name);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }

  private handlerClickBtnEdit(reserve: Reserve): void {
    ModalService.ins.push(
      <ModalReservationEditComponent
        article={this.state.article}
        reserve={reserve}
        callback={() => this.getApi()}
        articleCallback={() => this.props.callback()}
      />
    );
  }

  private handlerClickBtnClose(): void {
    this.state.call();
    ModalService.ins.close();
  }

  private handlerClickBtnAdd(): void {
    const dateAfter30day: Date = new Date();
    dateAfter30day.setDate(dateAfter30day.getDate() + 30);
    const reserve: Reserve = new Reserve(
      null,
      this.state.article.id as number,
      '',
      null,
      new Date(),
      dateAfter30day,
      null,
      '',
    );
    ModalService.ins.push(
      <ModalReservationEditComponent
        article={this.state.article}
        reserve={reserve}
        callback={() => { this.getApi(); }}
        articleCallback={this.state.call}
      />
    );
  }

  private createReserveList(list: {}[]) {
    const reserveList: Reserve[] = [];
    list.forEach((v: any) => {
      reserveList.push(new Reserve(
        Number(v['id']),
        Number(v['article_id']),
        v['customer_name'],
        Number(v['user_id']),
        DateFormatter.str2date(v['reserve_start']) as Date,
        DateFormatter.str2date(v['reserve_end']) as Date,
        v['purchase_card_id'],
        v['purchase_card_file_name']
      ));
    });
    return reserveList;
  }

}
