type ProcessEnv = NodeJS.ProcessEnv & Partial<{
  REACT_APP_MODE: string;
  REACT_APP_API_DEFAULT_HOST: string;
}>;

const ENV: ProcessEnv = process.env;

export class Config {

  static mode = ENV.REACT_APP_MODE || '';

  static endPoint = ENV.REACT_APP_API_DEFAULT_HOST || '';
}
