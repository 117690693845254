import * as React from 'react';
import './modal-user-picker-component.scss';
import { User } from '../../../../model/user';
import { ModalService } from '../../../../service/modal-service';
import { DataManagerService } from '../../../../service/data-manager-service';

interface ModalShareComponentProps {
  callback: (userId: number) => void;
  onDialog?: boolean;
}

interface ModalShareComponentState {
  callback: (userId: number) => void;
  userList: User[];
  inputUserName: string;
}

export class ModalUserPickerComponent extends React.Component<ModalShareComponentProps, ModalShareComponentState> {

  constructor(props: ModalShareComponentProps) {
    super(props);
    this.state = {
      callback: props.callback,
      userList: [],
      inputUserName: '',
    };
  }

  render() {
    const userList: User[] = this.state.userList;
    const inputUserName: string = this.state.inputUserName;
    return (
      <div className={'modalInnerBody_wrap shareModal_wrap'}>
        <div className={'userSelect_wrap flex_box'}>

          {/* 未選択ユーザ一覧 */}
          <div className={'userList_noSelected_wrap'}>

            <div>ユーザを選択してください。</div>
            <div className={'divide10'} />

            <div>
              <input
                className={'textInput'}
                placeholder={'検索'}
                onChange={(e) => {
                  this.setState({ inputUserName: e.target.value });
                }}
              />
            </div>

            <div className={'divide10'} />

            <div className={'userList_noSelected'}>
              {userList.filter((v) => v.fullname['value'].match(inputUserName) !== null).map((user, i) => (
                <div
                  // style={{backgroundColor: ColorGenerator.createRandomRGBA(0.8)}}
                  onClick={() => this.handlerClickUser(user)}
                  key={user.id}
                >
                  {user.fullname['value']}
                </div>
              ))}
            </div>
          </div>

        </div>

        <div className={'divide20'} />

        {/* ボタン */}
        <div className={'btn_wrap flex_box flex_space_between'}>
          <div>
            <button
              className={'reset btnStyle_1'}
              onClick={() => this.handlerClickBtnClose()}
            >閉じる
            </button>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount(): void {
    DataManagerService.ins.getUserList()
      .then((v) => this.setState({ userList: v }))
      .catch((v) => this.setState({ userList: v }));
  }

  private handlerClickUser(user: User): void {
    this.state.callback(user.id);
    if (this.props.onDialog) {
      ModalService.ins.pop();
    } else {
      ModalService.ins.close();
    }
  }

  private handlerClickBtnClose(): void {
    if (this.props.onDialog) {
      ModalService.ins.pop();
    } else {
      ModalService.ins.close();
    }
  }
}
