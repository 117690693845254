import {HTTPMethod, RequestBase} from './request-base';

export class RequestUsersPostPassword_request extends RequestBase {

  param: {
    email: string,
  };

  constructor(email: string) {
    super(HTTPMethod.POST, '/api/users/password_request');
    this.param = {
      email: email
    };
  }

  // getParam(): FormData {
  //   const formData: FormData = new FormData();
  //   Object.keys(this.param).forEach((v) => {
  //     formData.append(v, this.param[v] + '');
  //   });
  //   return formData;
  // }

  getUrl(): string {
    return this.host + this.endPoint;
  }

}
