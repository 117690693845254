import * as React from 'react';
import './master-store-component.scss';
import { HeaderComponent } from '../../common/header/header-component';
import { SideMenuComponent } from '../../common/side-menu/side-menu-component';
import iconCheckBoxChecked from '../../../img/common/checkbox_checked.png';
import iconCheckBox from '../../../img/common/checkbox.png';
import { MasterListTableComponent } from '../../common/master-list/master-list-table/master-list-table-component';
import { MasterListHeader } from '../../common/master-list/master-list-header';
import { Store } from '../../../model/store';
import { MasterListRow } from '../../common/master-list/master-list-row';
import { PagerComponent } from '../../common/pager/pager-component';
import { ModalService } from '../../../service/modal-service';
import { ModalAlertComponent } from '../../common/modal/modal-alert/modal-alert-component';
import { ModalEditStoreComponent } from './modal-edit-store/modal-edit-store-component';
import { Prefecture } from '../../../model/prefecture';
import { City } from '../../../model/city';
import { DataManagerService } from '../../../service/data-manager-service';
import { ConnectionService } from '../../../service/connection-service';
import { ErrorService } from '../../../service/error-service';
import { RequestStoresGetSearch } from '../../../model/api/request/request-stores-get-search';
import { RequestStoresPost } from '../../../model/api/request/request-stores-post';

interface MasterStoreComponentProps {
}

interface MasterStoreComponentState {
  searchParamName: string;
  searchParamIsDisplayInvalid: boolean;
  searchParamPrefectureId: number;
  searchParamCityId: number;
  sortType: number;
  sortOrder: number;
  sortLimit: number;
  storeList: Store[];
  prefectureList: Prefecture[];
  cityList: City[];
  pageCurrent: number;
  pageMax: number;
  numberTotal: number; // 件数
  numberCurrentFrom: number; // 現在の表示件数
  numberCurrentTo: number; // 現在の表示件数
}

export class MasterStoreComponent extends React.Component<MasterStoreComponentProps, MasterStoreComponentState> {

  private sortOrderList: { value: number, label: string; }[] = [
    { value: 0, label: '降順' },
    { value: 1, label: '昇順' },
  ];

  private sortLimitList: { value: number, label: string; }[] = [
    { value: 10, label: '10件' },
    { value: 25, label: '25件' },
    { value: 50, label: '50件' },
    { value: 100, label: '100件' },
  ];

  private headerList: MasterListHeader[] = [
    new MasterListHeader('id', 'No'),
    new MasterListHeader('name', '支店名'),
    new MasterListHeader('prefecture', '都道府県'),
    new MasterListHeader('city', '市区町村'),
    new MasterListHeader('address', '住所'),
    new MasterListHeader('tel', '電話番号'),
    new MasterListHeader('fax', 'FAX番号'),
    //new MasterListHeader('email', 'メールアドレス'),
  ];

  private testStoreList: Store[] = [
    new Store(1, '渋谷支店', '東京都渋谷区', '03-1111-1111', '03-1111-1112', 'test@a.b', true, 1, 2, '111-0011'),
    new Store(2, '渋谷支店', '東京都渋谷区', '03-1111-1111', '03-1111-1112', 'test@a.b', true, 1, 2, '111-0011'),
    new Store(3, '渋谷支店', '東京都渋谷区', '03-1111-1111', '03-1111-1112', 'test@a.b', true, 1, 2, '111-0011'),
    new Store(4, '渋谷支店', '東京都渋谷区', '03-1111-1111', '03-1111-1112', 'test@a.b', true, 1, 2, '111-0011'),
    new Store(5, '渋谷支店', '東京都渋谷区', '03-1111-1111', '03-1111-1112', 'test@a.b', true, 1, 2, '111-0011'),
    new Store(6, '渋谷支店', '東京都渋谷区', '03-1111-1111', '03-1111-1112', 'test@a.b', true, 1, 2, '111-0011'),
  ];

  constructor(props: MasterStoreComponentProps) {
    super(props);
    this.state = {
      searchParamName: '',
      searchParamIsDisplayInvalid: false,
      searchParamPrefectureId: 0,
      searchParamCityId: 0,
      sortType: 0,
      sortOrder: 0,
      sortLimit: 25,
      storeList: [],
      prefectureList: [],
      cityList: [],
      pageCurrent: 1,
      pageMax: 1,
      numberTotal: 0,
      numberCurrentFrom: 0,
      numberCurrentTo: 0,
    };
  }

  render() {
    const searchParamName: string = this.state.searchParamName;
    const searchParamIsDisplayInvalid: boolean = this.state.searchParamIsDisplayInvalid;
    const searchParamPrefectureId: number = this.state.searchParamPrefectureId;
    const searchParamCityId: number = this.state.searchParamCityId;
    const sortType: number = this.state.sortType;
    const sortOrder: number = this.state.sortOrder;
    const sortLimit: number = this.state.sortLimit;
    const headerList: MasterListHeader[] = this.headerList;
    const storeList: Store[] = this.state.storeList;
    const prefectureList: Prefecture[] = this.state.prefectureList;
    const cityList: City[] = this.state.cityList;
    const numberTotal = this.state.numberTotal;
    const numberCurrentFrom = this.state.numberCurrentFrom;
    const numberCurrentTo = this.state.numberCurrentTo;
    return (
      <div>

        {/* --- header --- */}
        <HeaderComponent />

        <div className={'body_wrap flex_box'}>

          {/* side menu */}
          <div className={'pc menu_wrap'}>
            <SideMenuComponent />
          </div>

          <div className={'content_wrap masterSystem_cont'}>
            <div className={'contentInner_wrap table_masterStore'}>


              {/* title */}
              <div className={'contentHead_wrap'}>
                <div className={'contentHeadInner_wrap flex_box flex_align_center flex_space_between'}>
                  <div className={'contentHead_text flex_box flex_align_center'}>
                    <span>支店マスタ</span>
                    <span></span>
                  </div>
                </div>
              </div>
              {/** contentHead_wrap */}

              {/* search */}
              <div className={'searchArea masterSchool'}>
                <div className={'select_wrap flex_box flex_align_center'}>
                  <div className={'left_wrap flex_box flex_align_center'}>
                    <div>都道府県</div>
                    <div>
                      <select
                        onChange={(e) => {
                          this.setState({ searchParamPrefectureId: Number(e.target.value) || 0, searchParamCityId: 0 });
                          this.postSearchAPI({ ...this.state, searchParamPrefectureId: Number(e.target.value) || 0, searchParamCityId: 0 });
                          DataManagerService.ins.getCityList(Number(e.target.value) || null)
                            .then((v) => {
                              this.setState({ cityList: v });
                            })
                            .catch((v) => {
                              this.setState({ cityList: v });
                            });
                        }}
                        value={searchParamPrefectureId}
                      >
                        <option
                          value={0}
                        >
                          未選択
                        </option>
                        {prefectureList.map((prefecture: Prefecture) => (
                          <option
                            key={prefecture.id}
                            value={prefecture.id}
                          >
                            {prefecture.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className={'right_wrap flex_box flex_align_center'}>
                    <div>市区町村</div>
                    <div>
                      <select
                        onChange={(e) => {
                          this.setState({ searchParamCityId: Number(e.target.value) || 0 });
                          this.postSearchAPI({ ...this.state, searchParamCityId: Number(e.target.value) || 0 });
                        }}
                        value={searchParamCityId}
                      >
                        <option
                          value={0}
                        >
                          未選択
                        </option>
                        {cityList.map((city: City) => (
                          <option
                            key={city.id}
                            value={city.id ?? ''}
                          >
                            {city.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className={'divide10'}></div>

                <div className={'masterInput_wrap flex_box flex_align_center'}>
                  <div className={'left_wrap flex_box flex_align_center'}>
                    <div>支店名&emsp;</div>
                    <div>
                      <input
                        className={'textInput'}
                        type={'search'}
                        value={searchParamName}
                        onChange={(e) => this.setState({ searchParamName: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className={'right_wrap'}>
                    <div
                      className={'checkbox_wrap flex_box flex_align_center'}
                      onClick={() => {
                        this.setState({ searchParamIsDisplayInvalid: !searchParamIsDisplayInvalid });
                        this.postSearchAPI({ ...this.state, searchParamIsDisplayInvalid: !searchParamIsDisplayInvalid });
                      }}
                    >
                      {searchParamIsDisplayInvalid ? (
                        <div className={'innerImg checked'}><img src={iconCheckBoxChecked} /></div>
                      ) : (
                        <div className={'innerImg noCheck'}><img src={iconCheckBox} /></div>
                      )}
                      <div className={'innerText selectDisabled'}>無効データを含める</div>
                    </div>
                  </div>
                </div>

                <div className={'divide20'}></div>

                <div className={'searchBtn_wrap'}>
                  <button
                    className={'reset btnStyle_1 marg_auto'}
                    onClick={() => this.postSearchAPI(this.state)}
                  >
                    検索
                  </button>
                </div>
              </div>

              {/* sort */}
              <div className={'sort_pager_wrap flex_box flex_align_center flex_space_between'}>
                <div className={'sort_pager_inner_wrap flex_box flex_align_center flex_space_between'}>
                  <div className={'select_wrap flex_box flex_align_center'}>

                    <div>
                      <select
                        onChange={(e) => {
                          this.setState({ sortType: Number(e.target.value) });
                          this.postSearchAPI({ ...this.state, sortType: Number(e.target.value) });
                        }}
                        value={sortType}
                      >
                        {headerList.map((header, i) => (
                          <option key={header.value} value={i + 1}>{header.label}</option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <select
                        onChange={(e) => {
                          this.setState({ sortOrder: Number(e.target.value) });
                          this.postSearchAPI({ ...this.state, sortOrder: Number(e.target.value) });
                        }}
                        value={sortOrder}
                      >
                        {this.sortOrderList.map((v) => (
                          <option key={v.value} value={v.value}>{v.label}</option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <select
                        onChange={(e) => {
                          this.setState({ sortLimit: Number(e.target.value) });
                          this.postSearchAPI({ ...this.state, sortLimit: Number(e.target.value) });
                        }}
                        value={sortLimit}
                      >
                        {this.sortLimitList.map((v) => (
                          <option key={v.value} value={v.value}>{v.label}</option>
                        ))}
                      </select>
                    </div>

                  </div>
                </div>

                <div className={'btn_newAdd_wrap'}>
                  <button
                    className={'reset btnStyle_1'}
                    onClick={() => this.handlerBtnNew()}
                  >
                    新規登録
                  </button>
                </div>
              </div>

              <div className={'divide10'}></div>

              <div className={'displayNum_pager_wrap flex_box flex_align_center flex_space_between'}>

                <div className={'pagenum_wrap'}>
                  <div className={'pagenumInner_wrap flex_box flex_align_center'}>
                    <div>{numberCurrentFrom}~{numberCurrentTo}件</div>
                    <div>/</div>
                    <div>全{numberTotal}件</div>
                  </div>
                </div>
                {/** pagenum_wrap */}

                {/* ページャ */}
                <PagerComponent
                  currentPage={this.state.pageCurrent}
                  max={this.state.pageMax}
                  callback={(page) => this.callbackSelectPage(page)}
                />

              </div>{/* displayNum_pager_wrap */}

              <div className={'divide10'} />

              {/* テーブル */}
              <MasterListTableComponent
                headerList={headerList}
                rowList={this.makeTableData(storeList, searchParamIsDisplayInvalid)}
                isEdited={true}
                isValidEdited={true}
              />

              <div className={'divide10'} />

            </div>
          </div>
        </div>

      </div>
    );
  }

  componentDidMount(): void {
    this.postSearchAPI(this.state);
    DataManagerService.ins.getPrefectureList()
      .then((v) => {
        this.setState({ prefectureList: v });
      })
      .catch((v) => {
        this.setState({ prefectureList: v });
      });
  }

  private callbackBtnInvalid(): void {
  }

  private callbackBtnEdit(id: number): void {
    const store = this.state.storeList.find((v) => v.id === id);
    if (!store) return;
    ModalService.ins.display(
      <ModalEditStoreComponent
        store={store}
        callback={(store) => this.postEditAPI(store)}
      />,
    );
  }

  private callbackSelectPage(page: number): void {
    this.setState({ pageCurrent: page });
    this.postSearchAPI({ ...this.state, pageCurrent: page });
  }

  private handlerBtnNew(): void {
    const store: Store = new Store(null, '', '', '', '', 'test@a.b', true, 0, 0, '');
    ModalService.ins.display(
      <ModalEditStoreComponent
        store={store}
        callback={(store) => this.postEditAPI(store)}
      />,
    );
  }

  private postSearchAPI(state: MasterStoreComponentState): void {
    const request: RequestStoresGetSearch = new RequestStoresGetSearch(
      state.searchParamName,
      state.sortLimit,
      state.pageCurrent,
      state.sortType,
      state.sortOrder,
      state.searchParamIsDisplayInvalid ? 0 : 1,
      state.searchParamPrefectureId,
      state.searchParamCityId,
    );
    ConnectionService.ins.connect(request)
      .then((res) => {
        const list: Store[] = res.data.list;
        this.setState({
          storeList: list,
          pageCurrent: state.pageCurrent,
          pageMax: Math.ceil(res.data.count / res.data.limit),
          numberTotal: res.data.count,
          numberCurrentFrom: res.data.from,
          numberCurrentTo: res.data.to,
        });
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }

  private postEditAPI(store: Store): void {
    const request: RequestStoresPost = new RequestStoresPost(
      store.id,
      store.name,
      store.prefecture_id,
      store.city_id,
      store.address,
      store.post_code,
      store.tel,
      store.fax,
      store.email,
      +store.valid_flag,
    );
    ConnectionService.ins.connect(request)
      .then((res) => {
        ModalService.ins.push(
          <ModalAlertComponent
            msgList={[store.id ? '更新しました。' : '登録しました。']}
            callback={() => {
              ModalService.ins.close();
              this.postSearchAPI(this.state);
            }}
          />
        );
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }

  private makeTableData(list: Store[], isDisplayInvalid: boolean): MasterListRow[] {
    return list
      .filter((v) => (isDisplayInvalid || (!isDisplayInvalid && v.valid_flag)))
      .map((v:any) => (
        new MasterListRow(
          this.headerList.map((header) => {
            if (
              header.value === 'prefecture' ||
              header.value === 'city'
            ) {
              return v[header.value]['name'];
            }
            return v[header.value];
          }),
          +v.valid_flag === 1,
          () => this.callbackBtnEdit(v.id),
          this.callbackBtnInvalid,
        )
      ));
  }

}
