import {HTTPMethod, RequestBase} from './request-base';

export class RequestArticlesintroducedPost extends RequestBase {

  // param: {
  //   id: number,
  //   name: string,
  //   //sales_status: number,
  //   //type: number,
  //   prefecture_id: number,
  //   city_id: number,
  //   address: string,
  //   price: number,
  //   //building_name: string,
  //   tsubo: number,
  //   area_measuresize: number,
  //   land_category: number,
  //   use_district: string,
  //   //purchasing_manager_id: number,
  //   user_id: number,
  //   //build_datetime: string,
  //   //purchase_datetime: string,
  //   //has_purchase_card: boolean,
  //   //customer_name: string,
  //   //conclusion_datetime: string,
  //   pschool_id: number,
  //   mschool_id: number,
  //   remarks: string,
  //   valid_flag: number,
  // };

  constructor(
    id: number,
    name: string,
    //sales_status: number,
    //type: number,
    prefecture_id: number,
    city_id: number,
    address: string,
    price: number,
    //building_name: string,
    tsubo: number,
    area_measuresize: number,
    land_category: number,
    use_district: string,
    //purchasing_manager_id: number,
    user_id: number,
    //build_datetime: string,
    //purchase_datetime: string,
    //has_purchase_card: boolean,
    //customer_name: string,
    //conclusion_datetime: string,
    pschool_id: number,
    mschool_id: number,
    remarks: string,
    valid_flag: number,
  ) {
    super(HTTPMethod.POST, '/api/articlesintroduced');
    this.param = {
      id: id ,
      name: name,
      prefecture_id: prefecture_id,
      city_id: city_id,
      address: address,
      price: price,
      tsubo: tsubo,
      area_measuresize: area_measuresize,
      land_category: land_category,
      use_district: use_district,
      user_id: user_id,
      pschool_id: pschool_id,
      mschool_id: mschool_id,
      remarks: remarks,
      valid_flag: valid_flag,
    };
  }

  // getParam(): FormData {
  //   const formData: FormData = new FormData();
  //   Object.keys(this.param).forEach((v) => {
  //     /*if (v === 'id') {
  //       formData.append(v, '');
  //       return;
  //     }*/
  //     if (this.param[v] === null || this.param[v] === undefined) {
  //       formData.append(v, '');
  //       return;
  //     }
  //     formData.append(v, this.param[v] + '');
  //   });
  //   //formData.append('valid_flag', '');
  //   return formData;
  // }

  getUrl(): string {
    return this.host + this.endPoint;
  }

}
