import * as React from 'react';
import './master-city-component.scss';
import { HeaderComponent } from '../../common/header/header-component';
import { SideMenuComponent } from '../../common/side-menu/side-menu-component';
import iconCheckBoxChecked from '../../../img/common/checkbox_checked.png';
import iconCheckBox from '../../../img/common/checkbox.png';
import { MasterListTableComponent } from '../../common/master-list/master-list-table/master-list-table-component';
import { MasterListHeader } from '../../common/master-list/master-list-header';
import { MasterListRow } from '../../common/master-list/master-list-row';
import { City } from '../../../model/city';
import { PagerComponent } from '../../common/pager/pager-component';
import { Prefecture } from '../../../model/prefecture';
import { ModalService } from '../../../service/modal-service';
import { ModalEditCityComponent } from './modal-edit-city/modal-edit-city-component';
import { ModalAlertComponent } from '../../common/modal/modal-alert/modal-alert-component';
import { DataManagerService } from '../../../service/data-manager-service';
import { RequestPrefecuresGetSearch } from '../../../model/api/request/request-prefecures-get-search';
import { ConnectionService } from '../../../service/connection-service';
import { ErrorService } from '../../../service/error-service';
import { RequestCitiesGetSearch } from '../../../model/api/request/request-cities-get-search';
import { RequestPrefecturesPost } from '../../../model/api/request/request-prefectures-post';
import { RequestCitiesPost } from '../../../model/api/request/request-cities-post';

interface MasterCityComponentProps {
}

interface MasterCityComponentState {
  searchParamName: string;
  searchParamIsDisplayInvalid: boolean;
  searchParamPrefectureId: number;
  sortType: number;
  sortOrder: number;
  sortLimit: number;
  pageCurrent: number,
  pageMax: number,
  numberTotal: number; // 件数
  numberCurrentFrom: number; // 現在の表示件数
  numberCurrentTo: number; // 現在の表示件数
  cityList: City[];
  prefectureList: Prefecture[];
}

export class MasterCityComponent extends React.Component<MasterCityComponentProps, MasterCityComponentState> {

  private sortOrderList: { value: number, label: string; }[] = [
    { value: 0, label: '降順' },
    { value: 1, label: '昇順' },
  ];

  private sortLimitList: { value: number, label: string; }[] = [
    { value: 10, label: '10件' },
    { value: 25, label: '25件' },
    { value: 50, label: '50件' },
    { value: 100, label: '100件' },
  ];

  private headerList: MasterListHeader[] = [
    new MasterListHeader('id', 'No'),
    new MasterListHeader('name', '市町村名'),
    new MasterListHeader('prefecture', '都道府県'),
  ];

  private testCityList: City[] = [
    new City(1, '北九州市八幡西区', 1, true),
    new City(2, '北九州市八幡東区', 1, true),
    new City(3, '北九州市八幡北区', 1, true),
    new City(4, '北九州市八幡南区', 1, true),
    new City(5, '北九州市小倉北区', 1, false),
    new City(6, '北九州市小倉南区', 1, false),
    new City(7, '北九州市小倉東区', 1, false),
    new City(8, '北九州市小倉西区', 1, false),
    new City(9, '北九州市博多区', 1, true),
    new City(10, '北九州市門司区', 1, true),
  ];

  private testPrefectureList: Prefecture[] = [
    new Prefecture(1, '福岡県', true),
    new Prefecture(2, '佐賀県', true),
    new Prefecture(3, '大分県', false),
    new Prefecture(4, '熊本県', true),
    new Prefecture(5, '宮崎県', false),
    new Prefecture(6, '長崎県', false),
    new Prefecture(7, '鹿児島県', false),
    new Prefecture(8, '沖縄県', false),
    new Prefecture(9, '山口県', false),
    new Prefecture(10, '島根県', false),
    new Prefecture(11, '鳥取県', false),
    new Prefecture(12, '広島県', false),
  ];

  constructor(props: MasterCityComponentProps) {
    super(props);
    this.state = {
      searchParamName: '',
      searchParamIsDisplayInvalid: false,
      searchParamPrefectureId: 0,
      sortType: 1,
      sortOrder: 0,
      sortLimit: 25,
      pageCurrent: 1,
      pageMax: 1,
      numberTotal: 0,
      numberCurrentFrom: 0,
      numberCurrentTo: 0,
      cityList: [],
      prefectureList: []
    };
  }

  render() {
    const searchParamName: string = this.state.searchParamName;
    const searchParamIsDisplayInvalid: boolean = this.state.searchParamIsDisplayInvalid;
    const searchParamPrefectureId: number = this.state.searchParamPrefectureId;
    const sortType: number = this.state.sortType;
    const sortOrder: number = this.state.sortOrder;
    const sortLimit: number = this.state.sortLimit;
    const headerList: MasterListHeader[] = this.headerList;
    const cityList: City[] = this.state.cityList;
    const prefectureList: Prefecture[] = this.state.prefectureList;
    const numberTotal = this.state.numberTotal;
    const numberCurrentFrom = this.state.numberCurrentFrom;
    const numberCurrentTo = this.state.numberCurrentTo;
    return (
      <div>

        {/* --- header --- */}
        <HeaderComponent />

        <div className={'body_wrap flex_box'}>

          {/* side menu */}
          <div className={'pc menu_wrap'}>
            <SideMenuComponent />
          </div>

          <div className={'content_wrap masterSystem_cont'}>
            <div className={'contentInner_wrap table_masterCity'}>


              {/* title */}
              <div className={'contentHead_wrap'}>
                <div className={'contentHeadInner_wrap flex_box flex_align_center flex_space_between'}>
                  <div className={'contentHead_text flex_box flex_align_center'}>
                    <span>市区町村マスタ</span>
                    <span></span>
                  </div>
                </div>
              </div>{/** contentHead_wrap */}

              {/* search */}
              <div className={'searchArea masterCity'}>
                <div className={'select_wrap flex_box flex_align_center'}>
                  <div>都道府県</div>
                  <div>
                    <select
                      onChange={(e) => {
                        this.setState({ searchParamPrefectureId: Number(e.target.value) || 0 });
                        this.postSearchAPI({ ...this.state, searchParamPrefectureId: Number(e.target.value) || 0 });
                      }}
                      value={searchParamPrefectureId}
                    >
                      <option
                        value={0}
                      >
                        未選択
                      </option>
                      {prefectureList.map((prefecture: Prefecture) => (
                        <option
                          key={prefecture.id}
                          value={prefecture.id}
                        >
                          {prefecture.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className={'divide10'}></div>

                <div className={'masterInput_wrap flex_box flex_align_center'}>
                  <div className={'left_wrap flex_box flex_align_center'}>
                    <div>市区町村名</div>
                    <div>
                      <input
                        className={'textInput'}
                        type={'search'}
                        value={searchParamName}
                        onChange={(e) => this.setState({ searchParamName: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className={'right_wrap'}>
                    <div
                      className={'checkbox_wrap flex_box flex_align_center'}
                      onClick={() => {
                        this.setState({ searchParamIsDisplayInvalid: !searchParamIsDisplayInvalid });
                        this.postSearchAPI({ ...this.state, searchParamIsDisplayInvalid: !searchParamIsDisplayInvalid });
                      }}
                    >
                      {searchParamIsDisplayInvalid ? (
                        <div className={'innerImg checked'}><img src={iconCheckBoxChecked} /></div>
                      ) : (
                        <div className={'innerImg noCheck'}><img src={iconCheckBox} /></div>
                      )}
                      <div className={'innerText selectDisabled'}>無効データを含める</div>
                    </div>
                  </div>
                </div>

                <div className={'divide20'}></div>

                <div className={'searchBtn_wrap'}>
                  <button
                    className={'reset btnStyle_1 marg_auto'}
                    onClick={() => this.postSearchAPI(this.state)}
                  >
                    検索
                  </button>
                </div>
              </div>

              {/* sort */}
              <div className={'sort_pager_wrap flex_box flex_align_center flex_space_between'}>
                <div className={'sort_pager_inner_wrap flex_box flex_align_center flex_space_between'}>
                  <div className={'select_wrap flex_box flex_align_center'}>

                    <div>
                      <select
                        onChange={(e) => {
                          this.setState({ sortType: Number(e.target.value) });
                          this.postSearchAPI({ ...this.state, sortType: Number(e.target.value) });
                        }}
                        value={sortType}
                      >
                        {headerList.map((header, i) => (
                          <option key={header.value} value={i + 1}>{header.label}</option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <select
                        onChange={(e) => {
                          this.setState({ sortOrder: Number(e.target.value) });
                          this.postSearchAPI({ ...this.state, sortOrder: Number(e.target.value) });
                        }}
                        value={sortOrder}
                      >
                        {this.sortOrderList.map((v) => (
                          <option key={v.value} value={v.value}>{v.label}</option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <select
                        onChange={(e) => {
                          this.setState({ sortLimit: Number(e.target.value) });
                          this.postSearchAPI({ ...this.state, sortLimit: Number(e.target.value), pageCurrent: 1 });
                        }}
                        value={sortLimit}
                      >
                        {this.sortLimitList.map((v) => (
                          <option key={v.value} value={v.value}>{v.label}</option>
                        ))}
                      </select>
                    </div>

                  </div>
                </div>

                <div className={'btn_newAdd_wrap'}>
                  <button
                    className={'reset btnStyle_1'}
                    onClick={() => this.handlerBtnNew()}
                  >
                    新規登録
                  </button>
                </div>
              </div>

              <div className={'divide10'}></div>

              <div className={'displayNum_pager_wrap flex_box flex_align_center flex_space_between'}>

                <div className={'pagenum_wrap'}>
                  <div className={'pagenumInner_wrap flex_box flex_align_center'}>
                    <div>{numberCurrentFrom}~{numberCurrentTo}件</div>
                    <div>/</div>
                    <div>全{numberTotal}件</div>
                  </div>
                </div>{/** pagenum_wrap */}

                {/* ページャ */}
                <PagerComponent
                  currentPage={this.state.pageCurrent}
                  max={this.state.pageMax}
                  callback={(page) => this.callbackSelectPage(page)}
                />

              </div>{/* displayNum_pager_wrap */}

              <div className={'divide10'} />

              {/* テーブル */}
              <MasterListTableComponent
                headerList={headerList}
                rowList={this.makeTableData(cityList, searchParamIsDisplayInvalid)}
                isEdited={true}
                isValidEdited={true}
              />

            </div>
          </div>
        </div>

      </div>
    );
  }

  componentDidMount(): void {
    DataManagerService.ins.getPrefectureList()
      .then((v) => {
        this.setState({ prefectureList: v });
      })
      .catch((v) => {
        this.setState({ prefectureList: v });
      });
    this.postSearchAPI(this.state);
  }

  private callbackBtnInvalid(id: number): void {
  }

  private callbackBtnEdit(id: number): void {
    const city = this.state.cityList.find((v) => v.id === id);
    if (!city) return;
    ModalService.ins.display(
      <ModalEditCityComponent
        city={city}
        callback={(city) => this.postEditAPI(city)}
      />
    );
  }

  private callbackSelectPage(page: number): void {
    this.setState({ pageCurrent: page });
    this.postSearchAPI({ ...this.state, pageCurrent: page });
  }

  private handlerBtnNew(): void {
    const city: City = new City(null, '', null, true);
    ModalService.ins.display(
      <ModalEditCityComponent
        city={city}
        callback={(city) => this.postEditAPI(city)}
      />
    );
  }

  private postSearchAPI(state: MasterCityComponentState): void {
    const request: RequestCitiesGetSearch = new RequestCitiesGetSearch(
      state.searchParamName,
      state.sortLimit,
      state.pageCurrent,
      state.sortType,
      state.sortOrder,
      state.searchParamIsDisplayInvalid ? 0 : 1,
      state.searchParamPrefectureId
    );
    ConnectionService.ins.connect(request)
      .then((res) => {
        const list: City[] = res.data.list;
        this.setState({
          cityList: list,
          pageCurrent: state.pageCurrent,
          pageMax: Math.ceil(res.data.count / res.data.limit),
          numberTotal: res.data.count,
          numberCurrentFrom: res.data.from,
          numberCurrentTo: res.data.to,
        });
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }

  private postEditAPI(city: City): void {
    const request: RequestCitiesPost = new RequestCitiesPost(
      city.id,
      city.name,
      city.prefecture_id,
      +city.valid_flag,
    );
    ConnectionService.ins.connect(request)
      .then((res) => {
        ModalService.ins.push(
          <ModalAlertComponent
            msgList={[city.id ? '更新しました。' : '登録しました。']}
            callback={() => {
              ModalService.ins.close();
              this.postSearchAPI(this.state);
            }}
          />
        );
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }

  private makeTableData(list: City[], isDisplayInvalid: boolean): MasterListRow[] {
    return list
      .filter((v) => (isDisplayInvalid || (!isDisplayInvalid && v.valid_flag)))
      .map((v: any) => (
        new MasterListRow(
          //Object.keys(v).map((key) => v[key] + '').filter((_, i) => i < this.headerList.length),
          this.headerList.map((header) => header.value === 'prefecture' ? v[header.value]['name'] : v[header.value]),
          +v.valid_flag === 1,
          () => this.callbackBtnEdit(v.id),
          () => this.callbackBtnInvalid(v.id)
        )
      ));
  }

}
