import * as UAParser from 'ua-parser-js';

export class UserAgentService {

  private static _ins: UserAgentService;

  private constructor() {}

  static get ins(): UserAgentService {
    if (!this._ins) {
      this._ins = new UserAgentService();
    }
    return this._ins;
  }

  isMobile(): boolean {
    // @ts-ignore
    const uaParser = new UAParser();
    const osName: string = uaParser.getOS().name;
    return (
      osName === 'iOS' ||
      osName === 'Android'
    );
  }

  isChrome(): boolean {
    // @ts-ignore
    const uaParser = new UAParser();
    if (this.isMobile()) {
      return false;
    }
    return uaParser.getBrowser().name === 'Chrome';
  }

}
