import { Store } from './../redux/store';
import { SideMenuType } from '../component/common/side-menu/side-menu-component';
import { RoutingPass } from '../constant/routing-pass';
import { UserActions } from '../redux/user/user.action';
import { SystemActions } from '../redux/system/system.action';

export class SideMenuService {

  isAdmin: boolean = false;
  currentMenu: SideMenuType | null = null;

  private static _ins: SideMenuService;

  private constructor() { }

  static get ins(): SideMenuService {
    if (!this._ins) {
      this._ins = new SideMenuService();
    }
    return this._ins;
  }

  init(isAdmin: boolean, menu: SideMenuType): void {
    this.currentMenu = this.getCurrentMenu(window.location.hash);
    this.isAdmin = this.isAdmin ?? isAdmin;
    this.currentMenu = this.currentMenu ?? menu;
    Store.dispatch(UserActions.setIsAdmin(this.isAdmin));
    Store.dispatch(SystemActions.setCurrentMenu(this.currentMenu));
    // this.component.setState({
    //   isAdmin: this.isAdmin,
    //   currentMenu: this.currentMenu,
    // });
  }

  destroy(): void {
    this.currentMenu = null;
  }

  getCurrentMenu(hash: string): SideMenuType {
    let type: SideMenuType = SideMenuType.article;
    if (
      hash.match(new RegExp(RoutingPass.ARTICLE_LIST)) ||
      hash.match(new RegExp(RoutingPass.ARTICLE_DETAIL)) ||
      hash.match(new RegExp(RoutingPass.ARTICLE_NEW))
    ) {
      type = SideMenuType.article;
    } else if (
      hash.match(new RegExp(RoutingPass.ARTICLE_LIST_INTRODUCED)) ||
      hash.match(new RegExp(RoutingPass.ARTICLE_DETAIL_INTRODUCED)) ||
      hash.match(new RegExp(RoutingPass.ARTICLE_NEW_INTRODUCED))
    ) {
      type = SideMenuType.articleIntroduced;
    } else if (hash.match(new RegExp(RoutingPass.MASTER_PREFECTURE))) {
      type = SideMenuType.prefecture;
    } else if (hash.match(new RegExp(RoutingPass.MASTER_CITY))) {
      type = SideMenuType.city;
    } else if (hash.match(new RegExp(RoutingPass.MASTER_PRIMARY_SCHOOL))) {
      type = SideMenuType.primarySchool;
    } else if (hash.match(new RegExp(RoutingPass.MASTER_MIDDLE_SCHOOL))) {
      type = SideMenuType.middleSchool;
    } else if (hash.match(new RegExp(RoutingPass.MASTER_STORE))) {
      type = SideMenuType.store;
    } else if (hash.match(new RegExp(RoutingPass.MASTER_USER))) {
      type = SideMenuType.user;
    } else if (hash.match(new RegExp(RoutingPass.MASTER_SUB_DIVISION)) || hash.match(new RegExp(RoutingPass.SUBDIVISION_DETAIL_COMPONENT))) {
      type = SideMenuType.subDivision;
    }
    return type;
  }


}
