import * as React from 'react';
import { ReactElement } from 'react';
import './modal-component.scss';
import { ModalService } from '../../../service/modal-service';
import { ColorGenerator } from '../../../util/color-generator';
import { DebugService } from '../../../service/debug-service';
import { useSelector } from 'react-redux';
import { State } from '../../../redux/root.reducer';

export enum ModalType {
  alert,
  dialog,
  custom,
}

interface ModalComponentProps {
}

interface ModalComponentState {
  isDisplay: boolean;
  bodyList: ReactElement<any>[];
}

export const ModalComponent2 = () => {
  const { isDisplay, bodyList } = useSelector((state: State) => ({
    isDisplay: state.modal.isDisplay,
    bodyList: state.modal.bodyList,
  }));
  
  return (
    isDisplay ? (
      <div className={'modal_wrap'}>

        <div className={'modal_bg'} />

        {bodyList.map((ele, i) => (
          <div
            id={`id-modal-${i}`}
            key={i}
          >
            {/*<div
              className={'modal_bg'}
              style={this.getWH(`id-modal-${i - 1}`)}
            />*/}
            <div
              className={'modalbody_wrap flex_box flex_align_center flex_content_center'}
            >
              {ele}
            </div>
          </div>
        ))}

      </div>
    ) : (
      <></>
    )
  )
};

export class ModalComponent extends React.Component<ModalComponentProps, ModalComponentState> {

  constructor(props: ModalComponentProps) {
    super(props);
    this.state = {
      isDisplay: false,
      bodyList: [],
    };
  }

  render() {
    const isDisplay: boolean = this.state.isDisplay;
    const bodyList: ReactElement<any>[] = this.state.bodyList;
    return (
      isDisplay ? (
        <div className={'modal_wrap'}>

          <div className={'modal_bg'} />

          {bodyList.map((ele, i) => (
            <div
              id={`id-modal-${i}`}
              key={i}
            >
              {/*<div
                className={'modal_bg'}
                style={this.getWH(`id-modal-${i - 1}`)}
              />*/}
              <div
                className={'modalbody_wrap flex_box flex_align_center flex_content_center'}
              >
                {ele}
              </div>
            </div>
          ))}

        </div>
      ) : (
        <></>
      )
    );
  }

  componentDidMount(): void {
    ModalService.ins.init(this);
  }

  // componentDidUpdate(prevProps: Readonly<ModalComponentProps>, prevState: Readonly<ModalComponentState>, snapshot?: any): void {
  //   // 入れ子のモーダルには背景を引かない想定だったけど、
  //   // あとから追加することになったから↓以下の処理を無理やり追加した。
  //   // 反省してます。
  //   // 子階層の背景を親階層と同じサイズにして、親階層の中に入れてます。
  //   const bodyList: ReactElement<any>[] = this.state.bodyList;
  //   const bgChildList: NodeListOf<Element> = document.querySelectorAll('.modal_bg_child');
  //   Array.prototype.forEach.call(bgChildList, (v) => {
  //     v.parentElement.removeChild(v);
  //   });
  //   bodyList.forEach((_, i) => {
  //     if (i === 0) return;
  //     const idParent: string = 'id-modal-' + (i - 1);
  //     const eleParent: HTMLElement | null = document.getElementById(idParent);
  //     const eleParentInner: Element | null = eleParent?.getElementsByClassName('modalInnerBody_wrap')[0] || null;
  //     const ele: HTMLElement = document.createElementNS('http://www.w3.org/1999/xhtml', 'div');
  //     ele.classList.add('modal_bg');
  //     ele.classList.add('modal_bg_child');
  //     const parentRect: any = this.getWH(idParent);
  //     ele.setAttribute('style',
  //       `width: ${parentRect.width}px;` +
  //       `height: ${parentRect.height}px;` +
  //       `top: ${parentRect.top}px;` +
  //       `left: ${parentRect.left}px;` +
  //       `border-radius: ${parentRect.borderRadius}px;`
  //     );
  //     if (eleParentInner) {
  //       eleParentInner.appendChild(ele);
  //     }
  //   });
  // }

  getWH(id: string): {} {
    const eleParent: HTMLElement | null = document.getElementById(id);
    if (!eleParent) return {};
    const ele: Element = eleParent.getElementsByClassName('modalInnerBody_wrap')[0];
    if (!ele) return {};
    return {
      width: ele.getBoundingClientRect().width,
      height: ele.getBoundingClientRect().height,
      top: ele.getBoundingClientRect().top,
      left: ele.getBoundingClientRect().left,
      borderRadius: 5
    };
  }

}
