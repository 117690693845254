import {HTTPMethod, RequestBase} from './request-base';

export class RequestArticlereservesDelete extends RequestBase {

  param: {
    id: number
  };

  constructor(id: number) {
    super(HTTPMethod.DELETE, '/api/articlereserves/');
    this.param = {
      id: id
    };
  }


  getUrl(): string {
    return this.host + this.endPoint + this.param.id;
  }

}
