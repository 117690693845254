import { delay, put, takeEvery } from 'redux-saga/effects';
import { SystemActions } from './system.action';

function* tryLoading(action: ReturnType<typeof SystemActions.isLoading>) {
  const isLoading = action.payload;
  if (!isLoading) {
    yield delay(200);
    yield put(SystemActions.setIsLoading(false));
  } else {
    yield put(SystemActions.setIsLoading(true));
  }
}

export function* SystemSaga() {
  yield takeEvery(SystemActions.isLoading, tryLoading);
}
