import actionCreatorFactory from 'typescript-fsa';
import { SideMenuType } from '../../component/common/side-menu/side-menu-component';

const ActionCreator = actionCreatorFactory('system');

export const SystemActions = {
  isLoading: ActionCreator<boolean>('is/loading'),
  setIsLoading: ActionCreator<boolean>('set/is/loading'),
  setIsBusy: ActionCreator<boolean>('set/is/busy'),
  setCurrentMenu: ActionCreator<SideMenuType | null>('set/current/menu'),
  setHideMenu: ActionCreator<boolean>('set/hide/menu'),
  networkError: ActionCreator('network/error'),
};
