import * as React from 'react';
import './article-reservation-component.scss';
import iconCheckBoxChecked from '../../../img/common/checkbox_checked.png';
import iconCheckBox from '../../../img/common/checkbox.png';
import { RequestArticlesPostReservation } from '../../../model/api/request/request-articles-post-reservation';
import { ConnectionService } from '../../../service/connection-service';
import { ErrorService } from '../../../service/error-service';
import { ValidationInput, ValidationModel } from '../article-detail/validation/validation-input';

interface AdvanceReservationComponentProps {
}

interface AdvanceReservationComponentState {
  isChecked: boolean;
  isInput: boolean;
  isConfirmation: boolean;
  name: string;
  phoneNumber: string;
  address: string;
  selectedRadioIndex: number;
  reservedCount: number | null;
  isTelCheck: boolean;
}

export class ArticleReservationComponent extends React.Component<AdvanceReservationComponentProps, AdvanceReservationComponentState> {

  private triggerMessages: string[] = [
    'きっかけ1',
    'きっかけ2',
    'きっかけ3',
  ];

  constructor(props: AdvanceReservationComponentProps) {
    super(props);
    this.state = {
      isChecked: false,
      isInput: true,
      isConfirmation: false,
      name: '',
      phoneNumber: '',
      address: '',
      selectedRadioIndex: -1,
      reservedCount: null,
      isTelCheck: false,
    };
  }

  render() {
    const {
      isChecked,
      isInput,
      isConfirmation,
      name,
      phoneNumber,
      address,
      selectedRadioIndex,
      reservedCount,
      isTelCheck,
    } = this.state;
    const valid = isChecked && (selectedRadioIndex > -1) && name && address;
    const valid2 = phoneNumber && isTelCheck;
    return (
      <div>
        {/* 入力画面 */}
        <div className={'body_wrap advanceReservation_body'}>
          <div className={'content_wrap cont_article_detail'}>
            <div className={'contentInner_wrap'}>
              <div className={'contentHead_wrap'}>
                <div className={'contentHeadInner_wrap flex_box flex_align_center flex_space_between'}>
                  <div className={'contentHead_text flex_box flex_align_center'}>
                    <span>分譲地先行予約申し込みフォーム</span>
                    <span className={'innerBar'} />
                    <span>{isInput ? '入力画面' : isConfirmation ? '確認画面' : '完了画面'}</span>
                  </div>
                </div>
              </div>

              {isInput || isConfirmation ? (
                <>
                  <div className={'divide20'} />
                  <div className={'tableArea'}>
                    <div className={'tableInner_wrap'}>

                      <div className={'flex_box'}>
                        <div className={'padd_t_adjust'}>お名前{isInput ? (<>*</>) : (<></>)}</div>
                        {isInput ? (
                          <div className={''}>
                            <input
                              className={'textInput width_name'}
                              value={name}
                              type={'text'}
                              onChange={(e) => {
                                this.setState({
                                  name: e.target.value,
                                });
                              }}
                            />
                          </div>
                        ) : isConfirmation ? (
                          <div className={'flex_box flex_align_center'}>{name}</div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className={'flex_box'}>
                        <div className={'padd_t_adjust'}>電話番号{isInput ? (<>*</>) : (<></>)}</div>
                        {isInput ? (
                          <div className={''}>
                            <ValidationInput
                              value={phoneNumber}
                              onChange={(e) => this.setState({ phoneNumber: e.target.value })}
                              validation={new ValidationModel([
                                {
                                  func: (v) => this.checkTelStr(v),
                                  invalidMessage: '正しい形式で電話番号を入力してください。',
                                },
                              ])}
                            />
                          </div>
                        ) : isConfirmation ? (
                          <div className={'flex_box flex_align_center'}>{phoneNumber}</div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className={'flex_box'}>
                        <div className={'padd_t_adjust'}>ご住所{isInput ? (<>*</>) : (<></>)}</div>
                        {isInput ? (
                          <div className={''}>
                            <input
                              className={'textInput'}
                              value={address}
                              type={'text'}
                              onChange={(e) => {
                                this.setState({
                                  address: e.target.value,
                                });
                              }}
                            />
                          </div>
                        ) : isConfirmation ? (
                          <div className={'flex_box flex_align_center'}>{address}</div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className={'flex_box'}>
                        <div className={''}>
                          ご予約のきっかけ{isInput ? (<>*</>) : (<></>)}
                          <div className={'divide5'} />
                          <div className={'innerSmallText'}>※いずれかご選択ください</div>
                        </div>
                        <div className={'radio_wrap flex_box flex_align_center'}>
                          {isInput ? (
                            <div className={'flex_box flex_align_center'}>
                              {this.triggerMessages.map((message, i) => (
                                <label key={i}>
                                  <input
                                    className={''}
                                    type={'radio'}
                                    value={String(selectedRadioIndex)}
                                    onChange={() => this.setState({ selectedRadioIndex: i })}
                                    checked={selectedRadioIndex === i}
                                  />{message}
                                </label>
                              ))}
                            </div>
                          ) : isConfirmation ? (
                            <div>
                              {this.triggerMessages[selectedRadioIndex]}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={'divide20'} />
                  {isInput ? (
                    <>
                      <div className={'attention_wrap'}>
                        ※個人情報の取り扱いに関する同意事項<br />
                        お問い合わせにあたり、<a href={'https://www.libwork.co.jp/privacy/'}
                          target={'_blank'} rel="noreferrer">個人情報の取り扱い</a>をお読みいただき、同意いただいた上で確認画面へお進みください。個人情報の取り扱いについてご同意いただけない場合は、申し訳ございませんがご利用いただけませんのでご了承ください。
                      </div>
                      <div className={'divide20'} />
                    </>
                  ) : (
                    <></>
                  )}
                  <div className={'flex_box flex_content_center'}>
                    {isInput ? (
                      <div
                        className={'checkbox_wrap flex_box flex_align_center'}
                        onClick={() => this.changeCheckStatus(isChecked)}
                      >
                        {isChecked ? (
                          <div className={'innerImg checked'}><img src={iconCheckBoxChecked} /></div>
                        ) : (
                          <div className={'innerImg noCheck'}><img src={iconCheckBox} /></div>
                        )}
                        <div className={'innerText selectDisabled'}>同意する</div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className={'divide20'} />
                  <div className={'btn_wrap flex_box flex_align_center flex_content_center'}>
                    {isInput ? (
                      <div>
                        <button
                          className={'reset btnStyle_1' + (valid && valid2 ? '' : ' disabled')}
                          onClick={() => this.handlerClickBtnNext()}
                        >
                          送信する
                        </button>
                      </div>
                    ) : isConfirmation ? (
                      <>
                        <div>
                          <button
                            className={'reset btnStyle_1'}
                            onClick={() => this.handlerClickBtnBack()}
                          >
                            戻る
                          </button>
                        </div>
                        <div>
                          <button
                            className={'reset btnStyle_1'}
                            onClick={() => this.handlerClickBtnSend()}
                          >
                            確定
                          </button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className={'divide40'} />

                  <div className={'completeMessage'}>
                    <div className={'mainText font_b align_c'}>ご予約を承りました。</div>
                    <div className={'divide30'} />
                    ありがとうございました。<br />
                    お客様は{reservedCount}番目のご予約となります。<br />
                    日程等、詳細につきましては、お申込みから3営業日以内に弊社担当からご連絡をいたします。
                  </div>

                  <div className={'divide40'} />
                </>
              )}
            </div>
            {/* //.contentInner_wrap */}
          </div>
          {/* //.content_wrap .cont_article_detail .advanceReservation_wrap */}
        </div>
        {/* //.body_wrap */}
        {/* 入力画面 end */}
      </div>
    );
  }

  private checkTelStr(inputStr: string): boolean {
    const {
      phoneNumber,
      isTelCheck
    } = this.state;
    if (!inputStr) {
      return true;
    }
    const txt = inputStr;
    const cellPhoneRegex = txt.match(/^\d{2,5}-\d{1,4}-\d{4}$/);
    const cellPhoneRegex2 = txt.match(/^\d{11}$/);
    const telRegex = txt.match(/^\d{10}$/);
    if (cellPhoneRegex || cellPhoneRegex2) {
      if (!cellPhoneRegex && !cellPhoneRegex2) {
        if (isTelCheck) {
          setTimeout(() => {
            this.setState({
              isTelCheck: false,
            });
          });
        }
        return false;
      }
      if (!isTelCheck) {
        setTimeout(() => {
          this.setState({
            isTelCheck: true,
          });
        });
      }
      return true;
    }
    if (telRegex) {
      if (!isTelCheck) {
        setTimeout(() => {
          this.setState({
            isTelCheck: true,
          });
        });
      }
      return true;
    }
    if (isTelCheck) {
      setTimeout(() => {
        this.setState({
          isTelCheck: false,
        });
      });
    }
    return false;
  }

  private changeCheckStatus(isChecked: boolean): void {
    this.setState({
      isChecked: !isChecked,
    });
  }

  private handlerClickBtnBack(): void {
    this.setState({
      isInput: true
    });
  }

  private handlerClickBtnNext(): void {
    this.setState({
      isInput: false,
      isConfirmation: true,
    });
  }

  private handlerClickBtnSend(): void {
    const id = parseInt((this.props as any)['match']['params']['id'], 10);
    this.setState({
      isInput: false,
      isConfirmation: false,
    });
    const selectedRadioIndex = this.state.selectedRadioIndex;
    const send = {
      article_id: id,
      name: this.state.name,
      phoneNumber: this.state.phoneNumber,
      address: this.state.address,
      trigger: this.triggerMessages[selectedRadioIndex]
    };
    const request: RequestArticlesPostReservation = new RequestArticlesPostReservation(
      send.article_id,
      send.name,
      send.phoneNumber,
      send.address,
      send.trigger,
    );
    ConnectionService.ins.connect(request)
      .then((res) => {
        this.setState({
          reservedCount: res.data['reserved_count'],
        });
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }
}
