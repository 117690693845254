import { User } from '../model/user';
import { Prefecture } from '../model/prefecture';
import { City } from '../model/city';
import { RequestPrefecuresGetSearch } from '../model/api/request/request-prefecures-get-search';
import { ConnectionService } from './connection-service';
import { RequestCitiesGetSearch } from '../model/api/request/request-cities-get-search';
import { School } from '../model/school';
import { RequestPschoolsGetSearch } from '../model/api/request/request-pschools-get-search';
import { RequestMschoolsGetSearch } from '../model/api/request/request-mschools-get-search';
import { RequestUsersGetSearch } from '../model/api/request/request-users-get-search';
import { Store } from '../model/store';
import { RequestStoresGetSearch } from '../model/api/request/request-stores-get-search';
import { RequestSubdivisionGetSearch } from '../model/api/request/request-subdivision-get-search';
import { Subdivision } from '../model/subdivision';

export class DataManagerService {

  private static _ins: DataManagerService;

  private constructor() { }

  static get ins(): DataManagerService {
    if (!this._ins) {
      this._ins = new DataManagerService();
    }
    return this._ins;
  }

  getUserList(limit?:number): Promise<User[]> {
    const request: RequestUsersGetSearch = new RequestUsersGetSearch(
      '',
      99999,
      1,
      1,
      1,
      null,
      1
    );
    return new Promise<User[]>((resolve, reject) => {
      ConnectionService.ins.connect(request)
        .then((res) => {
          resolve(res.data.list);
        })
        .catch((err) => {
          reject([]);
        });
    });
  }

  getPrefectureList(): Promise<Prefecture[]> {
    const request: RequestPrefecuresGetSearch = new RequestPrefecuresGetSearch(
      '',
      99999,
      1,
      1,
      1,
      1
    );
    return new Promise<Prefecture[]>((resolve, reject) => {
      ConnectionService.ins.connect(request)
        .then((res) => {
          resolve(res.data.list);
        })
        .catch((err) => {
          reject([]);
        });
    });
  }

  getCityList(prefectureId: number | null): Promise<City[]> {
    return new Promise<City[]>((resolve, reject) => {
      if (prefectureId === null) {
        reject([]);
        return;
      }
      const request: RequestCitiesGetSearch = new RequestCitiesGetSearch(
        '',
        99999,
        1,
        1,
        1,
        1,
        prefectureId
      );
      ConnectionService.ins.connect(request)
        .then((res) => {
          resolve(res.data.list);
        })
        .catch((err) => {
          reject([]);
        });
    });
  }

  getPrimarySchoolList(prefectureId: number | null = null, cityId: number | null = null): Promise<School[]> {
    const request: RequestPschoolsGetSearch = new RequestPschoolsGetSearch(
      '',
      99999,
      1,
      1,
      1,
      1,
      prefectureId,
      cityId,
    );
    return new Promise<School[]>((resolve, reject) => {
      ConnectionService.ins.connect(request)
        .then((res) => {
          resolve(res.data.list);
        })
        .catch((err) => {
          reject([]);
        });
    });
  }

  getMiddleSchoolList(prefectureId: number | null = null, cityId: number | null = null): Promise<School[]> {
    const request: RequestMschoolsGetSearch = new RequestMschoolsGetSearch(
      '',
      99999,
      1,
      1,
      1,
      1,
      prefectureId,
      cityId,
    );
    return new Promise<School[]>((resolve, reject) => {
      ConnectionService.ins.connect(request)
        .then((res) => {
          resolve(res.data.list);
        })
        .catch((err) => {
          reject([]);
        });
    });
  }

  //REVIEW subdivison追加メソッド
  getSubdivisionList(prefectureId: number | null = null, cityId: number | null = null): Promise<Subdivision[]> {
    const request: RequestSubdivisionGetSearch = new RequestSubdivisionGetSearch(
      '',
      99999,
      1,
      1,
      1,
      1,
      prefectureId,
      cityId,
    );
    return new Promise<Subdivision[]>((resolve, reject) => {
      ConnectionService.ins.connect(request)
        .then((res) => {
          resolve(res.data.list);
        })
        .catch((err) => {
          reject([]);
        });
    });
  }

  getStoreList(): Promise<Store[]> {
    const request: RequestStoresGetSearch = new RequestStoresGetSearch(
      '',
      99999,
      1,
      1,
      1,
      1,
      null,
      null,
    );
    return new Promise<Store[]>((resolve, reject) => {
      ConnectionService.ins.connect(request)
        .then((res) => {
          resolve(res.data.list);
        })
        .catch((err) => {
          reject([]);
        });
    });
  }

  /*private testUserList: User[] = [
    new User(1, 'test@test.com', '山田太郎'),
    new User(2, 'test@test.com', '田中次郎'),
    new User(3, 'test@test.com', '中村三郎'),
    new User(4, 'test@test.com', '山本元柳斎重國'),
    new User(5, 'test@test.com', '浦原喜助'),
    new User(6, 'test@test.com', '浮竹十四郎'),
    new User(7, 'test@test.com', 'グリムジョージャガージャック'),
    new User(8, 'test@test.com', 'ゴータマシッダールタ'),
  ];*/

  private testPrefectureList: Prefecture[] = [
    new Prefecture(1, '福岡県', true),
    new Prefecture(2, '佐賀県', true),
    new Prefecture(3, '大分県', false),
    new Prefecture(4, '熊本県', true),
    new Prefecture(5, '宮崎県', false),
    new Prefecture(6, '長崎県', false),
    new Prefecture(7, '鹿児島県', false),
    new Prefecture(8, '沖縄県', false),
    new Prefecture(9, '山口県', false),
    new Prefecture(10, '島根県', false),
    new Prefecture(11, '鳥取県', false),
    new Prefecture(12, '広島県', false),
  ];

  private testCityList: City[] = [
    new City(1, '北九州市八幡西区', 1, true),
    new City(2, '北九州市八幡東区', 1, true),
    new City(3, '北九州市八幡北区', 1, true),
    new City(4, '北九州市八幡南区', 1, true),
    new City(5, '北九州市小倉北区', 1, false),
    new City(6, '北九州市小倉南区', 1, false),
    new City(7, '北九州市小倉東区', 1, false),
    new City(8, '北九州市小倉西区', 1, false),
    new City(9, '北九州市博多区', 1, true),
    new City(10, '北九州市門司区', 1, true),
  ];

  private testSchoolList: School[] = [
    new School(1, 'テスト学校0', 'てすとがっこう', '福岡県北九州市八幡西区', true, 0, 1),
    new School(2, 'テスト学校1', 'てすとがっこう', '福岡県北九州市八幡東区', true, 0, 1),
    new School(3, 'テスト学校2', 'てすとがっこう', '福岡県北九州市小倉北区', true, 0, 1),
    new School(4, 'テスト学校3', 'てすとがっこう', '福岡県北九州市小倉東区', true, 0, 1),
    new School(5, 'テスト学校4', 'てすとがっこう', '福岡県北九州市小倉西区', true, 0, 1),
    new School(6, 'テスト学校5', 'てすとがっこう', '福岡県北九州市小倉南区', true, 0, 1),
    new School(7, 'テスト学校6', 'てすとがっこう', '福岡県北九州市博多区', true, 0, 1),
  ];

}
