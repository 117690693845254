import Axios, { AxiosError, AxiosResponse } from 'axios';
import { APIType, HTTPMethod, RequestBase } from '../model/api/request/request-base';
import { UserAgentService } from './user-agent-service';
import { hasOwnProperty } from 'tslint/lib/utils';

export class ConnectionService {

  private static _ins: ConnectionService;

  private constructor() { }

  static get ins(): ConnectionService {
    if (!this._ins) {
      this._ins = new ConnectionService();
    }
    return this._ins;
  }

  getFileFromUrl(url: string): Promise<File> {
    const fileName: string = url.split('/')[url.split('/').length - 1];
    return new Promise((resolve, reject) => {
      Axios.get(url, { responseType: 'blob' })
        .then((v: AxiosResponse) => {
          // resolve(new File([v.data], fileName));
          const date = new Date();
          const blob = new Blob([v.data], { type: v.data.type });
          const blobAssigned = Object.assign(blob, {
            name: fileName,
            lastModified: date.getTime(),
            lastModifiedDate: date
          });
          resolve(blobAssigned as File);
        })
        .catch((e: AxiosError) => {
          reject(null);
        });
    });
  }

  connect(request: RequestBase): Promise<AxiosResponse> {
    const method: HTTPMethod = request.getMethod();
    Axios.defaults.withCredentials = true;
    Axios.defaults.headers = {
      'content-type': 'multipart/form-data'
    };
    return new Promise<AxiosResponse>(((resolve, reject) => {
      switch (method) {
        case HTTPMethod.GET:
          this.getRequest(request, resolve, reject);
          break;
        case HTTPMethod.POST:
          this.postRequest(request, resolve, reject);
          break;
        case HTTPMethod.DELETE:
          this.deleteRequest(request, resolve, reject);
          break;
        default:
          break;
      }
    }));
  }

  private getRequest(request: RequestBase, resolve: (v: any) => void, reject: (v: any) => void): void {
    const config: any = request.responseType ? (
      { 'responseType': request['responseType'] }
    ) : (
      {}
    );
    Axios.get(request.getUrl(), config)
      .then((response: AxiosResponse) => {
        this.displayDebugLog(true, request, response);
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        this.displayDebugLog(false, request, error);
        reject(error.response);
      });
  }

  private postRequest(request: RequestBase, resolve: (v: any) => void, reject: (v: any) => void): void {
    const config: any = request.responseType ? (
      { 'responseType': request['responseType'] }
    ) : (
      {}
    );
    Axios.post(request.getUrl(), request.createFormData(), config)
      .then((response: AxiosResponse) => {
        this.displayDebugLog(true, request, response);
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        this.displayDebugLog(false, request, error);
        reject(error.response);
      });
  }

  private deleteRequest(request: RequestBase, resolve: (v: any) => void, reject: (v: any) => void): void {
    Axios.delete(request.getUrl())
      .then((response: AxiosResponse) => {
        this.displayDebugLog(true, request, response);
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        this.displayDebugLog(false, request, error);
        reject(error.response);
      });
  }

  private displayDebugLog(isSuccess: boolean, request: RequestBase, response: AxiosResponse | AxiosError): void {
    const debug = false;
    if (!debug) return;
    const isChrome: boolean = UserAgentService.ins.isChrome();
    const black = isChrome ? '\u001b[30m' : '';
    const red = isChrome ? '\u001b[31m' : '';
    const green = isChrome ? '\u001b[32m' : '';
    const yellow = isChrome ? '\u001b[33m' : '';
    const blue = isChrome ? '\u001b[34m' : '';
    const magenta = isChrome ? '\u001b[35m' : '';
    const cyan = isChrome ? '\u001b[36m' : '';
    const white = isChrome ? '\u001b[37m' : '';
    const reset = isChrome ? '\u001b[39m' : '';
    if (isSuccess) {
      if (!hasOwnProperty(response, 'status')) return;
      console.groupCollapsed(`- [${green}${(response as any).status}${reset}] (${this.convertHTTPMethod2String(request.getMethod())}) ${request.getEndPoint()} -`);
    } else {
      if (!hasOwnProperty(response, 'response')) return;
      if (!hasOwnProperty((response as any)['response'], 'status')) return;
      console.groupCollapsed(`- [${red}${(response as any)['response']['status']}${reset}] (${this.convertHTTPMethod2String(request.getMethod())}) ${request.getEndPoint()} -`);
    }
    console.log('type     : ', this.convertAPIType2String(RequestBase.API_TYPE));
    console.log('method   : ', this.convertHTTPMethod2String(request.getMethod()));
    console.log('host     : ', request.getHost());
    console.log('endpoint : ', request.getEndPoint());
    console.log('url      : ', request.getUrl());
    console.log('param    : ', request.param);
    console.log(' ------');
    if (isSuccess) {
      console.log(' [Success]');
      console.log('response : ', response);
    } else {
      console.log(' [Error]');
      console.log('response : ', response);
    }
    console.groupEnd();
  }

  private convertAPIType2String(type: APIType): string {
    switch (type) {
      case APIType.local:
        return 'local';
      case APIType.develop:
        return 'develop';
      case APIType.distribution:
        return 'distribution';
      default:
        return 'undefined';
    }
  }

  private convertHTTPMethod2String(method: HTTPMethod): string {
    switch (method) {
      case HTTPMethod.GET:
        return 'GET';
      case HTTPMethod.POST:
        return 'POST';
      case HTTPMethod.DELETE:
        return 'DELETE';
      default:
        return 'undefined';
    }
  }

}
