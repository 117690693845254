
export class Store {

  constructor(
    public id: number | null,
    public name: string,
    public address: string,
    public tel: string,
    public fax: string,
    public email: string,
    public valid_flag: boolean,
    public prefecture_id: number,
    public city_id: number,
    public post_code: string,
  ) {}

}
