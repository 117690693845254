import { Config } from '../../../config/config';

export enum APIType {
  local,
  develop,
  distribution,
}

export enum HTTPMethod {
  GET,
  POST,
  DELETE,
}

export type NullPermission<T> = {
  [P in keyof T]?: T[P] | null;
};

export abstract class RequestBase {

  // API 接続先を変更する場合は ↓ ココ!!
  static API_TYPE: APIType = APIType.local;
  // static API_TYPE: APIType = APIType.distribution;

  host: string = '';
  endPoint: string = '';
  method: HTTPMethod;
  param: NullPermission<{}> = {};
  responseType: string = '';

  protected constructor(method: HTTPMethod, endpoint: string) {
    if (Config.mode === 'server') {
      this.host = window.location.origin;
    } else {
      this.host = Config.endPoint;
    }
    this.endPoint = endpoint;
    this.method = method;
  }

  getHost(): string {
    return this.host;
  }

  getEndPoint(): string {
    return this.endPoint;
  }

  getMethod(): HTTPMethod {
    return this.method;
  }

  abstract getUrl(): string;

  getParam2(): any {
  };

  getParam() {
    let queryParam = '?';
    const keys = Object.keys(this.param);
    const values = Object.values(this.param);
    for (let i = 0; i < keys.length; i += 1) {
      if (values[i] !== undefined && values[i] !== null) {
        queryParam += `${i ? '&' : ''}${keys[i]}=${values[i]}`;
      }
    }
    return queryParam;
  }

  public createFormData(): FormData {
    const formData = new FormData();
    const keys = Object.keys(this.param);
    const values: (string | string[] | number | undefined | File)[] = Object.values(this.param);
    for (let i = 0; i < keys.length; i += 1) {
      if (values[i] !== undefined && values[i] !== null) {
        formData.append(keys[i], values[i] as string);
      } else {
        formData.append(keys[i], '');
      }
    }
    return formData;
  }

  selectHost(type: APIType): string {
    switch (type) {
      case APIType.local:
        return 'https://rms.marietta.co.jp';
      // return 'http://192.168.0.91';
      case APIType.develop:
        return '.';
      case APIType.distribution:
        return '.';
    }
  }

}
