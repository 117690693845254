import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { UserActions } from './user.action';
import { User } from '../../model/user';
import lodash from 'lodash';

export type UserState = {
  isAdmin: boolean;
  userData: User | null;
};

const initialState: UserState = {
  isAdmin: false,
  userData: null,
};

export const UserReducer = reducerWithInitialState<UserState>(initialState)
  .case(UserActions.setIsAdmin, (state, payload) => (
    {
      ...state,
      isAdmin: payload,
    }
  ))
  .case(UserActions.setUserData, (state, payload) => (
    {
      ...state,
      userData: lodash.cloneDeep(payload),
    }
  ));
