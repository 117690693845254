import {HTTPMethod, RequestBase} from './request-base';

export class RequestArticlereservesGetSearch extends RequestBase {

  param: {
    q: string,
    limit: number,
    p: number,
    sort: number,
    is_descending: number,
    article_id: number,
  };

  constructor(
    q: string,
    limit: number,
    p: number,
    sort: number,
    is_descending: number,
    article_id: number,
  ) {
    super(HTTPMethod.GET, '/api/articlereserves/search');
    this.param = {
      q: q,
      limit: limit,
      p: p,
      sort: sort,
      is_descending: is_descending,
      article_id: article_id,
    };
  }

  getUrl(): string {
    return this.host + this.endPoint + this.getParam();
  }

}
