import actionCreatorFactory from 'typescript-fsa';
import { SideMenuType } from '../../component/common/side-menu/side-menu-component';
import { User } from '../../model/user';

const ActionCreator = actionCreatorFactory('user');

export const UserActions = {
  setUserData: ActionCreator<User>('set/user/data'),
  setIsAdmin: ActionCreator<boolean>('set/is/admin'),
};
