import * as React from 'react';
import './header-component.scss';
import imgLogo from '../../../img/common/logo.png';
import iconLogout from '../../../img/header/icon_logout.png';
import iconSetting from '../../../img/header/icon_setting.png';
import iconSpMenu from '../../../img/header/icon_sp_menu.png';
import { SideMenuComponent } from '../side-menu/side-menu-component';
import { ModalService } from '../../../service/modal-service';
import { ModalDialogComponent } from '../modal/modal-dialog/modal-dialog-component';
import { RoutingPass } from '../../../constant/routing-pass';
import { RequestUsersPostLogout } from '../../../model/api/request/request-users-post-logout';
import { ConnectionService } from '../../../service/connection-service';
import { ErrorService } from '../../../service/error-service';
import { User } from '../../../model/user';
import { UserService } from '../../../service/user-service';
import { SideMenuService } from '../../../service/side-menu-service';
import { RequestGetVersion } from '../../../model/api/request/request-get-version';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { State } from '../../../redux/root.reducer';
import { UserActions } from '../../../redux/user/user.action';
import { ModalConfigComponent } from './modal-setting/modal-config-component';

export const HeaderComponent = () => {
  const dispatch = useDispatch();
  const { hideMenu, userData } = useSelector((state: State) => ({
    hideMenu: state.system.hideMenu,
    userData: state.user.userData,
  }));

  const [isDisplaySideMenu, setIsDisplaySideMenu] = useState(false);
  const [version, setVersion] = useState('');

  useEffect(() => {
    if (hideMenu) return;
    UserService.ins.getMe()
      .then((v) => dispatch(UserActions.setUserData(v)))
      .catch((v) => dispatch(UserActions.setUserData(v)));
    ConnectionService.ins.connect(new RequestGetVersion())
      .then((res: any) => setVersion(res.version))
      .catch(() => setVersion(''));
  }, [dispatch, hideMenu]);

  const userName: string = userData ? userData.fullname.value + ' さん' : '';

  const handlerClickHamburger = useCallback(() => {
    setIsDisplaySideMenu(!isDisplaySideMenu);
  }, [isDisplaySideMenu]);

  const handlerClickBtnConfig = () => {
    ModalService.ins.display(
      <ModalConfigComponent />
    );
  };

  const handlerClickBtnLogout = () => {
    ModalService.ins.display(
      <ModalDialogComponent
        msgList={['ログアウトしますか？']}
        callbackOk={() => {
          const request: RequestUsersPostLogout = new RequestUsersPostLogout();
          ConnectionService.ins.connect(request)
            .then(() => {
              ModalService.ins.close();
              UserService.ins.destroy();
              SideMenuService.ins.destroy();
              dispatch(push(RoutingPass.LOGIN));
            })
            .catch((err) => {
              ErrorService.ins.response(err);
            });
        }}
        callbackNg={() => ModalService.ins.close()}
      />
    );
  };

  return (
    <>
      <div className={'header_wrap'}
      >
        <div className={'headerInner_wrap flex_box flex_align_center flex_space_between'}>

          {/* 左側 */}
          <div className={`left_wrap flex_box flex_align_end${hideMenu ? (' hide_menu') : ('')}`}>

            {/* title */}
            <div className={'logo_wrap'}><img src={imgLogo} alt={'LibWork'} /></div>

            {/* sub title */}
            {hideMenu ? <></> : (
              <div className={'subTitle_wrap'}>不動産管理システム&nbsp;Ver {version}</div>
            )}
          </div>
          {/** left_wrap */}

          {/***---------------------------------------------------------------------- */}

          {/* 右側 */}
          <div className={'right_wrap flex_box flex_align_center'}>

            {/* ハンバーガ */}
            {hideMenu ? <></> : (
              <div className={'sp_menu_btn'}>
                <button
                  className={'reset flex_box flex_align_center flex_content_center'}
                  onClick={handlerClickHamburger}
                ><img src={iconSpMenu} alt={''} /></button>
              </div>
            )}

            {/* side menu */}
            {isDisplaySideMenu ? (
              <SideMenuComponent />
            ) : null}

            {/* アイコン */}
            {/*<div className={'iconUser'}>
              <img src={iconUser}/>
            </div>*/}
            {/* user name */}
            {hideMenu ? <></> : (
              <>
                <div className={'userName'}>
                  {userName}
                </div>
                {/* 設定ボタン */}
                <div
                  className={'btn_wrap btn_setting'}
                  onClick={handlerClickBtnConfig}
                >
                  <button className={'reset'}><img src={iconSetting} alt={''} /></button>
                </div>
                {/* ログアウトボタン */}
                <div
                  className={'btn_wrap btn_logout'}
                  onClick={handlerClickBtnLogout}
                >
                  <button className={'reset'}><img src={iconLogout} alt={''} /></button>
                </div>
              </>
            )}
          </div>
          {/** right_wrap */}

        </div>
        {/** headerInner_wrap */}
      </div>
      {/** header_wrap */}

      {hideMenu ? <></> : (
        <div className={'header_spacer'} />
      )}
    </>
  );

};

// export class HeaderComponent extends React.Component<HeaderComponentProps, HeaderComponentState> {

//   constructor(props: HeaderComponentProps) {
//     super(props);
//     this.state = {
//       isDisplaySideMenu: false,
//       me: null,
//       version: '',
//     };

//   }

//   render() {
//     const userName: string = this.state.me ? this.state.me.fullname + ' さん' : '';
//     const isDisplaySideMenu: boolean = this.state.isDisplaySideMenu;
//     const version: string = this.state.version;
//     return (
//       <>
//         <div className={'header_wrap'}>
//           <div className={'headerInner_wrap flex_box flex_align_center flex_space_between'}>

//             {/* 左側 */}
//             <div className={`left_wrap flex_box flex_align_end${this.props.hideMenu ? (' hide_menu') : ('')}`}>

//               {/* title */}
//               <div className={'logo_wrap'}><img src={imgLogo} alt={'LibWork'} /></div>

//               {/* sub title */}
//               {this.props.hideMenu ? <></> : (
//                 <div className={'subTitle_wrap'}>不動産管理システム&nbsp;Ver {version}</div>
//               )}
//             </div>
//             {/** left_wrap */}

//             {/***---------------------------------------------------------------------- */}

//             {/* 右側 */}
//             <div className={'right_wrap flex_box flex_align_center'}>

//               {/* ハンバーガ */}
//               {this.props.hideMenu ? <></> : (
//                 <div className={'sp_menu_btn'}>
//                   <button
//                     className={'reset flex_box flex_align_center flex_content_center'}
//                     onClick={() => this.handlerClickHamburger()}
//                   ><img src={iconSpMenu} alt={''} /></button>
//                 </div>
//               )}

//               {/* side menu */}
//               {isDisplaySideMenu ? (
//                 <SideMenuComponent />
//               ) : null}

//               {/* アイコン */}
//               {/*<div className={'iconUser'}>
//                 <img src={iconUser}/>
//               </div>*/}
//               {/* user name */}
//               {this.props.hideMenu ? <></> : (
//                 <>
//                   <div className={'userName'}>
//                     {userName}
//                   </div>
//                   {/* 設定ボタン */}
//                   <div
//                     className={'btn_wrap btn_setting'}
//                     onClick={() => this.handlerClickBtnConfig()}
//                   >
//                     <button className={'reset'}><img src={iconSetting} alt={''} /></button>
//                   </div>
//                   {/* ログアウトボタン */}
//                   <div
//                     className={'btn_wrap btn_logout'}
//                     onClick={() => this.handlerClickBtnLogout()}
//                   >
//                     <button className={'reset'}><img src={iconLogout} alt={''} /></button>
//                   </div>
//                 </>
//               )}
//             </div>
//             {/** right_wrap */}

//           </div>
//           {/** headerInner_wrap */}
//         </div>
//         {/** header_wrap */}

//         {this.props.hideMenu ? <></> : (
//           <div className={'header_spacer'} />
//         )}
//       </>
//     );
//   }

//   componentWillMount(): void {
//     if (this.props.hideMenu) {
//       return;
//     }
//     UserService.ins.getMe()
//       .then((v) => this.setState({ me: v }))
//       .catch((v) => this.setState({ me: v }));
//     ConnectionService.ins.connect(new RequestGetVersion())
//       .then((res: any) => this.setState({ version: res.version }))
//       .catch(() => this.setState({ version: '' }));
//   }

//   private handlerClickHamburger(): void {
//     this.setState({
//       isDisplaySideMenu: !this.state.isDisplaySideMenu
//     });
//   }

//   private handlerClickBtnConfig(): void {
//     ModalService.ins.display(
//       <ModalConfigComponent />
//     );
//   }

//   private handlerClickBtnLogout(): void {
//     ModalService.ins.display(
//       <ModalDialogComponent
//         msgList={['ログアウトしますか？']}
//         callbackOk={() => {
//           const request: RequestUsersPostLogout = new RequestUsersPostLogout();
//           ConnectionService.ins.connect(request)
//             .then(() => {
//               ModalService.ins.close();
//               UserService.ins.destroy();
//               SideMenuService.ins.destroy();
//               this.props.history.push(RoutingPass.LOGIN);
//             })
//             .catch((err) => {
//               ErrorService.ins.response(err);
//             });
//         }}
//         callbackNg={() => {
//           ModalService.ins.close();
//         }}
//       />
//     );
//   }

// }
