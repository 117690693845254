import * as React from 'react';
import './master-user-component.scss';
import { HeaderComponent } from '../../common/header/header-component';
import { SideMenuComponent } from '../../common/side-menu/side-menu-component';
import { MasterListRow } from '../../common/master-list/master-list-row';
import { User } from '../../../model/user';
import { MasterListHeader } from '../../common/master-list/master-list-header';
import { MasterListTableComponent } from '../../common/master-list/master-list-table/master-list-table-component';
import { PagerComponent } from '../../common/pager/pager-component';
import { ModalService } from '../../../service/modal-service';
import { ModalAlertComponent } from '../../common/modal/modal-alert/modal-alert-component';
import { ModalEditUserComponent } from './modal-edit-user/modal-edit-user-component';
import { RequestUsersGetSearch } from '../../../model/api/request/request-users-get-search';
import { ConnectionService } from '../../../service/connection-service';
import { ErrorService } from '../../../service/error-service';
import { Store } from '../../../model/store';
import { DataManagerService } from '../../../service/data-manager-service';
import { RequestUsersPostAdd } from '../../../model/api/request/request-users-post-add';
import { RequestUsersPostEdit } from '../../../model/api/request/request-users-post-edit';
import { DebugService } from '../../../service/debug-service';
import iconCheckBoxChecked from '../../../img/common/checkbox_checked.png';
import iconCheckBox from '../../../img/common/checkbox.png';

interface MasterUserComponentProps {
}

interface MasterUserComponentState {
  searchParamName: string,
  searchParamIsDisplayInvalid: boolean,
  searchParamStoreId: number | null,
  sortType: number,
  sortOrder: number,
  sortLimit: number,
  userList: User[],
  storeList: Store[],
  pageCurrent: number,
  pageMax: number,
  numberTotal: number; // 件数
  numberCurrentFrom: number; // 現在の表示件数
  numberCurrentTo: number; // 現在の表示件数
}

export class MasterUserComponent extends React.Component<MasterUserComponentProps, MasterUserComponentState> {

  private sortOrderList: { value: number, label: string; }[] = [
    { value: 0, label: '降順' },
    { value: 1, label: '昇順' },
  ];

  private sortLimitList: { value: number, label: string; }[] = [
    { value: 10, label: '10件' },
    { value: 25, label: '25件' },
    { value: 50, label: '50件' },
    { value: 100, label: '100件' },
  ];

  private headerList: MasterListHeader[] = [
    new MasterListHeader('id', 'No'),
    new MasterListHeader('email', 'メールアドレス'),
    new MasterListHeader('fullname', '名前'),
    new MasterListHeader('store_id', '支店'),
    new MasterListHeader('group_id', '権限'),
  ];

  /*private testUserList: User[] = [
    new User(1, 'hoge@piyo.com', '山田太郎'),
    new User(2, 'hoge@piyo.com', '山田次郎'),
    new User(3, 'hoge@piyo.com', '山田三郎'),
    new User(4, 'hoge@piyo.com', '山田四郎'),
    new User(5, 'hoge@piyo.com', '山田五郎'),
    new User(6, 'hoge@piyo.com', '山田六郎'),
    new User(7, 'hoge@piyo.com', '山田七郎'),
    new User(8, 'hoge@piyo.com', '山田八郎'),
    new User(9, 'hoge@piyo.com', '山田九郎'),
  ];*/

  constructor(props: MasterUserComponentProps) {
    super(props);
    this.state = {
      searchParamName: '',
      searchParamIsDisplayInvalid: false,
      searchParamStoreId: null,
      sortType: 1,
      sortOrder: 0,
      sortLimit: 25,
      userList: [],
      storeList: [],
      pageCurrent: 1,
      pageMax: 1,
      numberTotal: 0,
      numberCurrentFrom: 0,
      numberCurrentTo: 0,
    };
  }

  render() {
    const searchParamName: string = this.state.searchParamName;
    const searchParamIsDisplayInvalid: boolean = this.state.searchParamIsDisplayInvalid;
    const sortType: number = this.state.sortType;
    const sortOrder: number = this.state.sortOrder;
    const sortLimit: number = this.state.sortLimit;
    const headerList: MasterListHeader[] = this.headerList;
    const userList: User[] = this.state.userList;
    const storeList: Store[] = this.state.storeList;
    const numberTotal = this.state.numberTotal;
    const numberCurrentFrom = this.state.numberCurrentFrom;
    const numberCurrentTo = this.state.numberCurrentTo;
    return (
      <div>

        {/* --- header --- */}
        <HeaderComponent />

        <div className={'body_wrap flex_box'}>

          {/* side menu */}
          <div className={'pc menu_wrap'}>
            <SideMenuComponent />
          </div>

          <div className={'content_wrap masterSystem_cont'}>
            <div className={'contentInner_wrap table_masterUser'}>


              {/* title */}
              <div className={'contentHead_wrap'}>
                <div className={'contentHeadInner_wrap flex_box flex_align_center flex_space_between'}>
                  <div className={'contentHead_text flex_box flex_align_center'}>
                    <span>ユーザマスタ</span>
                    <span></span>
                  </div>
                </div>
              </div>
              {/** contentHead_wrap */}

              {/* search */}
              <div className={'searchArea masterUser'}>
                <div className={'userName_wrap flex_box flex_align_center'}>
                  <div>支店&emsp;&emsp;&emsp;</div>
                  <div>
                    <select
                      value={this.state.searchParamStoreId || 0}
                      onChange={(e) => {
                        this.setState({ searchParamStoreId: +e.target.value ? Number(e.target.value) : null });
                        this.postSearchAPI({ ...this.state, searchParamStoreId: +e.target.value ? Number(e.target.value) : null });
                      }}
                    >
                      <option value={0}>未選択</option>
                      {storeList.map((v) => (
                        <option key={v.id} value={v.id ?? ''}>{v.name}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className={'divide10'}></div>

                <div className={'masterInput_wrap flex_box flex_align_center'}>
                  <div className={'userName_wrap flex_box flex_align_center'}>
                    <div>キーワード</div>
                    <div>
                      <input
                        className={'textInput'}
                        type={'search'}
                        value={searchParamName}
                        onChange={(e) => this.setState({ searchParamName: e.target.value })}
                        placeholder={'名前 / メールアドレス'}
                      />
                    </div>
                  </div>

                  <div className={'right_wrap'}>
                    <div
                      className={'checkbox_wrap flex_box flex_align_center'}
                      onClick={() => {
                        this.setState({ searchParamIsDisplayInvalid: !searchParamIsDisplayInvalid });
                        this.postSearchAPI({ ...this.state, searchParamIsDisplayInvalid: !searchParamIsDisplayInvalid });
                      }}
                    >
                      {searchParamIsDisplayInvalid ? (
                        <div className={'innerImg checked'}><img src={iconCheckBoxChecked} /></div>
                      ) : (
                        <div className={'innerImg noCheck'}><img src={iconCheckBox} /></div>
                      )}
                      <div className={'innerText selectDisabled'}>無効データを含める</div>
                    </div>
                  </div>
                </div>

                <div>
                  {/*<div
                    className={``}
                    onClick={() => this.setState({searchParamIsDisplayInvalid: !searchParamIsDisplayInvalid})}
                  >
                    {searchParamIsDisplayInvalid ? (
                      <div className={'innerImg checked'}><img src={iconCheckBoxChecked}/></div>
                    ) : (
                      <div className={'innerImg noCheck'}><img src={iconCheckBox}/></div>
                    )}
                    <div className={'innerText selectDisabled'}>無効データを含める</div>
                  </div>*/}
                </div>

                <div className={'divide20'}></div>

                <div className={'searchBtn_wrap'}>
                  <button
                    className={'reset btnStyle_1 marg_auto'}
                    onClick={() => this.handlerClickBtnSearch()}
                  >
                    検索
                  </button>
                </div>
              </div>

              {/* sort */}
              <div className={'sort_pager_wrap flex_box flex_align_center flex_space_between'}>
                <div className={'sort_pager_inner_wrap flex_box flex_align_center flex_space_between'}>
                  <div className={'select_wrap flex_box flex_align_center'}>

                    <div>
                      <select
                        onChange={(e) => {
                          this.setState({ sortType: Number(e.target.value) });
                          this.postSearchAPI({ ...this.state, sortType: Number(e.target.value) });
                        }}
                        value={sortType}
                      >
                        {headerList.map((header, i) => (
                          <option key={header.value} value={i + 1}>{header.label}</option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <select
                        onChange={(e) => {
                          this.setState({ sortOrder: Number(e.target.value) });
                          this.postSearchAPI({ ...this.state, sortOrder: Number(e.target.value) });
                        }}
                        value={sortOrder}
                      >
                        {this.sortOrderList.map((v) => (
                          <option key={v.value} value={v.value}>{v.label}</option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <select
                        onChange={(e) => {
                          this.setState({ sortLimit: Number(e.target.value) });
                          this.postSearchAPI({ ...this.state, sortLimit: Number(e.target.value) });
                        }}
                        value={sortLimit}
                      >
                        {this.sortLimitList.map((v) => (
                          <option key={v.value} value={v.value}>{v.label}</option>
                        ))}
                      </select>
                    </div>

                  </div>
                </div>

                <div className={'btn_newAdd_wrap'}>
                  <button
                    className={'reset btnStyle_1'}
                    onClick={() => this.handlerBtnNew()}
                  >
                    新規登録
                  </button>
                </div>
              </div>

              <div className={'divide10'}></div>

              <div className={'displayNum_pager_wrap flex_box flex_align_center flex_space_between'}>

                <div className={'pagenum_wrap'}>
                  <div className={'pagenumInner_wrap flex_box flex_align_center'}>
                    <div>{numberCurrentFrom}~{numberCurrentTo}件</div>
                    <div>/</div>
                    <div>全{numberTotal}件</div>
                  </div>
                </div>
                {/** pagenum_wrap */}

                {/* ページャ */}
                <PagerComponent
                  currentPage={this.state.pageCurrent}
                  max={this.state.pageMax}
                  callback={(page) => this.callbackSelectPage(page)}
                />

              </div>{/* displayNum_pager_wrap */}

              <div className={'divide10'} />

              {/* テーブル */}
              <MasterListTableComponent
                headerList={headerList}
                rowList={this.makeTableData(userList, storeList, searchParamIsDisplayInvalid)}
                isEdited={true}
                isValidEdited={false}
              />

              <div className={'divide10'} />

            </div>
          </div>
        </div>

      </div>
    );
  }

  componentDidMount(): void {
    DebugService.ins.push('debug', () => {
    });
    DataManagerService.ins.getStoreList()
      .then((v) => {
        this.setState({ storeList: v });
      })
      .catch((v) => {
        this.setState({ storeList: v });
      });
    this.postSearchAPI(this.state);
  }

  private callbackBtnInvalid(): void {
  }

  private callbackBtnEdit(id: number): void {
    const user = this.state.userList.find((v) => v.id === id);
    if (!user) return;
    ModalService.ins.display(
      <ModalEditUserComponent
        user={user}
        callback={(user) => this.postEditAPI(user)}
      />,
    );
  }

  private callbackSelectPage(page: number): void {
    this.setState({ pageCurrent: page });
    this.postSearchAPI({ ...this.state, pageCurrent: page });
  }

  private handlerBtnNew(): void {
    const user: User = new User(null as unknown as number, { value: 0 }, '', { value: '' }, { value: '' }, { value: '' }, 2, 1);
    ModalService.ins.display(
      <ModalEditUserComponent
        user={user}
        callback={(user) => this.postEditAPI(user)}
      />,
    );
  }

  private handlerClickBtnSearch(): void {
    this.postSearchAPI(this.state);
  }

  private postSearchAPI(state: MasterUserComponentState): void {
    const request: RequestUsersGetSearch = new RequestUsersGetSearch(
      state.searchParamName,
      state.sortLimit,
      state.pageCurrent,
      state.sortType,
      state.sortOrder,
      state.searchParamStoreId,
      state.searchParamIsDisplayInvalid ? 0 : 1,
    );
    ConnectionService.ins.connect(request)
      .then((res) => {
        const list: User[] = res.data.list;
        this.setState({
          userList: list,
          pageCurrent: state.pageCurrent,
          pageMax: Math.ceil(res.data.count / res.data.limit),
          numberTotal: res.data.count,
          numberCurrentFrom: res.data.from,
          numberCurrentTo: res.data.to,
        });
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }

  private postEditAPI(user: User): void {
    const isNew: boolean = user.id === null;
    const request = isNew ? (
      new RequestUsersPostAdd(
        user.last_name.value,
        user.first_name.value,
        user.email,
        user.store_id.value,
        user.valid_flag,
        user.group_id,
      )
    ) : (
      new RequestUsersPostEdit(
        user.id,
        user.last_name.value,
        user.first_name.value,
        user.email,
        user.store_id.value,
        user.valid_flag,
        user.group_id,
      )
    );
    ConnectionService.ins.connect(request)
      .then((res) => {
        ModalService.ins.push(
          <ModalAlertComponent
            msgList={[user.id ? '更新しました。' : '登録しました。']}
            callback={() => {
              ModalService.ins.close();
              this.postSearchAPI(this.state);
            }}
          />,
        );
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }

  private makeTableData(list: User[], storeList: Store[], isDisplayInvalid: boolean): MasterListRow[] {
    return list
      .map((v: any) => (
        new MasterListRow(
          this.headerList.map((header) => {
            if (
              header.value === 'prefecture' ||
              header.value === 'city'
            ) {
              return v[header.value]['name'];
            }
            if (header.value === 'fullname') {
              return v[header.value] ? v[header.value]['value'] : '---';
            }
            if (header.value === 'store_id') {
              if (!v[header.value]) {
                return '---';
              }
              return (
                storeList.find((store) => +(store.id ?? '') === +v[header.value]['value']) ? (
                  storeList.find((store) => +(store.id ?? '') === +v[header.value]['value'])?.name
                ) : ('---')
              );
            }
            if (header.value === 'group_id') {
              if (+v[header.value] === 1) {
                return '管理者';
              } else if (+v[header.value] === 2) {
                return '一般ユーザ';
              } else {
                return '---';
              }
            }
            return v[header.value];
          }),
          true,
          () => this.callbackBtnEdit(v.id),
          () => this.callbackBtnInvalid(),
        )
      ));
  }

}
