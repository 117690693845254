import {HTTPMethod, RequestBase} from './request-base';

export class RequestUsersPostEdit extends RequestBase {

  param: {
    id: number | null,
    last_name: string,
    first_name: string,
    email: string,
    store_id: number,
    valid_flag: number,
    group_id: number,
  };

  constructor(
    id: number | null,
    last_name: string,
    first_name: string,
    email: string,
    store_id: number,
    valid_flag: number,
    group_id: number,
  ) {
    super(HTTPMethod.POST, '/api/users/edit');
    this.param = {
      id: id,
      last_name: last_name,
      first_name: first_name,
      email: email,
      store_id: store_id,
      valid_flag: valid_flag,
      group_id: group_id,
    };
  }

  // getParam(): FormData {
  //   const formData: FormData = new FormData();
  //   Object.keys(this.param).forEach((v) => {
  //     /*if (v === 'id') {
  //       formData.append(v, '');
  //       return;
  //     }*/
  //     if (this.param[v] === null) {
  //       formData.append(v, '');
  //       return;
  //     }
  //     formData.append(v, this.param[v] + '');
  //   });
  //   return formData;
  // }

  getUrl(): string {
    return this.host + this.endPoint;
  }

}
