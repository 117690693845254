import { DebugComponent } from '../component/common/debug/debug-component';


export class DebugService {

  private component: DebugComponent | null = null;
  private callbackList: (() => void)[] = [];
  private titleList: string[] = [];

  private static _ins: DebugService;

  private constructor() { }

  static get ins(): DebugService {
    if (!this._ins) {
      this._ins = new DebugService();
    }
    return this._ins;
  }

  init(component: DebugComponent): void {
    this.callbackList = [];
    this.titleList = [];
    this.component = component;
  }

  push(title: string, callback: () => void): void {
    if (!this.titleList) return;
    if (this.titleList.find((v) => v === title)) return;
    this.callbackList.push(callback);
    this.titleList.push(title);
    setTimeout(() => {
      if (this.component) {
        this.component.setState({
          isDisplay: true,
          callbackList: this.callbackList,
          titleList: this.titleList
        });
      }
    }, 100);
  }

}
