import { useDispatch } from 'react-redux';
import { RoutingPass } from '../../../constant/routing-pass';
import './error-component.scss';
import { push } from 'connected-react-router';

export const ErrorComponent = () => {
  const dispatch = useDispatch();

  const handlerClickBtnTop = () => {
    dispatch(push(RoutingPass.LOGIN));
  };

  return (
    <div className={'errorPage_wrap flex_box flex_align_center flex_content_center'}>
      <div className={'errorPageInner_wrap'}>

        <p>page not found ... </p>

        <div className={'divide20'} />

        <p>
          <button className={'reset btnStyle_1'} onClick={handlerClickBtnTop}>Topへ</button>
        </p>

      </div>
    </div>
  );

};