
export enum DateFormat {
  YYYY,
  YYYYMMDD,
  YYYYMMDD_HHmm,
  YYYYMMDD_HHmmSS,
  MMDD,
  MMDD_HHmm,
  MMDD_HHmmSS,
  HHmm,
  HHmmSS
}

export class DateFormatter {

  static date2str(date: Date | null, format: DateFormat = DateFormat.YYYYMMDD, dateSplitStr: string = '/'): string {
    if (!date) {
      return '';
    }
    const YYYY: string = date.getFullYear() + '';
    const MM: string = ('0' + String(date.getMonth() + 1)).slice(-2);
    const DD: string = ('0' + String(date.getDate())).slice(-2);
    const HH: string = ('0' + String(date.getHours())).slice(-2);
    const mm: string = ('0' + String(date.getMinutes())).slice(-2);
    const SS: string = ('0' + String(date.getSeconds())).slice(-2);
    switch (format) {
      case DateFormat.YYYY:
        return YYYY;
      case DateFormat.YYYYMMDD:
        return `${YYYY}${dateSplitStr}${MM}${dateSplitStr}${DD}`;
      case DateFormat.YYYYMMDD_HHmm:
        return `${YYYY}${dateSplitStr}${MM}${dateSplitStr}${DD} ${HH}:${mm}`;
      case DateFormat.YYYYMMDD_HHmmSS:
        return `${YYYY}${dateSplitStr}${MM}${dateSplitStr}${DD} ${HH}:${mm}:${SS}`;
      case DateFormat.MMDD:
        return `${MM}${dateSplitStr}${DD}`;
      case DateFormat.MMDD_HHmm:
        return `${MM}${dateSplitStr}${DD} ${HH}:${mm}`;
      case DateFormat.MMDD_HHmmSS:
        return `${MM}${dateSplitStr}${DD} ${HH}:${mm}:${SS}`;
      case DateFormat.HHmm:
        return `${HH}:${mm}`;
      case DateFormat.HHmmSS:
        return `${HH}:${mm}:${SS}`;
      default:
        return 'error';
    }
  }

  static str2date(str: string): Date | null {
    return (str ? (
      new Date(
        str
          .replace(/\//g, '-')
          .replace(/\s/g, 'T')
      )
    ) : (
      null
    ));
  }

}
