import * as React from 'react';
import './modal-share-component.scss';
import { Article } from '../../../../model/article';
import { ColorGenerator } from '../../../../util/color-generator';
import { User } from '../../../../model/user';
import { ModalService } from '../../../../service/modal-service';
import { ModalAlertComponent } from '../../../common/modal/modal-alert/modal-alert-component';
import iconArrowShareUser from '../../../../img/common/arrow_shareUser.png';
import iconArrowShareUserSp from '../../../../img/common/arrow_shareUser_sp.png';
import { DataManagerService } from '../../../../service/data-manager-service';
import { RequestArticlesPostShare } from '../../../../model/api/request/request-articles-post-share';
import { ConnectionService } from '../../../../service/connection-service';
import { ErrorService } from '../../../../service/error-service';
import { RequestBase } from '../../../../model/api/request/request-base';
import { RequestArticlesintroducedPostShare } from '../../../../model/api/request/request-articlesintroduced-post-share';
import lodash from 'lodash';

enum ModalShareType {
  search,
  confirm
}

interface ModalShareComponentProps {
  article: Article;
  isIntroduced: boolean;
}

interface ModalShareComponentState {
  article: Article;
  type: ModalShareType;
  userList: User[];
  selectedUserList: User[];
  isIntroduced: boolean;
  inputUserName: string;
}

export class ModalShareComponent extends React.Component<ModalShareComponentProps, ModalShareComponentState> {

  /*private testUserList: User[] = [
    new User(0, 'test@test.com', '山田','太郎'),
    new User(1, 'test@test.com', '田中','次郎'),
    new User(2, 'test@test.com', '中村','三郎'),
    new User(3, 'test@test.com', '山本','元柳斎重國'),
    new User(4, 'test@test.com', '浦原','喜助'),
    new User(5, 'test@test.com', '浮竹','十四郎'),
    new User(6, 'test@test.com', 'グリムジョー', 'ジャガージャック'),
    new User(7, 'test@test.com', 'ゴータマ','シッダールタ'),
  ];*/

  constructor(props: ModalShareComponentProps) {
    super(props);
    this.state = {
      article: props.article,
      type: ModalShareType.search,
      userList: [],
      selectedUserList: [],
      isIntroduced: props.isIntroduced,
      inputUserName: '',
    };
  }

  render() {
    const article: Article = this.state.article;
    const type: ModalShareType = this.state.type;
    const userList: User[] = this.state.userList;
    const selectedUserList: User[] = this.state.selectedUserList;
    const inputUserName: string = this.state.inputUserName;
    return (
      //<div className={'modalInnerBody_wrap shareModal_wrap'}>
      <>
        {type === ModalShareType.search ? (
          // 検索
          <div className={'modalInnerBody_wrap shareModal_wrap'}>
            <div className={'userSelect_wrap flex_box'}>

              {/* 未選択ユーザ一覧 */}
              <div className={'userList_noSelected_wrap'}>

                <div>どなたと共有しますか？</div>
                <div className={'divide10'}></div>

                <div>
                  <input
                    className={'textInput'}
                    placeholder={'検索'}
                    onChange={(e) => {
                      this.setState({ inputUserName: e.target.value });
                    }}
                  />
                </div>

                <div className={'divide10'}></div>

                <div className={'userList_noSelected'}>
                  {userList.filter((v) => v.fullname['value'].match(inputUserName) !== null).map((user, i) => (
                    <div
                      //style={{backgroundColor: ColorGenerator.createRandomRGBA(0.8)}}
                      onClick={() => this.handlerClickUser(user)}
                      key={user.id}
                    >
                      {user.fullname['value']}
                    </div>
                  ))}
                </div>
              </div>

              <div className={'imgArrow flex_box flex_align_center flex_content_center'}>
                <img className={'img_pc'} src={iconArrowShareUser} />
                <img className={'img_sp'} src={iconArrowShareUserSp} />
              </div>

              {/* 選択ユーザ一覧 */}
              <div className={'userList_selected_wrap'}>

                <div>選択済みユーザー</div>
                <div className={'divide10'}></div>

                <div className={'userList_selected'}>
                  {selectedUserList.map((user, i) => (
                    <div
                      //style={{backgroundColor: ColorGenerator.createRandomRGBA(0.8)}}
                      onClick={() => this.handlerClickSelectedUser(user)}
                      key={user.id}
                    >
                      {user.fullname['value']}
                    </div>
                  ))}
                </div>
              </div>

            </div>

            <div className={'divide20'}></div>

            {/* ボタン */}
            <div className={'btn_wrap flex_box flex_space_between'}>
              <div>
                <button
                  className={'reset btnStyle_1'}
                  onClick={() => this.handlerClickBtnClose()}
                >戻る</button>
              </div>
              <div>
                <button
                  className={`reset btnStyle_1 ${selectedUserList.length > 0 ? '' : ' disabled'}`}
                  onClick={() => this.handlerClickBtnConfirm()}
                >次へ</button>
              </div>
            </div>
          </div>
        ) : (
          // 確認
          <div className={'modalInnerBody_wrap shareModalSelected_wrap'}>

            <div className={'shareModalSelectedInner'}>
              <div>共有相手を確認してください</div>
              <div className={'divide10'}></div>
              <div className={'selectedUserList'}>
                {selectedUserList.map((user, i) => (
                  <div
                    key={user.id}
                  >
                    {user.fullname['value']}
                  </div>
                ))}
              </div>
            </div>

            <div className={'divide10'}></div>

            {/* ボタン */}
            <div className={'btn_wrap flex_box flex_space_between'}>
              <div>
                <button
                  className={'reset btnStyle_1'}
                  onClick={() => this.handlerClickBtnReselect()}
                >修正</button>
              </div>
              <div>
                <button
                  className={'reset btnStyle_1'}
                  onClick={() => this.handlerClickBtnShare()}
                >共有する</button>
              </div>
            </div>

          </div>
        )}
      </>
    );
  }

  componentDidMount(): void {
    DataManagerService.ins.getUserList()
      .then((v) => this.setState({ userList: v }))
      .catch((v) => this.setState({ userList: v }));
  }

  private handlerClickUser(user: User): void {
    const userList: User[] = lodash.cloneDeep(this.state.userList);
    const selectedUserList: User[] = lodash.cloneDeep(this.state.selectedUserList);
    const targetIndex = userList.findIndex((v) => v.id === user.id);

    selectedUserList.splice(targetIndex, 0, userList[targetIndex]);
    userList.splice(targetIndex, 1);
    userList.sort((a, b) => a.id - b.id);
    selectedUserList.sort((a, b) => a.id - b?.id);
    this.setState({
      userList: lodash.cloneDeep(userList),
      selectedUserList: lodash.cloneDeep(selectedUserList),
    });
  }

  private handlerClickSelectedUser(user: User): void {
    const userList: User[] = lodash.cloneDeep(this.state.userList);
    const selectedUserList: User[] = lodash.cloneDeep(this.state.selectedUserList);
    const targetIndex = selectedUserList.findIndex((v) => v.id === user.id);
    if (targetIndex === undefined) return;
    userList.splice(targetIndex, 0, lodash.cloneDeep(selectedUserList[targetIndex]));
    selectedUserList.splice(targetIndex, 1);
    userList.sort((a, b) => a.id - b.id);
    selectedUserList.sort((a, b) => a.id - b.id);
    this.setState({
      userList: lodash.cloneDeep(userList),
      selectedUserList: lodash.cloneDeep(selectedUserList),
    });
  }

  private handlerClickBtnClose(): void {
    ModalService.ins.close();
  }

  private handlerClickBtnConfirm(): void {
    this.setState({
      type: ModalShareType.confirm
    });
  }

  private handlerClickBtnReselect(): void {
    this.setState({
      type: ModalShareType.search
    });
  }

  private handlerClickBtnShare(): void {
    const request: RequestBase = this.state.isIntroduced ? (
      new RequestArticlesintroducedPostShare(
        this.state.article.id as number,
        JSON.stringify(this.state.selectedUserList.map((v) => v.id))
      )
    ) : (
      new RequestArticlesPostShare(
        this.state.article.id as number,
        JSON.stringify(this.state.selectedUserList.map((v) => v.id))
      )
    );
    ConnectionService.ins.connect(request)
      .then((res) => {
        ModalService.ins.push(
          <ModalAlertComponent
            msgList={['共有メールが送信されました。', '詳細画面へ戻ります。']}
            callback={() => {
              ModalService.ins.close();
            }}
          />
        );
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }

  private searchUser(): void {
  }

}
