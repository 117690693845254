import { HTTPMethod, RequestBase } from './request-base';

type Param = {
  q: string,
  limit: number,
  p: number,
  sort: number,
  is_descending: number | null,
  prefecture_id: number | null,
  city_id: number | null,
  mschool_id: number | null,
  pschool_id: number | null,
  user_id: number | null,
  purchasing_manager_id: number | null,
  purchase_datetime_start: string | null,
  purchase_datetime_end: string | null,
  tsubo_start: number | null,
  tsubo_end: number | null,
  area_measuresize_start: number | null,
  area_measuresize_end: number | null,
  price_start: number | null,
  price_end: number | null,
  location: string | null,
};

export class RequestArticlesGetCsv_export extends RequestBase {

  responseType: string = 'blob';
  param:Param


  constructor(
    param: Param
  ) {
    super(HTTPMethod.GET, '/api/articles/csv_export');
    this.param = param;
  }

  // getParam(): string {
  //   let param: string = '?';
  //   Object.keys(this.param).forEach((v) => {
  //     if (this.param[v] === null) {
  //       return;
  //     }
  //     param += `${v}=${this.param[v]}&`;
  //   });
  //   return param;
  // }

  getUrl(): string {
    return this.host + this.endPoint + this.getParam();
  }

}
