import {User} from '../model/user';
import {RequestUsersGetMe} from '../model/api/request/request-users-get-me';
import {ConnectionService} from './connection-service';

export class UserService {

  private user: User | null = null;

  private static _ins: UserService;

  private constructor() {}

  static get ins(): UserService {
    if (!this._ins) {
      this._ins = new UserService();
    }
    return this._ins;
  }

  getMe(): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      if (!this.user) {
        const request: RequestUsersGetMe = new RequestUsersGetMe();
        ConnectionService.ins.connect(request)
          .then((res) => {
            this.user = res.data;
            if (this.user) {
              resolve(this.user);
            } else {
              reject(null)
            }
          })
          .catch(() => {
            reject(null);
          });
      } else {
        resolve(this.user);
      }
    });
  }

  destroy(): void {
    this.user = null;
  }

}
