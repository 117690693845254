import * as React from 'react';
import { MasterListHeader } from '../master-list-header';
import { MasterListRow } from '../master-list-row';
import './master-list-table-component.scss';
import { State } from '../../../../redux/root.reducer';
import { useSelector } from 'react-redux';
import { SideMenuType } from '../../side-menu/side-menu-component';

interface MasterListTableComponentProps {
  headerList: MasterListHeader[],
  rowList: MasterListRow[],
  isEdited: boolean,
  isValidEdited: boolean,
  open?: (v: number) => void;
  close?: (v: number) => void;
  isSubdivision?: boolean;
}

interface MasterListTableComponentState {
  headerList: MasterListHeader[],
  rowList: MasterListRow[],
  isEdited: boolean,
  isValidEdited: boolean,
}

export const MasterListTableComponent = (props: MasterListTableComponentProps) => {
  const currentMenu = useSelector((state:State) => state.system.currentMenu)
  const {
    headerList,
    rowList,
    isEdited,
    isValidEdited,
    isSubdivision,
  } = props;
  return (
    <div className={'tableArea masterTableStyle'}>
      <div className={'table_wrap'}>

        {/* - header - */}
        <div className={'table_row table_head'}>
          {headerList.map((header) => (
            <div
              className={'table_cell'}
              key={'head' + header.value}
            >
              {header.label}
            </div>
          ))}
          {isSubdivision && isValidEdited ? (
            <div className={'table_cell'}>
              ステータス
            </div>
          ) : (<></>)}
        </div>
        {/* - header - */}

        {/* -------------------------------------------------- */}

        {/* -- row -- */}
        {rowList.map((row, indexRow) => (
          <div
            className={'table_row table_body' + (!row.isParent && currentMenu  === SideMenuType.subDivision? ' isChild' : '')}
            //style={{backgroundColor: ColorGenerator.createRandomRGBA(0.2)}}
            key={'row' + indexRow}
            onClick={() => row.callbackBtnEdit()}
          >
            {/* -- cell -- */}
            {row.cellList.map((cell, indexCell) => (
              <div
                className={'table_cell'}
                key={'cell' + indexCell}
              >
                <div className={'sp_table_head'}>{/** モバイル用ヘッダ */}
                  {headerList[indexCell].label}
                </div>
                <div className={'table_cell_content' + (row.isParent && indexCell === 1 ? ' isArrow' : '')}>
                  {row.isParent && indexCell === 1 ? (
                    <>
                      {row.hasChild ? (
                        <>
                          <span
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              if (props.open && props.close && row.isParent) {
                                if (row.isOpen) {
                                  props.close(row.parentId as number);
                                } else {
                                  props.open(row.parentId as number);
                                }
                              }
                            }}
                            className="innerArrow"
                          >
                            {row.isOpen ? (<>&#9650;</>) : (<>&#9660;</>)}
                          </span>
                        </>
                      ) : (<></>)}
                    </>
                  ) : (<></>)}
                  {cell ? cell : 'ー'}
                </div>
              </div>
            ))}
            {row.isParent && isValidEdited ? (
              <div className={'table_cell'}>
                <div className={'sp_table_head'}>{/** モバイル用ヘッダ */}
                  ステータス
                </div>
                <div className={'table_cell_content'}>
                  {row.isValid ? (
                    <span>有効</span>
                  ) : (
                    <>
                      <span>無効</span>
                    </>
                  )}
                </div>
              </div>
            ) : (<></>)}

            {/* 子分譲地のステータス */}
            {isSubdivision && !row.isParent ? (
              <>
                <div className="table_cell">
                  <div className={'sp_table_head'}>ステータス</div>{/** モバイル用ヘッダ */}
                  <div className={'table_cell_content statusCell'}>
                    {row.salesStatus === '1' ? (<><span className="innerStatus isPurchasing">買付中</span></>) : (<></>)}
                    {row.salesStatus === '2' ? (<><span className="innerStatus contract">契約</span></>) : (<></>)}
                    {row.salesStatus === '3' ? (<><span className="innerStatus isContracted">契約済み</span></>) : (<></>)}
                    {row.salesStatus === '4' ? (<><span className="innerStatus isApplication">申請中</span></>) : (<></>)}
                    {row.salesStatus === '5' ? (<><span className="innerStatus isComplete">造成中</span></>) : (<></>)}
                    {row.salesStatus === '6' ? (<><span className="innerStatus isEndSale">販売終了</span></>) : (<></>)}
                    {row.salesStatus === '7' ? (<><span className="innerStatus isSale">販売中</span></>) : (<></>)}
                  </div>
                </div>
              </>
            ) : (<></>)}
            {/* -- cell -- */}
          </div>
        ))}
        {/* -- row -- */}

      </div>{/* talbe_wrap */}
    </div>
  );
};
