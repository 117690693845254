import {HTTPMethod, RequestBase} from './request-base';

export class RequestArticlesPostReservation extends RequestBase {

  param: {
    article_id: number,
    name: string,
    phone_number: string,
    address: string,
    trigger: string,
  };

  constructor(
    article_id: number,
    name: string,
    phone_number: string,
    address: string,
    trigger: string,
  ) {
    super(HTTPMethod.POST, '/api/articles/web_reservation');
    this.param = {
      article_id: article_id,
      name: name,
      phone_number: phone_number,
      address: address,
      trigger: trigger,
    };
  }

  // getParam(): FormData {
  //   const formData: FormData = new FormData();
  //   Object.keys(this.param).forEach((v) => {
  //     /*if (v === 'id') {
  //       formData.append(v, '');
  //       return;
  //     }*/
  //     if (this.param[v] === null) {
  //       formData.append(v, '');
  //       return;
  //     }
  //     formData.append(v, this.param[v] + '');
  //   });
  //   formData.append('reservation_flag', '');
  //   return formData;
  // }

  getUrl(): string {
    return this.host + this.endPoint;
  }

}
