//REVIEW csvアップロードコンポーネント
import * as React from 'react';
import './file-loader-component.scss';
import FileDnDComponent from './file-dnd/file-dnd-component';

interface CsvLoaderComponentProps {
  callbackAccept: (files: File[], callback: () => void, errorCallback: () => void,) => void;
  callbackError: (rejectFiles: File[]) => void;
  isAllowMulti: boolean;
  callbackCsvFile: () => void;
  files: File[],
}

interface CsvLoaderComponentState {
  callbackAccept: (files: File[], callback: () => void, errorCallback: () => void) => void;
  callbackError: (rejectFiles: File[]) => void;
  isAllowMulti: boolean;
}

export class CsvLoaderComponent extends React.Component<CsvLoaderComponentProps, CsvLoaderComponentState> {

  private accept: string = '.csv';

  constructor(props: CsvLoaderComponentProps) {
    super(props);
    this.state = {
      callbackAccept: props.callbackAccept,
      callbackError: props.callbackError,
      isAllowMulti: props.isAllowMulti,
    };
  }

  render() {
    const callbackAccept = this.state.callbackAccept;
    const callbackError = this.state.callbackError;
    const isAllowMulti = this.state.isAllowMulti;
    const callbackCsvFile = this.props.callbackCsvFile;
    return (
      <>
        <FileDnDComponent
          files={this.props.files}
          callbackAccept={(files: File[], callback: () => void, errorCallback) => callbackAccept(files, callback, errorCallback)}
          callbackError={(rejectFiles: File[]) => callbackError(rejectFiles)}
          isAllowMulti={isAllowMulti}
          accept={this.accept}
          callbackCsvFile={callbackCsvFile}
        />
      </>
    );
  }

}
