import * as React from 'react';
import { ReactElement } from 'react';
import * as Lodash from 'lodash';
import { DateFormatter } from '../../../util/date-formatter';
import { Article } from '../../../model/article';
import './article-release-component.scss';
import { HeaderComponent } from '../../common/header/header-component';
import { ModalService } from '../../../service/modal-service';
import { ConnectionService } from '../../../service/connection-service';
import { ErrorService } from '../../../service/error-service';
import { Prefecture } from '../../../model/prefecture';
import { City } from '../../../model/city';
import { User } from '../../../model/user';
import { Subdivision } from '../../../model/subdivision';
import { DataManagerService } from '../../../service/data-manager-service';
import { School } from '../../../model/school';
import { RequestArticlesGetOne } from '../../../model/api/request/request-articles-get-one';
import { ResponseArticlesGetOne } from '../../../model/api/response/response-articles-get-one';
import { ArticleInfo } from '../../../model/articleInfo';
import { RoutingPass } from '../../../constant/routing-pass';
import { RequestBase } from '../../../model/api/request/request-base';
import iconCheckBoxChecked from '../../../img/common/checkbox_checked.png';
import iconCheckBox from '../../../img/common/checkbox.png';
import { ModalUserPickerComponent } from '../article-detail/modal-user-picker/modal-user-picker-component';
import { ModalSubdivisionPickerComponent } from '../master-subdivision/modal-subdivision-picker/modal-subdivision-picker-component';
import thumbNoImage from '../../../img/detail/thumb_noimage.jpg';
import { MoneyFormatter } from '../../../util/money-formatter';
import { ArticleLinkedFile } from '../../../model/article-linked-file';
import { RequestArticlesLinked_fileGet } from '../../../model/api/request/request-articles-linked_file-get';
import { RequestArticlesPostPeriodValidation } from '../../../model/api/request/request-articles-post-period_validaiton';
import { ModalAlertComponent } from '../../common/modal/modal-alert/modal-alert-component';
import { ImgLoaderComponent } from '../article-detail/img-loader/img-loader-component';
import thumbPdf from '../../../img/detail/thumb_pdf.png';
import { connect } from 'react-redux';
import { DispatchToProps } from '../../../redux/store';
import { SystemActions } from '../../../redux/system/system.action';
import { push } from 'connected-react-router';

interface ArticleListComponentProps {
  match: {
    params: {
      id: string;
    };
  };
  refreshArticle: () => void;
  dispatchPush: (v: string) => void;
}

interface ArticleListComponentState {
  me: User | null;
  id: number | null;
  isBeforeLoad: boolean;
  article: any;
  //
  prefectureList: Prefecture[];
  cityList: City[];
  userList: User[];
  primarySchoolList: School[];
  middleSchoolList: School[];
  //
  infoList: ArticleInfo[];
  editedInfoList: ArticleInfo[];
  infoInput: string;
  //
  sales_month: number;
  //
  route: string, //最寄り路線
  station: string, //最寄り駅
  privateArea: string, //私道面積
  privateUnit: string, //私道坪数
  tsuboPrice: string, //坪単価
  traffic_route: string, //交通_路線
  traffic_station: string, //交通_駅
  traffic_minute: string, //交通_徒歩○分
  landRight: number, //土地権利
  ground: number, //地目
  cityPlanning: string, //都市計画
  useDistrict: number, //土地用途
  coverageRatioInput: string; //建ぺい率
  floorAreaRatioInput: string; //容積率
  legalAuthorityInput: string; //法令n上の権限
  presentStatusInput: string; //現況
  approachSituationInput: string; //接道状況
  facilityInput: string; //設備
  remarksInput: string; //備考
  //
  imageList: ArticleLinkedFile[];
  subdivisionList: Subdivision[];
  periodMessage: string;
  pageCheck: boolean;
}

export class ArticleReleaseBodyComponent extends React.Component<ArticleListComponentProps, ArticleListComponentState> {

  private toolTipMsg: string = '追加したユーザのみ編集できます。';

  private defaultArticleData = new Article(
    null,
    '',
    null,
    null,
    null,
    '',
    null,
    null,
    null,
    null,
    null,
    '',
    null,
    null,
    null,
    null,
    null,
    null,
    '',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null
  );

  private useDistrictList: { value: number, label: string; }[] =
    [
      { value: 100, label: '無指定' },
      { value: 1, label: '1種低層' },
      { value: 2, label: '2種低層' },
      { value: 3, label: '1種中高層' },
      { value: 4, label: '2種中高層' },
      { value: 5, label: '1種住居' },
      { value: 6, label: '2種住居' },
      { value: 7, label: '準住居' },
      { value: 8, label: '近隣商業' },
      { value: 9, label: '準工業' },
      { value: 10, label: '工業' },
      { value: 11, label: '工業専用' },
    ];

  private landRightList: { value: number, label: string; }[] = [
    { value: 1, label: '所有権' },
    { value: 2, label: '旧法地上権' },
    { value: 3, label: '旧法賃借権' },
    { value: 4, label: '普通地上権' },
    { value: 5, label: '普通賃借権' },
    { value: 6, label: '定期借地権（地上権）' },
    { value: 7, label: '定期借地権（賃借権）' },
  ];

  private salesStatusList: { value: number, label: string; }[] = [
    { value: 1, label: '買付中' },
    { value: 2, label: '契約' },
    { value: 3, label: '契約済み' },
    { value: 4, label: '申請中' },
    { value: 5, label: '造成中' },
    { value: 6, label: '販売終了' },
    { value: 7, label: '販売中' },
  ];

  private typeList: { value: number, label: string; }[] = [
    { value: 1, label: '売地' },
    { value: 2, label: '建売' },
    { value: 3, label: '分譲' },
  ];

  private landCategoryList: { value: number, label: string; }[] = [
    { value: 1, label: '宅地' },
    { value: 2, label: '田' },
    { value: 3, label: '畑' },
  ];

  //REVIEW 現状画像枚数10枚まで増やしたので、合わせて12個設定
  private loadedFileList: File[] | (string | null)[] = [null, null, null, null, null, null, null, null, null, null, null, null];

  constructor(props: ArticleListComponentProps) {
    super(props);
    this.state = {
      me: null,
      id: null,
      isBeforeLoad: true,
      article: this.defaultArticleData, // デフォルトデータ
      //
      prefectureList: [],
      cityList: [],
      userList: [],
      primarySchoolList: [],
      middleSchoolList: [],
      subdivisionList: [],
      //
      infoList: [],
      editedInfoList: [],
      infoInput: '',
      sales_month: 0,
      //
      route: '',
      station: '',
      privateArea: '',
      privateUnit: '',
      tsuboPrice: '',
      traffic_route: '',
      traffic_station: '',
      traffic_minute: '',
      landRight: 0,
      ground: 0,
      cityPlanning: '',
      useDistrict: 0,
      coverageRatioInput: '',
      floorAreaRatioInput: '',
      legalAuthorityInput: '',
      presentStatusInput: '',
      approachSituationInput: '',
      facilityInput: '',
      remarksInput: '',
      //
      imageList: [],
      periodMessage: '',
      pageCheck: false,
    };
  }

  render() {
    const me = this.state.me;
    const isBeforeLoad: boolean = this.state.isBeforeLoad;
    const article: Article = this.state.article;
    const prefectureList = this.state.prefectureList;
    const cityList = this.state.cityList;
    const userList = this.state.userList;
    const primarySchoolList = this.state.primarySchoolList;
    const middleSchoolList = this.state.middleSchoolList;
    const subdivisionList = this.state.subdivisionList;
    const infoList: ArticleInfo[] = this.state.infoList;
    const editedInfoList: ArticleInfo[] = this.state.editedInfoList;
    const infoInput: string = this.state.infoInput;
    const periodMessage: string = this.state.periodMessage;
    const pageCheck: boolean = this.state.pageCheck;
    // table body
    const tableBody = this.renderTableBodyDefault();
    return (
      <div className={''}>
        {/** RealEstateList */}
        {/* --- header --- */}
        <HeaderComponent />

        <div className={'body_wrap flex_box'}>

          <div className={`content_wrap cont_article_detail`}>
            <div className={'contentInner_wrap'}>

              {/* ------------------------------------------------------------- */}

              {/* タイトル */}
              <div className={'contentHead_wrap'}>
                <div className={'contentHeadInner_wrap flex_box flex_align_center flex_space_between'}>
                  <div className={'contentHead_text flex_box flex_align_center'}>
                    <span>分譲地詳細ページ</span>
                  </div>
                </div>
              </div>
              {/** contentHead_wrap */}

              {/* ------------------------------------------------------------- */}
              <div className={'divide30'} />
              {periodMessage && +this.salesStatusList !== 6 ? (
                <>
                  <h3>{periodMessage}</h3>
                  <div className={'divide30'} />
                  {/*<button*/}
                  {/*  className={'reset btnStyle_1 marg_auto btn_toReservation disable'}*/}
                  {/*>*/}
                  {/*  予約フォームへ*/}
                  {/*</button>*/}
                </>
              ) : pageCheck ? (
                <>
                  <h3>このページは存在しません</h3>
                </>
              ) : (
                <>
                  <button
                    className={'reset btnStyle_1 marg_auto btn_toReservation'}
                    onClick={() => {
                      const id = parseInt(this.props['match']['params']['id'], 10);
                      this.props.dispatchPush(RoutingPass.ARTICLE_RESERVATION + '/' + id);
                      // this.props['history'].push(RoutingPass.ARTICLE_RESERVATION + '/' + id);
                    }}
                  >
                    予約フォームへ
                  </button>
                </>
              )}

              <div className={'divide30'} />

              <div className={'tableArea'}>

                {/* 表 */}
                {/** テーブルのボディ */}
                {periodMessage && +this.salesStatusList !== 6 ? (
                  <></>
                ) : (
                  <>
                    <div className={'tableInner_wrap'}>
                      {tableBody}
                    </div>
                    {/** table_wrap */}
                  </>
                )}
              </div>
              {/** tableArea */}
            </div>
            {/** contentInner_wrap */}
          </div>
          {/** content_wrap */}
        </div>
        {/** body_wrap */}
      </div>
    );
  }

  private renderTableBodyDefault(): ReactElement<any> {
    const me = this.state.me;
    const isBeforeLoad: boolean = this.state.isBeforeLoad;
    const article: Article = this.state.article;
    const prefectureList = this.state.prefectureList;
    const cityList = this.state.cityList;
    const userList = this.state.userList;
    const primarySchoolList = this.state.primarySchoolList;
    const middleSchoolList = this.state.middleSchoolList;
    const subdivisionList = this.state.subdivisionList;
    const infoList: ArticleInfo[] = this.state.infoList;
    const editedInfoList: ArticleInfo[] = this.state.editedInfoList;
    const infoInput: string = this.state.infoInput;
    const {
      route,
      station,
      privateArea,
      privateUnit,
      tsuboPrice,
      traffic_route,
      traffic_station,
      traffic_minute,
      landRight,
      ground,
      cityPlanning,
      useDistrict,
      coverageRatioInput,
      floorAreaRatioInput,
      legalAuthorityInput,
      presentStatusInput,
      approachSituationInput,
      facilityInput,
      remarksInput,
      imageList,
    } = this.state;
    // 現状写真1~10
    const imgUrlList: string[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(v => this.getLinkedFileUrl(v as any, imageList));
    // 地図
    const img11: string = this.getLinkedFileUrl(11, this.state.imageList);
    const pdf: string = this.getLinkedFileUrl(12, this.state.imageList);
    const pdfFileName = this.state.imageList.find((v) => +v.article_file_type === 12) ?
      this.state.imageList.find((v) => +v.article_file_type === 12)?.name || '' : '';
    return (
      <>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 最寄駅 */}
          <div className={'padd_t_adjust'}>
            現在の予約人数
          </div>
          <div>
            {+(article.sales_status ?? '') === 6 ? (
              `現在販売終了しております。`
            ) : (
              <>
                現在、{article.reserve_count}件のご予約を頂いております。
              </>
            )}
          </div>
        </div>

        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 種別 */}
          <div className={'padd_t_adjust'}>
            種別
          </div>
          <div className={'item_type'}>
            <div className={'flex_box flex_align_center'}>
              {this.typeList.find((v) => +v.value === +(article.type ?? '')) ? (
                <>
                  {Number(article.type) === 3 ? (
                    <>
                      <div className={''}>{this.typeList.find((v) => +v.value === +(article.type ?? ''))?.label}</div>
                    </>
                  ) : (this.typeList.find((v) => +v.value === +(article.type ?? ''))?.label)}
                </>
              ) : ''}
            </div>
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 都道府県 */}
          <div className={'padd_t_adjust'}>
            都道府県
          </div>
          <div>
            {prefectureList.find((v) => +v.id === +(article.prefecture_id ?? '')) ? (
              prefectureList.find((v) => +v.id === +(article.prefecture_id ?? ''))?.name
            ) : (
              ''
            )}
          </div>
          {/* 市区町村 */}
          <div className={'padd_t_adjust'}>
            市区町村
          </div>
          <div>
            {article.city}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 住所 */}
          <div className={'padd_t_adjust'}>
            住所
          </div>
          <div>
            {article.address}
          </div>
        </div>

        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 最寄駅 */}
          <div className={'padd_t_adjust'}>
            最寄駅
          </div>
          <div>
            {route}線&nbsp;{station}駅
          </div>
        </div>

        {/** ------------------------------------------------------------------ */}
        {/*現状写真*/}
        <>
          {imgUrlList.filter((v, i) => !!v).map((v, i, arr) => (
            i % 2 ? <div key={`${v}_${i}`} /> : (
              <div className={'flex_box sp_flex_container'} key={`${v}_${i}`}>
                <div className={'padd_t_adjust'}>
                  現状写真{i + 1}
                </div>
                <div className={'displayImage_wrap flex_box flex_align_center flex_content_center'}>
                  <img
                    src={v || thumbNoImage}
                    className={'marg_auto'}
                    alt={''}
                  />
                </div>
                <div className={'padd_t_adjust'}>
                  {arr[i + 1] ? (
                    <>
                      現状写真{i + 2}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className={'displayImage_wrap flex_box flex_align_center flex_content_center'}>
                  {arr[i + 1] ? (
                    <img
                      src={arr[i + 1] || thumbNoImage}
                      className={'marg_auto'}
                      alt={''}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            )
          ))}
        </>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 地図画像 */}
          <div className={'padd_t_adjust'}>
            地図画像
          </div>
          <div className={'displayImage_wrap flex_box flex_align_center flex_content_center'}>
            <img
              src={img11 || thumbNoImage}
              className={'marg_auto'}
              alt={''}
            />
          </div>
          {/* 区画図PDF */}
          <div className={'padd_t_adjust'}>
            区画図PDF
          </div>
          <div className={'displayImage_wrap flex_box flex_align_center flex_content_center'}>
            <>
              {pdf ? (
                <div className={'pdf_wrap'}>
                  <div className={'align_c'}>{pdfFileName}</div>
                  <div className={'divide20'} />
                  <button
                    className={'reset flex_box flex_align_center flex_content_center'}
                    // onClick={() => this.handlerClickBtnOpenPdf('http://sv1.marietta.co.jp/_users/tenryu/Demo/sample.pdf')}
                    onClick={() => this.handlerClickBtnOpenPdf(pdf)}
                  >
                    <img
                      src={thumbPdf}
                      alt={''}
                    />
                    &nbsp;PDFを開く
                  </button>
                </div>
              ) : (
                <div>区画図は登録されていません。</div>
              )}
            </>
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 価格 */}
          <div className={'padd_t_adjust'}>
            価格
          </div>
          <div>
            {MoneyFormatter.thousandSeparate(article['price'] ?? NaN)}&nbsp;円
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 面積 */}
          <div className={'padd_t_adjust'}>
            土地面積
          </div>
          <div>
            {article.area_measuresize} &nbsp;&#13217;
          </div>
          {/* 坪数 */}
          <div className={'padd_t_adjust'}>
            土地坪数
          </div>
          <div>
            {article.tsubo}&nbsp;坪
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 私道面積 */}
          <div className={'padd_t_adjust'}>
            私道面積
          </div>
          <div>
            {privateArea} &nbsp;&#13217;
          </div>
          {/* 私道坪数 */}
          <div className={'padd_t_adjust'}>
            私道坪数
          </div>
          <div>
            {privateUnit}&nbsp;坪
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 坪単価 */}
          <div className={'padd_t_adjust'}>
            坪単価
          </div>
          <div>
            {tsuboPrice}万円&nbsp;/&nbsp;坪
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 交通 */}
          <div className={'padd_t_adjust'}>
            交通
          </div>
          <div>
            {traffic_route}線&nbsp;{traffic_station}駅&nbsp;徒歩{traffic_minute}分
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 土地権利 */}
          <div className={'padd_t_adjust'}>
            土地権利
          </div>
          <div>
            {this.landRightList.find((v) => +v.value === +landRight) ?
              this.landRightList.find((v) => +v.value === +landRight)?.label : '未選択'}
          </div>
          {/* 地目 */}
          <div className={'padd_t_adjust'}>
            地目
          </div>
          <div>
            {this.landCategoryList.find((v) => +v.value === +ground) ?
              this.landCategoryList.find((v) => +v.value === +ground)?.label : '未選択'}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 都市計画 */}
          <div className={'padd_t_adjust'}>
            都市計画
          </div>
          <div>
            {cityPlanning || ''}
          </div>
          {/* 用途地域 */}
          <div className={'padd_t_adjust'}>
            用途地域
          </div>
          <div>
            {this.useDistrictList.find((v) => +v.value === +useDistrict) ?
              this.useDistrictList.find((v) => +v.value === +useDistrict)?.label : '未選択'}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 建ぺい率 */}
          <div className={'padd_t_adjust'}>
            建ぺい率
          </div>
          <div>
            {coverageRatioInput}{coverageRatioInput ? ' %' : ''}
          </div>
          {/* 容積率 */}
          <div className={'padd_t_adjust'}>
            容積率
          </div>
          <div>
            {floorAreaRatioInput}{floorAreaRatioInput ? ' %' : ''}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 法令上の制限 */}
          <div className={'padd_t_adjust'}>
            法令上の制限
          </div>
          <div>
            {legalAuthorityInput}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 現況 */}
          <div className={'padd_t_adjust'}>
            現況
          </div>
          <div>
            {presentStatusInput}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 接道状況 */}
          <div className={'padd_t_adjust'}>
            接道状況
          </div>
          <div>
            {approachSituationInput}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 設備 */}
          <div className={'padd_t_adjust'}>
            設備
          </div>
          <div>
            {facilityInput}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 備考 */}
          <div className={'padd_t_adjust'}>
            備考
          </div>
          <div>
            {remarksInput}
          </div>
        </div>
      </>
    );
  }

  private renderTableBodyIntroduced(): ReactElement<any> {
    const me = this.state.me;
    const isBeforeLoad: boolean = this.state.isBeforeLoad;
    const article: Article = this.state.article;
    const prefectureList = this.state.prefectureList;
    const cityList = this.state.cityList;
    const userList = this.state.userList;
    const primarySchoolList = this.state.primarySchoolList;
    const middleSchoolList = this.state.middleSchoolList;
    const infoList: ArticleInfo[] = this.state.infoList;
    const editedInfoList: ArticleInfo[] = this.state.editedInfoList;
    const infoInput: string = this.state.infoInput;
    return (
      <>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 都道府県 */}
          <div className={'padd_t_adjust'}>
            都道府県
          </div>
          <div>
            {prefectureList.find((v) => +v.id === +(article.prefecture_id ?? '')) ? (
              prefectureList.find((v) => +v.id === +(article.prefecture_id ?? ''))?.name
            ) : (
              ''
            )}
          </div>
          {/* 市区町村 */}
          <div className={'padd_t_adjust'}>
            市区町村
          </div>
          <div>
            {article.city}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 住所 */}
          <div className={'padd_t_adjust'}>
            住所
          </div>
          <div>
            {article.address}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 営業担当者名 */}
          <div className={'padd_t_adjust'}>
            営業担当者名
          </div>
          <div className={'flex_box flex_align_center withButton_wrap'}>
            {userList.find((v) => +v.id === +(article.user_id ?? '')) ? (
              userList.find((v) => +v.id === +(article.user_id ?? ''))?.fullname['value']
            ) : (
              ''
            )}
          </div>
          {/* 坪数 */}
          <div className={'padd_t_adjust'}>
            坪数
          </div>
          <div>
            {article['tsubo']}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 価格 */}
          <div className={'padd_t_adjust'}>
            価格
          </div>
          <div className={'flex_box flex_align_center'}>
            {MoneyFormatter.thousandSeparate(article['price'] ?? NaN)}&nbsp;円
          </div>
          {/* 面積 */}
          <div className={'padd_t_adjust'}>
            面積
          </div>
          <div>
            {article.area_measuresize} &nbsp;&#13217;
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 地目 */}
          <div className={'padd_t_adjust'}>
            地目
          </div>
          <div>
            {this.landCategoryList.find((v) => +v.value === +(article['land_category'] ?? '')) ? (
              this.landCategoryList.find((v) => +v.value === +(article['land_category'] ?? ''))?.label
            ) : (
              ''
            )}
          </div>
          {/* 用途地域 */}
          <div className={'padd_t_adjust'}>
            用途地域
          </div>
          <div>
            {this.useDistrictList.find((v) => +v.value === +(article['use_district'] ?? '')) ? (
              this.useDistrictList.find((v) => +v.value === +(article['use_district'] ?? ''))?.label
            ) : (
              '未選択'
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 中学校*/}
          <div className={'padd_t_adjust'}>
            中学校
          </div>
          <div>
            {middleSchoolList.find((v) => +(v.id ?? '') === +(article.m_school_id ?? '')) ? (
              middleSchoolList.find((v) => +(v.id ?? '') === +(article.m_school_id ?? ''))?.name
            ) : (
              ''
            )}
          </div>
          {/* 小学校 */}
          <div className={'padd_t_adjust'}>
            小学校
          </div>
          <div>
            {primarySchoolList.find((v) => +(v.id ?? '') === +(article.p_school_id ?? '')) ? (
              primarySchoolList.find((v) => +(v.id ?? '') === +(article.p_school_id ?? ''))?.name
            ) : (
              ''
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 有効/無効 */}
          <div className={'padd_t_adjust'}>
            有効/無効
          </div>
          <div>
            {+(article['valid_flag'] ?? '') ? (
              <div className={'innerImg checked'}><img src={iconCheckBoxChecked} alt={''} /></div>
            ) : (
              <div className={'innerImg noCheck'}><img src={iconCheckBox} alt={''} /></div>
            )}
          </div>
        </div>
      </>
    );
  }
  componentDidMount(): void {
    window.scrollTo(0, 0);
    this.setState({
      editedInfoList: [],
    });
    this.urlIsNotValid();
  }

  async componentWillReceiveProps(nextProps: Readonly<ArticleListComponentProps>, nextContext: any): Promise<void> {
    this.urlIsNotValid();
  }

  //ページが存在しない&期限切れの場合、アラート
  private urlIsNotValid(): void {
    const id = parseInt(this.props['match']['params']['id'], 10);
    const send = {
      article_id: id
    };
    const request: RequestArticlesPostPeriodValidation = new RequestArticlesPostPeriodValidation(
      send.article_id,
    );
    ConnectionService.ins.connect(request)
      .then((res) => {
        if (res.data['status'] === 0) {
          this.setState({
            periodMessage: res.data['message'],
          });
          ModalService.ins.push(
            <ModalAlertComponent
              msgList={[res.data['message']]}
              callback={() => {
                ModalService.ins.close();
              }}
              hideBtn={true}
            />
          );
        } else {
          this.getDetail(id);
        }
      })
      .catch((err) => {
        this.setState({
          pageCheck: true,
        });
        ErrorService.ins.response(err);
      });
  }

  // private handlerClickBtnSubdivisionPicker(callback: (subdivisionId: number) => void): void {
  //   ModalService.ins.display(
  //     <ModalSubdivisionPickerComponent callback={callback} />
  //   );
  // }

  private handlerClickBtnUserPicker(callback: (userId: number) => void): void {
    ModalService.ins.display(
      <ModalUserPickerComponent callback={callback} />
    );
  }

  // private handlerChangeInputEdit(e, v, editedInfoList): void {
  //   let editedInfo: ArticleInfo = editedInfoList.find((w) => w.id === v.id);
  //   if (editedInfo) {
  //     editedInfo.item_content = e.target.value;
  //     this.setState({ editedInfoList: editedInfoList });
  //     return;
  //   }
  //   editedInfo = Lodash.cloneDeep(v);
  //   editedInfo.item_content = e.target.value;
  //   editedInfoList.push(editedInfo);
  //   this.setState({ editedInfoList: editedInfoList });
  // }

  private getDetail(id: number): void {
    const request: RequestBase = new RequestArticlesGetOne(id);
    ConnectionService.ins.connect(request)
      .then(async (res: ResponseArticlesGetOne | any) => {
        const article =
          new Article(
            res.data.id,
            res.data.name,
            res.data.sales_status,
            Boolean(+res.data.reservation_flag),
            res.data.type,
            res.data.building_name,
            res.data.tsubo,
            res.data.price,
            res.data.prefecture_id,
            res.data['city']['name'],
            res.data.city_id,
            res.data.address,
            res.data.area_measuresize,
            res.data.purchasing_manager_id,
            res.data.user_id,
            DateFormatter.str2date(res.data.build_datetime ? res.data.build_datetime + '' : ''),
            DateFormatter.str2date(res.data.purchase_datetime ? res.data.purchase_datetime + '' : ''),
            Boolean(+res.data.has_purchase_card),
            res.data.customer_name,
            DateFormatter.str2date(res.data.conclusion_datetime ? res.data.conclusion_datetime + '' : ''),
            res.data.mschool_id,
            res.data.pschool_id,
            res.data.subdivision_id,
            res.data.period_start,
            res.data.period_end,
            res.data.message,
            res.data.email,
            res.data.reserve_count,
            res.data.area_count,
            res.data.area_max
          );
        //画像取得処理実装
        const fileRequest: RequestArticlesLinked_fileGet = new RequestArticlesLinked_fileGet(id, 1);
        ConnectionService.ins.connect(fileRequest)
          .then(async (fileResponse) => {
            const imageList = fileResponse.data.list as ArticleLinkedFile[];
            // pdf のURL取得
            const fileRequest2: RequestArticlesLinked_fileGet = new RequestArticlesLinked_fileGet(id, 0);
            const fileResponse2 = await ConnectionService.ins.connect(fileRequest2);
            const pdf = fileResponse2.data.list.find((v: any) => Number(v.article_file_type) === 12) || null;
            if (pdf) {
              imageList.push(pdf);
            }
            // this.getInfoList(id);
            this.setState({
              id: id,
              isBeforeLoad: false,
              article: article,
              //
              route: res.data.route1,
              station: res.data.station1,
              traffic_route: res.data.route2,
              traffic_station: res.data.station2,
              traffic_minute: res.data.walk_time,
              privateArea: res.data.private_road_measuresize,
              privateUnit: res.data.private_road_tsubo,
              tsuboPrice: res.data.tsubo_unit,
              landRight: res.data.ownership || 0,
              ground: res.data.land_category || 0,
              cityPlanning: res.data.city_planning,
              useDistrict: res.data.use_district || 0,
              coverageRatioInput: res.data.coverage,
              floorAreaRatioInput: res.data.floor_area,
              legalAuthorityInput: res.data.law_limitation,
              presentStatusInput: res.data.current_state,
              approachSituationInput: res.data.connecting_roads,
              facilityInput: res.data.equipment,
              remarksInput: res.data.remarks,
              imageList: fileResponse.data.list as ArticleLinkedFile[],
            });
            this.refreshList(article);
          })
          .catch((err) => {
            ErrorService.ins.response(err);
          });
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }

  private refreshList(article: any): void {
    DataManagerService.ins.getPrefectureList()
      .then((v) => {
        this.setState({ prefectureList: v });
        DataManagerService.ins.getCityList(article.prefecture_id)
          .then((v) => {
            this.setState({ cityList: v });
          })
          .catch((v) => {
            this.setState({ cityList: v });
          });
      })
      .catch((v) => {
        this.setState({ prefectureList: v });
      });
    DataManagerService.ins.getMiddleSchoolList(article.prefecture_id, article.city_id)
      .then((v) => {
        this.setState({ middleSchoolList: v });
      })
      .catch((v) => {
        this.setState({ middleSchoolList: v });
      });
    DataManagerService.ins.getPrimarySchoolList(article.prefecture_id, article.city_id)
      .then((v) => {
        this.setState({ primarySchoolList: v });
      })
      .catch((v) => {
        this.setState({ primarySchoolList: v });
      });
    DataManagerService.ins.getSubdivisionList()
      .then((v) => {
        this.setState({ subdivisionList: v });
      })
      .catch((v) => {
        this.setState({ subdivisionList: v });
      });
  }

  /**
   * 現状写真とかのURL生成処理
   * @param {1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 11 | 12} index
   * @param {ArticleLinkedFile[]} linkedFileList
   * @return {string}
   */
  private getLinkedFileUrl(index: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12, linkedFileList: ArticleLinkedFile[]): string {
    const origin: string = window.location.origin === 'http://localhost:3000' ? 'https://rms.marietta.co.jp' : window.location.origin;
    const dir: string = '/contents/';
    const fileName: string = linkedFileList.find((v) => +v.article_file_type === index) ?
      linkedFileList.find((v) => +(v.article_file_type ?? '') === index)?.saved_as || '' : '';
    return fileName ? `${origin}${dir}${fileName}` : '';
  }

  private convertFile2Base64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const fr: FileReader = new FileReader();
      fr.onload = (e) => {
        resolve(e.target?.result as any);
      };
      fr.readAsDataURL(file);
    });
  }

  /**
   * pdf 開くボタンのハンドラ
   * @param {string} src
   */
  private handlerClickBtnOpenPdf(src: string): void {
    ConnectionService.ins.getFileFromUrl(src)
      .then((v) => {
        window.open(URL.createObjectURL(v));
      });
  }

}

const mapDispatchToProps: DispatchToProps = (dispatch) => ({
  dispatchPush: (v: string) => dispatch(push(v)),
});

export const ArticleReleaseComponent = connect(() => ({}), mapDispatchToProps)(ArticleReleaseBodyComponent);