import {HTTPMethod, RequestBase} from './request-base';

export class RequestGetVersion extends RequestBase {

  constructor() {
    super(HTTPMethod.GET, '/version.json');
  }

  getParam(): any {
  }

  getUrl(): string {
    return this.host + this.endPoint;
  }

}
