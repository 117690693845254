import * as React from 'react';
import thumbPdf2 from '../../../../img/detail/thumb_pdf2.png';
import { ImgRotater } from '../../../../util/img-rotater';
import { ModalService } from '../../../../service/modal-service';
import { ModalAlertComponent } from '../../../common/modal/modal-alert/modal-alert-component';

interface ImgLoaderComponentProps {
  src?: string;
  isPdfOnly?: boolean;
  pdfFileName?: string;
  callback: (file: File) => void,
  callbackDell: () => void,
}

interface ImgLoaderComponentState {
  src?: string;
  pdfFileName: string;
}

export class ImgLoaderComponent extends React.Component<ImgLoaderComponentProps, ImgLoaderComponentState> {

  private acceptFileType: string[] = [
    'image/png',
    'image/jpeg',
  ];

  private acceptFileTypePdf: string[] = [
    'application/pdf',
  ];

  constructor(props: ImgLoaderComponentProps) {
    super(props);
    this.state = {
      src: props.src,
      pdfFileName: props.pdfFileName || '',
    };
  }

  render() {
    const { isPdfOnly } = this.props;
    const {
      src,
      pdfFileName,
    } = this.state;
    return (
      src ? (
        <>
          {isPdfOnly ? (
            <div className={'editImage_wrap editPdf'}>
              <div className={'editPdfInner flex_box flex_align_center flex_content_center'}>
                <div>
                  <img
                    src={thumbPdf2}
                    className={'marg_auto'}
                    alt={''}
                  />
                  <div className={'divide10'} />
                  <div>{pdfFileName}</div>
                </div>
              </div>

              <div className={'divide10'} />

              <div className={'flex_box flex_align_center'}>
                <div className={'selectImageBtn_wrap align_r'}>
                  <button
                    className={'reset selectImageBtn'}
                    onClick={() => this.handlerClickBtnChoseFile()}
                  >
                    再選択
                  </button>
                </div>
                <div className={'selectImageBtn_wrap'}>
                  <button
                    className={'reset selectedImageDeleteBtn'}
                    onClick={() => this.handlerClickBtnDeleteFile()}
                  >
                    削除
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className={'editImage_wrap'}>
              <div className={'editImageInne_wrap flex_box flex_align_center'}>
                <img
                  src={src}
                  className={'marg_auto'}
                  alt={''}
                />
              </div>

              <div className={'divide10'} />

              <div className={'flex_box flex_align_center'}>
                <div className={'selectImageBtn_wrap align_r'}>
                  <button
                    className={'reset selectImageBtn'}
                    onClick={() => this.handlerClickBtnChoseFile()}
                  >
                    再選択
                  </button>
                </div>
                <div className={'selectImageBtn_wrap'}>
                  <button
                    className={'reset selectedImageDeleteBtn'}
                    onClick={() => this.handlerClickBtnDeleteFile()}
                  >
                    削除
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div className={'addFile_wrap'}>
            <div
              className={'addFileArea_wrap flex_box flex_align_center flex_content_center'}
              onDragOver={(e) => this.handlerDragOverFile(e)}
              onDrop={(e) => this.handlerDropFile(e)}
            >
              <div>
                <div className={'align_c font_b'}>ファイルをドラッグ&ドロップ</div>
                <div className={'divide10'} />
                <div className={'align_c font_b'}>または</div>
                <div className={'divide10'} />
                <button
                  className={'reset marg_auto'}
                  onClick={() => this.handlerClickBtnChoseFile()}
                >
                  ファイルを選択
                </button>
              </div>
            </div>
          </div>
        </>
      )
    );
  }

  /**
   * ファイルドラッグオーバー時のハンドラ
   * ブラウザがファイルをオープンしないために必要
   * @param {React.DragEvent} e
   */
  private handlerDragOverFile(e: React.DragEvent): void {
    e.preventDefault();
  }

  /**
   * ファイルのドロップ時のハンドラ
   * @param {React.DragEvent} e
   */
  private handlerDropFile(e: React.DragEvent): void {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    ImgRotater.rotate(file)
      .then((rotatedFile) => {
        this.props.callback(rotatedFile);
        this.getBlobPath(rotatedFile)
          .then((path: string) => {
            // 選択完了時の処理
            this.setState({
              src: path,
              pdfFileName: file.name,
            });
          })
          .catch((fileName) => {
            ModalService.ins.push(
              <ModalAlertComponent
                msgList={[fileName, '対応していない拡張子です']}
                callback={() => {
                  ModalService.ins.close();
                }}
              />
            );
            return;
          });
      });
  }

  /**
   * ファイル選択ボタン押下時のハンドラ
   */
  private handlerClickBtnChoseFile(): void {
    const acceptFileType: string[] = this.props.isPdfOnly ? this.acceptFileTypePdf : this.acceptFileType;
    const inputElement: HTMLInputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = acceptFileType.reduce((prevValue: string, currentValue: string) => prevValue += `${currentValue}, `, '');
    inputElement.className = 'hideEle';
    inputElement.onchange = (e: any) => {
      const file: File = e.target.files[0];
      ImgRotater.rotate(file)
        .then((rotatedFile) => {
          this.props.callback(rotatedFile);
          this.getBlobPath(rotatedFile)
            .then((path: string) => {
              // 選択完了時の処理
              this.setState({
                src: path,
                pdfFileName: file.name,
              });
            });
        });
    };
    document.body.appendChild(inputElement);
    inputElement.value = '';
    (window as any)['_tmp'] = inputElement;
    inputElement.click();
    document.body.removeChild(inputElement);
  }

  /**
   * ファイル削除ボタン押下時のハンドラ
   */
  private handlerClickBtnDeleteFile(): void {
    // 削除処理
    this.setState({
      src: '',
      pdfFileName: '',
    });
    this.props.callbackDell();
  }

  /**
   * Fileオブジェクトのパス取得メソッド
   * @param {File} file
   * @return {Promise<string>}
   */
  private getBlobPath(file: File): Promise<string> {
    const acceptFileType: string[] = this.props.isPdfOnly ? this.acceptFileTypePdf : this.acceptFileType;
    return new Promise<string>((resolve, reject) => {
      if (acceptFileType.findIndex((v) => v === file.type) === -1) {
        reject(file.name);
        return;
      }
      const reader: FileReader = new FileReader();
      reader.onload = (readerEvent) => {
        resolve(readerEvent.target?.result ? readerEvent.target.result as string : '');
      };
      reader.onerror = () => {
        reject();
      };
      reader.readAsDataURL(file);
    });
  }

}
