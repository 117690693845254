import * as React from 'react';
import { UserAgentService } from '../../../service/user-agent-service';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DateFormat, DateFormatter } from '../../../util/date-formatter';
import * as ja from 'date-fns/locale/ja';

interface DatePickerComponentProps {
  min: Date | null;
  max: Date | null;
  current: Date | null;
  onChange: (date: Date | null) => void;
}


export const DatePickerComponent = (props: DatePickerComponentProps) => {
  const {
    min,
    max,
    current,
    onChange,
  } = props;

  (ja as any).options.weekStartsOn = 0;
  registerLocale('ja', ja);

  const isMobile = UserAgentService.ins.isMobile();


  return (
    isMobile ? (
      <div className={'sp_dataPicker_wrap flex_box flex_align_center'}>
        <div>
          <input
            type={'date'}
            className={'textInput'}
            min={min ? DateFormatter.date2str(min, DateFormat.YYYYMMDD, '-') : ''}
            max={max ? DateFormatter.date2str(max, DateFormat.YYYYMMDD, '-') : ''}
            value={current ? DateFormatter.date2str(current, DateFormat.YYYYMMDD, '-') : ''}
            onChange={(e) => {
              if (e.target.value) {
                const date: Date = new Date(e.target.value);
                if (min && date < min) {
                  onChange(min);
                } else if (max && date > max) {
                  onChange(max);
                } else {
                  onChange(date);
                }
              } else {
                onChange(null);
              }
            }}
          />
        </div>
        <div><button className={'reset'} onClick={() => onChange(null)}>&#10005;</button></div>
      </div>
    ) : (
      <>
        <DatePicker
          selected={current}
          minDate={min}
          maxDate={max}
          onChange={(date: Date) => onChange(date)}
          dateFormat={'yyyy/MM/dd'}
          isClearable={true}
          disabledKeyboardNavigation
          locale='ja'
          dateFormatCalendar={'yyyy MMM'}
        />
      </>
    )
  );
};







// export class DatePickerComponent extends React.Component<DatePickerComponentProps, DatePickerComponentState> {

//   constructor(props: DatePickerComponentProps) {
//     super(props);
//     this.state = {
//       min: props.min,
//       max: props.max,
//       current: props.current,
//       onChange: props.onChange,
//       isMobile: UserAgentService.ins.isMobile(),
//     };
//     (ja as any).options.weekStartsOn = 0;
//     registerLocale('ja', ja);
//   }

//   render() {
//     const min: Date = this.state.min;
//     const max: Date = this.state.max;
//     const current: Date = this.state.current;
//     const onChange: (date: Date | null) => void = this.state.onChange;
//     const isMobile: boolean = this.state.isMobile;
//     return (
//       isMobile ? (
//         <div className={'sp_dataPicker_wrap flex_box flex_align_center'}>
//           <div>
//             <input
//               type={'date'}
//               className={'textInput'}
//               min={min ? DateFormatter.date2str(min, DateFormat.YYYYMMDD, '-') : ''}
//               max={max ? DateFormatter.date2str(max, DateFormat.YYYYMMDD, '-') : ''}
//               value={current ? DateFormatter.date2str(current, DateFormat.YYYYMMDD, '-') : ''}
//               onChange={(e) => {
//                 if (e.target.value) {
//                   const date: Date = new Date(e.target.value);
//                   if (min && date < min) {
//                     onChange(min);
//                   } else if (max && date > max) {
//                     onChange(max);
//                   } else {
//                     onChange(date);
//                   }
//                 } else {
//                   onChange(null);
//                 }
//               }}
//             />
//           </div>
//           <div><button className={'reset'} onClick={() => onChange(null)}>&#10005;</button></div>
//         </div>
//       ) : (
//         <>
//           <DatePicker
//             selected={current}
//             minDate={min}
//             maxDate={max}
//             onChange={(date: Date) => onChange(date)}
//             dateFormat={'yyyy/MM/dd'}
//             isClearable={true}
//             disabledKeyboardNavigation
//             locale='ja'
//             dateFormatCalendar={'yyyy MMM'}
//           />
//         </>
//       )
//     );
//   }

//   componentDidUpdate(nextProps: DatePickerComponentProps): void {
//     this.setState({
//       min: nextProps.min,
//       max: nextProps.max,
//       current: nextProps.current,
//       onChange: nextProps.onChange,
//       isMobile: UserAgentService.ins.isMobile(),
//     });
//   }

// }
