import {HTTPMethod, RequestBase} from './request-base';

export class RequestSubdivisionPost extends RequestBase {

  param: {
    id: number | null,
    name: string,
    name_kana: string,
    address: string,
    valid_flag: number,
    prefecture_id: number,
    city_id: number,
    area_max: number,
    reservation_count: number,
  };

  constructor(
    id: number | null,
    name: string,
    name_kana: string,
    address: string,
    valid_flag: number,
    prefecture_id: number,
    city_id: number,
    area_max: number,
    reservation_count: number,
  ) {
    super(HTTPMethod.POST, '/api/subdivisions');
    this.param = {
      id: id,
      name: name,
      name_kana: name_kana,
      address: address,
      valid_flag: valid_flag,
      prefecture_id: prefecture_id,
      city_id: city_id,
      area_max: area_max,
      reservation_count: reservation_count,
    };
  }

  // getParam(): FormData {
  //   const formData: FormData = new FormData();
  //   Object.keys(this.param).forEach((v) => {
  //     /*if (v === 'id') {
  //       formData.append(v, '');
  //       return;
  //     }*/
  //     if (this.param[v] === null) {
  //       formData.append(v, '');
  //       return;
  //     }
  //     formData.append(v, this.param[v] + '');
  //   });
  //   formData.append('reservation_flag', '');
  //   return formData;
  // }

  getUrl(): string {
    return this.host + this.endPoint;
  }

}
