
export class User {

  constructor(
    public id: number ,
    public store_id: {value: number},
    public email: string,
    public fullname: {value: string} = {value: ''},
    public last_name: {value: string} = {value: ''},
    public first_name: {value: string} = {value: ''},
    public group_id: number,
    public valid_flag: number,
  ) {}

}
