import {HTTPMethod, RequestBase} from './request-base';

export class RequestSubdivisionsLinkedFileGet extends RequestBase {

  param: {
    subdivision_id: number,
    publish_flag?: number,
  };

  constructor(
    subdivision_id: number,
    publish_flag?: number,
  ) {
    super(HTTPMethod.GET, '/api/subdivisions/linked_file/');
    this.param = {
      subdivision_id: subdivision_id,
      publish_flag: publish_flag,
    };
  }

  // getParam(): string {
  //   let param: string = '?';
  //   Object.keys(this.param).forEach((v) => {
  //     if (this.param[v] === null) {
  //       return;
  //     }
  //     param += `${v}=${this.param[v]}&`;
  //   });
  //   return param;
  // }

  getUrl(): string {
    return this.host + this.endPoint + this.getParam();
  }

}
