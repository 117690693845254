import { HTTPMethod, RequestBase, NullPermission } from './request-base';

type Param = {
  file_list: string,
};

export class RequestQuestionnaireDownload extends RequestBase {
  responseType: string = 'blob';

  constructor(
    public param: Param
  ) {
    super(HTTPMethod.POST, '/api/subdivisionfiles/download/');
  }

  getUrl(): string {
    return this.host + this.endPoint;
  }
}