import './modal-config-component.scss';
import { ModalService } from '../../../../service/modal-service';
import { ModalAlertComponent } from '../../modal/modal-alert/modal-alert-component';
import { RequestUsersPostPassword } from '../../../../model/api/request/request-users-post-password';
import { ConnectionService } from '../../../../service/connection-service';
import { ErrorService } from '../../../../service/error-service';
import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { RoutingPass } from '../../../../constant/routing-pass';

interface ModalConfigComponentProps {
}

export const ModalConfigComponent = (props: ModalConfigComponentProps) => {
  const dispatch = useDispatch();

  const [inputStr, setInputStr] = useState('');
  const [inputStrConfirm, setInputStrConfirm] = useState('');

  const checkValidation = useCallback(() => {
    const msgList: string[] = [];
    if (!inputStr) {
      msgList.push('新しいパスワード が未入力です。');
    }
    if (!inputStrConfirm) {
      msgList.push('新しいパスワード (確認) が未入力です。');
    }
    if (msgList.length > 0) {
      return msgList;
    }
    if (inputStr !== inputStrConfirm) {
      msgList.push('入力されたパスワードが一致していません。');
    }
    return msgList;
  }, [inputStr, inputStrConfirm]
  );

  const handlerClickBtnCancel = () => {
    ModalService.ins.close();
  };

  const handlerClickBtnSubmit = () => {
    const errorMsgList: string[] = checkValidation();
    if (errorMsgList.length > 0) {
      ModalService.ins.push(
        <ModalAlertComponent
          msgList={errorMsgList}
          callback={() => {
            ModalService.ins.pop();
          }}
        />
      );
      return;
    }
    const request: RequestUsersPostPassword = new RequestUsersPostPassword(null, inputStr);
    ConnectionService.ins.connect(request)
      .then((res) => {
        ModalService.ins.push(
          <ModalAlertComponent
            msgList={[
              'パスワードを設定しました。',
              'ログイン画面に戻ります。'
            ]}
            callback={() => {
              ModalService.ins.close();
              dispatch(push(RoutingPass.LOGIN));
            }}
          />
        );
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  };

  return (
    <div className={'modalInnerBody_wrap settingModal_wrap'}>
      {/* タイトル */}
      <div>
        パスワード変更
      </div>

      <div className={'divide20'}></div>

      {/* body */}
      <div>
        <div>
          新しいパスワード
          <div className={'divide5'}></div>
          <input
            className={'textInput'}
            onChange={(e) => setInputStr(e.target.value)}
            type={'password'}
          />
        </div>
        <div className={'divide10'}></div>
        <div>
          新しいパスワード (確認)
          <div className={'divide5'}></div>
          <input
            className={'textInput'}
            onChange={(e) => setInputStrConfirm(e.target.value)}
            type={'password'}
          />
        </div>
      </div>

      <div className={'divide20'}></div>

      {/* ボタン */}
      <div className={'btn_wrap flex_box flex_align_center flex_space_between'}>
        <div>
          <button
            className={'reset btnStyle_1'}
            onClick={handlerClickBtnCancel}
          >キャンセル</button>
        </div>
        <div>
          <button
            className={'reset btnStyle_1'}
            onClick={handlerClickBtnSubmit}
          >変更</button>
        </div>
      </div>
    </div>
  );
};




