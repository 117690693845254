import * as React from 'react';
import './modal-questionnaire-edit-component.scss';
import { DatePickerComponent } from '../../../common/date-picker/date-picker-component';
import { ModalService } from '../../../../service/modal-service';
import { FileLoaderComponent } from '../../../common/file-loader/file-loader-component';
import { ModalQuestionnaireComponent } from '../modal-questionnaire/modal-questionnaire-component';
import { SubdivisionQuestionnaire } from '../../../../model/subdivision-questionnaire';
import { SubdivisionDetail } from '../../../../model/subdivision-detail';
import { ConnectionService } from '../../../../service/connection-service';
import { ModalAlertComponent } from '../../../common/modal/modal-alert/modal-alert-component';
import { ErrorService } from '../../../../service/error-service';
import { RequestSubdivisionsQuestionNairePost } from '../../../../model/api/request/request-subdivisions-question-naire-post';
import { DateFormat, DateFormatter } from '../../../../util/date-formatter';
import { RequestQuestionnaireUpload } from '../../../../model/api/request/request-questionnaire-upload';
import { ModalDialogComponent } from '../../../common/modal/modal-dialog/modal-dialog-component';
import { RequestQuestionnaireDelete } from '../../../../model/api/request/request-questionnaire-delete';
import { DataManagerService } from '../../../../service/data-manager-service';
import { User } from '../../../../model/user';
import { ModalUserPickerComponent } from '../../article-detail/modal-user-picker/modal-user-picker-component';
import iconSearch from '../../../../img/common/icon_search.png';
import iconSearchOn from '../../../../img/common/icon_search_on.png';

interface ModalQuestionnaireEditComponentProps {
  subdivision: SubdivisionDetail;
  questionnaire: SubdivisionQuestionnaire;
  callback: () => void;
}

interface ModalQuestionnaireEditComponentState {
  subdivision: SubdivisionDetail;
  questionnaire: SubdivisionQuestionnaire;
  file: File | null;
  userList: User[];
}

export class ModalQuestionnaireEditComponent extends React.Component<ModalQuestionnaireEditComponentProps, ModalQuestionnaireEditComponentState> {

  constructor(props: ModalQuestionnaireEditComponentProps) {
    super(props);
    this.state = {
      subdivision: props.subdivision,
      questionnaire: props.questionnaire,
      file: null,
      userList: []
    };
  }

  render() {
    const subdivision = this.state.subdivision;
    const questionnaire = this.state.questionnaire;
    const isNew: boolean = this.state.questionnaire.id === null;
    return (
      <div className={'modalInnerBody_wrap reservationEditModal_wrap'}>

        <div className={'table_wrap'}>

          <div className={'table_row'}>
            <div className={'table_cell'}>
              分譲地名
            </div>
            <div className={'table_cell'}>
              {subdivision.name}
            </div>
          </div>

          <div className={'table_row'}>
            <div className={'table_cell'}>
              申込日*
            </div>
            <div className={'table_cell reservationDateCell'}>
              <div className={'flex_box'}>
                <div>
                  <DatePickerComponent
                    // min={reserve.reserve_start || new Date()}
                    min={null}
                    max={null}
                    // current={reserve.reserve_start || null}
                    current={questionnaire.application_date}
                    onChange={(v) => {
                      questionnaire.application_date = v;
                      this.setState({ questionnaire: questionnaire });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={'table_row'}>
            <div className={'table_cell'}>
              顧客名*
            </div>
            <div className={'table_cell customerNameCell'}>
              <div className={'flex_box flex_align_center'}>
                <div>
                  <input
                    className={'textInput'}
                    value={questionnaire.customer_name}
                    onChange={(e) => {
                      questionnaire.customer_name = e.target.value;
                      this.setState({ questionnaire });
                    }}
                  />
                </div>
                <div>様</div>
              </div>
            </div>
          </div>

          <div className={'table_row'}>
            <div className={'table_cell'}>
              営業担当*
            </div>
            <div className={'table_cell customerNameCell'}>
              <div className={'flex_box flex_align_center withButton_wrap'}>
                <div>
                  <select
                    className={'textInput max_w_8'}
                    value={questionnaire.sales_staff || 0}
                    onChange={async (e) => {
                      questionnaire.sales_staff = Number(e.target.value) || null;
                      this.setState({
                        questionnaire: questionnaire,
                      });
                    }}
                  >
                    <option value={''}>未選択</option>
                    {this.state.userList.map((v) => (
                      <option value={v.id ?? ''} key={v.id}>{v.fullname['value']}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <button
                    className={'reset btnStyle_1'}
                    onClick={() => this.handlerClickBtnUserPicker((userId) => {
                      questionnaire.sales_staff = Number(userId) || null;
                      this.setState({ questionnaire: questionnaire });
                    })}
                  >
                    <img className={'iconSearch'} src={iconSearch} alt={''} />
                    <img className={'iconSearchOn'} src={iconSearchOn} alt={''} />
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>{/* table_wrap */}

        <div className={'divide10'} />

        {/* ファイルDDコンポネント */}
        <div>
          アンケート申込書
          <FileLoaderComponent
            callbackAccept={(v) => {
              this.setState({ file: v[0] });
            }}
            callbackError={() => { }}
            isAllowMulti={false}
          />
        </div>

        <div className={'divide10'} />

        {/* ボタン */}
        <div className={'btn_wrap flex_box flex_align_center flex_space_between'}>
          {isNew ? (
            <div />
          ) : (
            <div>
              <button
                className={'reset btnStyle_1'}
                onClick={() => this.handlerClickBtnDelete()}
              >予約取り消し</button>
            </div>
          )}
          <div className={'flex_box flex_align_center'}>
            <div>
              <button
                className={'reset btnStyle_1'}
                onClick={() => this.handlerClickBtnBack()}
              >一覧へ戻る</button>
            </div>
            <div>
              <button
                className={'reset btnStyle_1'}
                onClick={() => this.handleClickSave()}
              >申込</button>
            </div>
          </div>
        </div>

      </div >
    );
  }

  componentDidMount() {
    DataManagerService.ins.getUserList()
      .then((v) => {
        this.setState({ userList: v });
      })
      .catch((v) => {
        this.setState({ userList: v });
      });
  }

  private handlerClickBtnBack(): void {
    this.gotoReserveList();
  }

  private gotoReserveList(): void {
    ModalService.ins.display(
      <ModalQuestionnaireComponent subdivision={
        this.state.subdivision
      } />
    );
  }

  private handleClickSave() {
    if (this.state.file) {
      const requestFileUpload = new RequestQuestionnaireUpload({
        id: this.state.questionnaire.questionnaire_id,
        subdivision_id: this.state.subdivision.id,
        file: this.state.file,
      });
      ConnectionService.ins.connect(requestFileUpload)
        .then((res) => {
          this.postReserve(res.data.id);
        })
        .catch((err) => {
          ErrorService.ins.response(err);
        });
    } else {
      this.postReserve(this.state.questionnaire.questionnaire_id as number);
    }
  }

  private postReserve(fileId: number) {
    const questionnaire = this.state.questionnaire;
    const request = new RequestSubdivisionsQuestionNairePost({
      ...questionnaire,
      application_date: DateFormatter.date2str(questionnaire.application_date, DateFormat.YYYYMMDD, '-'),
      questionnaire_id: fileId,
    });
    ConnectionService.ins.connect(request)
      .then(() => {
        ModalService.ins.push(
          <ModalAlertComponent
            msgList={['申込しました。']}
            callback={() => {
              this.gotoReserveList();
            }}
          />
        );
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }


  private handlerClickBtnDelete(): void {
    ModalService.ins.push(
      <ModalDialogComponent
        msgList={['申込を削除してもよろしいですか？']}
        callbackOk={() => {
          ModalService.ins.pop();
          const request = new RequestQuestionnaireDelete({ id: this.state.questionnaire.id as number });
          ConnectionService.ins.connect(request)
            .then(() => {
              ModalService.ins.push(
                <ModalAlertComponent
                  msgList={['削除しました。']}
                  callback={() => {
                    this.gotoReserveList();
                  }}
                />
              );
            })
            .catch((err) => {
              ErrorService.ins.response(err);
            });
        }}
        callbackNg={() => {
          ModalService.ins.pop();
        }} />
    );
  }

  private handlerClickBtnUserPicker(callback: (userId: number) => void): void {
    ModalService.ins.push(
      <ModalUserPickerComponent callback={callback} onDialog />
    );
  }
}
