import * as React from 'react';
import './modal-document-component.scss';
import { Article } from '../../../../model/article';
import { FileLoaderComponent } from '../../../common/file-loader/file-loader-component';
import { ArticleFile } from '../../../../model/article-file';
import { ColorGenerator } from '../../../../util/color-generator';
import { ModalService } from '../../../../service/modal-service';
import iconCheckBox from '../../../../img/common/checkbox.png';
import iconCheckBoxChecked from '../../../../img/common/checkbox_checked.png';
import { RequestArticlefilesGetSearch } from '../../../../model/api/request/request-articlefiles-get-search';
import { ConnectionService } from '../../../../service/connection-service';
import { ErrorService } from '../../../../service/error-service';
import { User } from '../../../../model/user';
import { DataManagerService } from '../../../../service/data-manager-service';
import { RequestArticlefilesPostImport_file } from '../../../../model/api/request/request-articlefiles-post-import_file';
import { ModalAlertComponent } from '../../../common/modal/modal-alert/modal-alert-component';
import { RequestArticlefilesPostDownload } from '../../../../model/api/request/request-articlefiles-post-download';
import { RequestArtiflefilesDelete } from '../../../../model/api/request/request-artiflefiles-delete';
import { ModalDialogComponent } from '../../../common/modal/modal-dialog/modal-dialog-component';
import { UserAgentService } from '../../../../service/user-agent-service';

interface ModalDocumentComponentProps {
  article: Article;
  isIntroduced: boolean;
}

interface ModalDocumentComponentState {
  article: Article;
  fileList: ArticleFile[];
  selectFlagList: boolean[];
  userList: User[];
  isIntroduced: boolean;
}

export class ModalDocumentComponent extends React.Component<ModalDocumentComponentProps, ModalDocumentComponentState> {

  /*private testFileList: ArticleFile[] = [
    new ArticleFile(0, 10, 'テストファイル.pdf', 'https://google.com', '山田'),
    new ArticleFile(1, 10, '(機密)耐震工事偽装手順書.doc', 'https://google.com', '田中'),
    new ArticleFile(2, 10, 'テストファイル.xml', 'https://google.com', '中村'),
    new ArticleFile(3, 10, 'テストファイル_2018_10_23.pdf', 'https://google.com', '村山'),
  ];*/

  constructor(props: ModalDocumentComponentProps) {
    super(props);
    this.state = {
      article: props.article,
      fileList: [],
      selectFlagList: [],
      userList: [],
      isIntroduced: props.isIntroduced,
    };
  }

  render() {
    const article: Article = this.state.article;
    const fileList: ArticleFile[] = this.state.fileList;
    const selectFlagList: boolean[] = this.state.selectFlagList;
    const userList: User[] = this.state.userList;
    const isSelected: boolean = selectFlagList.filter(v => v).length > 0;
    return (
      <div className={'modalInnerBody_wrap documentModal_wrap'}>

        <div className={'table_wrap'}>
          <div className={'table_row'}>
            <div className={'table_cell'}>
              物件名
            </div>
            <div className={'table_cell'}>
              {article.name}
            </div>
          </div>
        </div>

        <div className={'divide10'} />

        {/* DDエリア */}
        <div>
          <div className={'c_red'}>
            現況写真、不動産計画表、区画図、登記簿謄本、字図を登録してください。
          </div>
          <div className={'divide10'} />
          <FileLoaderComponent
            callbackAccept={(fileList, callback) => this.callbackFileAdd(fileList, callback)}
            callbackError={() => {
            }}
            isAllowMulti={true}
          />
        </div>

        <div className={'divide10'} />

        {/* リスト */}
        <div className={'documentList_wrap'}>
          {fileList.map((file: ArticleFile, i) => (
            <div
              className={'documentListInner_wrap flex_box flex_align_center'}
              key={file.id}
              onClick={() => {
                selectFlagList[i] = !selectFlagList[i];
                this.setState({
                  selectFlagList: selectFlagList,
                });
              }}
            >
              {/* チェックボックス */}
              <div>
                {selectFlagList[i] ? (
                  <img src={iconCheckBoxChecked} />
                ) : (
                  <img src={iconCheckBox} />
                )}
              </div>
              {/* ファイル名 */}
              <div>
                {file.name}
              </div>
              {/* ユーザ名 */}
              <div>
                {userList.find((v) => +file.uploaded_by === +(v.id ?? '')) ? (
                  userList.find((v) => +file.uploaded_by === +(v.id ?? ''))?.fullname['value']
                ) : (
                  ''
                )}
              </div>
            </div>
          ))}
        </div>

        <div className={'divide10'} />

        {/* ボタン */}
        <div className={'btn_wrap flex_box flex_align_center flex_space_between'}>
          <div>
            <button
              className={'reset btnStyle_1'}
              onClick={() => this.handlerClickBtnBack()}
            >戻る
            </button>
          </div>
          <div className={'flex_box flex_align_center'}>
            <div>
              <button
                className={`reset btnStyle_1 ${isSelected ? '' : ' disabled'}`}
                onClick={() => this.handlerClickBtnDelete()}
              >削除する
              </button>
            </div>
            <div>
              <button
                className={`reset btnStyle_1 ${isSelected ? '' : ' disabled'}`}
                onClick={() => this.handlerClickBtnDownload()}
              >ダウンロード
              </button>
            </div>
          </div>
        </div>

      </div>
    );
  }

  componentDidMount(): void {
    this.getDocumentList();
    DataManagerService.ins.getUserList()
      .then((v) => this.setState({ userList: v }))
      .catch((v) => this.setState({ userList: v }));
  }

  private callbackFileAdd(fileList: File[], callback: () => void): void {
    let count: number = 0;
    let errFlag: boolean = false;
    fileList.forEach((file) => {
      if (errFlag) {
        callback();
        return;
      }
      const request: RequestArticlefilesPostImport_file = new RequestArticlefilesPostImport_file(
        null,
        this.state.isIntroduced ? null : this.state.article.id,
        this.state.isIntroduced ? this.state.article.id : null,
        Number(!this.state.isIntroduced),
        file,
      );
      ConnectionService.ins.connect(request)
        .then((res) => {
          count++;
          if (count === fileList.length) {
            ModalService.ins.push(
              <ModalAlertComponent
                msgList={[`ファイルを ${fileList.length}件 アップロードしました。`]}
                callback={() => {
                  this.getDocumentList();
                  callback();
                  ModalService.ins.pop();
                }}
              />,
            );
          }
        })
        .catch((err) => {
          errFlag = true;
          ErrorService.ins.response(err);
        });
    });
  }

  private handlerClickBtnBack(): void {
    ModalService.ins.close();
  }

  private handlerClickBtnDelete(): void {
    const idList: number[] = [];
    this.state.selectFlagList.forEach((v, i) => {
      if (v) {
        idList.push(this.state.fileList[i].id);
      }
    });
    ModalService.ins.push(
      <ModalDialogComponent
        msgList={[`${idList.length}件 のファイルを削除します。`, 'よろしいですか？']}
        callbackOk={() => {
          ModalService.ins.pop();
          let successCounter = 0;
          let errCounter = 0;
          idList.forEach((v) => {
            const request: RequestArtiflefilesDelete = new RequestArtiflefilesDelete(v);
            ConnectionService.ins.connect(request)
              .then(() => {
                successCounter++;
                if (successCounter + errCounter === idList.length) {
                  ModalService.ins.push(
                    <ModalAlertComponent
                      msgList={[`${idList.length}件 のファイルを削除しました。`]}
                      callback={() => {
                        this.getDocumentList();
                        ModalService.ins.pop();
                      }}
                    />
                  );
                }
              })
              .catch((err) => {
                errCounter++;
                if (successCounter + errCounter === idList.length) {
                  this.getDocumentList();
                  ErrorService.ins.response(err);
                }
              });
          });
        }}
        callbackNg={() => {
          ModalService.ins.pop();
        }}
      />
    );
  }

  private handlerClickBtnDownload(): void {
    const idList: number[] = [];
    let fileName: string = this.state.fileList[0].name;
    this.state.selectFlagList.forEach((v, i) => {
      if (v) {
        idList.push(this.state.fileList[i].id);
        fileName = this.state.fileList[i].name;
      }
    });
    const request: RequestArticlefilesPostDownload = new RequestArticlefilesPostDownload(JSON.stringify(idList));
    ConnectionService.ins.connect(request)
      .then((res) => {
        const url: string = window.URL.createObjectURL(res);
        if (UserAgentService.ins.isMobile()) {
          window.location.href = url;
          return;
        }
        const link: HTMLAnchorElement = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.setAttribute('download', idList.length > 1 ? 'download.zip' : fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }

  private getDocumentList(): void {
    const request: RequestArticlefilesGetSearch = new RequestArticlefilesGetSearch(
      '',
      100,
      1,
      1,
      1,
      this.state.isIntroduced ? null : this.state.article.id,
      this.state.isIntroduced ? this.state.article.id : null,
    );
    ConnectionService.ins.connect(request)
      .then((res) => {
        this.setState({
          fileList: res.data.list,
          selectFlagList: res.data.list.map((_: any) => false),
        });
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }

}
