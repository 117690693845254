
export class ArticleIntroduced {

  constructor(
    /*public id: number,
    public name: string,
    public sales_status: number,
    public reservation_flag: boolean,
    public type: number,
    public building_name: string,
    public tsubo: number,
    public prefecture_id: number,
    public city_id: number,
    public address: string,
    public area_measuresize: number,
    public purchasing_manager_id: number,
    public user_id: number,
    public build_datetime: Date,
    public purchase_datetime: Date,
    public has_purchase_card: boolean,
    public customer_name: string,
    public conclusion_datetime: Date,
    public m_school_id: number,
    public p_school_id: number,*/
    public id: number,
    public name: string,
    public prefecture_id: number,
    public city: any,
    public city_id: number,
    public address: string,
    public price: number,
    public tsubo: number,
    public area_measuresize: number,
    public land_category: number,
    public use_district: string,
    public user_id: number,
    public p_school_id: number,
    public m_school_id: number,
    public remarks: string,
    public valid_flag: number,
  ) {
  }

}
