import * as React from 'react';
import { ReactElement } from 'react';
import { DateFormat, DateFormatter } from '../../../util/date-formatter';
import './subdivision-detail-component.scss';
import { HeaderComponent } from '../../common/header/header-component';
import { SideMenuComponent } from '../../common/side-menu/side-menu-component';
import { ModalService } from '../../../service/modal-service';
import { ConnectionService } from '../../../service/connection-service';
import { ErrorService } from '../../../service/error-service';
import { Prefecture } from '../../../model/prefecture';
import { City } from '../../../model/city';
import { User } from '../../../model/user';
import { Subdivision } from '../../../model/subdivision';
import { DataManagerService } from '../../../service/data-manager-service';
import { School } from '../../../model/school';
import { DatePickerComponent } from '../../common/date-picker/date-picker-component';
import { ArticleInfo } from '../../../model/articleInfo';
import { RequestArticleinformationsGetSearch } from '../../../model/api/request/request-articleinformations-get-search';
import { RequestArticleinformationsPost } from '../../../model/api/request/request-articleinformations-post';
import { RoutingPass } from '../../../constant/routing-pass';
import { RequestBase } from '../../../model/api/request/request-base';
import { UserService } from '../../../service/user-service';
import { ModalAlertComponent } from '../../common/modal/modal-alert/modal-alert-component';
import iconCheckBoxChecked from '../../../img/common/checkbox_checked.png';
import iconCheckBox from '../../../img/common/checkbox.png';
import { ModalDialogComponent } from '../../common/modal/modal-dialog/modal-dialog-component';
import iconSearch from '../../../img/common/icon_search.png';
import iconSearchOn from '../../../img/common/icon_search_on.png';
import thumbNoImage from '../../../img/detail/thumb_noimage.jpg';
import thumbPdf from '../../../img/detail/thumb_pdf.png';
import { MoneyFormatter } from '../../../util/money-formatter';
import { ArticleLinkedFile } from '../../../model/article-linked-file';
import { RequestArticlesPostImport_file } from '../../../model/api/request/request-articles-post-import_file';
import { RequestArticlesLinked_fileGet } from '../../../model/api/request/request-articles-linked_file-get';
import { validationArticleDetail } from '../../../model/validation';
import { ElementId } from '../../../constant/element-id';
import { DispatchToProps } from '../../../redux/store';
import { connect } from 'react-redux';
import { ModalSubdivisionPickerComponent } from '../master-subdivision/modal-subdivision-picker/modal-subdivision-picker-component';
import { ValidationComponent } from '../article-detail/validation/validation-component';
import { ImagesLoaderComponent } from '../article-detail/images-loader/images-loader-component';
import { ImgLoaderComponent } from '../article-detail/img-loader/img-loader-component';
import { ModalUserPickerComponent } from '../article-detail/modal-user-picker/modal-user-picker-component';
import { ModalQuestionnaireComponent } from './modal-questionnaire/modal-questionnaire-component';
import { RequestSubdivisionDetailGet } from '../../../model/api/request/request-subdivision-detail-get';
import { SubdivisionDetail } from '../../../model/subdivision-detail';
import { SubdivisionDetailModel } from './subdivision-detail.model';
import { RequestSubdivisionsPost } from '../../../model/api/request/request-subdivisions-post';
import { RequestQuestionnaireUpload } from '../../../model/api/request/request-questionnaire-upload';
import { RequestSubdivisionsLinkedFileGet } from '../../../model/api/request/request-subdivision-linlked-file-get';
import { RequestSubdivisionFilesUpload } from '../../../model/api/request/request-subdibision-files-upload';

interface ArticleDetailComponentProps {
}

interface ArticleDetailComponentState {
  me: User | null;
  id: number | null;
  isNew: boolean;
  isEdit: boolean;
  isBeforeLoad: boolean;
  isIntroduced: boolean;
  subdivisionDetail: SubdivisionDetail;
  //
  prefectureList: Prefecture[];
  cityList: City[];
  userList: User[];
  primarySchoolList: School[];
  middleSchoolList: School[];
  //
  infoList: ArticleInfo[];
  editedInfoList: ArticleInfo[];
  infoInput: string;
  //
  sales_month: number;
  //
  route: string, //最寄り路線
  station: string, //最寄り駅
  privateArea: string, //私道面積
  privateUnit: string, //私道坪数
  tsuboPrice: string, //坪単価
  traffic_route: string, //交通_路線
  traffic_station: string, //交通_駅
  traffic_minute: string, //交通_徒歩○分
  landRight: number, //土地権利
  ground: number, //地目
  cityPlanning: string, //都市計画
  useDistrict: number, //土地用途
  coverageRatioInput: string; //建ぺい率
  floorAreaRatioInput: string; //容積率
  legalAuthorityInput: string; //法令n上の権限
  presentStatusInput: string; //現況
  approachSituationInput: string; //接道状況
  facilityInput: string; //設備
  remarksInput: string; //備考
  //
  imageList: ArticleLinkedFile[];
  subdivisionList: Subdivision[];
}



class SubdivisionDetailBodyComponent extends React.Component<ArticleDetailComponentProps, ArticleDetailComponentState> {
  private loadedFileList: File[] | (string | null)[] = [null, null, null, null, null, null, null, null, null, null, null, null];
  private loadedFileAndUrlList: { file: File, url: string; }[] = [];

  private defaultSubdivisionDetailData = new SubdivisionDetail({
    id: null,
    name: '',
    sales_status: null,
    reservation_flag: null,
    type: 3,
    building_name: '',
    tsubo: null,
    price: null,
    prefecture_id: null,
    city: null,
    city_id: null,
    address: '',
    area_measuresize: null,
    purchasing_manager_id: null,
    user_id: null,
    build_datetime: null,
    purchase_datetime: null,
    has_purchase_card: null,
    customer_name: '',
    conclusion_datetime: null,
    m_school_id: null,
    p_school_id: null,
    subdivision_id: null,
    period_start: null,
    period_end: null,
    message: null,
    email: null,
    reserve_count: null,
    area_count: null,
    area_max: null,
    remarks: null,
    land_category: null,
    use_district: null,
    valid_flag: 1,
    subdivision_upper_limit_flag: null,
  });

  constructor(props: ArticleDetailComponentProps) {
    super(props);
    this.state = {
      me: null,
      id: null,
      isNew: false,
      isEdit: false,
      isBeforeLoad: true,
      isIntroduced: false,
      subdivisionDetail: this.defaultSubdivisionDetailData, // デフォルトデータ
      //
      prefectureList: [],
      cityList: [],
      userList: [],
      primarySchoolList: [],
      middleSchoolList: [],
      // subdivisionList
      subdivisionList: [],
      //
      infoList: [],
      editedInfoList: [],
      infoInput: '',
      sales_month: 0,
      //
      route: '',
      station: '',
      privateArea: '',
      privateUnit: '',
      tsuboPrice: '',
      traffic_route: '',
      traffic_station: '',
      traffic_minute: '',
      landRight: 0,
      ground: 0,
      cityPlanning: '',
      useDistrict: 0,
      coverageRatioInput: '',
      floorAreaRatioInput: '',
      legalAuthorityInput: '',
      presentStatusInput: '',
      approachSituationInput: '',
      facilityInput: '',
      remarksInput: '',
      //
      imageList: [],
    };
  }

  render() {
    const isNew: boolean = this.state.isNew;
    const isEdit: boolean = this.state.isEdit || isNew;
    const isIntroduced: boolean = this.state.isIntroduced;
    // table body
    const tableBody = this.renderTableBodyDefault();
    const tableBodyIntroduced = <></>;
    // const tableBodyIntroduced = this.renderTableBodyIntroduced();

    const subdivisionDetail: SubdivisionDetail = this.state.subdivisionDetail;
    const prefectureList = this.state.prefectureList;
    const cityList = this.state.cityList;
    const userList = this.state.userList;
    const primarySchoolList = this.state.primarySchoolList;
    const middleSchoolList = this.state.middleSchoolList;
    // subdivisionList
    const subdivisionList = this.state.subdivisionList;
    const infoList: ArticleInfo[] = this.state.infoList;
    const editedInfoList: ArticleInfo[] = this.state.editedInfoList;
    const infoInput: string = this.state.infoInput;

    return (
      <div className={''}
      >
        {/** RealEstateList */}

        {/* --- header --- */}
        <HeaderComponent />

        <div className={'body_wrap flex_box'}>

          {/* side menu */}
          <div className={'pc menu_wrap'}>
            <SideMenuComponent />
          </div>
          <div className={`content_wrap cont_article_detail ${isEdit ? 'mode_edit' : ''}`}>
            <div className={'contentInner_wrap'}>

              {/* ------------------------------------------------------------- */}

              {/* タイトル */}
              <div className={'contentHead_wrap'}>
                <div className={'contentHeadInner_wrap flex_box flex_align_center flex_space_between'}>
                  <div className={'contentHead_text flex_box flex_align_center'}>
                    <span>分譲地管理</span>
                    <span className={'innerBar'} />
                    <span>{isNew ? '新規登録' : '詳細画面'}</span>
                  </div>
                </div>
              </div>
              {/** contentHead_wrap */}

              {/* ------------------------------------------------------------- */}

              <div className={'btnArea flex_box flex_align_center flex_space_between'}>

                {/* ステータス */}
                <div className={'status_wrap'} />

                {/* button */}
                <div className={'btnList_wrap flex_box flex_align_center'}>
                  {isEdit ? (
                    <>
                      <div>
                        <button
                          className={'reset btnStyle_1'}
                          onClick={() => this.handlerClickBtnCancel()}
                        >キャンセル
                        </button>
                      </div>
                      <div>
                        <button
                          className={'reset btnStyle_1'}
                          onClick={() => this.handlerClickBtnSave()}
                        >保存
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <button
                          className={'reset btnStyle_1'}
                          onClick={() => this.handlerClickBtnQuestionnaire()}
                        >アンケート申込
                        </button>
                      </div>
                      <div>
                        <button
                          className={'reset btnStyle_1'}
                          onClick={() => this.handlerClickBtnEdit()}
                        >編集
                        </button>
                      </div>
                    </>
                  )}
                </div>
                {/** btnList_wrap */}

              </div>
              {/** btn_area */}

              {/* ------------------------------------------------------------- */}

              <div className={'tableArea'}>

                {/* 表 */}
                <div className={'tableInner_wrap'}>

                  {/** --------------------------------- */}

                  <div className={'flex_box'}>
                    {/* 分譲地名 */}
                    <div className={'padd_t_adjust'}>
                      分譲地名{isEdit ? (<>*</>) : (<></>)}
                    </div>
                    <div className={''}>
                      {isEdit ? (
                        <>
                          <input
                            className={'textInput'}
                            value={subdivisionDetail.name || ''}
                            onChange={(e) => {
                              subdivisionDetail.name = e.target.value;
                              this.setState({ subdivisionDetail: subdivisionDetail });
                            }}
                          />
                          <ValidationComponent validation={validationArticleDetail.name} str={''} />
                        </>
                      ) : (
                        <>{this.state.subdivisionDetail.name}</>
                      )}
                    </div>
                  </div>

                  <div className={'flex_box'}>
                    {/* 有効 / 無効 */}
                    <div className={'padd_t_adjust'}>
                      有効 / 無効{isEdit ? (<>*</>) : (<></>)}
                    </div>
                    <div className={''}>
                      {isEdit ? (
                        <>
                          <div
                            onClick={() => {
                              subdivisionDetail['valid_flag'] = +(subdivisionDetail['valid_flag'] ?? '') === 1 ? 0 : 1;
                              this.setState({ subdivisionDetail: subdivisionDetail });
                            }}
                          >
                            {+(subdivisionDetail['valid_flag'] ?? '') ? (
                              <div className={'innerImg checked'}><img src={iconCheckBoxChecked} alt={''} /></div>
                            ) : (
                              <div className={'innerImg noCheck'}><img src={iconCheckBox} alt={''} /></div>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          {+(subdivisionDetail['valid_flag'] ?? '') ? (
                            <div className={'innerImg checked'}><img src={iconCheckBoxChecked} alt={''} /></div>
                          ) : (
                            <div className={'innerImg noCheck'}><img src={iconCheckBox} alt={''} /></div>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div className={'flex_box'}>
                    {/* 区画数 */}
                    <div className={'padd_t_adjust'}>
                      区画数{isEdit ? (<>*</>) : (<></>)}
                    </div>
                    <div className={''}>
                      {isEdit ? (
                        <>
                          <input
                            className={'textInput'}
                            value={subdivisionDetail.area_max || 0}
                            onChange={(e) => {
                              subdivisionDetail.area_max = Number(e.target.value) || 0;
                              this.setState({ subdivisionDetail });
                            }}
                          />
                          {/*<ValidationComponent validation={validationArticleDetail.name} str={''} />*/}
                        </>
                      ) : (
                        <>{subdivisionDetail.area_max || ''}</>
                      )}
                    </div>
                  </div>

                  {/** --------------------------------- */}
                  {/** テーブルのボディ */}
                  {isIntroduced ? (
                    /* 不動産 */
                    tableBodyIntroduced
                  ) : (
                    /* 普通 */
                    tableBody
                  )}
                  {/** --------------------------------- */}

                  {!isNew && !isIntroduced ? (
                    <div className={'flex_box salesSituation'}>
                      {/* 当月販売状況 */}
                      <div className={''}>
                        当月販売状況
                      </div>
                      <div>
                        {isEdit ? (
                          <>
                            <textarea
                              className={'textInput'}
                              style={{
                                height: '100%',
                                maxHeight: '100%',
                                maxWidth: '100%',
                                minHeight: '100%',
                                minWidth: '100%',
                              }}
                              onChange={(e) => {
                                subdivisionDetail.itemcontent = e.target.value;
                                this.setState({
                                  subdivisionDetail: subdivisionDetail,
                                });
                              }}
                              value={subdivisionDetail.itemcontent || ''}
                            >
                            </textarea>
                          </>
                        ) : (
                          <>
                            {subdivisionDetail.itemcontent?.split('\n').map((v, i) => (
                              <div
                                key={v + '_' + i}
                                style={{ minHeight: 'calc(1rem + 5px)' }}
                              >
                                {v ? v : '　'}
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {isIntroduced ? (
                    <div className={'flex_box'}>
                      {/* 備考 */}
                      <div className={'padd_t_adjust'}>
                        備考
                      </div>
                      <div>
                        {isEdit ? (
                          <input
                            className={'textInput max_w_8'}
                            value={''}
                            type={''}
                            onChange={(e) => { }}
                          />
                        ) : (
                          <>hoge{/* article['remarks'] */}</>
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {/** --------------------------------- */}

                </div>
                {/** table_wrap */}

              </div>
              {/** tableArea */}

              {/* ------------------------------------------------------------- */}

            </div>
            {/** contentInner_wrap */}
          </div>
          {/** content_wrap */}

        </div>
        {/** body_wrap */}
      </div>
    );
  }

  private renderTableBodyDefault(): ReactElement<any> {
    const isNew: boolean = this.state.isNew;
    const isEdit: boolean = this.state.isEdit || isNew;
    const subdivisionDetail: SubdivisionDetail = this.state.subdivisionDetail;
    const prefectureList = this.state.prefectureList;
    const imgUrlList: string[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(v => this.getLinkedFileUrl(v as any, this.state.imageList));
    const img11: string = this.getLinkedFileUrl(11, this.state.imageList);
    const pdf: string = this.getLinkedFileUrl(12, this.state.imageList);
    const pdfFileName: string | undefined = this.state.imageList.find((v) => +v.article_file_type === 12) ?
      this.state.imageList?.find((v) => +v.article_file_type === 12)?.name : '';
    const {
      route,
      station,
      privateArea,
      privateUnit,
      tsuboPrice,
      traffic_route,
      traffic_station,
      traffic_minute,
      landRight,
      ground,
      cityPlanning,
      useDistrict,
      coverageRatioInput,
      floorAreaRatioInput,
      legalAuthorityInput,
      presentStatusInput,
      approachSituationInput,
      facilityInput,
      remarksInput,
      imageList,
    } = this.state;
    return (
      <>
        {/** ------------------------------------------------------------------ */}
        {/*<div className={'flex_box sp_flex_container'}>*/}
        {/*  /!* 販売状況 *!/*/}
        {/*  <div className={'padd_t_adjust'}>*/}
        {/*    販売状況{isEdit ? (<>*</>) : (<></>)}*/}
        {/*  </div>*/}
        {/*  <div className={''}>*/}
        {/*    {isEdit ? (*/}
        {/*      <select*/}
        {/*        className={'textInput max_w_8'}*/}
        {/*        value={subdivisionDetail.sales_status || 0}*/}
        {/*        onChange={async (e) => {*/}
        {/*          subdivisionDetail.sales_status = Number(e.target.value) || null;*/}
        {/*          this.setState({*/}
        {/*            subdivisionDetail,*/}
        {/*          });*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <option value={''}>未選択</option>*/}
        {/*        {SubdivisionDetailModel.salesStatusList.map((v) => (*/}
        {/*          <option*/}
        {/*            value={v.value}*/}
        {/*            key={v.value}*/}
        {/*            disabled={*/}
        {/*              Boolean(*/}
        {/*                v.value === 6 && this.state.me?.group_id && (*/}
        {/*                  subdivisionDetail.user_id !== this.state.me?.id &&*/}
        {/*                  +this.state.me?.group_id !== 1*/}
        {/*                ))*/}
        {/*            }*/}
        {/*          >*/}
        {/*            {v.label}*/}
        {/*          </option>*/}
        {/*        ))}*/}
        {/*      </select>*/}
        {/*    ) : (*/}
        {/*      <>*/}
        {/*        {*/}
        {/*          subdivisionDetail.sales_status*/}
        {/*            ? SubdivisionDetailModel.salesStatusList.find((v) => +v.value === +(subdivisionDetail.sales_status ?? ''))?.label*/}
        {/*            : ''*/}
        {/*        }*/}
        {/*      </>*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 種別 */}
          <div className={'padd_t_adjust'}>
            種別{isEdit ? (<>*</>) : (<></>)}
          </div>
          <div className={'item_type'}>
            {isEdit ? (
              <select
                disabled
                className={'textInput max_w_8'}
                value={subdivisionDetail.type || 0}
              // onChange={async (e) => {
              //   subdivisionDetail.type = Number(e.target.value) || null;
              //   this.setState({
              //     subdivisionDetail,
              //   });
              // }}
              >
                <option value={''}>未選択</option>
                {SubdivisionDetailModel.typeList.map((v) => (
                  <option value={v.value} key={v.value}>{v.label}</option>
                ))}
              </select>
            ) : (
              <div className={'flex_box flex_align_center'}>
                {SubdivisionDetailModel.typeList.find((v) => +v.value === +(subdivisionDetail?.type ?? '')) ? (
                  <>
                    {Number(subdivisionDetail.type) === 3 ? (
                      <>
                        {/*<div className={''}>{this.typeList.find((v) => +v.value === +subdivisionDetail.type).label}</div>*/}
                        {/*<div className={''}>WEB予約 {subdivisionDetail.reserve_count}件 <div className={'minBR'} />{subdivisionDetail.area_count}/{subdivisionDetail.area_max} 区分</div>*/}
                        <div className={''}>{SubdivisionDetailModel.typeList.find((v) => +v.value === +(subdivisionDetail?.type ?? ''))?.label}</div>
                        <div className={''}>WEB予約 {subdivisionDetail.reserve_count}件 <div
                          className={'minBR'} />{subdivisionDetail.area_count}/{subdivisionDetail.area_max} 区分
                        </div>
                      </>
                    ) : (SubdivisionDetailModel.typeList.find((v) => +v.value === +(subdivisionDetail?.type ?? ''))?.label)}
                  </>
                ) : ''}
              </div>
            )}
          </div>

          {/** ------------------------------------------------------------------ */}
          {/* 分譲地名 */}
          {/*{Number(article.type) === 3 ? (*/}
          {/*  <>*/}
          {/*    <div className={'padd_t_adjust'}>*/}
          {/*      分譲地名{isEdit ? (<>*</>) : (<></>)}*/}
          {/*    </div>*/}
          {/*    <div className={'flex_box flex_align_center withButton_wrap'}>*/}
          {/*      {isEdit ? (*/}
          {/*        <>*/}
          {/*          <div className={'flex_box_child'}>*/}
          {/*            {+(article?.reserve_count ?? '') === 0 ? (*/}
          {/*              <></>*/}
          {/*            ) : (*/}
          {/*              <div className={'filterEle'}></div>*/}
          {/*            )}*/}
          {/*            <select*/}
          {/*              className={'textInput max_w_8'}*/}
          {/*              value={article.subdivision_id || 0}*/}
          {/*              onChange={async (e) => {*/}
          {/*                article.subdivision_id = Number(e.target.value) || null;*/}
          {/*                this.setState({*/}
          {/*                  article: article,*/}
          {/*                });*/}
          {/*              }}*/}
          {/*            >*/}
          {/*              <option value={''}>未選択</option>*/}
          {/*              {subdivisionList.map((v) => (*/}
          {/*                <option value={v.id ?? ''} key={v.id}>{v.name}</option>*/}
          {/*              ))}*/}
          {/*            </select>*/}
          {/*          </div>*/}
          {/*          {+(article?.reserve_count ?? '') === 0 ? (*/}
          {/*            <>*/}
          {/*              <div>*/}
          {/*                <button*/}
          {/*                  className={'reset btnStyle_1'}*/}
          {/*                  onClick={() => this.handlerClickBtnSubdivisionPicker((subdivisionId) => {*/}
          {/*                    article.subdivision_id = Number(subdivisionId) || null;*/}
          {/*                    this.setState({ article: article });*/}
          {/*                  })}*/}
          {/*                >*/}
          {/*                  <img className={'iconSearch'} src={iconSearch} alt={''} />*/}
          {/*                  <img className={'iconSearchOn'} src={iconSearchOn} alt={''} />*/}
          {/*                </button>*/}
          {/*              </div>*/}
          {/*            </>*/}
          {/*          ) : (*/}
          {/*            <></>*/}
          {/*          )}*/}
          {/*        </>*/}
          {/*      ) : (*/}
          {/*        <>*/}
          {/*          {subdivisionList.find((v) => +(v.id ?? '') === +(article?.subdivision_id ?? '')) ? (*/}
          {/*            subdivisionList.find((v) => +(v.id ?? '') === +(article?.subdivision_id ?? ''))?.name*/}
          {/*          ) : (*/}
          {/*            ''*/}
          {/*          )}*/}
          {/*        </>*/}
          {/*      )}*/}
          {/*    </div>*/}
          {/*  </>*/}
          {/*) : (*/}
          {/*  <></>*/}
          {/*)}*/}
          {/* <div className={'padd_t_adjust'}>
            分譲地名{isEdit ? (<>*</>) : (<></>)}
          </div>
          <div className={'flex_box flex_align_center withButton_wrap'}>
            {isEdit ? (
              <>
                <div className={'flex_box_child'}>
                  <div className={'filterEle'} />
                  <select
                    className={'textInput max_w_8'}
                    value={0}
                    onChange={(e) => { }}
                  >
                    <option value={''}>未選択</option>
                    <option value={''}>hoge</option>
                  </select>
                </div>
                <div>
                  <button
                    className={'reset btnStyle_1'}
                    onClick={() => { }}
                  >
                    <img className={'iconSearch'} src={iconSearch} alt={''} />
                    <img className={'iconSearchOn'} src={iconSearchOn} alt={''} />
                  </button>
                </div>
              </>
            ) : (
              <>
                hoge
              </>
            )}
          </div> */}


        </div>

        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 都道府県 */}
          <div className={'padd_t_adjust'}>
            都道府県{isEdit ? (<>*</>) : (<></>)}
          </div>
          <div>
            {isEdit ? (
              <select
                className={'textInput max_w_8'}
                value={subdivisionDetail.prefecture_id || 0}
                onChange={async (e) => {
                  subdivisionDetail.prefecture_id = Number(e.target.value) || null;
                  subdivisionDetail.city_id = null;
                  subdivisionDetail.m_school_id = null;
                  subdivisionDetail.p_school_id = null;
                  this.setState({
                    subdivisionDetail: subdivisionDetail,
                    cityList: subdivisionDetail.prefecture_id ? await DataManagerService.ins.getCityList(subdivisionDetail.prefecture_id) : [],
                  });
                }}
              >
                <option value={''}>未選択</option>
                {prefectureList.map((v) => (
                  <option value={v.id} key={v.id}>{v.name}</option>
                ))}
              </select>
            ) : (
              <>
                {prefectureList.find((v) => +v.id === +(subdivisionDetail?.prefecture_id ?? '')) ? (
                  prefectureList.find((v) => +v.id === +(subdivisionDetail?.prefecture_id ?? ''))?.name
                ) : (
                  ''
                )}
              </>
            )}
          </div>
          {/* 市区町村 */}
          <div className={'padd_t_adjust'}>
            市区町村{isEdit ? (<>*</>) : (<></>)}
          </div>
          <div>
            {isEdit ? (
              <select
                className={'textInput max_w_8'}
                value={subdivisionDetail.city_id || 0}
                onChange={async (e) => {
                  subdivisionDetail.city_id = Number(e.target.value) || null;
                  subdivisionDetail.m_school_id = null;
                  subdivisionDetail.p_school_id = null;
                  this.setState({
                    subdivisionDetail: subdivisionDetail,
                    primarySchoolList: await DataManagerService.ins.getPrimarySchoolList(subdivisionDetail.prefecture_id, subdivisionDetail.city_id),
                    middleSchoolList: await DataManagerService.ins.getMiddleSchoolList(subdivisionDetail.prefecture_id, subdivisionDetail.city_id),
                  });
                }}
              >
                <option value={''}>未選択</option>
                {this.state.cityList.map((v) => (
                  <option value={v.id ?? ''} key={v.id}>{v.name}</option>
                ))}
              </select>
            ) : (
              <>
                {subdivisionDetail.city}
              </>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 住所 */}
          <div className={'padd_t_adjust'}>
            住所
          </div>
          <div>
            {isEdit ? (
              <>
                <input
                  className={'textInput'}
                  value={subdivisionDetail.address || ''}
                  onChange={(e) => {
                    subdivisionDetail.address = e.target.value;
                    this.setState({
                      subdivisionDetail: subdivisionDetail
                    });
                  }}
                />
                <ValidationComponent validation={validationArticleDetail.address} str={String(subdivisionDetail.address || '')} />
              </>
            ) : (
              <>{subdivisionDetail.address}</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 最寄駅 */}
          <div className={'padd_t_adjust'}>
            最寄駅
          </div>
          <div>
            {/**/}
            {isEdit ? (
              <>
                <div className={'flex_box flex_align_center sp_block'}>
                  <div className={'flex_box flex_align_center basis_10rem'}>
                    <input
                      className={'textInput'}
                      value={this.state.route || ''}
                      onChange={(e) => this.setState({ route: e.target.value })}
                    />&nbsp;線
                  </div>
                  <div className={'flex_box flex_align_center basis_10rem margin_l_20'}>
                    <input
                      className={'textInput'}
                      value={this.state.station || ''}
                      onChange={(e) => this.setState({ station: e.target.value })}
                    />&nbsp;駅
                  </div>
                </div>
                <ValidationComponent validation={validationArticleDetail.station}
                  str={(this.state.route || '') + (this.state.station || '')} />
              </>
            ) : (
              <>{this.state.route}線&nbsp;{this.state.station}駅</>
            )}
            {/* change */}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}

        {isEdit ? (
          <>
            {this.loadedFileAndUrlList.map((v, i, arr) => (
              i % 2 ? <div key={`loadedFileAndUrlList_${i}`} /> : (
                <div className={'flex_box sp_flex_container'} key={`loadedFileAndUrlList_main_${i}`}>
                  <div className={'padd_t_adjust'}>
                    現状写真{i + 1}
                  </div>
                  <div className={'displayImage_wrap flex_box flex_align_center flex_content_center'}>
                    <div className={'editImage_wrap'}>
                      <div className={'editImageInne_wrap flex_box flex_align_center'}>
                        <img
                          src={v ? v.url : thumbNoImage}
                          className={'marg_auto'}
                          alt={''}
                        />
                      </div>
                      <div className={'divide10'} />
                      <div className={'flex_box flex_align_center'}>
                        <div className={'selectImageBtn_wrap singleImageBtn'}>
                          {v && (
                            <button
                              className={'reset selectedImageDeleteBtn'}
                              onClick={() => {
                                this.loadedFileAndUrlList.splice(i, 1);
                                this.setState({});
                              }}
                            >削除</button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={'padd_t_adjust'}>
                    現状写真{i + 2}
                  </div>
                  <div className={'displayImage_wrap flex_box flex_align_center flex_content_center'}>
                    <div className={'editImage_wrap'}>
                      <div className={'editImageInne_wrap flex_box flex_align_center'}>
                        <img
                          src={arr[i + 1] ? arr[i + 1].url : thumbNoImage}
                          className={'marg_auto'}
                          alt={''}
                        />
                      </div>
                      <div className={'divide10'} />
                      <div className={'flex_box flex_align_center'}>
                        <div className={'selectImageBtn_wrap singleImageBtn'}>
                          {arr[i + 1] && (
                            <button
                              className={'reset selectedImageDeleteBtn'}
                              onClick={() => {
                                this.loadedFileAndUrlList.splice(i + 1, 1);
                                this.setState({});
                              }}
                            >削除</button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            ))}
            {this.loadedFileAndUrlList.length < 10 ? (
              <div className={'flex_box sp_flex_container'}>
                <div className={'padd_t_adjust'}>
                  現状写真
                </div>
                <div className={'displayImage_wrap flex_box flex_align_center flex_content_center'}>
                  <ImagesLoaderComponent
                    srcList={[]}
                    callback={async (fileList) => {
                      const urlList: string[] = [];
                      // file[] -> string[] へ変換
                      for (let i = 0; i < fileList.length; i++) {
                        urlList.push(await this.convertFile2Base64(fileList[i]));
                      }
                      if ((fileList.length + this.loadedFileAndUrlList.length) > 10) {
                        ModalService.ins.push(
                          <ModalAlertComponent
                            msgList={['アップロードできる画像ファイルは10件までです。']}
                            callback={() => {
                              ModalService.ins.close();
                            }}
                          />
                        );
                        return;
                      }
                      this.loadedFileAndUrlList =
                        this.loadedFileAndUrlList
                          .concat(fileList.map((file, i) => ({ file: file, url: urlList[i] })));
                      // .filter((_, i) => i < 10);
                      this.setState({});
                    }}
                    callbackDell={() => { }}
                  />
                </div>
              </div>
            ) : (
              <>
                {}
              </>
            )}
          </>
        ) : (
          <>
            {imgUrlList.filter((v, i) => !!v).map((v, i, arr) => (
              i % 2 ? <div key={`${v}_${i}`} /> : (
                <div className={'flex_box sp_flex_container'} key={`${v}_${i}`}>
                  <div className={'padd_t_adjust'}>
                    現状写真{i + 1}
                  </div>
                  <div className={'displayImage_wrap flex_box flex_align_center flex_content_center'}>
                    <img
                      src={v || thumbNoImage}
                      className={'marg_auto'}
                      alt={''}
                    />
                  </div>
                  <div className={'padd_t_adjust'}>
                    {arr[i + 1] ? (
                      <>
                        現状写真{i + 2}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className={'displayImage_wrap flex_box flex_align_center flex_content_center'}>
                    {arr[i + 1] ? (
                      <img
                        src={arr[i + 1] || thumbNoImage}
                        className={'marg_auto'}
                        alt={''}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              )
            ))}
          </>
        )}

        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 地図画像 */}
          <div className={'padd_t_adjust'}>
            地図画像
          </div>
          <div className={'displayImage_wrap flex_box flex_align_center flex_content_center'}>
            {isEdit ? (
              <>
                <ImgLoaderComponent
                  src={img11}
                  callback={(file) => this.loadedFileList[10] = file}
                  callbackDell={() => this.loadedFileList[10] = ''}
                />
              </>
            ) : (
              <>
                <img
                  src={img11 || thumbNoImage}
                  className={'marg_auto'}
                  alt={''}
                />
              </>
            )}
          </div>
          {/* 区画図PDF */}
          <div className={'padd_t_adjust'}>
            区画図PDF
          </div>
          <div className={'displayImage_wrap flex_box flex_align_center flex_content_center'}>
            {isEdit ? (
              <>
                <ImgLoaderComponent
                  src={pdf}
                  isPdfOnly={true}
                  pdfFileName={pdf ? pdfFileName : ''}
                  callback={(file) => this.loadedFileList[11] = file}
                  callbackDell={() => this.loadedFileList[11] = ''}
                />
              </>
            ) : (
              <>
                {pdf ? (
                  <div className={'pdf_wrap'}>
                    <div className={'align_c'}>{pdfFileName}</div>
                    <div className={'divide20'} />
                    <button
                      className={'reset flex_box flex_align_center flex_content_center'}
                      // onClick={() => this.handlerClickBtnOpenPdf('http://sv1.marietta.co.jp/_users/tenryu/Demo/sample.pdf')}
                      onClick={() => this.handlerClickBtnOpenPdf(pdf)}
                    >
                      <img
                        src={thumbPdf}
                        alt={''}
                      />
                      &nbsp;PDFを開く
                    </button>
                  </div>
                ) : (
                  <div>区画図は登録されていません。</div>
                )}
              </>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 価格 */}
          <div className={'padd_t_adjust'}>
            価格
          </div>
          <div>
            {isEdit ? (
              <>
                <div className={'flex_box flex_align_center'}>
                  <input
                    className={'textInput max_w_8'}
                    value={subdivisionDetail['price'] || ''}
                    type={'number'}
                    onChange={(e) => {
                      subdivisionDetail['price'] = Number(e.target.value) || null;
                      this.setState({
                        subdivisionDetail: subdivisionDetail
                      });
                    }}
                  />&nbsp;円
                </div>
                <ValidationComponent validation={validationArticleDetail.price} str={subdivisionDetail['price'] + '' || ''} />
              </>
            ) : (
              <>{MoneyFormatter.thousandSeparate(subdivisionDetail['price'] as number)}&nbsp;円</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 面積 */}
          <div className={'padd_t_adjust'}>
            土地面積
          </div>
          <div>
            {isEdit ? (
              <>
                <div className={'flex_box flex_align_center'}>
                  <input
                    className={'textInput max_w_8'}
                    value={subdivisionDetail.area_measuresize || ''}
                    type={'number'}
                    onChange={(e) => {
                      subdivisionDetail.area_measuresize = Number(e.target.value) || 0;
                      this.setState({
                        subdivisionDetail: subdivisionDetail
                      });
                    }}
                  />&nbsp;&#13217;
                </div>
                <ValidationComponent validation={validationArticleDetail.areaMeasureSize}
                  str={String(subdivisionDetail.area_measuresize || '') + String(subdivisionDetail.tsubo || '')} />
              </>
            ) : (
              <>{subdivisionDetail.area_measuresize} &nbsp;&#13217;</>
            )}
          </div>
          {/* 坪数 */}
          <div className={'padd_t_adjust'}>
            土地坪数
          </div>
          <div>
            {isEdit ? (
              <>
                <input
                  className={'textInput max_w_8'}
                  value={subdivisionDetail.tsubo || ''}
                  type={'number'}
                  onChange={(e) => {
                    subdivisionDetail.tsubo = Number(e.target.value) || 0;
                    this.setState({
                      subdivisionDetail: subdivisionDetail
                    });
                  }}
                />
                <ValidationComponent validation={validationArticleDetail.tsubo}
                  str={String(subdivisionDetail.area_measuresize || '') + String(subdivisionDetail.tsubo || '')} />
              </>
            ) : (
              <>{subdivisionDetail.tsubo}&nbsp;坪</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 私道面積 */}
          <div className={'padd_t_adjust'}>
            私道面積
          </div>
          <div>
            {isEdit ? (
              <>
                <div className={'flex_box flex_align_center'}>
                  <input
                    className={'textInput max_w_8'}
                    type={'number'}
                    value={this.state.privateArea || ''}
                    onChange={(e) => this.setState({ privateArea: e.target.value })}
                  />&nbsp;&#13217;
                </div>
                <ValidationComponent validation={validationArticleDetail.privateArea}
                  str={(this.state.privateArea + '') + (this.state.privateUnit + '') || ''} />
              </>
            ) : (
              <>{this.state.privateArea} &nbsp;&#13217;</>
            )}
          </div>
          {/* 私道坪数 */}
          <div className={'padd_t_adjust'}>
            私道坪数
          </div>
          <div>
            {isEdit ? (
              <>
                <input
                  className={'textInput max_w_8'}
                  type={'number'}
                  value={this.state.privateUnit || ''}
                  onChange={(e) => this.setState({ privateUnit: e.target.value })}
                />
                <ValidationComponent validation={validationArticleDetail.privateUnit}
                  str={(this.state.privateArea + '') + (this.state.privateUnit + '') || ''} />
              </>
            ) : (
              <>{this.state.privateUnit}&nbsp;坪</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 坪単価 */}
          <div className={'padd_t_adjust'}>
            坪単価
          </div>
          <div>
            {isEdit ? (
              <>
                <div className={'flex_box flex_align_center'}>
                  <input
                    className={'textInput max_w_8'}
                    type={'number'}
                    value={this.state.tsuboPrice || ''}
                    onChange={(e) => this.setState({ tsuboPrice: e.target.value })}
                  />&nbsp;万円&nbsp;/&nbsp;坪
                </div>
                {
                  (Number(this.state.tsuboPrice) >= 1000)
                  ? (<ValidationComponent validation={validationArticleDetail.tsuboPriceMax} str={String(this.state.tsuboPrice || '')} />)
                  : (<ValidationComponent validation={validationArticleDetail.tsuboPrice} str={String(this.state.tsuboPrice || '')} />)}
              </>
            ) : (
              <>{this.state.tsuboPrice}万円&nbsp;/&nbsp;坪</>
            )}
          </div>
          {/* 買付証 */}
          <div className={'padd_t_adjust'}>
            買付証
          </div>
          <div>
            {isEdit ? (
              <select
                className={'textInput max_w_8'}
                value={Number(subdivisionDetail.has_purchase_card) || 0}
                onChange={(e) => {
                  subdivisionDetail.has_purchase_card = e.target.value === '1';
                  this.setState({
                    subdivisionDetail: subdivisionDetail
                  });
                }}
              >
                <option value={0}>なし</option>
                <option value={1}>あり</option>
              </select>
            ) : (
              <>{subdivisionDetail.has_purchase_card ? 'あり' : 'なし'}</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 交通 */}
          <div className={'padd_t_adjust'}>
            交通
          </div>
          <div>
            {isEdit ? (
              <>
                <div className={'flex_box flex_align_center sp_block input_3_col'}>
                  <div className={'flex_box flex_align_center basis_10rem'}>
                    <input
                      className={'textInput'}
                      value={this.state.traffic_route || ''}
                      onChange={(e) => this.setState({ traffic_route: e.target.value })}
                    />&nbsp;線
                  </div>
                  <div className={'flex_box flex_align_center basis_10rem margin_l_20'}>
                    <input
                      className={'textInput'}
                      value={this.state.traffic_station || ''}
                      onChange={(e) => this.setState({ traffic_station: e.target.value })}
                    />&nbsp;駅
                  </div>
                  <div className={'flex_box flex_align_center basis_10rem margin_l_20'}>
                    徒歩&nbsp;
                    <input
                      className={'textInput'}
                      type={'number'}
                      value={this.state.traffic_minute || ''}
                      onChange={(e) => this.setState({ traffic_minute: e.target.value })}
                    />&nbsp;分
                  </div>
                </div>
                <ValidationComponent validation={validationArticleDetail.traffic}
                  str={String(this.state.traffic_route || '') + String(this.state.traffic_station || '')} />
                {!validationArticleDetail.traffic.checkFunc(String(this.state.traffic_route || '') + String(this.state.traffic_station || '')) &&
                  !validationArticleDetail.trafficMinute.checkFunc(String(this.state.traffic_minute || ''))
                  ? (<br />) : (<></>)}
                <ValidationComponent validation={validationArticleDetail.trafficMinute}
                  str={String(this.state.traffic_minute || '')} />
              </>
            ) : (
              <>{this.state.traffic_route}線&nbsp;{this.state.traffic_station}駅&nbsp;徒歩{this.state.traffic_minute}分</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 土地権利 */}
          <div className={'padd_t_adjust'}>
            土地権利
          </div>
          <div>
            {isEdit ? (
              <select
                className={'textInput max_w_8'}
                value={this.state.landRight || 0}
                onChange={(e) => this.setState({ landRight: +e.target.value })}
              >
                <option value={''}>未選択</option>
                {SubdivisionDetailModel.landRightList.map((v) => (
                  <option value={v.value} key={v.value + '_' + v.label}>{v.label}</option>
                ))}
              </select>
            ) : (
              <>
                {SubdivisionDetailModel.landRightList.find((v) => +v.value === +(this.state.landRight ?? '')) ?
                  SubdivisionDetailModel.landRightList.find((v) => +v.value === +(this.state.landRight ?? ''))?.label : '未選択'}
              </>
            )}
          </div>
          {/* 地目 */}
          <div className={'padd_t_adjust'}>
            地目
          </div>
          <div>
            {isEdit ? (
              <select
                className={'textInput max_w_8'}
                value={this.state.ground || 0}
                onChange={(e) => this.setState({ ground: +e.target.value })}
              >
                <option value={''}>未選択</option>
                {SubdivisionDetailModel.landCategoryList.map((v) => (
                  <option value={v.value} key={v.value + '_' + v.label}>{v.label}</option>
                ))}
              </select>
            ) : (
              <>
                {SubdivisionDetailModel.landCategoryList.find((v) => +v.value === +(this.state.ground ?? '')) ?
                  SubdivisionDetailModel.landCategoryList.find((v) => +v.value === +(this.state.ground ?? ''))?.label : '未選択'}
              </>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 都市計画 */}
          <div className={'padd_t_adjust'}>
            都市計画
          </div>
          <div>
            {isEdit ? (
              <>
                <input
                  className={'textInput max_w_8'}
                  value={this.state.cityPlanning || ''}
                  onChange={(e) => this.setState({ cityPlanning: e.target.value })}
                />
                <ValidationComponent validation={validationArticleDetail.cityPlanning}
                  str={String(this.state.cityPlanning || '')} />
              </>
            ) : (
              <>{this.state.cityPlanning || ''}</>
            )}
          </div>
          {/* 用途地域 */}
          <div className={'padd_t_adjust'}>
            用途地域
          </div>
          <div>
            {isEdit ? (
              <select
                className={'textInput max_w_8'}
                value={this.state.useDistrict || 0}
                onChange={(e) => this.setState({ useDistrict: +e.target.value })}
              >
                <option value={''}>未選択</option>
                {SubdivisionDetailModel.useDistrictList.map((v) => (
                  <option value={v.value} key={v.value + '_' + v.label}>{v.label}</option>
                ))}
              </select>
            ) : (
              <>
                {SubdivisionDetailModel.useDistrictList.find((v) => +v.value === +this.state.useDistrict) ?
                  SubdivisionDetailModel.useDistrictList.find((v) => +v.value === +this.state.useDistrict)?.label : '未選択'}
              </>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 建ぺい率 */}
          <div className={'padd_t_adjust'}>
            建ぺい率
          </div>
          <div>
            {isEdit ? (
              <>
                <div className={'flex_box flex_align_center'}>
                  <input
                    className={'textInput max_w_8'}
                    type={'number'}
                    value={this.state.coverageRatioInput || ''}
                    onChange={(e) => this.setState({ coverageRatioInput: e.target.value })}
                  />&nbsp;%
                </div>
                <ValidationComponent validation={validationArticleDetail.coverageRatio}
                  str={String(this.state.coverageRatioInput || '')} />
              </>
            ) : (
              <>{this.state.coverageRatioInput}{this.state.coverageRatioInput ? ' %' : ''}</>
            )}
          </div>
          {/* 容積率 */}
          <div className={'padd_t_adjust'}>
            容積率
          </div>
          <div>
            {isEdit ? (
              <>
                <div className={'flex_box flex_align_center'}>
                  <input
                    className={'textInput max_w_8'}
                    value={this.state.floorAreaRatioInput || ''}
                    type={'number'}
                    onChange={(e) => this.setState({ floorAreaRatioInput: e.target.value })}
                  />&nbsp;%
                </div>
                <ValidationComponent validation={validationArticleDetail.floorAreaRatio}
                  str={String(this.state.floorAreaRatioInput || '')} />
              </>
            ) : (
              <>{this.state.floorAreaRatioInput}{this.state.floorAreaRatioInput ? ' %' : ''}</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 法令上の制限 */}
          <div className={'padd_t_adjust'}>
            法令上の制限
          </div>
          <div>
            {isEdit ? (
              <>
                <input
                  className={'textInput'}
                  value={legalAuthorityInput || ''}
                  onChange={(e) => this.setState({ legalAuthorityInput: e.target.value })}
                />
                <ValidationComponent validation={validationArticleDetail.legalAuthority}
                  str={String(legalAuthorityInput || '')} />
              </>
            ) : (
              <>{legalAuthorityInput}</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 現況 */}
          <div className={'padd_t_adjust'}>
            現況
          </div>
          <div>
            {isEdit ? (
              <>
                <input
                  className={'textInput'}
                  value={presentStatusInput || ''}
                  onChange={(e) => this.setState({ presentStatusInput: e.target.value })}
                />
                <ValidationComponent validation={validationArticleDetail.presentStatus}
                  str={String(presentStatusInput || '')} />
              </>
            ) : (
              <>{presentStatusInput}</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 接道状況 */}
          <div className={'padd_t_adjust'}>
            接道状況
          </div>
          <div>
            {isEdit ? (
              <>
                <input
                  className={'textInput'}
                  value={approachSituationInput || ''}
                  onChange={(e) => this.setState({ approachSituationInput: e.target.value })}
                />
                <ValidationComponent validation={validationArticleDetail.approachSituation}
                  str={String(approachSituationInput || '')} />
              </>
            ) : (
              <>{approachSituationInput}</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 設備 */}
          <div className={'padd_t_adjust'}>
            設備
          </div>
          <div>
            {isEdit ? (
              <>
                <input
                  className={'textInput'}
                  value={facilityInput || ''}
                  onChange={(e) => this.setState({ facilityInput: e.target.value })}
                />
                <ValidationComponent validation={validationArticleDetail.facility} str={String(facilityInput || '')} />
              </>
            ) : (
              <>{facilityInput}</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 備考 */}
          <div className={'padd_t_adjust'}>
            備考
          </div>
          <div>
            {isEdit ? (
              <>
                <textarea
                  className={'textInput'}
                  value={remarksInput || ''}
                  onChange={(e) => this.setState({ remarksInput: e.target.value.replace('\n', '') })}
                />
                <ValidationComponent validation={validationArticleDetail.remarks} str={String(remarksInput || '')} />
              </>
            ) : (
              <>{remarksInput}</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 営業担当者名 */}
          <div className={'padd_t_adjust'}>
            営業担当者名{isEdit ? (<>*</>) : (<></>)}
          </div>
          <div className={'flex_box flex_align_center withButton_wrap'}>
            {isEdit ? (
              <>
                <div>
                  <select
                    className={'textInput max_w_8'}
                    value={subdivisionDetail.user_id || 0}
                    onChange={async (e) => {
                      subdivisionDetail.user_id = Number(e.target.value) || null;
                      this.setState({
                        subdivisionDetail: subdivisionDetail,
                      });
                    }}
                  >
                    <option value={''}>未選択</option>
                    {this.state.userList.map((v) => (
                      <option value={v.id ?? ''} key={v.id}>{v.fullname['value']}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <button
                    className={'reset btnStyle_1'}
                    onClick={() => this.handlerClickBtnUserPicker((userId) => {
                      subdivisionDetail.user_id = Number(userId) || null;
                      this.setState({ subdivisionDetail: subdivisionDetail });
                    })}
                  >
                    <img className={'iconSearch'} src={iconSearch} alt={''} />
                    <img className={'iconSearchOn'} src={iconSearchOn} alt={''} />
                  </button>
                </div>
              </>
            ) : (
              <>
                {this.state.userList.find((v) => +(v.id ?? '') === +(subdivisionDetail.user_id ?? '')) ? (
                  this.state.userList.find((v) => +(v.id ?? '') === +(subdivisionDetail.user_id ?? ''))?.fullname['value']
                ) : (
                  ''
                )}
              </>
            )}
          </div>
          {/* 仕入れ責任者 */}
          <div className={'padd_t_adjust'}>
            仕入れ責任者{isEdit ? (<>*</>) : (<></>)}
          </div>
          <div className={'flex_box flex_align_center withButton_wrap'}>
            {isEdit ? (
              <>
                <div>
                  <select
                    className={'textInput max_w_8'}
                    value={subdivisionDetail.purchasing_manager_id || 0}
                    onChange={async (e) => {
                      subdivisionDetail.purchasing_manager_id = Number(e.target.value) || null;
                      this.setState({
                        subdivisionDetail: subdivisionDetail,
                      });
                    }}
                  >
                    <option value={''}>未選択</option>
                    {this.state.userList.map((v) => (
                      <option value={v.id ?? ''} key={v.id}>{v.fullname['value']}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <button
                    className={'reset btnStyle_1'}
                    onClick={() => this.handlerClickBtnUserPicker((userId) => {
                      subdivisionDetail.purchasing_manager_id = Number(userId) || null;
                      this.setState({ subdivisionDetail: subdivisionDetail });
                    })}
                  >
                    <img className={'iconSearch'} src={iconSearch} alt={''} />
                    <img className={'iconSearchOn'} src={iconSearchOn} alt={''} />
                  </button>
                </div>
              </>
            ) : (
              <>
                {this.state.userList.find((v) => +(v.id ?? '') === +(subdivisionDetail.purchasing_manager_id ?? '')) ? (
                  this.state.userList.find((v) => +(v.id ?? '') === +(subdivisionDetail.purchasing_manager_id ?? ''))?.fullname['value']
                ) : (
                  ''
                )}
              </>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 仕入れ年月日 */}
          <div className={'padd_t_adjust'}>
            仕入れ年月日
          </div>
          <div>
            {isEdit ? (
              <DatePickerComponent
                min={null}
                max={null}
                current={subdivisionDetail.purchase_datetime}
                onChange={(v) => {
                  subdivisionDetail.purchase_datetime = v;
                  this.setState({
                    subdivisionDetail: subdivisionDetail
                  });
                }}
              />
            ) : (
              <>{DateFormatter.date2str(subdivisionDetail.purchase_datetime, DateFormat.YYYYMMDD)}</>
            )}
          </div>
          {/* 建物建築年月日 */}
          <div className={'padd_t_adjust'}>
            建築年月日
          </div>
          <div>
            {isEdit ? (
              <DatePickerComponent
                min={null}
                max={null}
                current={subdivisionDetail.build_datetime}
                onChange={(v) => {
                  subdivisionDetail.build_datetime = v;
                  this.setState({
                    subdivisionDetail: subdivisionDetail
                  });
                }}
              />
            ) : (
              <>{DateFormatter.date2str(subdivisionDetail.build_datetime, DateFormat.YYYYMMDD)}</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 顧客名 */}
          <div className={'padd_t_adjust'}>
            顧客名
          </div>
          <div>
            {isEdit ? (
              <input
                className={'textInput'}
                value={subdivisionDetail.customer_name || ''}
                onChange={(e) => {
                  subdivisionDetail.customer_name = e.target.value;
                  this.setState({
                    subdivisionDetail: subdivisionDetail
                  });
                }}
              />
            ) : (
              <>{subdivisionDetail.customer_name}</>
            )}
          </div>
          {/* 売買締結日 */}
          <div className={'padd_t_adjust'}>
            売買締結日
          </div>
          <div>
            {isEdit ? (
              <DatePickerComponent
                min={null}
                max={null}
                current={subdivisionDetail.conclusion_datetime}
                onChange={(v) => {
                  subdivisionDetail.conclusion_datetime = v;
                  this.setState({
                    subdivisionDetail: subdivisionDetail
                  });
                }}
              />
            ) : (
              <>{DateFormatter.date2str(subdivisionDetail.conclusion_datetime, DateFormat.YYYYMMDD)}</>
            )}
          </div>
        </div>
        {/** ------------------------------------------------------------------ */}
        <div className={'flex_box sp_flex_container'}>
          {/* 中学校*/}
          <div className={'padd_t_adjust'}>
            中学校{/*{isEdit ? (<>*</>) : (<></>)}*/}
          </div>
          <div>
            {isEdit ? (
              <select
                className={'textInput max_w_8'}
                value={Number(subdivisionDetail.m_school_id) ?? 0}
                onChange={async (e) => {
                  subdivisionDetail.m_school_id = Number(e.target.value) || null;
                  this.setState({
                    subdivisionDetail: subdivisionDetail,
                  });
                }}
              >
                <option value={''}>未選択</option>
                {this.state.middleSchoolList.map((v) => (
                  <option value={v.id ?? ''} key={v.id}>{v.name}</option>
                ))}
              </select>
            ) : (
              <>
                {this.state.middleSchoolList.find((v) => +(v.id ?? '') === +(subdivisionDetail.m_school_id ?? '')) ? (
                  this.state.middleSchoolList.find((v) => v.id === subdivisionDetail.m_school_id)?.name
                ) : (
                  ''
                )}
              </>
            )}
          </div>
          {/* 小学校 */}
          <div className={'padd_t_adjust'}>
            小学校{/*{isEdit ? (<>*</>) : (<></>)}*/}
          </div>
          <div>
            {isEdit ? (
              <select
                className={'textInput max_w_8'}
                value={Number(subdivisionDetail.p_school_id) ?? 0}
                onChange={async (e) => {
                  subdivisionDetail.p_school_id = Number(e.target.value) || null;
                  this.setState({
                    subdivisionDetail: subdivisionDetail,
                  });
                }}
              >
                <option value={''}>未選択</option>
                {this.state.primarySchoolList.map((v) => (
                  <option value={v.id ?? ''} key={v.id}>{v.name}</option>
                ))}
              </select>
            ) : (
              <>
                {this.state.primarySchoolList.find((v) => +(v.id ?? '') === +(subdivisionDetail.p_school_id ?? '')) ? (
                  this.state.primarySchoolList.find((v) => v.id === subdivisionDetail.p_school_id)?.name
                ) : (
                  ''
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  }

  // private renderTableBodyIntroduced(): ReactElement<any> {
  //   // const me = this.state.me;
  //   const isNew: boolean = this.state.isNew;
  //   const isEdit: boolean = this.state.isEdit || isNew;
  //   // const isBeforeLoad: boolean = this.state.isBeforeLoad;
  //   // const isIntroduced: boolean = this.state.isIntroduced;
  //   // const article: Article = this.state.article;
  //   // const prefectureList = this.state.prefectureList;
  //   // const cityList = this.state.cityList;
  //   // const userList = this.state.userList;
  //   // const primarySchoolList = this.state.primarySchoolList;
  //   // const middleSchoolList = this.state.middleSchoolList;
  //   // const infoList: ArticleInfo[] = this.state.infoList;
  //   // const editedInfoList: ArticleInfo[] = this.state.editedInfoList;
  //   // const infoInput: string = this.state.infoInput;
  //   return (
  //     <>
  //       {/** ------------------------------------------------------------------ */}
  //       <div className={'flex_box sp_flex_container'}>
  //         {/* 都道府県 */}
  //         <div className={'padd_t_adjust'}>
  //           都道府県{isEdit ? (<>*</>) : (<></>)}
  //         </div>
  //         <div>
  //           {isEdit ? (
  //             <select
  //               className={'textInput max_w_8'}
  //               value={0}
  //               onChange={(e) => { }}
  //             >
  //               <option value={''}>未選択</option>
  //               {/*{prefectureList.map((v) => (*/}
  //               {/*  <option value={v.id} key={v.id}>{v.name}</option>*/}
  //               {/*))}*/}
  //               <option value={0}>hoge</option>
  //             </select>
  //           ) : (
  //             <>
  //               {/*{prefectureList.find((v) => +v.id === +(article.prefecture_id ?? '')) ? (*/}
  //               {/*  prefectureList.find((v) => +v.id === +(article.prefecture_id ?? ''))?.name*/}
  //               {/*) : (*/}
  //               {/*  ''*/}
  //               {/*)}*/}
  //               hoge
  //             </>
  //           )}
  //         </div>
  //         {/* 市区町村 */}
  //         <div className={'padd_t_adjust'}>
  //           市区町村{isEdit ? (<>*</>) : (<></>)}
  //         </div>
  //         <div>
  //           {isEdit ? (
  //             <select
  //               className={'textInput max_w_8'}
  //               value={0}
  //               onChange={(e) => { }}
  //             >
  //               <option value={''}>未選択</option>
  //               {/*{cityList.map((v) => (*/}
  //               {/*  <option value={v.id ?? ''} key={v.id}>{v.name}</option>*/}
  //               {/*))}*/}
  //               <option value={0}>hoge</option>
  //             </select>
  //           ) : (
  //             <>
  //               {/*{article.city}*/}
  //               hoge
  //             </>
  //           )}
  //         </div>
  //       </div>
  //       {/** ------------------------------------------------------------------ */}
  //       <div className={'flex_box sp_flex_container'}>
  //         {/* 住所 */}
  //         <div className={'padd_t_adjust'}>
  //           住所
  //         </div>
  //         <div>
  //           {isEdit ? (
  //             <input
  //               className={'textInput'}
  //               value={''}
  //               onChange={(e) => { }}
  //             />
  //           ) : (
  //             <>{/* article.address */}hoge</>
  //           )}
  //         </div>
  //       </div>
  //       {/** ------------------------------------------------------------------ */}
  //       <div className={'flex_box sp_flex_container'}>
  //         {/* 営業担当者名 */}
  //         <div className={'padd_t_adjust'}>
  //           営業担当者名{isEdit ? (<>*</>) : (<></>)}
  //         </div>
  //         <div className={'flex_box flex_align_center withButton_wrap'}>
  //           {isEdit ? (
  //             <>
  //               <div>
  //                 <select
  //                   className={'textInput max_w_8'}
  //                   value={0}
  //                   onChange={async (e) => { }}
  //                 >
  //                   <option value={''}>未選択</option>
  //                   {/*{userList.map((v) => (*/}
  //                   {/*  <option value={v.id ?? ''} key={v.id}>{v.fullname['value']}</option>*/}
  //                   {/*))}*/}
  //                   <option value={''}>hoge</option>
  //                 </select>
  //               </div>
  //               <div>
  //                 <button
  //                   className={'reset btnStyle_1'}
  //                   onClick={() => { }}
  //                 >
  //                   <img className={'iconSearch'} src={iconSearch} alt={''} />
  //                   <img className={'iconSearchOn'} src={iconSearchOn} alt={''} />
  //                 </button>
  //               </div>
  //             </>
  //           ) : (
  //             <>
  //               {/*{userList.find((v) => +(v.id ?? '') === +(article.user_id ?? '')) ? (*/}
  //               {/*  userList.find((v) => +(v.id ?? '') === +(article.user_id ?? ''))?.fullname['value']*/}
  //               {/*) : (*/}
  //               {/*  ''*/}
  //               {/*)}*/}
  //               hoge
  //             </>
  //           )}
  //         </div>
  //         {/* 坪数 */}
  //         <div className={'padd_t_adjust'}>
  //           坪数
  //         </div>
  //         <div>
  //           {isEdit ? (
  //             <input
  //               className={'textInput max_w_8'}
  //               value={''}
  //               type={'number'}
  //               onChange={(e) => { }}
  //             />
  //           ) : (
  //             <>{/* article['tsubo'] */}</>
  //           )}
  //         </div>
  //       </div>
  //       {/** ------------------------------------------------------------------ */}
  //       <div className={'flex_box sp_flex_container'}>
  //         {/* 価格 */}
  //         <div className={'padd_t_adjust'}>
  //           価格
  //         </div>
  //         <div className={'flex_box flex_align_center'}>
  //           {isEdit ? (
  //             <>
  //               <input
  //                 className={'textInput'}
  //                 value={''}
  //                 type={'number'}
  //                 onChange={(e) => { }}
  //               />&nbsp;円
  //             </>
  //           ) : (
  //             <>{/* MoneyFormatter.thousandSeparate(article['price'] as number) */}000&nbsp;円</>
  //           )}
  //         </div>
  //         {/* 面積 */}
  //         <div className={'padd_t_adjust'}>
  //           面積
  //         </div>
  //         <div>
  //           {isEdit ? (
  //             <div className={'flex_box flex_align_center'}>
  //               <input
  //                 className={'textInput max_w_8'}
  //                 value={''}
  //                 type={'number'}
  //                 onChange={(e) => { }}
  //               />&nbsp;&#13217;
  //             </div>
  //           ) : (
  //             <>{/* article.area_measuresize */}hoge &nbsp;&#13217;</>
  //           )}
  //         </div>
  //       </div>
  //       {/** ------------------------------------------------------------------ */}
  //       <div className={'flex_box sp_flex_container'}>
  //         {/* 地目 */}
  //         <div className={'padd_t_adjust'}>
  //           地目
  //         </div>
  //         <div>
  //           {isEdit ? (
  //             <select
  //               className={'textInput max_w_8'}
  //               value={0}
  //               onChange={async (e) => { }}
  //             >
  //               <option value={''}>未選択</option>
  //               {/*{this.landCategoryList.map((v) => (*/}
  //               {/*  <option value={v.value} key={v.value}>{v.label}</option>*/}
  //               {/*))}*/}
  //               <option value={''}>hoge</option>
  //             </select>
  //           ) : (
  //             <>
  //               {/*{this.landCategoryList.find((v) => +v.value === +(article['land_category'] ?? '')) ? (*/}
  //               {/*  this.landCategoryList.find((v) => +v.value === +(article['land_category'] ?? ''))?.label*/}
  //               {/*) : (*/}
  //               {/*  ''*/}
  //               {/*)}*/}
  //               hoge
  //             </>
  //           )}
  //         </div>
  //         {/* 用途地域 */}
  //         <div className={'padd_t_adjust'}>
  //           用途地域
  //         </div>
  //         <div>
  //           {isEdit ? (
  //             <select
  //               className={'textInput max_w_8'}
  //               value={0}
  //               onChange={(e) => { }}
  //             >
  //               <option value={''}>未選択</option>
  //               {/*{this.useDistrictList.map((v) => (*/}
  //               {/*  <option value={v.value} key={v.value}>{v.label}</option>*/}
  //               {/*))}*/}
  //               <option value={0}>hoge</option>
  //             </select>
  //           ) : (
  //             <>
  //               {/*{this.useDistrictList.find((v) => +v.value === +(article['use_district'] ?? '')) ? (*/}
  //               {/*  this.useDistrictList.find((v) => +v.value === +(article['use_district'] ?? ''))?.label*/}
  //               {/*) : (*/}
  //               {/*  '未選択'*/}
  //               {/*)}*/}
  //               hoge
  //             </>
  //           )}
  //         </div>
  //       </div>
  //       {/** ------------------------------------------------------------------ */}
  //       <div className={'flex_box sp_flex_container'}>
  //         {/* 中学校*/}
  //         <div className={'padd_t_adjust'}>
  //           中学校{/*{isEdit ? (<>*</>) : (<></>)}*/}
  //         </div>
  //         <div>
  //           {isEdit ? (
  //             <select
  //               className={'textInput max_w_8'}
  //               value={0}
  //               onChange={(e) => { }}
  //             >
  //               <option value={''}>未選択</option>
  //               {/*{middleSchoolList.map((v) => (*/}
  //               {/*  <option value={v.id ?? ''} key={v.id}>{v.name}</option>*/}
  //               {/*))}*/}
  //               <option value={''}>hoge</option>
  //             </select>
  //           ) : (
  //             <>
  //               {/*{middleSchoolList.find((v) => +(v.id ?? '') === +(article.m_school_id ?? '')) ? (*/}
  //               {/*  middleSchoolList.find((v) => +(v.id ?? '') === +(article.m_school_id ?? ''))?.name*/}
  //               {/*) : (*/}
  //               {/*  ''*/}
  //               {/*)}*/}
  //               hoge
  //             </>
  //           )}
  //         </div>
  //         {/* 小学校 */}
  //         <div className={'padd_t_adjust'}>
  //           小学校{/*{isEdit ? (<>*</>) : (<></>)}*/}
  //         </div>
  //         <div>
  //           {isEdit ? (
  //             <select
  //               className={'textInput max_w_8'}
  //               value={0}
  //               onChange={(e) => { }}
  //             >
  //               <option value={''}>未選択</option>
  //               {/*{primarySchoolList.map((v) => (*/}
  //               {/*  <option value={v.id ?? ''} key={v.id}>{v.name}</option>*/}
  //               {/*))}*/}
  //               <option value={''}>hoge</option>
  //             </select>
  //           ) : (
  //             <>
  //               {/*{primarySchoolList.find((v) => +(v.id ?? '') === +(article.p_school_id ?? '')) ? (*/}
  //               {/*  primarySchoolList.find((v) => +(v.id ?? '') === +(article.p_school_id ?? ''))?.name*/}
  //               {/*) : (*/}
  //               {/*  ''*/}
  //               {/*)}*/}
  //               hoge
  //             </>
  //           )}
  //         </div>
  //       </div>
  //       {/** ------------------------------------------------------------------ */}
  //       <div className={'flex_box sp_flex_container'}>
  //         {/* 有効/無効 */}
  //         <div className={'padd_t_adjust'}>
  //           有効/無効
  //         </div>
  //         <div>
  //           {isEdit ? (
  //             <div
  //               onClick={() => { }}
  //             >
  //               {/*{+(article['valid_flag'] ?? '') ? (*/}
  //               {/*  <div className={'innerImg checked'}><img src={iconCheckBoxChecked} alt={''} /></div>*/}
  //               {/*) : (*/}
  //               {/*  <div className={'innerImg noCheck'}><img src={iconCheckBox} alt={''} /></div>*/}
  //               {/*)}*/}
  //               <div className={'innerImg noCheck'}><img src={iconCheckBox} alt={''} /></div>
  //             </div>
  //           ) : (
  //             <>
  //               {/*{+(article['valid_flag'] ?? '') ? (*/}
  //               {/*  <div className={'innerImg checked'}><img src={iconCheckBoxChecked} alt={''} /></div>*/}
  //               {/*) : (*/}
  //               {/*  <div className={'innerImg noCheck'}><img src={iconCheckBox} alt={''} /></div>*/}
  //               {/*)}*/}
  //               <div className={'innerImg noCheck'}><img src={iconCheckBox} alt={''} /></div>
  //             </>
  //           )}
  //         </div>
  //       </div>
  //     </>
  //   );
  // }

  private handlerClickBtnQuestionnaire(): void {

    ModalService.ins.display(
      <ModalQuestionnaireComponent subdivision={this.state.subdivisionDetail} />
    );
  }

  async componentDidMount(): Promise<void> {
    window.scrollTo(0, 0);
    await UserService.ins.getMe()
      .then((v) => {
        this.setState({ me: v });
      })
      .catch((v) => {
        this.setState({ me: v });
      });
    const id = parseInt((this.props as any)['match']['params']['id'], 10);
    const isNew = id === 0;
    // this.getInfoList(this.state.id);
    if (isNew) {
      this.setState({
        id: id,
        isNew: true,
      });
      DataManagerService.ins.getPrefectureList()
        .then((v) => this.setState({ prefectureList: v, isBeforeLoad: false }))
        .catch((v) => this.setState({ prefectureList: v, isBeforeLoad: false }));
      DataManagerService.ins.getUserList()
        .then((v) => this.setState({ userList: v, isBeforeLoad: false }))
        .catch((v) => this.setState({ userList: v, isBeforeLoad: false }));
      DataManagerService.ins.getSubdivisionList()
        .then((v) => this.setState({ subdivisionList: v, isBeforeLoad: false }))
        .catch((v) => this.setState({ subdivisionList: v, isBeforeLoad: false }));
    } else {
      this.getDetail(id);
    }
  }

  private handlerClickBtnSubdivisionPicker(callback: (subdivisionId: number | null) => void): void {
    ModalService.ins.display(
      <ModalSubdivisionPickerComponent callback={callback} />
    );
  }


  private handlerClickBtnUserPicker(callback: (userId: number) => void): void {
    ModalService.ins.display(
      <ModalUserPickerComponent callback={callback} />
    );
  }

  private async handlerClickBtnEdit() {
    const subdivision = this.state.subdivisionDetail;
    this.defaultSubdivisionDetailData = Object.assign({}, subdivision);
    this.loadedFileAndUrlList = await this.createLoadedFileAndUrlList();
    this.setState({
      isEdit: true
    });
  }

  private async handlerClickBtnCancel(): Promise<void> {
    this.setState({ editedInfoList: [], infoInput: '' });
    if (this.state.isNew) {
      (this.props as any)['history'].goBack();
      return;
    }
    this.setState({
      isEdit: false,
      subdivisionDetail: this.defaultSubdivisionDetailData,
      cityList: await DataManagerService.ins.getCityList(this.defaultSubdivisionDetailData.prefecture_id || null),
    });
    this.getDetail(this.state.id as number);
  }

  private handlerClickBtnSave(): void {
    const isValidSuccess: boolean = document.getElementsByClassName(ElementId.VALID_ERROR_CLASS).length === 0;
    if (!isValidSuccess) {
      ModalService.ins.push(
        <ModalAlertComponent
          msgList={['正しく入力されていない項目があります。']}
          callback={() => {
            ModalService.ins.pop();
          }}
        />
      );
      return;
    }
    const isIntroduced: boolean = window.location.hash.match(RoutingPass.ARTICLE_DETAIL_INTRODUCED) !== null;
    const subdivisionDetail = this.state.subdivisionDetail;
    const isNew = this.state.isNew;
    const {
      route,
      station,
      privateArea,
      privateUnit,
      tsuboPrice,
      traffic_route,
      traffic_station,
      traffic_minute,
      landRight,
      ground,
      cityPlanning,
      useDistrict,
      coverageRatioInput,
      floorAreaRatioInput,
      legalAuthorityInput,
      presentStatusInput,
      approachSituationInput,
      facilityInput,
      remarksInput,
    } = this.state;
    const request: RequestBase =
      new RequestSubdivisionsPost({
        id: subdivisionDetail.id,
        name: subdivisionDetail.name,
        sales_status: null,
        type: subdivisionDetail.type,
        prefecture_id: subdivisionDetail.prefecture_id,
        city_id: subdivisionDetail.city_id,
        address: subdivisionDetail.address,
        valid_flag: subdivisionDetail.valid_flag,
        tsubo: subdivisionDetail.tsubo,
        price: subdivisionDetail.price,
        area_measuresize: subdivisionDetail.area_measuresize,
        purchasing_manager_id: subdivisionDetail.purchasing_manager_id,
        user_id: subdivisionDetail.user_id,
        build_datetime: DateFormatter.date2str(subdivisionDetail.build_datetime, DateFormat.YYYYMMDD, '-'),
        purchase_datetime: DateFormatter.date2str(subdivisionDetail.purchase_datetime, DateFormat.YYYYMMDD, '-'),
        has_purchase_card: subdivisionDetail.has_purchase_card ? 1 : 0,
        customer_name: subdivisionDetail.customer_name,
        conclusion_datetime: DateFormatter.date2str(subdivisionDetail.conclusion_datetime, DateFormat.YYYYMMDD, '-'),
        pschool_id: subdivisionDetail.p_school_id,
        mschool_id: subdivisionDetail.m_school_id,
        //
        route1: route,
        station1: station,
        route2: traffic_route,
        station2: traffic_station,
        walk_time: +traffic_minute,
        private_road_measuresize: +privateArea,
        private_road_tsubo: +privateUnit,
        tsubo_unit: +tsuboPrice,
        ownership: landRight, //enum
        land_category: ground, //enum
        city_planning: cityPlanning,
        use_district: useDistrict, //enum
        coverage: +coverageRatioInput,
        floor_area: +floorAreaRatioInput,
        law_limitation: legalAuthorityInput,
        current_state: presentStatusInput,
        connecting_roads: approachSituationInput,
        equipment: facilityInput,
        remarks: remarksInput,
        area_max: subdivisionDetail.area_max,
        itemcontent: subdivisionDetail.itemcontent
      });

    const editedInfoList: ArticleInfo[] = this.state.infoList;
    if (!isIntroduced && +(subdivisionDetail.sales_status ?? '') === 6) {
      ModalService.ins.push(
        <ModalDialogComponent
          msgList={['この物件の予約情報がある場合、全て削除されます。', 'よろしいですか？']}
          callbackOk={() => {
            ModalService.ins.close();
            ConnectionService.ins.connect(request)
              .then((res) => {
                Promise.all([
                  ...this.loadedFileAndUrlList.map((v, i) => this.uploadLinkedFile(isNew ? res.data.id : subdivisionDetail.id, i + 1 as any, v.file)),
                  ...[...Array(10 - this.loadedFileAndUrlList.length)].fill('').map((v, i) => this.uploadLinkedFile(isNew ? res.data.id : subdivisionDetail.id, (i + 1 + this.loadedFileAndUrlList.length) as any, '')),
                  this.uploadLinkedFile(isNew ? res.data.id : subdivisionDetail.id, 11, this.loadedFileList[10] as File),
                  this.uploadLinkedFile(isNew ? res.data.id : subdivisionDetail.id, 12, this.loadedFileList[11] as File),
                ])
                  .then(() => {
                    ModalService.ins.push(
                      <ModalAlertComponent
                        msgList={['保存しました。']}
                        callback={() => {
                          // @ts-ignore
                          ModalService.ins.pop();
                          this.handlerClickBtnCancel();
                        }}
                      />
                    );
                  })
                  .catch((err) => {
                    ErrorService.ins.response(err);
                  });
              })
              .catch((err) => {
                ErrorService.ins.response(err);
              });
            this.setState({
              isEdit: true
            });
          }}
          callbackNg={() => {
            ModalService.ins.close();
          }} />
      );
    } else {
      ConnectionService.ins.connect(request)
        .then((res) => {
          Promise.all([
            ...this.loadedFileAndUrlList.map((v, i) => this.uploadLinkedFile(isNew ? res.data.id : subdivisionDetail.id, i + 1 as any, v.file)),
            ...[...Array(10 - this.loadedFileAndUrlList.length)].fill('').map((v, i) => this.uploadLinkedFile(isNew ? res.data.id : subdivisionDetail.id, (i + 1 + this.loadedFileAndUrlList.length) as any, '')),
            this.uploadLinkedFile(isNew ? res.data.id : subdivisionDetail.id, 11, this.loadedFileList[10] as File),
            this.uploadLinkedFile(isNew ? res.data.id : subdivisionDetail.id, 12, this.loadedFileList[11] as File),
          ])
            .then(() => {
              ModalService.ins.push(
                <ModalAlertComponent
                  msgList={['保存しました。']}
                  callback={() => {
                    ModalService.ins.pop();
                    this.handlerClickBtnCancel();
                  }}
                />
              );
            })
            .catch((err) => {
              ErrorService.ins.response(err);
            });
        })
        .catch((err) => {
          ErrorService.ins.response(err);
        });
      this.setState({
        isEdit: true
      });
    }
  }

  private getDetail(id: number): Promise<void> {
    return new Promise((resolve: () => void, reject: () => void) => {
      const request: RequestBase = new RequestSubdivisionDetailGet(id);
      ConnectionService.ins.connect(request)
        .then(async (res) => {
          const subdivisionDetail = new SubdivisionDetail({
            id: res.data.id,
            name: res.data.name,
            sales_status: res.data.sales_status,
            reservation_flag: res.data.reservation_flag,
            type: res.data.type,
            building_name: res.data.building_name,
            tsubo: res.data.tsubo,
            price: res.data.price,
            prefecture_id: res.data.prefecture_id,
            city: res.data['city']['name'],
            city_id: res.data.city_id,
            address: res.data.address,
            area_measuresize: res.data.area_measuresize,
            purchasing_manager_id: res.data.purchasing_manager_id,
            user_id: res.data.user_id,
            build_datetime: DateFormatter.str2date(res.data.build_datetime ? res.data.build_datetime + '' : ''),
            purchase_datetime: DateFormatter.str2date(res.data.purchase_datetime ? res.data.purchase_datetime + '' : ''),
            has_purchase_card: Boolean(+res.data.has_purchase_card),
            customer_name: res.data.customer_name,
            conclusion_datetime: DateFormatter.str2date(res.data.conclusion_datetime ? res.data.conclusion_datetime + '' : ''),
            m_school_id: res.data.mschool_id,
            p_school_id: res.data.pschool_id,
            subdivision_id: res.data.subdivision_id,
            period_start: res.data.period_start,
            period_end: res.data.period_end,
            message: res.data.message,
            email: res.data.email,
            reserve_count: res.data.reserve_count,
            area_count: res.data.area_count,
            area_max: res.data.area_max,
            remarks: res.data.remarks,
            land_category: res.data.land_category,
            use_district: res.data.use_district,
            valid_flag: res.data.valid_flag,
            subdivision_upper_limit_flag: res.data.subdivision_upper_limit_flag,
            itemcontent: res.data.itemcontent,
          });
          const fileRequest = new RequestSubdivisionsLinkedFileGet(id);
          ConnectionService.ins.connect(fileRequest)
            .then((fileResponse) => {
              this.getInfoList(id);
              this.setState({
                id: id,
                isNew: false,
                isBeforeLoad: false,
                subdivisionDetail,
                route: res.data.route1,
                station: res.data.station1,
                traffic_route: res.data.route2,
                traffic_station: res.data.station2,
                traffic_minute: res.data.walk_time,
                privateArea: res.data.private_road_measuresize,
                privateUnit: res.data.private_road_tsubo,
                tsuboPrice: res.data.tsubo_unit,
                landRight: res.data.ownership || 0,
                ground: res.data.land_category || 0,
                cityPlanning: res.data.city_planning,
                useDistrict: res.data.use_district || 0,
                coverageRatioInput: res.data.coverage,
                floorAreaRatioInput: res.data.floor_area,
                legalAuthorityInput: res.data.law_limitation,
                presentStatusInput: res.data.current_state,
                approachSituationInput: res.data.connecting_roads,
                facilityInput: res.data.equipment,
                remarksInput: res.data.remarks,
                imageList: fileResponse.data.list,
              });
              this.refreshList(subdivisionDetail);
              resolve();
            })
            .catch((err) => {
              ErrorService.ins.response(err);
              reject();
            });
        })
        .catch((err) => {
          ErrorService.ins.response(err);
          reject();
        });
    });
  }

  private refreshList(subdivisionDetail: SubdivisionDetail): void {
    DataManagerService.ins.getUserList()
      .then((v) => {
        this.setState({ userList: v });
      })
      .catch((v) => {
        this.setState({ userList: v });
      });
    DataManagerService.ins.getPrefectureList()
      .then((v) => {
        this.setState({ prefectureList: v });
        DataManagerService.ins.getCityList(subdivisionDetail.prefecture_id)
          .then((v) => {
            this.setState({ cityList: v });
          })
          .catch((v) => {
            this.setState({ cityList: v });
          });
      })
      .catch((v) => {
        this.setState({ prefectureList: v });
      });
    DataManagerService.ins.getMiddleSchoolList(subdivisionDetail.prefecture_id, subdivisionDetail.city_id)
      .then((v) => {
        this.setState({ middleSchoolList: v });
      })
      .catch((v) => {
        this.setState({ middleSchoolList: v });
      });
    DataManagerService.ins.getPrimarySchoolList(subdivisionDetail.prefecture_id, subdivisionDetail.city_id)
      .then((v) => {
        this.setState({ primarySchoolList: v });
      })
      .catch((v) => {
        this.setState({ primarySchoolList: v });
      });
    DataManagerService.ins.getSubdivisionList()
      .then((v) => {
        this.setState({ subdivisionList: v });
      })
      .catch((v) => {
        this.setState({ subdivisionList: v });
      });
  }

  private getInfoList(articleId: number | null, saleseMonth: number = this.state.sales_month): void {
    const isIntroduced: boolean = window.location.hash.match(RoutingPass.ARTICLE_DETAIL_INTRODUCED) !== null;
    const list: ArticleInfo[] = [];
    if (!articleId) {
      this.setState({ infoList: list });
      return;
    }
    const request: RequestArticleinformationsGetSearch = new RequestArticleinformationsGetSearch(
      '',
      100,
      1,
      1,
      1,
      isIntroduced ? null : articleId,
      isIntroduced ? articleId : null,
      saleseMonth
    );
    ConnectionService.ins.connect(request)
      .then((res) => {
        this.setState({
          infoList: res.data.list,
          editedInfoList: [],
          infoInput: res.data.list[0] ? res.data.list[0].item_content : ''
        });
      })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }

  /**
   * pdf 開くボタンのハンドラ
   * @param {string} src
   */
  private handlerClickBtnOpenPdf(src: string): void {
    ConnectionService.ins.getFileFromUrl(src)
      .then((v) => {
        window.open(URL.createObjectURL(v));
      });
  }

  /**
   * 現状写真とかのURL生成処理
   * @param {1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 11 | 12} index
   * @param {ArticleLinkedFile[]} linkedFileList
   * @return {string}
   */
  private getLinkedFileUrl(index: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12, linkedFileList: any[]): string {
    const origin: string = window.location.origin === 'http://localhost:3000' ? 'https://rms.marietta.co.jp' : window.location.origin;
    const dir: string = '/contents/';
    const fileName: string = linkedFileList.find((v) => +v.subdivision_file_type === index) ?
      linkedFileList.find((v) => +v.subdivision_file_type === index)?.saved_as || '' : '';
    return fileName ? `${origin}${dir}${fileName}` : '';
  }

  /**
   * ファイルアップロード
   * @param {number} articleId
   * @param {1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 11 | 12} index
   * @param {File} file
   */
  private async uploadLinkedFile(subdivisionId: number, index: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12, file: File | ''): Promise<void> {
    if (file === null) {
      return;
    }
    const request = new RequestSubdivisionFilesUpload(
      subdivisionId,
      index,
      file
    );
    await ConnectionService.ins.connect(request)
      .then((res) => { })
      .catch((err) => {
        ErrorService.ins.response(err);
      });
  }

  private convertFile2Base64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const fr: FileReader = new FileReader();
      fr.onload = (e) => {
        if (e.target?.result && typeof e.target.result === 'string') {
          resolve(e.target.result);
        }
      };
      fr.readAsDataURL(file);
    });
  }
  //
  private async createLoadedFileAndUrlList() {
    const imgUrlList: string[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(v => this.getLinkedFileUrl(v as any, this.state.imageList)).filter((v) => !!v);
    const loadedFileAndUrlList: { file: File, url: string; }[] = [];
    for (let i = 0; i < imgUrlList.length; i++) {
      loadedFileAndUrlList.push({
        file: await this.convertUrl2File(imgUrlList[i]),
        url: imgUrlList[i],
      });
    }
    return loadedFileAndUrlList;
  }
  //
  private async convertUrl2File(url: string): Promise<File> {
    return fetch(url)
      .then(res => res.blob())
      .then(blob => new File([blob], url.split('/')[url.split('/').length - 1]));
  }
}

const mapDispatchToProps: DispatchToProps = (dispatch) => ({
  dispatch: (v: any) => dispatch(v)
});

export const SubdivisionDetailComponent = connect(() => ({}), mapDispatchToProps)(SubdivisionDetailBodyComponent);