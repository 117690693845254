import { HTTPMethod, RequestBase } from './request-base';


export class RequestArticleinformationsPost extends RequestBase {

  param: {
    id: number | null,
    article_id: number | null,
    article_introduced_id: number | null,
    article_flag: number | null,
    item_content: string,
  };

  constructor(
    id: number | null,
    article_id: number | null,
    article_introduced_id: number | null,
    article_flag: number,
    item_content: string,
  ) {
    super(HTTPMethod.POST, '/api/articleinformations');
    this.param = {
      id: id,
      article_id: article_id,
      article_introduced_id: article_introduced_id,
      article_flag: article_flag,
      item_content: item_content,
    };
  }


  getUrl(): string {
    return this.host + this.endPoint;
  }

}
